import { Layout as AntLayout } from "antd";
import styled from "styled-components";

export const Layout = styled(AntLayout)`
  height: 100%;
  background-color: var(--velotax-background-color-ghost);
  &.showIrpfBanner {
    height: calc(100% - 80px);
  }
  & > .content {
    height: 100%;
    &.overflow {
      overflow-y: auto;
    }
  }

  .banner {
    padding: 8px 0;
    display: flex;
    justify-content: center;
    background-color: var(--ant-primary-6);
    color: black;
    font-weight: 600;
  }

  @media only screen and (max-device-width: 812px) {
    & > .content.tab-navigator {
      height: calc(100% - 64px - 64px);
    }
  }
`;
