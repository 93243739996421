import React, { createContext, useContext, useEffect, useRef, useState, useCallback } from 'react';
import { io, Socket } from 'socket.io-client';
import { useAuth } from './AuthContext';
import { message } from 'antd';

export enum WSClientEvents {
  INTEGRATION_STATUS = "integration_status",
  NOTIFY = "notify",
}

export const isValidEvent = (event: string): event is WSClientEvents => {
  return Object.values(WSClientEvents).includes(event as WSClientEvents);
};

type CallbackFunction<T = any> = (data: T) => void;

interface WebSocketContextType {
  addEventListener: <T>(
    event: WSClientEvents,
    callback: CallbackFunction<T>
  ) => void;
  removeEventListener: (
    event: WSClientEvents,
    callback: CallbackFunction
  ) => void;
  emitEvent: (event: WSClientEvents, data?: any) => void;
  open: boolean;
}

const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);
const PREFIX = "[VELOTAX-WS]:"

export const WebSocketProvider: React.FC = ({ children }) => {
  const { user } = useAuth();
  const socketRef = useRef<Socket | null>(null);
  const [open, setOpen] = useState(false);

  const handleMessage = useCallback((data: any) => {
    const { message: messageWs, type } = data;
    if (typeof messageWs === "string") {
      switch (type) {
        case "error":
          message.error(messageWs);
          break;
        case "info":
          message.info(messageWs);
          break;
        case "success":
          message.success(messageWs);
          break;
        default:
          console.error("[VELOTAX-WS]:", messageWs);
          break;
      }
    } else {
      console.error("[VELOTAX-WS]:", messageWs);
    }
  }, []);

  useEffect(() => {
    const socketUrl = process.env.REACT_APP_MS_NOTIFICATION_URL ?? "http://localhost:3333";

    if (user?.token) {
      socketRef.current = io(socketUrl, {
        reconnection: true,
        reconnectionDelay: 30000,
        reconnectionAttempts: 5,
        auth: {
          token: user.token,
        },
      });

      socketRef.current.on('connect', () => {
        socketRef.current?.on('notify', handleMessage);
        console.log(PREFIX, "Conectado")
        setOpen(true);
      });

      socketRef.current.on('disconnect', () => {
        console.log(PREFIX, "Desconectado")
        setOpen(false);
      });

      return () => {
        socketRef.current?.disconnect();
      };
    }
  }, [user?.token, handleMessage]);

  const addEventListener = useCallback(<T,>(event: WSClientEvents, callback: CallbackFunction<T>) => {
    if (socketRef.current && isValidEvent(event)) {
      console.log(PREFIX, `Starting event [${event}]`);
      socketRef.current.on(event, callback);
    }
  }, []);

  const removeEventListener = useCallback((event: WSClientEvents, callback: CallbackFunction) => {
    if (socketRef.current && isValidEvent(event)) {
      console.log(PREFIX, `Closing event [${event}]`);
      socketRef.current.off(event, callback);
    }
  }, []);

  const emitEvent = useCallback((event: WSClientEvents, data?: any) => {
    if (socketRef.current && isValidEvent(event)) {
      console.log(PREFIX, "Emit ev ->", event)
      socketRef.current.emit(event, data);
    }
  }, []);

  const value = {
    addEventListener,
    removeEventListener,
    emitEvent,
    open,
  };

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};
