import { AvisosContainer } from "./styles";
import { useEffect, useState } from "react";
import { Button, Spin, Typography, message } from "antd";
import { FiArrowRight } from "react-icons/fi";
import { AiOutlineLink } from "react-icons/ai";
import { BiX } from "react-icons/bi";
import api from "../../../services/api";
import { useAuth } from "../../../contexts/AuthContext";
import { IntegrationModal } from "../IntegrationModal";
import { useBroker } from "../../../contexts/BrokerContext";

export const Avisos = () => {
  const { user } = useAuth();
  const [dismissBolsa, setDismissBolsa] = useState(false);
  const [dismissExterior, setDismissExterior] = useState(false);
  const [loading, setLoading] = useState(false);
  const [integrationModal, setIntegrationModal] = useState<
    "B3" | "XP International" | null
  >(null);
  const { integration } = useBroker();

  const getCloseBanner = async () => {
    try {
      setLoading(true);
      const { data } = await api.post("user/xp/close-banner", {
        cpf: user?.user?.cpf,
      });
      setDismissBolsa(data.closeBannerBolsaXp);
      setDismissExterior(data.closeBannerExteriorXp);
    } catch (error) {
      message.error("Algo deu errado, tente mais tarde!");
    } finally {
      setLoading(false);
    }
  };

  const handleDismissBolsa = async () => {
    try {
      setLoading(true);
      setDismissBolsa(true);
      const { data } = await api.put("user/xp/close-banner", {
        cpf: user?.user?.cpf,
        closeBannerBolsaXp: true,
      });
      setDismissBolsa(data.closeBannerBolsaXp);
    } catch (error) {
      message.error("Algo deu errado, tente mais tarde!");
    } finally {
      setLoading(false);
    }
  };

  const handleDismissExterior = async () => {
    try {
      setLoading(true);
      setDismissExterior(true);
      const { data } = await api.put("user/xp/close-banner", {
        cpf: user?.user?.cpf,
        closeBannerExteriorXp: true,
      });
      setDismissExterior(data.closeBannerExteriorXp);
    } catch (error) {
      message.error("Algo deu errado, tente mais tarde!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCloseBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AvisosContainer>
      <div>
        <Spin spinning={loading}>
          {(!integration.integrated && !dismissBolsa) && (
            <div className="client-integration-card">
              <AiOutlineLink />
              <div className="client-integration-card-content">
                <Typography.Title level={3}>
                  Integração com bolsa pendente
                </Typography.Title>
                <Typography.Paragraph>
                  Conceda a permissão na B3 para que seja possível acessar suas
                  operações da bolsa de valores de forma automática.
                </Typography.Paragraph>
                <Button
                  type="link"
                  onClick={() => {
                    setIntegrationModal("B3");
                  }}
                >
                  Saiba mais
                  <FiArrowRight />
                </Button>
              </div>
              <BiX
                className="dismiss"
                onClick={() => {
                  handleDismissBolsa();
                }}
              />
            </div>
          )}
          {(!user.user.isIntegratedExterior && !dismissExterior) && (
            <div className="client-integration-card">
              <AiOutlineLink />
              <div className="client-integration-card-content">
                <Typography.Title level={3}>
                  Conecte com a XP International
                </Typography.Title>
                <Typography.Paragraph>
                  Conceda a permissão na XP International para que seja possível
                  acessar suas operações no exterior de forma automática.
                </Typography.Paragraph>
                <Button
                  type="link"
                  onClick={() => {
                    setIntegrationModal("XP International");
                  }}
                >
                  Saiba mais
                  <FiArrowRight />
                </Button>
              </div>
              <BiX
                className="dismiss"
                onClick={() => {
                  handleDismissExterior();
                }}
              />
            </div>
          )}
        </Spin>
      </div>
      <IntegrationModal
        integrationModal={integrationModal}
        setIntegrationModal={setIntegrationModal}
      />
    </AvisosContainer>
  );
};
