import { Button } from "@mui/material";
import { Col, Divider, Row, Typography } from "antd";
import { Content } from "./styles";
import { DrawerModal } from "../DrawerModal";
import { useRequireAccess } from "../../contexts/RequireAccessContext";

interface IPermissaoAssessorModal {
  visible: boolean;
  dataContent?: any;
  onCancel?: () => void;
  onOk: ({ token }: { token: string }) => void;
}

const PermissaoAssessorModal: React.FC<IPermissaoAssessorModal> = ({
  onOk,
  visible,
  onCancel,
  dataContent,
}) => {
  const { code } = dataContent;
  const { changePermission } = useRequireAccess();

  const handleCancel = () => {
    changePermission(code.token, false, true);
    if (onCancel) onCancel();
  };

  const handleOk = () => {
    changePermission(code.token, true, true);
    if (onOk) onOk(code.token);
  };

  return (
    <DrawerModal
      footer={null}
      height="auto"
      visible={visible}
      onCancel={handleCancel}
      title="Solicitação de acesso"
    >
      <Content>
        <Typography.Paragraph>
          O {code?.escritorio ? "escritório de assessoria" : "assessor"} de
          investimentos{" "}
          <strong>
            {code?.escritorio ? code?.escritorio?.name : code?.assessor?.name}{" "}
          </strong>
          solicitou o acesso à sua conta na XP by Velotax.
        </Typography.Paragraph>
        <Divider />
        <Row gutter={[16, 16]} className="column-reverse">
          <Col xs={24} sm={12}>
            <Button
              fullWidth
              size="large"
              color="info"
              variant="contained"
              onClick={handleCancel}
            >
              Não permitir
            </Button>
          </Col>
          <Col xs={24} sm={12}>
            <Button
              fullWidth
              size="large"
              color="secondary"
              onClick={handleOk}
              variant="contained"
            >
              Permitir acesso
            </Button>
          </Col>
        </Row>
      </Content>
    </DrawerModal>
  );
};

export default PermissaoAssessorModal;
