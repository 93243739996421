import { Col, Row } from "antd";
import PassfolioIntegration from "../PassfolioIntegration";

interface IExteriorIntegrationProps {
  id?: string;
  data?: any;
  dependentId?: string;
  setData?: ({ id, value, customData, from }: any, patch?: any) => void;
  customActions?: { [id: string]: (data: any) => void };
}

export type IntegrationStatus = "carregando" | "integrado" | "erro";

const ExteriorIntegration = (props: IExteriorIntegrationProps) => {
  const { id, dependentId, setData } = props;

  const handleIntegration = (type: IntegrationStatus) => {
    setData?.({ id, value: type === "carregando" }, false);
  };

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={8}>
        <PassfolioIntegration
          dependentId={dependentId}
          integrationCallback={handleIntegration}
        />
      </Col>
      {/* <Col xs={24} sm={8}>
        <NomadIntegration integrationCallback={handleIntegration} />
      </Col> */}
    </Row>
  );
};

export default ExteriorIntegration;
