import moment from "moment";
import { Button } from "@mui/material";
import { Table, Typography } from "antd";
import { AiOutlineDownload } from "react-icons/ai";
import { Container } from "./styles";
import { formatCurrency, isMobile } from "../../utils";
import { handleDownloadSheet } from "../../utils/handleDownloadSheet";
import { sendEmailSheet } from "../../utils/sendEmailSheet";
import { useAuth } from "../../contexts/AuthContext";

export const DividendosModal = ({ item }: any) => {
  const { user } = useAuth();
  return (
    <Container>
      {item?.operations?.length > 0 && (
        <Button
          color="info"
          variant="contained"
          startIcon={<AiOutlineDownload />}
          onClick={() => {
            if (isMobile()) 
            {
              sendEmailSheet('bolsa', item?.operations, '', user, "Dividendos");
            }
            else {
              handleDownloadSheet(item?.buffer?.data, "Dividendos")
            }
          }}
        >
          Baixar planilha
        </Button>
      )}
      <Table
        pagination={false}
        dataSource={item?.operations}
        locale={{ emptyText: "Nenhum dividendo recebido neste mês" }}
        columns={[
          {
            align: "left",
            title: "Ativo",
            dataIndex: "ticker",
            render: (value) => (
              <strong style={{ fontWeight: "500" }}>{value}</strong>
            ),
          },
          {
            align: "left",
            title: "Data",
            dataIndex: "referenceDate",
            render: (value) => moment(value).format("DD/MM/YYYY"),
          },
          {
            align: "left",
            dataIndex: "tipo",
            title: "Tipo de rendimento",
          },
          {
            align: "left",
            title: "Valor",
            width: "150px",
            dataIndex: "valor",
            render: (value) => formatCurrency(value ?? 0, "R$ "),
          },
        ]}
      />
      <div className="mobile-list">
        {item?.operations?.map((op: any, index: number) => (
          <div className="list-item" key={index}>
            <p>{op.ticker}</p>
            <span className="list-item-label">Tipo:</span>
            <span className="list-item-value">{op.tipo}</span>
            <span className="list-item-label">Data:</span>
            <span className="list-item-value">
              {moment(op.referenceDate).format("DD/MM/YYYY")}
            </span>
            <span className="list-item-label">Valor:</span>
            <span className="list-item-value">
              {formatCurrency(op.valor ?? 0, "R$ ")}
            </span>
          </div>
        ))}
        {!item?.operations?.length && (
          <div className="list-empty">Nenhum dividendo recebido neste mês</div>
        )}
      </div>
      {item?.operations?.length > 0 && (
        <div className="table-total">
          <Typography.Title level={3}>Total recebido no mês:</Typography.Title>
          <Typography.Text>{formatCurrency(item.value)}</Typography.Text>
        </div>
      )}
    </Container>
  );
};
