import { Typography, Image, message } from "antd";
import { DrawerModal } from "../DrawerModal";
import { B3Link, CopyLink, Instructions } from "./styles";
import b3IntegrationExample from "../../../assets/Integracao_XP_B3.gif";
import xpIntegrationExample from "../../../assets/integracao_xp_exterior_fundo_branco.gif";

export type TIntegrationModal = "B3" | "XP International" | null;
interface IProps {
  integrationModal: TIntegrationModal;
  setIntegrationModal: React.Dispatch<React.SetStateAction<TIntegrationModal>>;
}

const b3Link =
  "https://b3investidor.b2clogin.com/b3Investidor.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_FINTECH&client_id=069ecfdf-7471-48eb-be82-d50799c99e5c&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fwww.investidor.b3.com.br&scope=openid&response_type=code&prompt=login";
// const b3Encoded =
//   "https%3A%2F%2Fb3investidor.b2clogin.com%2Fb3Investidor.onmicrosoft.com%2Foauth2%2Fv2.0%2Fauthorize%3Fp%3DB2C_1A_FINTECH%26client_id%3D069ecfdf-7471-48eb-be82-d50799c99e5c%26nonce%3DdefaultNonce%26redirect_uri%3Dhttps%253A%252F%252Fwww.investidor.b3.com.br%26scope%3Dopenid%26response_type%3Dcode%26prompt%3Dlogin";

export const IntegrationModal = ({
  integrationModal,
  setIntegrationModal,
}: IProps) => {
  return (
    <DrawerModal
      width={580}
      footer={null}
      visible={!!integrationModal}
      title={`Conecte com a ${integrationModal}`}
      onCancel={() => {
        setIntegrationModal(null);
      }}
    >
      {integrationModal === "B3" ? (
        <Typography.Text>
          Permita a conexão entre Velotax e B3 para leitura das operações
          realizadas na bolsa de valores.
        </Typography.Text>
      ) : (
        <Typography.Text>
          Permita a conexão entre Velotax e XP International para leitura das
          operações realizadas.
        </Typography.Text>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          margin: "2rem 0",
        }}
      >
        <Image
          width={430}
          src={
            integrationModal === "B3"
              ? b3IntegrationExample
              : xpIntegrationExample
          }
        />
      </div>

      {integrationModal === "B3" ? (
        <>
          <B3Link
            style={{ fontSize: "14px" }}
            href={b3Link}
            target="_blank"
            rel="noreferrer"
          >
            {b3Link}
          </B3Link>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              margin: "1rem 0",
            }}
          >
            <CopyLink
              onClick={() => {
                navigator.clipboard.writeText(b3Link);
                message.success("Link copiado");
              }}
            >
              Copiar link
            </CopyLink>
          </div>
        </>
      ) : (
        <>
          <Typography.Title
            level={5}
            style={{ color: "#FFF", fontSize: "13px" }}
          >
            Instruções
          </Typography.Title>
          <div>
            <Instructions>
              <strong>Passo 1</strong>. Acesse a aba de{" "}
              <strong>Investimento Global</strong> dentro do aplicativo da XP
            </Instructions>
            <Instructions>
              <strong>Passo 2</strong>. Deslize a tela para baixo e clique em{" "}
              <strong>Imposto de Renda e DARF</strong>
            </Instructions>
            <Instructions>
              <strong>Passo 3</strong>. Clique em{" "}
              <strong>Calcular Imposto de Renda</strong> e aceite os termos
            </Instructions>
          </div>
        </>
      )}
    </DrawerModal>
  );
};
