import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  .progress_container {
    width: 100%;
    background-color: var(--velotax-ghost-white);
    height: 15px;
    position: relative;
    border-radius: 50px;
  }

  .loading_bar {
    height: 100%;
    background-color: var(--ant-primary-color);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    display: flex; /* Para que os elementos dentro da barra de progresso se alinhem corretamente */
    align-items: center; /* Para centralizar verticalmente o texto */
    justify-content: center; /* Para alinhar o texto à esquerda dentro da barra de progresso */
    padding-left: 10px; /* Adicione o padding necessário para dar espaço ao texto na barra */
  }

  .progress_container_text_align_center {
    /* Estilos para o texto (se necessário) */
    font-size: 11px;
    font-weight: 700;
  }
  .recharts-responsive-container {
    height: auto !important;
  }
  .success {
    color: var(--ant-success-color);
  }
  .error {
    color: var(--ant-error-color);
  }
  .page-title {
    display: flex;
    align-items: baseline;
  }
  .hide-values-btn {
    width: 24px;
    margin-left: 12px;
    border-radius: 4px;
    display: inline-flex;
    height: 32px !important;
    background-color: transparent;
    svg {
      width: 20px;
      height: 20px;
      fill: var(--ant-primary-color) !important;
    }
  }

  .patrimonio {
    width: 100%;
    margin-top: -32px;
    margin-bottom: -32px;

    h1:not(.page-title) {
      padding-bottom: 0;
      padding-left: 40px;
      font-size: 2.25rem;
      margin: 0 !important;
      color: var(--velotax-font-color-light);

      span {
        font-weight: 800;
      }

      :after {
        display: none;
      }
    }

    h2 {
      font-weight: 400;
      line-height: 1rem;
      padding-left: 40px;
      font-size: 0.875rem;
      margin: 0 !important;
      color: var(--velotax-font-color-light);
    }

    .lucro-prejuizo {
      padding: 16px 0 16px 16px;
      border-left: 2px solid #313131;

      .anticon.anticon-loading.anticon-spin {
        width: 14px;
        height: 14px;
        margin-left: 4px;
        svg {
          width: 14px;
          height: 14px;
        }
      }

      div.ant-typography {
        margin: 0.5rem 0;
        font-size: 0.875rem;
        span {
          font-weight: 600;
          white-space: nowrap;
        }
        & > span {
          display: block;
          &.error {
            margin: 0;
          }
        }
      }
    }
  }

  .list-empty {
    padding: 16px;
    margin-top: -16px;
    border-radius: 8px;
    color: var(--velotax-font-color);
    background-color: #313131;
  }

  .last-update {
    display: flex;
    margin-top: 32px;
    align-items: center;
    font-size: 0.75rem;
    line-height: 1.5rem;
    color: var(--ant-primary-color);
    .button-refresh-wallet {
      width: 20px;
      height: 20px;
      border: none;
      box-shadow: none;
      margin-right: 8px;
      background-color: #313131;
      color: var(--ant-primary-color) !important;
      svg {
        width: 10px !important;
        height: 10px !important;
        * {
          stroke: var(--ant-primary-color) !important;
        }
      }
    }
  }

  .messages {
    margin-top: 16px;
    font-weight: 400;
    padding-top: 16px;
    line-height: 1rem;
    font-size: 0.75rem;
    color: var(--velotax-font-color);
    border-top: 2px solid #313131;
    li {
      padding-left: 16px;
      margin-bottom: 6px;
      position: relative;
      .anticon-info-circle {
        left: 0;
        top: 1px;
        position: absolute;
        color: var(--velotax-font-color);
      }
    }
  }

  .select-container {
    top: 0;
    right: 0;
    width: 41.6666%; // 41.6666% para alinhar com o prejuizo (10/24 = 41.6666)
    position: absolute;
    .ant-select {
      min-width: 180px;
      height: 40px !important;
      .ant-select-selector {
        height: 40px !important;
      }
    }
  }
  .recharts-layer.recharts-pie-labels text {
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  @media only screen and (min-device-width: 813px) {
    .velotax-content.largeChart {
      .recharts-responsive-container {
        margin: 0 auto 80px;
      }
    }
    .recharts-responsive-container {
      margin: 0 auto 48px;
      position: relative;
      .recharts-wrapper {
        width: 100% !important;
        > .recharts-surface {
          margin-left: 40px;
        }
        .recharts-legend-wrapper {
          right: 0px !important;
          width: 41.6666% !important; // 41.6666% para alinhar com o prejuizo (10/24 = 41.6666)
          top: calc(48px + 20px) !important;
          .recharts-legend-item {
            margin: 4px 0;
            .recharts-surface {
              width: 16px;
              height: 16px;
              border-radius: 2px;
            }
            .recharts-legend-item-text {
              /* margin-left: 12px; */
              span {
                font-size: 14px;
                font-weight: 500;
                color: var(--velotax-font-color) !important;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    .page-title {
      align-items: center;
      justify-content: space-between;
    }
    .patrimonio {
      margin-top: 32px;
      margin-bottom: -24px;

      h1,
      h2 {
        padding-left: 0 !important;
      }

      .lucro-prejuizo {
        padding: 4px 0 0;
        border: none;
        margin-top: 16px;
        border-top: 2px solid #313131;

        .anticon.anticon-loading.anticon-spin {
          width: 10px;
          height: 10px;
          margin-left: 4px;
          svg {
            width: 10px;
            height: 10px;
          }
        }

        div.ant-typography {
          margin: 0.25rem 0;
          font-size: 0.75rem;
        }
      }
    }

    h1 {
      width: calc(100% - 48px);
      margin: 0 24px 1.5rem;
      padding: 24px 0 1rem;
    }

    .recharts-responsive-container {
      position: relative;
      margin: 32px auto;
      .select-container {
        top: -32px;
        width: 100%;
        .ant-select {
          min-width: 210px;
        }
      }
      .recharts-legend-wrapper {
        width: 98px !important;
        right: -24px !important;
        .recharts-legend-item {
          margin: 4px 0;
          align-items: start;
          display: flex !important;
          margin-right: 0 !important;
          .recharts-surface {
            border-radius: 2px;
            margin-top: 1px;
          }
          .recharts-legend-item-text {
            max-width: 80px;
            font-size: 13px;
            line-height: 14px;
            white-space: normal;
            display: inline-block;
            span {
              color: var(--velotax-font-color) !important;
            }
          }
        }
      }
    }
  }
`;

export const Content = styled.div`
  .ant-tooltip {
    z-index: 999;
    .ant-tooltip-inner {
      width: auto;
      white-space: nowrap;
    }
  }
  &.velotax-content {
    width: 100% !important;
    padding: 16px 0 48px !important;
  }
  .container-btn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 32px;
  }
  .download-report-btn {
    padding: 5px;
    width: 24px;
    z-index: 1;
    font-size: 14px;
    border-radius: 4px;
    display: inline-flex;
    height: 32px !important;
    background-color: var(--white);
    color: var(--velotax-font-color-dark);
    width: 32px;
    border: 1px solid var(--white);
    border-radius: 60px;
    svg {
      width: 16px;
      height: 16px;
      fill: var(--ant-primary-color) !important;
    }
    &.filter-btn {
      width: auto;
      margin-right: 8px;
      padding: 5px 12px;
      svg {
        stroke: var(--ant-primary-color) !important;
        fill: none !important;
      }
    }
  }
  .range {
    display: flex;
    justify-content: space-between;

    div.ant-typography {
      font-size: 13px;
    }
  }
  .ant-tabs-tabpane {
    min-height: 547px;
  }
  .isentometro {
    margin-bottom: 5px;
    font-size: 14px;
  }
  .box {
    margin: 50px 0px;
  }
  .overview {
    color: var(--ant-primary-color);
    font-size: 18px;
    font-weight: bold;
  }
  .ant-progress-success-bg {
    background-color: var(--ant-primary-color);
    height: 13px !important;
  }
  .ant-progress-inner {
    height: 13px !important;
  }
  .ant-progress-bg {
    height: 13px !important;
  }
  & > .ant-collapse {
    border: none;
    & > .ant-collapse-item.ativos-categoria {
      margin-bottom: 48px;
      &:last-of-type {
        margin-bottom: 0;
      }
      & > .ant-collapse-header {
        padding: 0;
        align-items: center;
        .ant-collapse-arrow {
          right: 0;
        }
        h2 {
          margin: 0;
          font-weight: 700;
          font-size: 1.125rem;
          line-height: 1.5rem;
          color: var(--ant-primary-color);
        }
        .collapse-title-divider {
          flex-grow: 1;
          display: block;
          margin: 0 16px;
          border-bottom: 2px solid #313131;
        }
        .collapse-title-hidden {
          width: 24px;
          height: 24px;
          display: block;
          visibility: hidden;
        }
      }
      & > .ant-collapse-content {
        border: none;
        background-color: transparent;
        & > .ant-collapse-content-box {
          padding: 24px 0 0;
          & > .ant-collapse {
            border: none;
          }
          & > div {
            font-size: 13px;
          }
        }
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    width: 100%;
    margin-bottom: 0;
    border-radius: 0;
    padding: 32px 24px;

    h1,
    h2 {
      padding: 0;
    }
  }
`;

export const BlurContent: any = styled.div`
  margin-top: 8px;
  position: relative;
  user-select: none;
  border-radius: 4px;
  border: 2px solid #313131;
  .blur-content {
    opacity: 0.5;
    filter: blur(4px);
  }
  .blur-content-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 100px;
    font-weight: 500;
    font-size: 1.25rem;
    text-align: center;
    row-gap: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: var(--velotax-font-color-light);
  }

  @media only screen and (max-device-width: 812px) {
    .blur-content-title {
      padding: 0 16px;
      font-size: 1rem;
    }
  }
`;

export const Toolbar = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
  margin: 0 auto 1.5rem;
  justify-content: space-between;

  @media only screen and (max-device-width: 812px) {
    width: 100%;
  }
`;

export const IsentCardStyled = styled.div`
  margin-bottom: 4px;
  height: 110px;
  padding: 23px;
  border: none !important;
  border-radius: 4px !important;
  background-color: var(--velotax-background-color-ghost);
  text-align: center;
  gap: 12px;
  flex-direction: column;
  display: flex;

  .overviewTitleIsent {
    color: var(--ant-primary-color);
    font-size: 13px;
  }

  .ant-tooltip-inner {
    display: flex;
    justify-content: center;
    width: 315px;
    padding: 5px;
  }
`;
