import { ReactNode } from "react";
import { FormItemProps } from "antd";
import { currencyToNumber } from "../../utils";
import {
  MaskInput,
  NumberInput,
  TextInput,
  SelectInput,
  RadioInput,
} from "../inputs";
import {
  validationFieldRequired,
  validationDate,
  validationNumberFieldPositive,
} from "../../utils/formValidations";

interface FormItemParams extends FormItemProps {
  name: string;
  span?: number;
  align?: "end";
  disabled?: boolean;
  placeholder?: string;
  onChange?: (changed: any, values: any) => any;
  hide?: ((data: any) => boolean) | boolean;
}

interface NumberFormItemParams extends FormItemParams {
  prefix?: string;
  decimalScale?: number;
  allowNegative?: boolean;
  maxLength?: number;
}

interface MaskFormItemParams extends FormItemParams {
  mask?: string;
}

interface SelectFormItemParams extends FormItemParams {
  options: { label: ReactNode; value: any }[];
  renderItem?: any;
  defaultValue?: any;
  mustUpdate?: boolean;
  value?: any;
}

interface RadioFormItemParams extends Omit<SelectFormItemParams, "options"> {
  options?: { label: ReactNode; value: any }[];
}

export const MaskFormItem = ({
  mask,
  label,
  placeholder,
  disabled,
  ...rest
}: MaskFormItemParams) => ({
  ...rest,
  getValueFromEvent: undefined,
  input: MaskInput({
    mask,
    label,
    placeholder,
    disabled,
  }),
});

export const DateFormItem = ({
  rules,
  label,
  placeholder,
  disabled,
  mask,
  ...rest
}: MaskFormItemParams) => ({
  ...rest,
  getValueFromEvent: undefined,
  rules: rules ? rules : validationDate,
  input: MaskInput({
    mask: mask ?? "99/99/9999",
    placeholder: placeholder || "01/01/2021",
    disabled,
    label,
  }),
});

export const DateFormItem2 = ({
  rules,
  label,
  placeholder,
  disabled,
  mask,
  ...rest
}: MaskFormItemParams) => ({
  ...rest,
  getValueFromEvent: undefined,
  rules: rules ? rules : validationDate,
  input: MaskInput({
    mask: mask ?? "9999/99/99",
    disabled,
    label,
  }),
});

export const TextFormItem = ({
  rules,
  placeholder,
  disabled,
  label,
  ...rest
}: FormItemParams) => ({
  ...rest,
  rules: rules || [],
  getValueFromEvent: undefined,
  input: TextInput({ placeholder, disabled, label }),
});

export const SelectFormItem = ({
  placeholder,
  disabled,
  options,
  label,
  rules,
  value,
  defaultValue,
  ...rest
}: SelectFormItemParams) => ({
  ...rest,
  getValueFromEvent: undefined,
  rules: rules ?? validationFieldRequired,
  input: SelectInput({
    placeholder,
    disabled,
    options,
    label,
    defaultValue,
    value,
  }),
});

export const RadioFormItem = ({
  disabled,
  options,
  label,
  rules,
  defaultValue,
  ...rest
}: RadioFormItemParams) => ({
  ...rest,
  getValueFromEvent: undefined,
  rules: rules ?? validationFieldRequired,
  input: RadioInput({ disabled, options, defaultValue }),
});

export const NumberFormItem = ({
  name,
  label,
  maxLength,
  rules = [],
  prefix = "",
  disabled = false,
  placeholder = "",
  decimalScale = 2,
  allowNegative = false,
  onChange,
}: NumberFormItemParams) => ({
  name,
  label,
  rules,
  placeholder,
  getValueFromEvent: undefined,
  input: NumberInput({
    prefix,
    decimalScale,
    placeholder,
    allowNegative,
    disabled,
    label,
    maxLength,
  }),
  onChange: onChange
    ? onChange
    : (changed: any, values: any) =>
        changed[name]
          ? Number(changed[name].replace(/\./g, "").replace(",", ".")) || 0
          : values[name],
});

export const CurrencyFormItem = ({
  name,
  label,
  rules,
  prefix = "R$ ",
  disabled = false,
  decimalScale = 2,
  placeholder = "R$ 0,00",
  allowNegative = false,
  onChange,
  className,
  ...rest
}: NumberFormItemParams) => ({
  ...rest,
  name,
  label,
  placeholder,
  getValueFromEvent: undefined,
  rules: rules === undefined ? validationNumberFieldPositive : rules,
  input: NumberInput({
    prefix,
    decimalScale,
    placeholder,
    allowNegative,
    disabled,
    label,
    className,
  }),
  onChange: onChange
    ? onChange
    : (changed: any, values: any) =>
        changed[name] ? currencyToNumber(changed[name], prefix) : values[name],
});
