import _ from "lodash";

export const serializePatrimonio = (
  itemPatrimonio: any,
  corretoraFilter?: string[]
) => {
  let posicoesFiltradas = itemPatrimonio?.posicoes || [];
  const corretoras = getTodasAsCorretoras(posicoesFiltradas);
  const corretorasSemFiltro = getTodasAsCorretoras(itemPatrimonio?.posicoesSemFiltro)
  if(corretoraFilter && corretoraFilter.length > 0) {
    const corretoraNames = corretoraFilter.map(c => getNomeCorretora({shortName: c}))
    posicoesFiltradas = posicoesFiltradas?.filter((p: any) => corretoraNames.includes(p.corretora))
  }

  const patrimonio = calculaSaldoCorretoras(
    posicoesFiltradas,
    itemPatrimonio?.posicao2019 || {}
  );

  const classesAtivos = calculaClassesAtivos(
    posicoesFiltradas,
    itemPatrimonio?.posicao2019 || {}
  );
  const posicoes = calculaPorcentagemPosicoes(posicoesFiltradas);

  return { patrimonio, classesAtivos, posicoes, corretoras, corretorasSemFiltro };
};

export const getNomeCorretora = ({
  name,
  shortName,
}: {
  name?: string;
  shortName?: string;
}) => {
  const corretoras = [
    {
      name: "XP INVESTIMENTOS CCTVM S/A",
      shortName: "XP Investimentos",
    },
    {
      name: "NU INVEST CORRETORA DE VALORES S.A.",
      shortName: "Nubank",
    },
    {
      name: "INTER DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA",
      shortName: "Inter",
    },
    {
      name: "ORAMA DTVM S.A.",
      shortName: "Orama",
    },
    {
      name: "CLEAR CORRETORA - GRUPO XP",
      shortName: "Clear Corretora",
    },
    {
      name: "Exterior",
      shortName: "XP International",
    },
  ];

  if (name) return corretoras.find((c) => c.name === name)?.shortName || name;
  if (shortName)
    return corretoras.find((c) => c.shortName === shortName)?.name || shortName;
  return "";
};

const getCategoriaShortName = (categoria: string) => {
  if (categoria.includes("Fundos de Investimentos")) return "FIs";
  if (categoria.includes("FII")) return "FIIs";
  if (categoria.includes("BDR")) return "BDRs";
  if (categoria.includes("ETF")) return "ETFs";
  if (categoria.includes("Empréstimo de Ativos (Doador)")) return "Empréstimos (Doador)";
  if (categoria.includes("Empréstimo de Ativos (Tomador)")) return "Empréstimos (Tomador)";
  if (categoria.includes("TesouroDireto")) return "Tesouro Direto";
  return categoria;
};

const calculaSaldoCorretoras = (posicoes: any, posicao2019: any) => {
  const agrupadoPorCorretora = _.groupBy(posicoes, (p) => p.corretora);
  const saldosCorretoras = [];
  for (const corretora in agrupadoPorCorretora) {
    const saldo = agrupadoPorCorretora[corretora].reduce((prev, curr) => {
      return (prev || 0) + (curr?.posicao || 0);
    }, 0);
    saldosCorretoras.push({
      name: getNomeCorretora({ name: corretora }),
      value: saldo,
    });
  }

  return calculaPorgentagensCategorias(saldosCorretoras);
};

const getTodasAsCorretoras = (posicoes: any) => {
  const agrupadoPorCorretora = _.groupBy(posicoes, (p) => p.corretora);
  const corretoras = Object.keys(agrupadoPorCorretora);
  return corretoras.map((c) => getNomeCorretora({ name: c }));
};

const calculaClassesAtivos = (posicoes: any, posicao2019: any) => {
  const agrupadoPorCategoria = _.groupBy(posicoes, (p) => p.categoria);
  const saldosCategorias = [];
  for (const categoria in agrupadoPorCategoria) {
    const saldo = agrupadoPorCategoria[categoria].reduce((prev, curr) => {
      return (prev || 0) + (curr?.posicao || 0);
    }, 0);
    saldosCategorias.push({
      name: getCategoriaShortName(categoria),
      value: saldo,
    });
  }

  return calculaPorgentagensCategorias(saldosCategorias);
};

const calculaPorgentagensCategorias = (
  saldosCategorias: Array<{ name: string; value: number }>
) => {
  const totalSaldos = saldosCategorias.reduce(
    (acc, curr) => acc + curr.value,
    0
  );

  const porcentagens = saldosCategorias.map((categoria) => ({
    name: categoria.name,
    value: categoria.value,
    percentage: Number(((categoria.value / totalSaldos) * 100).toFixed(1)),
  }));

  return porcentagens;
};

const calculaPorcentagemPosicoes = (
  posicoes: {
    codigo: string;
    posicao: number;
    custoMedio: number;
    precoAtual: number;
    precoVenda: number;
    quantidade: number;
    categoria: string;
    corretora: string;
    hasPosition2019: boolean;
  }[]
) => {
  if (!posicoes || posicoes.length === 0) return [];

  const patrimonioTotal = Number(
    posicoes.reduce((prev, curr) => prev + curr.posicao, 0).toFixed(2)
  );

  const posicoesOrdenadas = posicoes.sort((a, b) => {
    return b.posicao - a.posicao;
  });
  const quantidadeDeAtivosMostrados = 8;
  const porcentagens = [];

  for (
    let i = 0;
    i <
    (posicoesOrdenadas.length < quantidadeDeAtivosMostrados
      ? posicoesOrdenadas.length
      : quantidadeDeAtivosMostrados);
    i++
  ) {
    const posicao = posicoesOrdenadas[i];
    if (posicao.categoria === "Exterior" || !posicao.hasPosition2019) {
      const porcentagem = (posicao.posicao / patrimonioTotal) * 100;
      porcentagens.push({
        name: posicao.codigo,
        value: Number(posicao.posicao),
        percentage: Number(porcentagem.toFixed(1)),
      });
    }
  }

  let porcentagensOrdenadas = porcentagens.sort(
    (a, b) => a.percentage - b.percentage
  );

  if (porcentagensOrdenadas.length > quantidadeDeAtivosMostrados) {
    porcentagensOrdenadas = porcentagensOrdenadas.slice(
      0,
      quantidadeDeAtivosMostrados
    );
  }

  let somatorioDasMaioresPorcentagens = 0;
  let somatorioDosMaioresValores = 0;
  for (const po of porcentagensOrdenadas) {
    somatorioDasMaioresPorcentagens += po.percentage;
    somatorioDosMaioresValores += po.value;
  }

  const ret = porcentagensOrdenadas.sort((a, b) => b.percentage - a.percentage);

  if (somatorioDasMaioresPorcentagens < 100) {
    ret.push({
      name: "Outros",
      value: patrimonioTotal - somatorioDosMaioresValores,
      percentage: Number((100 - somatorioDasMaioresPorcentagens).toFixed(2)),
    });
  }

  return ret;
};
