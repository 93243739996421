import styled from "styled-components";
import { Modal } from "antd";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--velotax-background-color-ghost);
  span.ant-checkbox {
    top: 0;
  }
  h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--velotax-font-color-ghost);
  }
  .original-price {
    display: grid;
    max-width: 150px;
  }
  .price {
    display: flex;
    width: 100%;
    column-gap: 8px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    h4 {
      font-size: 22px;
      margin: 8px 0 !important;
      color: var(--ant-primary-color);
      del,
      span.per-month {
        font-size: 14px;
      }
      span.cupom-discount {
        font-size: 12px;
        color: var(--velotax-disabled);
      }

      del {
        opacity: 0.8;
        color: var(--velotax-font-color);
      }
    }
    .ant-typography-danger {
      font-size: 11px;
      text-align: right;
      max-width: 120px;
    }
  }
  .ant-divider.ant-divider-horizontal {
    margin: 16px 0;
  }
  .other-features,
  .included-features-user {
    .feature {
      display: flex;
      align-items: center;
      justify-content: space-between;
      opacity: 0.7;
      margin: 6px 0;
      column-gap: 16px;
      span {
        font-weight: 500;
        display: flex;
        column-gap: 4px;
        align-items: center;
        svg {
          min-width: 20px;
          min-height: 20px;
        }
      }
      svg.fill {
        fill: var(--velotax-font-color);
      }
      svg:not(.fill) {
        stroke: var(--velotax-font-color);
      }
      &.disabled {
        opacity: 0.4;
      }
      .feature-desc {
        display: block;
        font-size: 10px;
        font-weight: 400;
      }
    }
  }
  .other-features {
    margin-bottom: 24px;
  }

  .checkbox-terms {
    font-size: 12px;
    line-height: 16px;
    display: flex;
    column-gap: 4px;
    align-items: flex-start;
  }

  @media only screen and (max-device-width: 812px) {
    .original-price {
      font-size: 14px;
      max-width: 100px;

      .per-month {
        font-size: 12px !important;
      }
    }
  }
`;

export const PlanModalStyled = styled(Modal)`
  .ant-modal-body {
    padding: 32px !important;
  }

  .ant-tabs {
    width: 100%;
  }

  .ant-tabs-nav {
    margin: 0 auto 8px !important;
    width: calc(100% - 16px) !important;
  }

  .click-here {
    cursor: pointer;
    font-weight: 500;
    color: var(--ant-primary-color);
    display: flex;
    align-items: center;
    margin-top: 12px;

    svg {
      margin-right: 8px;
      color: var(--ant-primary-color);
    }
  }

  .cupom-content {
    .description-cupom-valid {
      font-size: 12px;
    }
    .input-content {
      display: flex;
      column-gap: 4px;
      align-items: center;
      margin-top: 10px;
      .icon-cupom {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .input-cupom {
        height: 40px;
        max-width: 200px;
      }
    }
    .cupom-msg {
      margin-top: 4px;
      display: inline-block;
    }
  }

  @media only screen and (max-device-width: 812px) {
    .ant-modal-body {
      padding: 16px !important;
    }
  }
`;
