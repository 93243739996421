import styled from "styled-components";

export const Container = styled.div`
  padding: 64px 32px 128px;
  @media only screen and (max-device-width: 812px) {
    padding: 0;
  }
`;

export const Content = styled.div`
  background-color: var(--velotax-background-color);
  border-radius: 16px;
  margin: 0 auto;
  padding: 32px;
  min-width: 500px;
  max-width: 800px;
  h1 {
    font-size: 24px;
    position: relative;
    margin-bottom: 32px;
    color: var(--velotax-font-color);
    &::before {
      content: "";
      position: absolute;
      width: 4px;
      top: 0;
      left: -32px;
      height: 100%;
      background-color: var(--ant-primary-color);
    }
  }
  @media only screen and (max-device-width: 812px) {
    border-radius: 0;
    min-width: 100%;
    padding: 16px;
    padding-bottom: 32px;
    h1 {
      &::before {
        display: none;
      }
    }
  }
`;
