import { message } from "antd";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  ReactNode,
  useMemo,
} from "react";
import api from "../services/api";
import apis from "../services/apis";
import { isMobile, planInfoToHasPlan } from "../utils";
import msDarf from "../services/msDarf";
import apiBolsa from "../services/apiBolsa";
import { apiPlan } from "../services/apiPlan";
import apiVeloPro from "../services/apiVeloPro";
import sslSecure from "../assets/ssl-secure.svg";
import apiExterior from "../services/apiExterior";
import { apiPayment } from "../services/apiPayment";
import backnedRendimentos from "../services/rendimentos";
import apiBolsaVelotax from "../services/apiBolsaVelotax";
import apiIrpf2023 from "../services/apiIrpf2023";
import { UserPlanModal } from "../components/UserPlanModal";

interface ILoginData {
  cpf: string;
  pass: string;
}

interface IModuleData {
  name: string;
  completed: boolean;
}

interface ISignUpData {
  email: string;
  pass: string;
  passConfirmation: string;
  cpf: string;
}

interface IDependent {
  name: string;
  cpf: string;
  profileId: string;
  type: string;
  _id: string;
}

export interface HasPlan {
  basic: boolean;
  premium: boolean;
  concierge: boolean;
}

interface IUser {
  name: string;
  email: string;
  cpf: string;
  birthdate?: string;
  cellphone?: string;
  cpfProtegido: boolean;
  profileId: string;
  dependents?: IDependent[];
  visibleCalcs: string[];
  visibleModules: IModuleData[];
  modulesStatus: IModuleData[];
  answeredStepDec: boolean;
  isAccountant?: boolean;
  userId?: string;
  cellphoneFilled?: boolean;
  showAccountantPremiumButton: {
    show: boolean;
    disabled: boolean;
  };
  showContratarContador: {
    show: boolean;
    disabled: boolean;
  };
  showContratarMalhaFina: {
    show: boolean;
    protected: boolean;
  };
  userPlanInfoVelotax: {
    active: boolean;
    dueDate: number;
    type: string;
    paid: boolean;
    actionFree: boolean;
    permissions?: any;
    cupomDueDate?: string;
    currentPlan?: any
  };
  isIntegratedBolsa?: boolean;
  isIntegratedExterior?: boolean;
  isAd?: boolean
}

interface ISessionData extends IUser {
  token: string;
}

interface IAuthState {
  token: string;
  user: IUser;
}

interface HandleCalcsParamas {
  calcs: any[];
  navigateTo?: string;
}

interface CameFromRegister {
  bolsa: boolean;
  phone: boolean;
}

interface IAuthContext {
  handleSignIn: (loginData: ILoginData) => void;
  handleSignUp: (
    signUpData: ISignUpData,
    onboardingData: any,
    hiringData?: any
  ) => void;
  setLocalStorageWithAuthData: (sesionData: ISessionData) => void;
  signOut: () => void;
  signed: boolean;
  user: IAuthState;
  loading: boolean;
  loadingSession: boolean;
  setLoadingSession: React.Dispatch<React.SetStateAction<boolean>>;
  getUserInfo: () => void;
  updateUserInfo: () => void;
  updateUserDec: () => void;
  authAccountantSSOCnpj: () => void;
  decUrl: string;
  setDecUrl: (decUrl: string) => void;
  showDbkModal: boolean;
  setShowDbkModal: (showDbkModal: boolean) => void;
  showIndicarModal: boolean;
  setShowIndicarModal: (showIndicarModal: boolean) => void;
  wasMessageSent: boolean;
  setWasMessageSent: (wasMessageSent: boolean) => void;
  showUserPlanModal: (show: boolean, title?: ReactNode) => void;
  userPlanModal: boolean;
  handleCalcs: (params: HandleCalcsParamas) => void;
  setUser: (data: any) => void;
  ssoToken: string;
  irpfBannerActive: boolean;
  forceUpdateTabNavigator: boolean;
  setForceUpdateTabNavigator: React.Dispatch<React.SetStateAction<boolean>>;
  hasPlan: any;
  b3Authorized: boolean;
  setB3Authorized: React.Dispatch<React.SetStateAction<boolean>>;
  showDrawer: boolean;
  setShowDrawer: Function;
  chatNotificationQtt: number;
  setChatNotificationQtt: React.Dispatch<React.SetStateAction<number>>;
  chatOpen: boolean;
  setChatOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showPhoneModal: boolean;
  setShowPhoneModal: React.Dispatch<React.SetStateAction<boolean>>;
  showNameModal: boolean;
  setShowNameModal: React.Dispatch<React.SetStateAction<boolean>>;
  cameFromRegister: CameFromRegister;
  setCameFromRegister: React.Dispatch<React.SetStateAction<CameFromRegister>>;
  hasPermissionExterior: boolean;
  hasPermissionExteriorPlanos: boolean;
  hasPermissionGeneral: boolean;
}

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();

  const [decUrl, setDecUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [chatOpen, setChatOpen] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showDbkModal, setShowDbkModal] = useState(false);
  const [b3Authorized, setB3Authorized] = useState(false);
  const [showNameModal, setShowNameModal] = useState(false);
  const [loadingSession, setLoadingSession] = useState(true);
  const [wasMessageSent, setWasMessageSent] = useState(false);
  const [showPhoneModal, setShowPhoneModal] = useState(false);
  const [user, setUser] = useState<IAuthState>({} as IAuthState);
  const [showIndicarModal, setShowIndicarModal] = useState(false);
  const [chatNotificationQtt, setChatNotificationQtt] = useState(0);
  const [userPlanModal, showUserPlanModal] = useState<boolean>(false);
  const [userPlanModalTitle, setUserPlanModalTitle] = useState<ReactNode>();
  const [forceUpdateTabNavigator, setForceUpdateTabNavigator] = useState(false);
  const [cameFromRegister, setCameFromRegister] = useState<CameFromRegister>({
    bolsa: false,
    phone: false,
  });

  const hasPermissionExterior = user?.user?.userPlanInfoVelotax?.permissions?.["exterior-xpinvestimentos"];
  const hasPermissionExteriorPlanos = user?.user?.userPlanInfoVelotax?.permissions?.["relatoriosanual-xpinvestimentos"];
  //@ts-ignore
  const hasPermissionGeneral = user?.user?.userPlanInfoVelotax?.permissions?.["xpinvestimentos"] && new Date(user?.user?.userPlanInfoVelotax?.dueDate) > new Date();

  const hasPlan = useMemo(
    () => planInfoToHasPlan(user?.user?.userPlanInfoVelotax, hasPermissionGeneral),
    [user?.user?.userPlanInfoVelotax, hasPermissionGeneral]
  );

  const handleCalcs = async ({ calcs, navigateTo }: HandleCalcsParamas) => {
    try {
      setLoading(true);
      await api.put("/configuracoes/calcs", { calcs });
      setUser((user) => ({
        ...user,
        user: { ...user.user, visibleCalcs: calcs },
      }));
      setLoading(false);
      navigate(navigateTo ?? "/");
    } catch (err) {
      setLoading(false);
      throw Error;
    }
  };

  const getUserInformation = async () => {
    const token = localStorage.getItem("@VeloTax:token");
    const user = localStorage.getItem("@VeloTax:user");
    if (user && token && !pathname.includes("/p/sso")) {
      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      apis.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      apiPayment.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      apiBolsa.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      apiVeloPro.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      backnedRendimentos.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      msDarf.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      const response = await api.get("/session/userInfo");
      if (response.data.isBlocked) {
        message.warning(
          "Sua conta foi temporariamente bloqueada, favor entrar em contato com o suporte.",
          5000
        );
        await signOut();
      }
      const retPlanInfo = await apiPayment.get(`/user-plan/plan-info`, {
        headers: {
          "x-api-provider": "xp-investimentos",
        },
      });

      let userFromApi = null;
      if (response.data) {
        const {
          cpf,
          cpfProtegido,
          name,
          email,
          birthdate,
          cellphone,
          profileId,
          dependents,
          visibleCalcs,
          visibleModules,
          modulesStatus,
          answeredStepDec,
          showContratarMalhaFina,
          userId,
          cellphoneFilled,
          showAccountantPremiumButton,
          showContratarContador,
          isBlocked,
          isIntegratedBolsa,
          isIntegratedExterior
        } = response.data;
        userFromApi = {
          cpf,
          cpfProtegido,
          name,
          email,
          birthdate,
          cellphone,
          profileId,
          dependents,
          visibleCalcs,
          visibleModules,
          modulesStatus,
          answeredStepDec,
          cellphoneFilled,
          showContratarContador,
          showContratarMalhaFina,
          isAccountant: cpf.length > 11,
          userId,
          showAccountantPremiumButton,
          userPlanInfoVelotax: retPlanInfo.data,
          isBlocked,
          isIntegratedBolsa,
          isIntegratedExterior
        };
      }

      api.defaults.headers.common["Authorization"] = `Bearer ${response.headers.token || token
        }`;
      apis.defaults.headers.common["Authorization"] = `Bearer ${response.headers.token || token
        }`;
      apiBolsa.defaults.headers.common["Authorization"] = `Bearer ${response.headers.token || token
        }`;
      apiExterior.defaults.headers.common["Authorization"] = `Bearer ${response.headers.token || token
        }`;
      backnedRendimentos.defaults.headers.common["Authorization"] = `Bearer ${response.headers.token || token
        }`;
      msDarf.defaults.headers.common["Authorization"] = `Bearer ${response.headers.token || token
        }`;
      apiPayment.defaults.headers.common["Authorization"] = `Bearer ${response.headers.token || token
        }`;
      apiVeloPro.defaults.headers.common["Authorization"] = `Bearer ${response.headers.token || token
        }`;
      apiPlan.defaults.headers.common["Authorization"] = `Bearer ${response.headers.token || token
        }`;
      apiBolsaVelotax.defaults.headers.common["Authorization"] = `Bearer ${response.headers.token || token
        }`;
      apiIrpf2023.defaults.headers.common["Authorization"] = `Bearer ${response.headers.token || token
        }`;

      return {
        token: response.headers.token || token,
        user: userFromApi || JSON.parse(user),
      };
    }
    return {} as IAuthState;
  };

  const getUserInfo = useCallback(
    (callback?: () => void) => {
      setLoadingSession(true);
      getUserInformation()
        .then((res) => {
          setUser(res);
          setLoadingSession(false);
          callback && callback();
        })
        .catch((err) => {
          console.log("err", err);
          setLoadingSession(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]
  );

  const updateUserInfo = useCallback(() => {
    getUserInformation().then((res) => {
      setUser(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const updateUserDec = useCallback(() => {
    const updatedUser = { ...user.user, answeredStepDec: true };
    const token = localStorage.getItem("@VeloTax:token") || "";

    setUser({ user: updatedUser, token });
  }, [user]);

  const signOut = useCallback(() => {
    setLoadingSession(true);
    localStorage.removeItem("@VeloTax:token");
    localStorage.removeItem("@VeloTax:user");
    api.defaults.headers.common["Authorization"] = "";
    apis.defaults.headers.common["Authorization"] = "";
    apiPayment.defaults.headers.common["Authorization"] = "";
    apiBolsa.defaults.headers.common["Authorization"] = "";
    apiBolsaVelotax.defaults.headers.common["Authorization"] = "";
    apiVeloPro.defaults.headers.common["Authorization"] = "";
    apiIrpf2023.defaults.headers.common["Authorization"] = "";

    setUser({} as IAuthState);
    navigate("/p/login");
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }, [navigate]);

  useEffect(() => {
    apiExterior.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401) {
          setTimeout(() => {
            signOut();
          }, 3000);
        }
        return Promise.reject(error);
      }
    );
    apiVeloPro.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401) {
          setTimeout(() => {
            signOut();
          }, 3000);
        }
        return Promise.reject(error);
      }
    );
    api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401) {
          signOut();
        }

        return Promise.reject(error);
      }
    );

    getUserInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setLocalStorageWithAuthData = useCallback(
    ({
      token,
      cpf,
      cpfProtegido,
      email,
      name,
      birthdate,
      cellphone,
      profileId,
      dependents,
      visibleCalcs,
      visibleModules,
      modulesStatus,
      answeredStepDec,
      cellphoneFilled,
      showContratarMalhaFina,
      userId,
      showAccountantPremiumButton,
      showContratarContador,
      userPlanInfoVelotax,
      isIntegratedBolsa,
      isIntegratedExterior
    }: ISessionData) => {
      localStorage.setItem("@VeloTax:token", token);
      localStorage.setItem(
        "@VeloTax:user",
        JSON.stringify({
          cpf,
          email,
          name,
          profileId,
          mostrouRecomendacao: false,
        })
      );
      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      apis.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      apiBolsa.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      apiExterior.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      backnedRendimentos.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      msDarf.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      apiPayment.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      apiPlan.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      apiBolsaVelotax.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
      apiVeloPro.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      apiIrpf2023.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      apis.post("/billing", { cpf, name, email });

      setUser({
        token,
        user: {
          cpfProtegido,
          cpf,
          email,
          name,
          birthdate,
          cellphone,
          profileId,
          dependents,
          visibleCalcs,
          visibleModules,
          modulesStatus,
          cellphoneFilled,
          answeredStepDec,
          showContratarMalhaFina,
          isAccountant: cpf.length > 11,
          userId,
          showAccountantPremiumButton,
          showContratarContador,
          userPlanInfoVelotax,
          isIntegratedBolsa,
          isIntegratedExterior
        },
      });
      if (search === "?redirect=true") {
        navigate("/conta");
      } else {
        navigate("/");
      }
    },
    // eslint-disable-next-line
    []
  );

  const authAccountantSSOCnpj = useCallback(async () => {
    if (!user.user.cpf.includes("_")) return;

    const { data } = await api.post("/session/accountant-sso-cnpj", {
      cnpj: user.user.cpf.includes("_")
        ? user.user.cpf.split("_")[1]
        : user.user.cpf,
    });

    navigate(data.url);
  }, [user, navigate]);

  const handleSignIn = useCallback(
    async (data: ILoginData) => {
      setLoading(true);
      try {
        const response: any = await api.post("/session", {
          cpf: data.cpf,
          pass: data.pass,
          from: "XP",
        });
        if (response.data.isBlocked) {
          message.warning(
            "Sua conta foi temporariamente bloqueada, favor entrar em contato com o suporte.",
            5000
          );
          await signOut();
        }
        const {
          token,
          cpf,
          cpfProtegido,
          email,
          name,
          birthdate,
          cellphone,
          profileId,
          dependents,
          visibleCalcs,
          visibleModules,
          modulesStatus,
          answeredStepDec,
          cellphoneFilled,
          showContratarMalhaFina,
          userId,
          showAccountantPremiumButton,
          showContratarContador,
          isIntegratedBolsa,
          isIntegratedExterior
        } = response.data;

        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        apis.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        apiPayment.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        apiPlan.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        apiBolsa.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        apiExterior.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token}`;
        backnedRendimentos.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token}`;
        msDarf.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        apiBolsaVelotax.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token}`;
        apiVeloPro.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        apiIrpf2023.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${token}`;

        const retPlanInfo = await apiPayment.get(`/user-plan/plan-info`, {
          headers: {
            "x-api-provider": "xp-investimentos",
          },
        });

        setLoadingSession(true);
        setLocalStorageWithAuthData({
          token,
          cpf,
          cpfProtegido,
          email,
          name,
          birthdate,
          cellphone,
          profileId,
          dependents,
          visibleCalcs,
          visibleModules,
          modulesStatus,
          cellphoneFilled,
          answeredStepDec,
          showContratarContador,
          showContratarMalhaFina,
          userId,
          showAccountantPremiumButton,
          userPlanInfoVelotax: retPlanInfo.data,
          isIntegratedBolsa,
          isIntegratedExterior
        });
        setLoading(false);
        setTimeout(() => {
          setLoadingSession(false);
        }, 500);
      } catch (err: any) {
        const errorMessage =
          err.response?.data?.message ||
          "CPF e/ou senha incorretos. Tente novamente";
        message.error(errorMessage);
        setLoading(false);
        throw Error;
      }
    },
    [setLocalStorageWithAuthData, signOut]
  );

  const handleSignUp = useCallback(
    (signUpData, onboardingData, hiringData = null) => {
      setLoading(true);
      const { name, cpf, email, pass, policiesAccepted } = signUpData;

      const modules = [
        ...(onboardingData.goods || []),
        ...(onboardingData.incomeTypes || []),
        ...(onboardingData.operationTypes || []),
      ];

      const user: { [key: string]: any } = {
        name,
        cpf,
        email,
        pass,
        policiesAccepted,
        from: "XP",
        dependents: onboardingData.dependents || [],
        modules: modules,
        isMobile: isMobile(),
        
      };

      const formData = hiringData ? (hiringData as FormData) : new FormData();

      for (const key in user) {
        formData.set(
          key,
          key === "modules" || key === "dependents"
            ? JSON.stringify(user[key])
            : user[key]
        );
      }

      api
        .post("/user", formData)
        .then((response) => {
          const { token, cpf, email, name, profileId } = response.data;

          localStorage.removeItem("publiconboarding");
          localStorage.setItem("@VeloTax:token", token);
          localStorage.setItem(
            "@VeloTax:user",
            JSON.stringify({
              cpf,
              email,
              name,
              profileId,
              mostrouRecomendacao: false,
            })
          );
          api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          apis.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          apiBolsa.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          apiExterior.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${token}`;
          backnedRendimentos.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${token}`;
          msDarf.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          apiPayment.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${token}`;
          apiPlan.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          apiBolsaVelotax.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${token}`;
          apiVeloPro.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${token}`;
          apiIrpf2023.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${token}`;

          apis.post("/billing", { cpf, name, email });

          setCameFromRegister({ bolsa: true, phone: true });
          getUserInfo(() => {
            navigate("/");
            setLoading(false);
          });
        })
        .catch((err) => {
          message.error(
            err.response?.data?.message ||
            "Algo deu errado, tente novemente mais tarde"
          );
          setLoading(false);
        });
    },
    [navigate, getUserInfo]
  );

  const irpfBannerActive = true;
  const [ssoToken, setSsoToken] = useState("");
  useEffect(() => {
    if (irpfBannerActive && user?.user?.cpf) {
      api
        .post("/user/sso-token", { from: "XP" })
        .then((response) => {
          setSsoToken(response.data.token || "");
        })
        .catch(() => { });
    }
  }, [irpfBannerActive, user?.user?.cpf]);

  return (
    <AuthContext.Provider
      value={{
        hasPlan,
        handleSignUp,
        handleSignIn,
        signOut,
        signed: !!Object.keys(user).length,
        user,
        loading,
        loadingSession,
        setLoadingSession,
        getUserInfo,
        setLocalStorageWithAuthData,
        updateUserInfo,
        updateUserDec,
        authAccountantSSOCnpj,
        decUrl,
        setDecUrl,
        showDbkModal,
        setShowDbkModal,
        showIndicarModal,
        setShowIndicarModal,
        wasMessageSent,
        setWasMessageSent,
        userPlanModal,
        showUserPlanModal: (show, title) => {
          // showUserPlanModal(show);
          navigate("/planos");
          // setUserPlanModalTitle(title);
        },
        setUser,
        chatOpen,
        ssoToken,
        showDrawer,
        handleCalcs,
        setChatOpen,
        b3Authorized,
        setShowDrawer,
        showNameModal,
        showPhoneModal,
        setB3Authorized,
        irpfBannerActive,
        cameFromRegister,
        setShowNameModal,
        setShowPhoneModal,
        setCameFromRegister,
        chatNotificationQtt,
        setChatNotificationQtt,
        forceUpdateTabNavigator,
        setForceUpdateTabNavigator,
        hasPermissionExterior,
        hasPermissionExteriorPlanos,
        hasPermissionGeneral,
      }}
    >
      <UserPlanModal
        title={userPlanModalTitle}
        visible={userPlanModal}
        onCancel={() => showUserPlanModal(false)}
        closeModal={showUserPlanModal}
      />
      {children}
      {!isMobile() ? (
        <div className="footer-secure">
          <img src={sslSecure} alt="ssl secure" />
        </div>
      ) : null}
    </AuthContext.Provider>
  );
};

export const useAuth = (): IAuthContext => useContext(AuthContext);
