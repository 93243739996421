import styled from "styled-components";

export const Container = styled.div`
  padding: 16px;
  .ant-typography {
    text-align: center;
    color: var(--velotax-font-color);
  }
  h1 {
    font-size: 32px;
  }
  div.ant-typography {
    font-size: 18px;
  }
  @media only screen and (max-device-width: 812px) {
    h1 {
      font-size: 24px;
    }
    div.ant-typography {
      font-size: 14px;
    }
  }
`;
