import moment from "moment";
import { useEffect, useState } from "react";
import { Divider, Form, Input, message, Typography } from "antd";
import apis from "../../services/apis";
import { errorMessage } from "../../utils";
import Button from "../../components/Button";
import { Container, Content } from "./styles";
import { Page } from "../../constants/brokers";
import { useAuth } from "../../contexts/AuthContext";
import { useBroker } from "../../contexts/BrokerContext";

interface IntegrationProps {
  item: Page;
}

export const Integration: React.FC<IntegrationProps> = ({ item }) => {
  const { user } = useAuth();
  const { initIntegration, integration, handleIntegrationInit,currentPage } = useBroker();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const feature = item.features[0];
  const inputs = feature.inputs || [];

  useEffect(() => {
    if (integration.key) {
      form.setFieldsValue({
        key: integration.key,
      });
    }
  }, [integration.key, form]);

  const onFinish = (data: any) => {
    setLoading(true);
    (currentPage?.api || apis)
      .post(feature.apiUrl, { ...data, email: user.user.email })
      .then(() => handleIntegrationInit())
      .catch((err) => {
        if (err?.response?.data?.message?.includes("Aguarde")) {
          message.error(err?.response?.data?.message);
        } else {
          message.error(errorMessage);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container>
      <Content>
        <Typography.Title level={2}>{item.title}</Typography.Title>
        {!integration.providerExists ? (
          <Typography.Paragraph>
            O acesso as outras funcionalidades da plataforma será liberado após
            você concluir a integração.
          </Typography.Paragraph>
        ) : integration.lastUpdate ? (
          <>
            <Typography.Text
              italic
              strong
              type="secondary"
              className="last-update"
            >
              Última integração:{" "}
              {moment(integration.lastUpdate)
                .utc()
                .local()
                .format("DD/MM/YYYY à[s] HH:mm")}
            </Typography.Text>
          </>
        ) : (
          <></>
        )}
        <Form form={form} size="large" layout="vertical" onFinish={onFinish}>
          {inputs.map((input: any) => (
            <Form.Item
              key={input.name}
              name={input.name}
              label={input.label}
              rules={input.rules}
            >
              <Input placeholder={input.placeholder} />
            </Form.Item>
          ))}
          <Typography.Text type="secondary">
            A integração pode demorar alguns minutos, por favor aguarde.
          </Typography.Text>
          <Divider />
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            className="full-width"
            loading={loading}
            disabled={initIntegration}
            icon={<></>}
          >
            {feature.buttonLabel}
          </Button>
        </Form>
      </Content>
    </Container>
  );
};
