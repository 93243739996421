import { Modal } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  padding: 0 32px 128px;
  @media only screen and (max-device-width: 812px) {
    padding: 0 16px 128px;
  }
`;

export const Content = styled.div`
  padding: 32px;
  margin: 0 auto;
  max-width: 600px;
  border-radius: 16px;
  background-color: var(--velotax-background-color);
  h1 {
    margin: 0 0 8px;
    font-size: 20px;
    color: var(--velotax-font-color);
  }
  h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    color: var(--velotax-font-color);
  }
  .ant-list.ant-list-split {
    margin: 24px 0 0;
    padding: 0 16px;
    background-color: var(--velotax-ghost);
    border: 1px solid var(--velotax-ghost);
    border-radius: 8px;
    .ant-list-item-action > li {
      padding: 0;
      button {
        margin: 0 auto;
      }
    }
    .ant-list-item-meta-description {
      opacity: 0.75;
    }
    .ant-list-item-action > li,
    .ant-list-item-meta-title,
    .ant-list-item-meta-description {
      color: var(--velotax-font-color);
    }
    .ant-list-item-action-split {
      opacity: 0;
    }
  }
  @media only screen and (max-device-width: 812px) {
    padding: 24px;
    .ant-list.ant-list-split {
      .ant-list-item-action {
        display: grid;
        align-items: end;
        grid-template-rows: 1fr 0.75fr;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
`;

export const ConfirmModal = styled(Modal)`
  .p {
    font-size: 16px;
    strong {
      font-weight: 500;
      color: var(--ant-primary-color);
    }
  }
`;
