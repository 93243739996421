import { Menu } from "antd";
import { BsChat } from "react-icons/bs";
import { BiUserCircle } from "react-icons/bi";
import { useEffect, useRef, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "./styles";
import { useAuth } from "../../contexts/AuthContext";
import { useBroker } from "../../contexts/BrokerContext";
import { ReactComponent as BolsaIcon } from "../../assets/calculadoras/bolsa-de-valores.svg";
import { ReactComponent as CryptoIcon } from "../../assets/calculadoras/criptomoedas.svg";
import { ReactComponent as ExteriorIcon } from "../../assets/calculadoras/ativos-no-exterior.svg";

export const TabNavigator = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { currentBroker, xtageIntegration } = useBroker();
  const {
    chatOpen,
    setChatOpen,
    b3Authorized,
    chatNotificationQtt,
    forceUpdateTabNavigator,
  } = useAuth();

  const [activeKey, setActiveKey] = useState<string>("");
  useEffect(() => {
    const path = pathname.substring(1, 40);
    setActiveKey(path);
  }, [navigate])

  const menuItems = useMemo(
    () => [
      {
        key: "carteira",
        label: "Carteira",
        icon: <BiUserCircle />,
      },
      {
        key: "xpinvestimentos/bolsa",
        label: "Bolsa",
        icon: <BolsaIcon />,
      },
      {
        key: "xpinvestimentos/exterior",
        label: "Exterior",
        icon: <ExteriorIcon />,
      },
      // {
      //   key: "xpinvestimentos/cripto",
      //   label: "Cripto",
      //   icon: <CryptoIcon />,
      // },
      {
        key: "chat",
        label: "Chat",
        icon: <BsChat />,
      },
    ],
    [chatNotificationQtt]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const element = document.getElementById('chatbase-bubble-window');
      setChatOpen(element?.style.display == 'flex');
    }, 500)
    return () => {
      clearInterval(interval);
    }
  }, [])

  useEffect(() => {
    if (!chatOpen)
    {
      const matchItem = menuItems
        .filter((item) => item.key !== "/")
        .find(
          (item) =>
            (item.key.includes("exterior") &&
              pathname.includes("transferencia-custodia")) ||
            pathname.includes(item.key)
        );
      setActiveKey(matchItem?.key || "carteira");
    }
  }, [chatOpen])

  return (
    <Container>
      <Menu
        mode="horizontal"
        disabledOverflow
        activeKey={activeKey}
        selectedKeys={[activeKey]}
        style={{ justifyContent: "space-between" }}
        onClick={(e) => {
          const element = document.getElementById('chatbase-bubble-window');
          if (element && element !== null) element.style.display = 'none';
          setActiveKey(e.key);
          if (e.key === "chat") {
            document.getElementById("chatbase-bubble-button")?.click();
          } else {
            navigate(
              e.key === `/${currentBroker.path}/bolsa` && !b3Authorized
                ? `/${currentBroker.path}/bolsa-integration`
                : e.key === `/${currentBroker.path}/cripto` && !xtageIntegration
                ? `/${currentBroker.path}/cripto-integration`
                : e.key
            );
          }
        }}
      >
        {menuItems.map((item) => (
          <Menu.Item key={item.key} icon={item.icon}>
            {item.label}
          </Menu.Item>
        ))}
      </Menu>
      <span className="hidden">{forceUpdateTabNavigator}</span>
    </Container>
  );
};
