import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Container } from "./styles";

interface BackButtonProps {
  to: string;
  className?: string;
}

export const BackButton: React.FC<BackButtonProps> = ({ to, className }) => {
  const navigate = useNavigate();
  return (
    <Container className={className}>
      <Button
        variant="text"
        color="secondary"
        onClick={() => {
          navigate(to);
        }}
      >
        Voltar
      </Button>
    </Container>
  );
};
