import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  body {
    --white: #ffffff;
    --black: #000000;
    --green: #08CC88;
    --velotax-ghost: #f4f6f8;
    --velotax-ghost-white: #f4f6f8;
    --velotax-disabled: #626368;
    --velotax-font-color: #272A30;
    --velotax-font-color-ghost: #9099aa;
    --velotax-background-color: #ffffff;
    --velotax-background-color-ghost: #eaecee;
    --velotax-shadow-primary: rgba(0, 0, 88, 0.24);
    --velotax-shadow-default: rgba(144, 153, 170, 0.16);
    --velotax-green-amount: #00B300;
    --velotax-red-prejuizo: #ff0000;
    --velotax-orange: #ff8400;
    --em-aberto: #9ABC;
    &.dark {
      --velotax-ghost: #101112;
      --velotax-ghost-white: #848688;
      --velotax-disabled: #525358;
      --velotax-font-color: #ABAAA8;
      --velotax-font-color-dark: #272A30;
      --velotax-font-color-light: #FFFFFF;
      --velotax-font-color-ghost: #808080;
      --velotax-background-color: #1f1f1f;
      --velotax-background-color-ghost: #121212;
      --velotax-shadow-primary: rgba(0, 0, 88, 0.24);
      --velotax-green-amount: #00B300;
      --velotax-red-prejuizo: #ff0000;
      --velotax-shadow-default: rgba(144, 153, 170, 0.16);
    }
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  path.recharts-sector {
    stroke: var(--velotax-background-color);
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    height: 100%;
    overflow: hidden;
    color: var(--velotax-font-color);
  }

  body {
    -webkit-font-smoothing: antialiased;
    position: relative;
    width: 100% !important;
    background: var(--velotax-background-color);
  }

  body, input, button {
    font: 14px 'Roboto', sans-serif;
    font-weight: 300;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
    background: transparent;
    border: 0;
    outline: 0;
    color: var(--white);
    letter-spacing: 0.4px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--velotax-font-color);
  }

  body > iframe {
    display: none;
  }
  .ant-steps-item-finish .ant-steps-item-icon { 
    background-color: var(--ant-primary-color);
  }

  .full-width {
    width: 100%;
  }

  .column-center {
    text-align: center;
  }

  .MuiButtonBase-root.MuiButton-containedSecondary.Mui-disabled {
    opacity: 0.35;
    color: var(--velotax-font-color-dark) !important;
    background-color: var(--ant-primary-color) !important;
  }

  .beauty-scrollbar.zero-scrollbar {
    &::-webkit-scrollbar,
    ::-webkit-scrollbar {
      width: 0;
    }
  }

  .modal-drawer.modalProventos {
    width: calc(100% - 128px) !important;
  }

  .notas-corretagem-historico-modal,
  .ant-modal.modal-drawer.modalProventos,
  .modal-drawer.modalProventos {
    .ant-modal-content {
      background-color: var(--white);
      .ant-modal-close {
        top: 8px;
        right: 8px;
        .anticon {
          color: var(--ant-primary-color);
        }
      }
      .ant-modal-header {
        padding: 64px 64px 32px;
        background-color: var(--white);
        .ant-modal-title {
          font-weight: 900;
          color: var(--velotax-font-color-dark);
        }
      }
      .ant-modal-body {
        padding: 32px 64px 32px;
      }
    }
  }

  .ant-drawer.modal-drawer.modalProventos {
    .ant-drawer-content {
      background-color: var(--white) !important;
      .ant-drawer-wrapper-body {
        .ant-drawer-header {
          .ant-drawer-header-title {
            .ant-drawer-title {
              color: var(--velotax-font-color-dark) !important;
            }
          }
        }
      }
    }
  }

  .modal-drawer.modalZeroBuyPrice {
    .ant-modal-body {
      padding: 0px 32px 16px !important;
    }
  }

  @media only screen and (max-device-width: 812px) {
    .modal-drawer.modalProventos {
      width: 100% !important;
    }

    #chatbase-bubble-button {
      display: none;
    }

    #chatbase-bubble-window {
      top: 0px;
      box-shadow: none !important;
      width: 50px;
      height: calc(100% - 64px) !important;
    }
  }

  .beauty-scrollbar.slim-scrollbar {
    &::-webkit-scrollbar,
    ::-webkit-scrollbar {
      width: 4px;
    }
  }

  .ant-modal-wrap,
  .beauty-scrollbar {
    &::-webkit-scrollbar,
    ::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb,
    ::-webkit-scrollbar-thumb {
      border-radius: 0;
      background-color: rgb(251, 193, 5);
    }

    &::-webkit-scrollbar-track,
    ::-webkit-scrollbar-track {
      background-color: var(--velotax-background-color-ghost);
    }
  }

  .input {

    .label {
      margin-bottom: 6px;
    }

    & + div {
      margin-top: 12px;
    }
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .error {
    margin: 12px auto -12px auto;
    color: var(--ant-error-color);
  }

  .loading-session {
    background: var(--ant-info-color);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 250px;
      padding: 20px;
    }

    .anticon {
      color: var(--white);

      svg {

        height: 40px;
        width: 40px;
      }
    }
  }

  .hiring-modal {
    .ant-typography {
      color: var(--velotax-font-color);
    }
    .ant-modal-title::before {
      display: none;
    }
    .hiring-modal-title {
      font-size: 32px;
      font-weight: bold;
      margin: 32px auto;
      text-align: center;
      color: var(--ant-primary-color);
    }
  }

  .ant-modal .malha-fina-button {
    width: 100% !important;
  }

  ::placeholder {
    color: var(--velotax-font-color-ghost) !important;
  }
  .ant-upload {
    color: var(--velotax-font-color);
    background-color: var(--velotax-ghost);
    border: none !important;
  }

  .ant-btn-text:focus,
  .ant-btn-text:hover {
    color: var(--velotax-font-color-ghost);
  }

  .ant-card,
  .ant-dropdown-menu,
  .ant-layout-sider-light,
  .ant-menu,
  .ant-modal-content,
  .ant-modal-header,
  .ant-select-dropdown,
  .ant-select-item {
    background-color: var(--velotax-background-color);
  }

  .anticon,
  .ant-btn-text,
  .ant-checkbox + span,
  .ant-divider-inner-text,
  .ant-dropdown-menu,
  .ant-dropdown-menu-item,
  .ant-empty,
  .ant-form,
  .ant-form-item-label > label,
  .ant-input,
  .ant-input-number-input,
  .ant-list,
  .ant-list-item,
  .ant-modal,
  .ant-modal-title,
  .ant-radio-wrapper,
  .ant-select,
  .ant-select-dropdown,
  .ant-select-item,
  .ant-tabs,
  .ant-upload-list-item-name,
  .ant-typography {
    color: var(--velotax-font-color-light);
  }
  .ant-tabs-tab.ant-tabs-tab-disabled {
    color: var(--velotax-disabled) !important;
  }
  .ant-input[disabled],
  .ant-select-disabled .ant-select-selector {
    color: var(--velotax-disabled) !important;
    background-color: var(--velotax-ghost);
  }
  .ant-select-item:hover,
  .ant-dropdown-menu-item:hover,
  .ant-select-item-option-active {
    background-color: var(--velotax-background-color-ghost) !important;
  }
  .ant-select-item-option-selected {
    color: var(--ant-primary-color) !important;
    background-color: var(--velotax-background-color-ghost) !important;
  }
  .ant-modal-footer,
  .ant-divider {
    border-color: var(--velotax-ghost-white);
  }
  .ant-btn.ant-btn-icon-only.ant-btn-primary {
    svg {
      fill: var(--white);
    }
  }
  .ant-btn.ant-btn-icon-only {
    svg {
      fill: var(--velotax-font-color);
    }
  }

  .ant-select.year-select {
    min-width: 120px;
    .ant-select-arrow {
      top: calc(50% - 6px);
      width: 24px;
      height: 24px;
      svg {
        fill: var(--velotax-font-color);
      }
    }
  }

  .success-icon {
    svg {
      fill: var(--ant-success-color) !important;
    }
  }
  .error-icon {
    svg {
      fill: var(--ant-error-color) !important;
    }
  }

  .footer-secure {
    position: fixed;
    background: var(--white);
    height: 56px;
    bottom: 24px;
    right: 104px;
    z-index: 10;
    display: flex;
    justify-content: flex-end;
    padding: 0 10px;
    max-height: 64px;
    border-radius: 6px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.1);

    &.goleft {
      left: 32px;
      right: auto;
    }

    img {
      width: 70px;
    }
  }

  .footer-velotax {
    left: 0;
    right: auto;
    row-gap: 5px;
    display: flex;
    box-shadow: none;
    align-items: center;
    flex-direction: column;
    background-color: transparent;
    transform: scale(0.75) translateY(16px);
    opacity: 0.625;
    color: var(--velotax-font-color);
    :hover {
      color: var(--velotax-font-color);
      i {
        opacity: 0.8;
      }
    }

    img {
      width: 128px;
    }

    i {
      font-size: 12px;
      font-weight: 600;
    }
  }

  .poweredby-velotax {
    height: 64px;
    padding: 6px 10px;
    max-height: 64px;
    row-gap: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--velotax-font-color);
    :hover {
      color: var(--velotax-font-color);
      i {
        opacity: 0.8;
      }
    }
    
    &.sidebar {
      img {
        height: 20px;
      }
      i {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }

  /**
  * ANT-RADIO
  */
  .ant-radio-group {
    display: flex;
    row-gap: 8px;
    flex-direction: column;

    .ant-radio-wrapper {
      font-size: 18px;

      .ant-radio {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }

      .ant-radio-inner {
        width: 22px;
        height: 22px;
        border-width: 2px;

        &:after {
          top: 2px;
          left: 2px;
          width: 14px;
          height: 14px;
          border-radius: 8px;
        }
      }
    }
  }

  /**
  * ANT-INPUT
  */
  .ant-input {
    font-weight: 500;
    border-radius: 8px;
    border: 2px solid var(--velotax-ghost);
    background-color: var(--velotax-ghost);

    &:hover, &:focus {
      border-right-width: 2px !important;
    }

    &:focus {
      box-shadow: 0 0 0 4px var(--ant-primary-color-outline);
    }

    &[disabled] {
      cursor: default;
      border-color: var(--velotax-ghost);
    }

    &.ant-input-lg {
      height: 56px;
    }
  }

  .ant-form-item-has-error {
    .ant-input:not(.ant-input-disabled):not(.ant-input-borderless) {
      background-color: var(--velotax-ghost) !important;
    }

    .ant-input:not(.ant-input-disabled):not(.ant-input-borderless):focus {
      border-right-width: 2px !important;
      box-shadow: 0 0 0 4px var(--ant-error-color-outline) !important;
    }
  }

  .ant-form-item-explain {
    margin-top: 4px;
  }

  /**
  * ANT-INPUT-NUMBER
  */
  .ant-input-number {
    font-weight: 500;
    border-radius: 8px;
    border: 2px solid var(--velotax-ghost);
    background-color: var(--velotax-ghost);
    display: flex;
    align-items: center;

    &:hover {
      border-right-width: 2px !important;
    }

    &.ant-input-number-focused {
      border-right-width: 2px !important;
      border-color: var(--ant-primary-color);
      box-shadow: 0 0 0 4px var(--ant-primary-color-outline);
    }

    &.ant-input-number-lg {
      height: 56px;
    }

    &.ant-input-number-disabled,
    &.ant-input-number-disabled .ant-input-number-input {
      cursor: default;
    }

    .ant-input-number-handler-wrap {
      display: none;
    }

    .ant-input-number-input-wrap {
      .ant-input-number-input {
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

  .ant-form-item-has-error {
    .ant-input-number:not([disabled]):hover,
    .ant-input-number-focused:not([disabled]) {
      background-color: var(--velotax-ghost-white) !important;
      border-right-width: 2px !important;
      border-color: var(--ant-error-color);
      box-shadow: 0 0 0 4px var(--ant-error-color-outline) !important;
    }
  }

  /**
  * ANT-SELECT
  */
  .ant-select {
    border-radius: 0px !important;

    &[disabled],
    &.ant-select-disabled,
    &.ant-select-disabled input,
    &.ant-select-disabled .ant-select-selector {
      cursor: default !important;
    }
  }

  .ant-select .ant-select-selector {
    border-radius: 0px !important;
    border: none !important;
    border-bottom: 2px solid var(--ant-primary-color) !important;
    background-color: var(--velotax-ghost) !important;
    .ant-select-selection-search, .ant-select-selection-item {
      display: flex;
      align-items: center;
      font-weight: 500 !important;
    }
  }

  .ant-select-focused.ant-select-open {
    box-shadow: none !important;

    .ant-select-selector {
      border-color: var(--ant-primary-color) !important;
    }
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none !important;
  }

  .ant-form-item-has-error {
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      box-shadow: nonde !important;
    }
  }

  .ant-select-lg, .ant-select-lg .ant-select-selector {
    height: 56px !important;

    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
    }
  }

  /**
  * ANT-DROPDOWN
  */
  .ant-dropdown,
  .ant-select-dropdown {
    border-radius: 4px;
    box-shadow: none;
    border: 2px solid var(--ant-primary-color);

    .ant-select-item,
    .ant-dropdown-menu-item {
      font-size: 16px
    }

    .ant-dropdown-menu {
      border-radius: 4px;
    }
  }

  /**
  * ANT-MODAL
  */
  .ant-modal-root {
    .ant-modal {
      top: 64px;

      .ant-modal-content {
        border-radius: 4px;

        .ant-modal-close {
          top: 16px;
          right: 16px;

          .ant-modal-close-x {
            .anticon.anticon-close.ant-modal-close-icon {
              font-size: 20px;
            }
          }
        }

        .ant-modal-header {
          border-bottom: none;
          padding: 32px 64px 32px 32px;
          border-radius: 16px 16px 0 0;

          .ant-modal-title {
            font-size: 20px;
            position: relative;

            &::after {
              content: '';
              left: 0;
              width: 50px;
              height: 2px;
              bottom: -12px;
              position: absolute;
              background-color: var(--ant-primary-color);
            }
          }
        }

        .ant-modal-body {
          padding: 32px 32px 16px;
        }
      }

      @media only screen and (max-device-width: 812px) {
        width: 100% !important;
        max-width: calc(100% - 32px);
        margin: 0 16px;
        padding-bottom: 54px;
      }
    }
  }

  /**
  * ANT-TABLE
  */
  .ant-table {
    .ant-table-cell {
      background-color: var(--white);
      :before {
        background-color: transparent !important;
      }
    }
    .ant-table-thead > tr > th {
      color: var(--velotax-font-color-dark);
    }
  }
  .ant-pagination-item-link {
    .anticon {
      color: inherit;
    }
  }
  /* .ant-table-title + .ant-table-container {
    border-radius: 12px;
  }
  .ant-table,
  .ant-table-title,
  .ant-table-footer {
    color: var(--velotax-font-color);
    background-color: var(--velotax-background-color);
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover,
  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background-color: var(--velotax-background-color-ghost);
  }

  .ant-table-title {
    font-weight: 600;
    padding: 16px 0;
  }

  .ant-table-container {
    border-radius: 12px;
    border: 1px solid var(--velotax-ghost-white);
    background-color: var(--velotax-background-color);

    .ant-table-content {
      border-radius: 8px;

      overflow-x: auto;
      @media only screen and (min-device-width: 813px) {
        &::-webkit-scrollbar,
        ::-webkit-scrollbar {
          height: 4px;
        }

        &::-webkit-scrollbar-thumb,
        ::-webkit-scrollbar-thumb {
          border-radius: 0;
          background-color: var(--ant-primary-color);
        }

        &::-webkit-scrollbar-track,
        ::-webkit-scrollbar-track {
          background-color: var(--velotax-background-color-ghost);
        }
      }

      .ant-table-thead {
        .ant-table-cell {
          color: var(--velotax-font-color);
          border-bottom-color: var(--velotax-ghost-white);
          background-color: var(--velotax-ghost);
          &::before {
            display: none;
          }

          &:first-child {
            border-radius: 12px 0 0 0;
          }

          &:last-child {
            border-radius: 0 12px 0 0;
          }
        }
      }

      .ant-table-tbody {
        .ant-table-cell {
          border-bottom-color: var(--velotax-ghost-white);
        }
        .ant-table-row:last-child {
          .ant-table-cell {
            border-bottom: none;

            &:first-child {
              border-radius: 0 0 0 12px;
            }

            &:last-child {
              border-radius: 0 0 12px 0;
            }
          }
        }
      }

      .ant-table-placeholder {
        .ant-table-cell {
          border-bottom: none;
        }
      }
    }

    .ant-table-row-error {
      .ant-table-cell {
        border-top: 1px solid;
        border-bottom: 1px solid !important;
        border-color: var(--ant-error-color) !important;

        &:first-of-type {
          border-left: 1px solid;
        }

        &:last-child {
          border-right: 1px solid;
        }
      }

      svg.error-icon {
        display: block;
        margin: 0 auto;
        fill: var(--ant-error-color);
      }
    }

    .ant-table-row-error + .ant-table-row-error {
      .ant-table-cell {
        border-top: none;
      }
    }
  }
  */

  /**
  * ANT-DESCRIPTIONS
  */
  .ant-descriptions.ant-descriptions-bordered {
    .ant-descriptions-view {
      border-radius: 8px;
      border-color: var(--velotax-background-color-ghost);

      tbody {
        .ant-descriptions-row {
          border-color: var(--velotax-background-color-ghost);
          .ant-descriptions-item-label {
            color: var(--velotax-font-color);
            background-color: var(--velotax-ghost);
            border-color: var(--velotax-background-color-ghost);
          }
          .ant-descriptions-item-content {
            background-color: var(--velotax-background-color);
            color: var(--velotax-font-color) !important;
          }
        }
      }
    }
  }

  /**
  * ANT-TABS
  */
  .ant-tabs {
    &.fillers-tabs {
      margin-top: -48px;

      .ant-tabs-nav {
        height: 32px;
        max-width: 600px;
        margin: 0 auto 16px;

        .ant-tabs-nav-wrap {
          justify-content: space-around;

          @media only screen and (max-device-width: 812px) {
            .ant-tabs-tab {
              display: none;
              &.ant-tabs-tab-active {
                display: inline-flex;
              }
            }
          }
        }
      }

      .ant-tabs-nav-operations {
        display: none;
      }
    }

    &.operations-tabs {
      margin-top: 0;
      overflow-x: auto;

      .ant-tabs-content-holder {
        min-width: 756px;
      }

      .ant-tabs-nav {
        margin: 0;
        height: auto;
        padding: 0 8px;
        min-width: 756px;
        max-width: 100%;

        .ant-tabs-nav-wrap {
          .ant-tabs-nav-list {
            width: 100%;
          }

          .ant-tabs-tab {
            display: inline-flex;
            @media only screen and (min-device-width: 813px) {
              width: calc(100% / 12);
            }
          }

          .ant-tabs-tab + .ant-tabs-tab {
            margin: 0 0 0 16px;
          }

          &:before {
            top: 4px;
            height: calc(100% - 8px);
            box-shadow: inset 10px 0 8px -10px rgb(0 0 0 / 8%);
          }
        }
      }

      .ant-tabs-nav-operations {
        display: flex;
      }
    }

    &.operations-report-tabs {
      margin-top: 0;

      .ant-tabs-nav {
        margin: 0;
        padding: 0;
        width: 64px;
        height: auto;

        .ant-tabs-tab {
          display: inline-flex !important;
          margin: 0 !important;
        }
      }

      .ant-tabs-nav-operations {
        display: flex;
      }
    }

    .ant-tabs-nav {
      margin: 0;

      &::before {
        display: none;
      }

      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            .ant-tabs-tab-btn {
              font-weight: 500;
            }
          }

          .ant-tabs-tab-disabled {
            cursor: default;
          }

          .ant-tabs-ink-bar {
            max-width: 32px;
          }
        }
      }
    }

    .anticon svg {
      color: var(--ant-primary-color);
    }
  }

  /**
  * ANT-COLLAPSE
  */
  .ant-collapse,
  .ant-collapse.ant-collapse-borderless {
    background-color: transparent;
    /* background-color: var(--white); verificar */
    .ant-collapse-item {
      border-bottom: none;
      .ant-collapse-header {
        color: var(--velotax-font-color);
      }
    }
  }

  /**
  * ANT-LIST
  */
  .ant-list {
    &.ant-list-vertical {
      .ant-list-item {
        .ant-list-item-extra {
          text-align: right;
        }
      }
    }
  }
  .ant-list.ant-list-split {
    .ant-list-item {
      border-bottom-color: var(--velotax-background-color-ghost);
    }
  }
  .ant-list-empty-text {
    opacity: 0.75;
    color: var(--velotax-font-color);
  }

  .ant-list-loading {
    .ant-spin-blur:after {
      opacity: 0;
    }
  }

  /**
  * ANT-STEPS
  */
  .ant-steps {
    .ant-steps-item {
      &.ant-steps-item-active .ant-steps-item-title {
        color: var(--ant-primary-color) !important;
      }
      &.ant-steps-item-disabled .ant-steps-item-title {
        color: var(--velotax-font-color-ghost) !important;
      }
      .ant-steps-item-title {
        color: var(--velotax-font-color) !important;
      }
    }
    .ant-steps-item-process
        > .ant-steps-item-container
        > .ant-steps-item-tail::after,
      .ant-steps-item-wait
        > .ant-steps-item-container
        > .ant-steps-item-tail::after {
          background: var(--velotax-background-color-ghost);
      }
  }

  /**
  * ANTICON-LOADING
  */
  .anticon.anticon-loading.anticon-spin {
    width: 24px;
    height: 24px;
    svg {
      width: 24px;
      height: 24px;
    }
  }

  /**
  * ANT-MESSAGE
  */
  .ant-message {
    .ant-message-notice {
      text-align: right;
      max-width: calc(100% - 8px);
    }
  }

  /**
  * ANT-PICKER
  */
  .ant-picker:not(.original-date-picker) {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid var(--ant-primary-color);
    background-color: var(--velotax-ghost);
    &.ant-picker-large {
      height: 56px;
      border-radius: 0;
    }
    &.ant-picker-disabled {
      background-color: var(--velotax-ghost);
      .ant-picker-input input {
        opacity: 0.75;
      }
    }
    .ant-picker-input input {
      color: var(--velotax-font-color);
    }
    .ant-picker-suffix {
      svg {
        fill: var(--velotax-font-color);
      }
    }
    &.ant-picker-focused {
      box-shadow: none;
      .ant-picker-input input {
        color: var(--ant-primary-color);
      }
      .ant-picker-suffix {
        svg {
          fill: var(--ant-primary-color);
        }
      }
    }
  }
  .ant-picker-dropdown:not(.original-date-picker) {
    .ant-picker-panel {
      border-bottom: none;
    }
    .ant-picker-panel-container {
      * {
        color: var(--velotax-font-color);
      }
      border-radius: 4px;
      background-color: var(--velotax-background-color);
      border: 2px solid var(--velotax-background-color-ghost);
      .ant-picker-header {
        border-bottom-color: var(--velotax-background-color-ghost);
      }
      .ant-picker-body {
        padding: 8px 0;
        .ant-picker-content {
          height: 240px;
          .ant-picker-cell-inner {
            height: 48px;
            line-height: 48px;
            border-radius: 6px;
            border: 1px solid var(--velotax-background-color-ghost);
          }
        }
      }
      .ant-picker-footer {
        border-top-color: var(--velotax-background-color-ghost);
      }
    }
    .ant-picker-cell:hover:not(.ant-picker-cell-selected) .ant-picker-cell-inner {
      opacity: 0.45;
      background-color: var(--velotax-ghost) !important;
    }
  }

  .ant-notification {
    .anticon.anticon-loading.anticon-spin {
      svg {
        fill: var(--ant-primary-color);
      }
    }
  }

  body.dark .ant-picker-dropdown .ant-picker-panel-container {
    box-shadow: 0 3px 6px -4px #6666661E, 0 6px 16px 0 #66666614, 0 9px 28px 8px #6666660C;
  }

  .ant-picker-cell-disabled {
    opacity: 0.2;
  }

  .sample-payed::after,
  .sample-regular::after,
  .sample-pending::after,
  .sample-not-payed::after,
  .ant-picker-cell-inner.payed::after,
  .ant-picker-cell-inner.regular::after,
  .ant-picker-cell-inner.pending::after,
  .ant-picker-cell-inner.not-payed::after {
    content: "";
    top: -4px;
    right: -4px;
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--ant-primary-color);
  }
  .sample-payed::after,
  .sample-regular::after,
  .ant-picker-cell-inner.payed::after,
  .ant-picker-cell-inner.regular::after {
    background-color: var(--ant-success-color);
  }
  .sample-pending::after,
  .ant-picker-cell-inner.pending::after {
    background-color: var(--ant-error-color);
  }
  .sample-not-payed::after,
  .ant-picker-cell-inner.not-payed::after {
    display: none;
  }
  .sample-payed,
  .sample-regular,
  .sample-pending,
  .sample-not-payed {
    position: relative;
    &::after {
      top: 11px;
      left: 0px;
    }
    span {
      margin-left: 24px;
    }
  }

  /**
  * ANT-UPLOAD
  */
  .ant-upload-text-icon {
    svg {
      fill: var(--ant-primary-color);
    }
  }

  .anticon.anticon-delete {
    svg {
      fill: var(--ant-error-color);
    }
  }

  .ant-upload-list-item .ant-upload-list-item-info {
    padding: 8px;
    height: 32px;
    border-radius: 8px;
  }
  .ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: var(--velotax-ghost);
  }

  .progress {
    height: 5px;
    z-index: 1;
    background: var(--ant-primary-color);
    position: fixed;
    -webkit-transition: width 0.6s ease-in-out;
    -moz-transition: width 0.6s ease-in-out;
    -o-transition: width 0.6s ease-in-out;
    transition: width 0.6s ease-in-out;
  }

  .xmovel-content.ant-collapse-item {
    border-top: none;
    margin-bottom: 16px;
    .ant-collapse-header {
      margin-bottom: 4px;
      border-radius: 8px !important;
      color: var(--velotax-font-color);
      background-color: var(--velotax-ghost-white) !important;
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }
    .ant-descriptions-view {
      table {
        tbody {
          .ant-descriptions-row {
            .ant-descriptions-item-label {
              border-color: var(--velotax-background-color-ghost) !important;
            }
            .ant-descriptions-item-content {
              border-color: var(--velotax-background-color-ghost);
              padding: 8px 16px !important;
            }
          }
        }
      }
    }
  }
  body.dark {
    .xmovel-content.ant-collapse-item {
      .ant-collapse-header {
        background-color: var(--velotax-background-color-ghost) !important;
      }
      .ant-descriptions-view {
        border-color: var(--velotax-background-color-ghost) !important;
        table {
          tbody {
            .ant-descriptions-row {
              border-color: var(--velotax-disabled) !important;
              .ant-descriptions-item-label {
                border-color: var(--velotax-background-color-ghost) !important;
                background-color: var(--velotax-background-color-ghost) !important;
              }
              .ant-descriptions-item-content {
                border-color: var(--velotax-background-color-ghost);
              }
            }
          }
        }
      }
    }
  }

  .exterior-mode {
    flex-direction: row;
    .ant-radio-wrapper {
      color: var(--velotax-font-color-dark);
    }
  }

  /** MUI-BUTTON */
  .MuiButton-root {
    border-radius: 0 !important;
    box-shadow: none !important;
    text-transform: uppercase !important;
    &.MuiButton-sizeLarge {
      height: 53px;
      font-size: 16px !important;
    }

    &.MuiButton-containedInherit {
      background-color: var(--white);
      color: var(--velotax-font-color-dark);
    }

    .MuiButton-startIcon,
    .MuiButton-endIcon {
      svg {
        fill: var(--velotax-font-color-dark);
      }
    }
  }

  .MuiTextField-root {
    width: 100%;
  }

  .MuiInputBase-root {
    width: 100%;
  }

  .xp-link {
    color: #18191a;
    font-weight: bold;
    text-decoration: none;
    border-bottom: 2px solid var(--ant-primary-color);
  }

  .uppercase {
    text-transform: uppercase;
  }

  .exterior-content-title {
    display: block;
    font-weight: 600;
    text-align: center;
    font-size: 1.125rem;
    color: var(--velotax-font-color-dark);
    border-bottom: 2px solid var(--ant-primary-color);
  }

  .mui-select-with-description {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      font-size: 1rem;
    }
    .right {
      font-weight: 500;
      font-size: 0.675rem;
      letter-spacing: 0;
    }
  }
  .installments-select {
    width: 100%;
    text-transform: none !important;
    .installment-tax {
      text-align: right;
      font-size: 8px;
      line-height: 12px;
    }
  }
  @media only screen and (max-device-width: 812px) {
    .installments-select {
      .installment-tax {
        text-align: left;
      }
    }
    .column-reverse {
      flex-direction: column-reverse;
    }
    .desc-content {
      .ant-form-item {
        margin: 0;
      }
    }
  }

  .justify {
    text-align: justify !important;
  }

  .juridic-messages-container-integration {
    opacity: 0.8;
    margin: 0 auto;
    padding: 32px 45px;
    max-width: 600px;
    .message {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1rem;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      text-align: justify;
      line-height: 1rem;
      color: var(--velotax-font-color-light);
      margin-bottom: 0;
      i {
        font-weight: bold;
        color: var(--velotax-font-color-light);
      }
    }
    
  }
  .info-circle-red {
    svg {
      fill: red !important;
    }
  }

  @media only screen and (max-device-width: 450px) {
    .form-card-container {
      .rccs {
        width: auto;
        margin: 0 -12px;
        max-width: calc(100% + 24px);
        .rccs__card {
          width: auto;
          max-width: 100%;
        }
      }
    }
  }
  .ant-popover{
    z-index: 1000;
  }
  .ant-popover-inner{
    background-color: #282828;
    border-bottom: 2px solid rgb(251, 193, 5);
  }
  .ant-popover-title{
    color: rgb(255 255 255 / 85%);
    border-bottom: 1px solid rgb(255 255 255 / 10%);
  }
  .ant-popover-inner-content{
    color: rgb(255 255 255 / 85%);
    max-width: 240px;
  }
  .ant-popover-arrow-content{
    background-color: #282828;
  }
  .parametrizacao-modal {
    svg {
      margin-right: 0.5rem;
      transform: translateY(2px);
    }
    .ant-select{
      margin: 1rem 0 2rem 0;
      width: 100%;
    }
    .ant-select-selection-item{
      justify-content: center;
    }
  }
  .ant-drawer-content, .ant-drawer-header {
    background-color: var(--velotax-background-color);
    .ant-drawer-title, .ant-drawer-close {
      color: white;
    }
  }
  .ant-drawer-header {
    border-bottom: 1px solid var(--velotax-disabled);
  }
  .filter-modal {
    .ant-checkbox-group {
      display: block;
    }
    .corretoras-allcheck {
      margin-bottom: 8px;
    }
    .ant-checkbox-wrapper {
      font-size: 15px;
      align-items: center;
      .ant-checkbox {
        top: 0;
      }
      .ant-checkbox-checked {
        .ant-checkbox-inner {
          border-color: var(--ant-primary-color);
          background-color: var(--ant-primary-color);
          :after {
            width: 7px;
            height: 13px;
            top: calc(50% - 2px);
          }
        }
      }
      .ant-checkbox-inner {
        width: 20px;
        height: 20px;
        border-color: var(--velotax-ghost);
        background-color: var(--velotax-ghost);
      }
    }
  }
  .filter-proventos-modal {
    .ant-modal-content {
      background-color: var(--white);
      .ant-modal-close {
        .ant-modal-close-x {
          .anticon {
            color: var(--ant-primary-color);
          }
        }
      }
      .ant-modal-header {
        background-color: var(--white);
        .ant-modal-title {
          color: var(--velotax-font-color-dark);
        }
      }
      .ant-modal-body {
        .ant-checkbox + span,
        .ant-typography {
          color: var(--velotax-font-color-dark);
        }
        .ant-picker {
          display: flex;
          .ant-picker-suffix {
            .anticon {
              color: var(--velotax-font-color-dark);
            }
          }
          .ant-picker-input {
            input {
              font-weight: 500;
            }
          }
        }
        .ant-checkbox-wrapper {
          align-items: center;
          .ant-checkbox {
            top: 0;
          }
          .ant-checkbox-checked {
            .ant-checkbox-inner {
              border-color: var(--ant-primary-color);
              background-color: var(--ant-primary-color);
            }
          }
          .ant-checkbox-inner {
            width: 20px;
            height: 20px;
            border-color: #e8e8e8;
            background-color: #e8e8e8;
            :after {
              width: 7px;
              height: 13px;
              top: calc(50% - 2px);
            }
          }
        }
      }
    }
  }
  .ant-select-clear > span > svg {
    background-color: var(--velotax-background-color) !important;
  }
  .ant-select-multiple > .ant-select-selector > .ant-select-selection-overflow > .ant-select-selection-overflow-item > .ant-select-selection-item {
    background-color: var(--velotax-font-color-dark);
    border: none;
  }
  .ant-select-item-group {
    font-weight: 700;
    padding-top: 1rem;
  }
  .ant-select-item-group:first-of-type{
    padding-top: 0;
  }
  .ant-select-item-option:hover{
    background-color: var(--velotax-font-color-dark) !important;
  }
`;
