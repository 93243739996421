import { Image } from "antd";
import { LogoStyled } from "./styles";
import { useBroker } from "../../contexts/BrokerContext";
import VelotaxLogo from "../../assets/velotax/velotax-light.svg";

export const Logo: React.FC = () => {
  const { currentBroker } = useBroker();
  return (
    <LogoStyled>
      <a
        href={currentBroker?.link || "https://www.velotax.com.br/"}
        target="_blank"
        rel="noreferrer"
      >
        <Image
          id="velo-xp"
          preview={false}
          alt={currentBroker?.name || "Velotax logo light"}
          src={
            currentBroker.iconAlternative
              ? currentBroker.iconAlternative
              : currentBroker?.icon
              ? currentBroker?.icon
              : VelotaxLogo
          }
        />
      </a>
    </LogoStyled>
  );
};
