import styled from "styled-components";

export const Container = styled.div`
  padding: 64px 32px 128px;
  h1 {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2rem;
    color: var(--velotax-font-color-light);
    width: 700px;
    margin: 0 auto 16px;
    padding-bottom: 16px;
    position: relative;

    &::after {
      content: "";
      left: 0;
      bottom: 0;
      width: 100px;
      height: 2px;
      position: absolute;
      background-color: var(--ant-primary-color);
    }
  }
  p {
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--velotax-font-color-light);
    width: 700px;
    margin: 0 auto 32px;
  }
  @media only screen and (max-device-width: 812px) {
    min-height: 100%;
    padding: 24px 0 0;
    h1 {
      width: calc(100% - 48px);
      margin: 0 24px 16px;
    }
    p {
      width: calc(100% - 48px);
      padding: 0 0 24px;
      margin: 0 24px;
    }
  }
`;

export const Content = styled.div`
  padding: 24px;
  margin: 0 auto;
  width: 700px;
  border-radius: 4px;
  background-color: var(--velotax-background-color);

  h3 {
    color: var(--velotax-font-color);
  }

  span.link {
    cursor: pointer;
    color: var(--ant-primary-color);
  }

  .ant-typography:not(h3) {
    text-align: justify;
  }

  a.ant-typography,
  .ant-typography a {
    color: var(--ant-primary-color);
  }

  .ant-typography i strong {
    font-size: 13px;
    letter-spacing: 0.625px;
    word-spacing: 0.625px;
  }

  iframe {
    max-width: 100%;
  }

  .ant-list {
    margin-bottom: 1em;
  }

  .ant-collapse,
  .ant-collapse.ant-collapse-borderless {
    border-radius: 8px;
    background-color: transparent;
    border: 1px solid var(--velotax-font-color-ghost);
    .ant-collapse-item {
      .ant-collapse-header {
        padding: 16px;
        font-size: 1.125rem;
        color: var(--velotax-font-color-light);
        .ant-collapse-arrow {
          font-size: 0.875rem;
        }
      }
      .ant-collapse-content {
        border-bottom: 1px solid;
        color: var(--velotax-font-color-light);
        background-color: var(--velotax-background-color);
        border-color: var(--velotax-font-color-ghost);
      }
    }

    .ant-collapse-item:last-child {
      .ant-collapse-content {
        border-bottom: none;
        border-radius: 0 0 8px 8px;
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    padding: 0;
    width: 100%;
    border-radius: 0;

    .ant-collapse,
    .ant-collapse.ant-collapse-borderless {
      border-radius: 0;
      border: none;
    }
  }
`;
