import { Typography } from "antd";
import { Container, Content } from "./styles";
import ExteriorIntegration from "../../components/IntegrationComponents/ExteriorIntegration";

interface IntegrationExteriorProps {}

export const IntegrationExterior: React.FC<IntegrationExteriorProps> = () => {
  return (
    <Container>
      <Content>
        <Typography.Title level={1}>
          Integrações com corretoras de ativos no exterior
        </Typography.Title>
        <ExteriorIntegration />
      </Content>
    </Container>
  );
};
