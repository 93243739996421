import styled from "styled-components";

export const Container = styled.div`
  padding: 64px 32px 32px;

  h1 {
    width: 700px;
    margin: 0 auto 1.5rem;
    font-weight: 400;
    font-size: 2rem;
    position: relative;
    line-height: 2.5rem;
    padding-bottom: 1rem;
    color: var(--velotax-font-color-light);

    :after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 100px;
      background-color: var(--ant-primary-color);
    }
  }

  span.ant-typography {
    opacity: 0.8;
    font-weight: 400;
    font-size: 0.85rem;
    line-height: 1.5rem;
    text-align: justify;
    color: var(--velotax-font-color-light);
  }

  h2.ant-typography {
    display: flex;
    align-items: center;
    column-gap: 8px;
    width: 700px;
    margin: 0 auto 2.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: var(--velotax-font-color-light);
  }

  @media only screen and (max-device-width: 812px) {
    padding: 0 0 32px;

    h1 {
      width: calc(100% - 48px);
      margin: 0 24px 1.5rem;
      padding: 24px 0 1rem;
    }

    h2.ant-typography {
      width: 100%;
      margin: 0 auto 1.5rem;
      padding: 0 24px;
    }
  }
`;

export const FlexDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const BolsaOperations = styled.div`
  margin-top: 20px;
  border-radius: 4px;
  color: var(--velotax-font-color-light);
  background-color: var(--velotax-ghost);
  border: 1px solid var(--velotax-background-color-ghost);

  div.strong span {
    font-weight: 700 !important;
  }
  div.opacity {
    opacity: 0.8;
  }

  span.dividendos-header {
    background-color: var(--velotax-background-color-ghost) !important;
    font-weight: 700 !important;
  }
  div.header,
  div.sub-header,
  div.row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 0.5fr;
    span {
      text-align: center;
      justify-content: center;
    }
  }

  div.sub-header,
  div.row {
    height: auto;
    min-height: 48px;
    border-top: none;
    & + div.sub-header {
      border-top: 1px solid var(--velotax-background-color-ghost);
    }
    & > span + span {
      background-color: #3d3d3d;
    }
    & > span {
      display: flex;
      align-items: center;
      border-left: none;
    }
    &:last-of-type > span:last-of-type {
      border-radius: 0 0 4px 0;
    }
  }

  div.header {
    padding: 16px;
    border-radius: 4px 4px 0 0;
    background-color: var(--velotax-background-color-ghost);
    span {
      font-weight: 700;
    }
    button {
      display: none;
    }
  }

  div.sub-header {
    font-size: 14px;
    & > span {
      font-weight: 300;
    }
  }

  div.row {
    opacity: 0.85;
    font-size: 13px;
    border-top: 1px solid var(--velotax-background-color-ghost);
  }

  @media only screen and (max-device-width: 812px) {
    div.sub-header {
      font-size: 12px;
    }
  }
`;

export const Content = styled.div`
  padding: 32px;
  margin: 0 auto;
  width: 700px;
  border-radius: 4px;
  background-color: var(--velotax-background-color);
  &.notas {
    padding: 32px 0;
    background-color: transparent;
  }
  h1 {
    margin: 0 0 8px;
    font-size: 20px;
    color: var(--velotax-font-color);
  }
  h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    color: var(--velotax-font-color);
  }
  .ant-list.ant-list-split {
    margin: 24px 0 0;
    padding: 0 16px;
    background-color: var(--velotax-ghost);
    border: 1px solid var(--velotax-ghost);
    border-radius: 8px;
    .ant-list-item-action > li {
      padding: 0;
    }
    .ant-list-item-meta-description {
      opacity: 0.75;
    }
    .ant-list-item-action > li,
    .ant-list-item-meta-title,
    .ant-list-item-meta-description {
      color: var(--velotax-font-color);
    }
    .ant-list-item-action-split {
      opacity: 0;
    }
  }
  .historic-month-status {
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 4px;
    color: var(--black);
    display: flex;
    &.payed,
    &.regular {
      background-color: var(--ant-success-color);
    }
    &.pending {
      background-color: var(--ant-error-color);
    }
    &.not-payed {
      background-color: var(--velotax-font-color);
    }
  }
  @media only screen and (max-device-width: 812px) {
    padding: 24px 24px 32px;
    border-radius: 0;
    width: 100%;
    &.notas {
      padding: 32px 24px;
    }
    .ant-list.ant-list-split {
      .ant-list-item-action {
        display: grid;
        align-items: end;
        grid-template-rows: 1fr 0.75fr;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
`;
