import styled from "styled-components";

export const AuthContainer = styled.section`
  margin: 80px auto 0;
  height: calc(100% - 80px);
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--white);

  .auth-content {
    min-height: 100%;
    margin: 0 auto;
    max-width: calc(100% - 128px);
    padding: 48px 0;
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;

    h1 {
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 2rem;
      color: var(--velotax-font-color-dark);
      margin: 0 0 32px;
    }

    p {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
      color: var(--velotax-font-color-dark);
      margin: 0 0 32px;
      text-align: center;
    }

    .form-container {
      width: 420px;

      form {
        row-gap: 0;
        display: flex;
        flex-direction: column;
      }

      .bottom-acessar {
        display: flex;
        justify-content: center;
      }
    }
  }

  .buttons {
    margin: 16px 0 8px;
    display: flex;
    column-gap: 16px;
    align-items: center;
    justify-content: space-between;
  }

  .btn-underlined {
    padding: 0;
    height: 32px;
    font-weight: 600;
    border-radius: 0;
    border-bottom: 2px dashed var(--ant-primary-color);
  }

  .footer {
    display: flex;
    column-gap: 16px;
    align-items: center;
    width: 100%;
    padding: 16px 0;
    margin: 24px auto 0;
    color: var(--velotax-font-color-dark);
    border-top: 1px solid rgba(0, 0, 0, 0.42);
  }

  .ant-checkbox-wrapper {
    a,
    span {
      color: var(--velotax-font-color-dark);
    }
    a {
      font-weight: 600;
    }
  }

  .checkbox {
    margin-top: 8px;
  }

  @media screen and (max-width: 576px) {
    .auth-content {
      border-left: none;
      padding: 64px 24px 0;

      :before {
        display: none;
      }

      .form-container {
        width: 100%;
      }
    }

    .buttons {
      button {
        width: 100%;
      }

      button + button {
        display: none;
      }
    }

    .footer {
      margin: 48px auto 0;
    }
  }

  @media only screen and (max-device-width: 812px) {
    .auth-content {
      max-width: 100%;
      padding: 48px 24px 64px;
      border-left: none;
    }
  }
`;
