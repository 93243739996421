import Payment from "payment";
import Masked from "react-input-mask";
import { TextField } from "@mui/material";
import { Focused } from "react-credit-cards";
import NumberFormat from "react-number-format";
import React, { forwardRef, useMemo } from "react";
import { Col, Form, FormInstance, Row } from "antd";
import { SelectLabled } from "../../../../../constants/inputs";
import getInstallmentsListByPrice from "../../../../../utils/getInstallmentsListByPrice";
import { cpfInputMask, getMuiInputCss } from "../../../../../utils";
import {
  validationAmexCVV,
  validationCardShelfLife,
  validationCpf,
  validationCreditCardNumber,
  validationCVV,
  validationFieldRequired,
  validationPhoneRequired,
} from "../../../../../utils/formValidations";

interface ICardFormProps {
  number: string;
  focused?: Focused;
  form: FormInstance;
  uniqueValue?: number;
  setCvc: (cvc: string) => void;
  setName: (name: string) => void;
  setPhone: (value: string) => void;
  setNumber: (number: string) => void;
  setExpiry: (expiry: string) => void;
  setFocused: (focused: Focused) => void;
  setHolderDocument: (value: string) => void;
  setIsValidCardForm: (isValidCardForm: boolean) => void;
  handleBack: (event: any) => void;
  fixedInstallments?: number;
  signature?: boolean;
  installmentSelected?: number;
  customInstallments?: number;
  addInfo?: Record<string, any>;
}

const CreditCardNumber = forwardRef((props, ref) => (
  <NumberFormat {...props} mask="_" format="#### #### #### ####" />
));

const CreditCardNumberDiner = forwardRef((props, ref) => (
  <NumberFormat {...props} mask="_" format="#### ###### ####" />
));

const CreditCardNumberAmex = forwardRef((props, ref) => (
  <NumberFormat {...props} mask="_" format="#### ###### #####" />
));

const ExpiryInput = forwardRef((props, ref) => (
  <Masked {...props} mask="99/99" />
));

const PhoneInput = forwardRef((props, ref) => (
  <Masked {...props} mask="(99) 99999-9999" />
));

const CPFInput = forwardRef((props, ref) => (
  <Masked {...props} mask={cpfInputMask} />
));

const CVVAmexInput = forwardRef((props, ref) => (
  <NumberFormat
    {...props}
    mask="_"
    format="####"
    className={`MuiInputBase-input MuiInput-input ${getMuiInputCss()}`}
  />
));

const CVVInput = forwardRef((props, ref) => (
  <NumberFormat
    {...props}
    mask="_"
    format="###"
    className={`MuiInputBase-input MuiInput-input ${getMuiInputCss()}`}
  />
));

export const CardForm: React.FC<ICardFormProps> = ({
  form,
  number,
  setCvc,
  setName,
  setPhone,
  setNumber,
  setExpiry,
  setFocused,
  setHolderDocument,
  setIsValidCardForm,
  uniqueValue,
  fixedInstallments,
  handleBack,
  signature,
  installmentSelected,
  customInstallments,
  addInfo
}) => {
  const cardType = useMemo(() => Payment.fns.cardType(number), [number]);

  let installments = useMemo(
    () =>
      getInstallmentsListByPrice(
        uniqueValue || 0,
        !!signature,
        fixedInstallments
      ),
    [signature, fixedInstallments, uniqueValue]
  );

  const onValuesChange = (changed: any, values: any) => {
    setCvc(values.cvv ?? "");
    setExpiry(values.expiry ?? "");
    setName(values.holderName ?? "");
    setHolderDocument(values.cpf?.replace(/[_.-]/g, "") ?? "");
    setPhone(values.phone?.replace(/[()_-]/g, "").replace(" ", "") ?? "");
    const key = Object.keys(changed)[0];
    setFocused(
      (key === "holderName" ? "name" : key === "cvv" ? "cvc" : key) as Focused
    );
    form.setFieldsValue({
      ...values,
      cpf: values.cpf?.replace(/[_.-]/g, "") ?? "",
      phone: values.phone?.replace(/[()_-]/g, "").replace(" ", "") ?? "",
    });

    setTimeout(() => {
      form
        .validateFields()
        .then(() => setIsValidCardForm(true))
        .catch(() => setIsValidCardForm(false));
    }, 500);
  };

  const SelectInput = forwardRef((props, ref) => (
    <SelectLabled
      {...props}
      options={installments}
      value={
        fixedInstallments || signature
          ? installments[0].value
          : installmentSelected
      }
      placeholder="Selecione a(s) parcela(s)"
      onChange={handleBack}
      label={signature ? "Mensalidade" : "Parcelas"}
    />
  ));

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        onValuesChange={onValuesChange}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item name="number" rules={validationCreditCardNumber}>
              <TextField
                variant="standard"
                label="Número do cartão"
                InputLabelProps={{
                  shrink: true,
                }}
                onPaste={(e) => {
                  (e.target as HTMLElement).setAttribute("pasted", "true");
                  const value = e.clipboardData.getData("text/plain");
                  setNumber(value);
                }}
                onChange={(e) => {
                  if ((e.target as HTMLElement).getAttribute("pasted")) {
                    (e.target as HTMLElement).removeAttribute("pasted");
                  } else {
                    setNumber(e.target.value);
                  }
                }}
                InputProps={{
                  inputComponent: (cardType === "dinersclub"
                    ? CreditCardNumberDiner
                    : cardType === "amex"
                    ? CreditCardNumberAmex
                    : CreditCardNumber) as any,
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="expiry" rules={validationCardShelfLife}>
              <TextField
                variant="standard"
                label="Data de vencimento"
                placeholder="Data de vencimento"
                InputProps={{
                  inputComponent: ExpiryInput as any,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="cvv"
              rules={
                cardType === "amex" || cardType === "discover"
                  ? validationAmexCVV
                  : validationCVV
              }
            >
              <TextField
                variant="standard"
                label="Código de segurança"
                placeholder="Código de segurança"
                InputProps={{
                  inputComponent: (cardType === "amex" ||
                  cardType === "discover"
                    ? CVVAmexInput
                    : CVVInput) as any,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="holderName" rules={validationFieldRequired}>
              <TextField
                variant="standard"
                label="Nome completo"
                placeholder="Nome completo"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="cpf" rules={validationCpf}>
              <TextField
                variant="standard"
                label="CPF do titular"
                placeholder="CPF do titular"
                InputProps={{
                  inputComponent: CPFInput as any,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="phone" rules={validationPhoneRequired}>
              <TextField
                variant="standard"
                label="Telefone"
                placeholder="Telefone"
                InputProps={{
                  inputComponent: PhoneInput as any,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Form.Item>
          </Col>
          {uniqueValue && !addInfo?.isPlan ? (
            <Col span={24}>
              <Form.Item name="installments">
                <SelectInput />
              </Form.Item>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Form>
    </>
  );
};
