import styled from "styled-components";

export const Container = styled.div`
  .velotax-content.carteira {
    width: 840px;
  }
  .header {
    display: flex;
    align-items: flex-start;
    width: 100%;
    width: 840px;

    h1 {
      flex-grow: 1;
    }
    .hide-values-btn {
      svg {
        margin-top: 1rem;
        transition: 0.3s;
        &:hover {
          fill: var(--ant-primary-3) !important;
          transition: 0.3s;
        }
      }
    }
    .notification-button {
      button {
        width: 52px;
        height: 52px;
        transition: 0.3s;
        border-radius: 50%;
        background-color: var(--ant-primary-color);

        &:hover {
          background-color: var(--ant-primary-5);
          box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.1) !important;
          transition: 0.3s;
        }

        svg {
          font-size: 28px;
          fill: var(--black);
        }
      }
    }
  }
  .ant-tabs-nav-list {
    width: 100%;
    border-bottom: 1px solid #313131;
    .ant-tabs-ink-bar {
      bottom: -1px;
    }
  }
  .ant-tabs-ink-bar {
    max-width: 50% !important;
    width: 100% !important;
  }
  .ant-tabs-tab {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .success {
    color: var(--ant-success-color);
  }
  .error {
    color: var(--ant-error-color);
  }
  .page-title {
    display: flex;
    align-items: baseline;
  }
  .hide-values-btn {
    width: 24px;
    margin-left: 12px;
    border-radius: 4px;
    display: inline-flex;
    height: 32px !important;
    background-color: transparent;
    svg {
      width: 20px;
      height: 20px;
      fill: var(--ant-primary-color) !important;
    }
  }

  .patrimonio {
    width: 100%;
    margin-bottom: -48px;

    h1:not(.page-title) {
      padding-bottom: 0;
      font-size: 2.25rem;
      margin: 0 !important;
      color: var(--velotax-font-color-light);

      span {
        font-weight: 800;
      }

      :after {
        display: none;
      }
    }

    h2 {
      font-weight: 400;
      line-height: 1rem;
      font-size: 0.875rem;
      margin: 0 !important;
      color: var(--velotax-font-color-light);
    }

    .lucro-prejuizo {
      padding: 16px 0 16px 16px;
      border-left: 2px solid #313131;

      .anticon.anticon-loading.anticon-spin {
        width: 14px;
        height: 14px;
        margin-left: 4px;
        svg {
          width: 14px;
          height: 14px;
        }
      }

      div.ant-typography {
        margin: 0.5rem 0;
        font-size: 0.875rem;
        span {
          font-weight: 600;
          white-space: nowrap;
        }
      }
    }
  }

  .list-empty {
    padding: 16px;
    margin-top: -16px;
    border-radius: 8px;
    color: var(--velotax-font-color);
    background-color: #313131;
  }

  .last-update {
    display: flex;
    margin-top: 32px;
    align-items: center;
    font-size: 0.75rem;
    line-height: 1.5rem;
    color: var(--ant-primary-color);
    .button-refresh-wallet {
      width: 20px;
      height: 20px;
      border: none;
      box-shadow: none;
      margin-right: 8px;
      background-color: #313131;
      color: var(--ant-primary-color) !important;
      svg {
        width: 10px !important;
        height: 10px !important;
        * {
          stroke: var(--ant-primary-color) !important;
        }
      }
    }
  }

  .messages {
    margin-top: 16px;
    font-weight: 400;
    padding-top: 16px;
    line-height: 1rem;
    font-size: 0.75rem;
    color: var(--velotax-font-color);
    border-top: 2px solid #313131;
    li {
      padding-left: 16px;
      margin-bottom: 6px;
      position: relative;
      .anticon-info-circle {
        left: 0;
        top: 1px;
        position: absolute;
        color: var(--velotax-font-color);
      }
    }
  }

  .select-container {
    top: 0;
    right: 0;
    width: 41.6666%; // 41.6666% para alinhar com o prejuizo (10/24 = 41.6666)
    position: absolute;
    .ant-select {
      min-width: 180px;
      height: 40px !important;
      .ant-select-selector {
        height: 40px !important;
      }
    }
  }
  .recharts-layer.recharts-pie-labels text {
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  @media only screen and (min-device-width: 813px) {
    .velotax-content.largeChart {
      .recharts-responsive-container {
        margin: 0 auto 80px;
      }
    }
    .recharts-responsive-container {
      margin: 0 auto 48px;
      position: relative;
      .recharts-wrapper {
        width: 100% !important;
        > .recharts-surface {
          margin-left: 80px;
        }
        .recharts-legend-wrapper {
          right: 0px !important;
          .recharts-legend-item {
            margin: 4px 0;
            .recharts-surface {
              width: 16px;
              height: 16px;
              border-radius: 2px;
            }
            .recharts-legend-item-text {
              /* margin-left: 12px; */
              span {
                font-size: 14px;
                font-weight: 500;
                color: var(--velotax-font-color) !important;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    .velotax-content.carteira {
      width: 100%;
    }
    .header {
      width: 100%;
      padding-right: 16px;
      align-items: center;
      .page-title {
        font-size: 22px;
        align-items: center;
      }
      .hide-values-btn {
        svg {
          margin: 0;
        }
      }
    }
    .patrimonio {
      margin-bottom: -48px;

      .lucro-prejuizo {
        padding: 4px 0 0;
        border: none;
        margin-top: 16px;
        border-top: 2px solid #313131;

        .anticon.anticon-loading.anticon-spin {
          width: 10px;
          height: 10px;
          margin-left: 4px;
          svg {
            width: 10px;
            height: 10px;
          }
        }

        div.ant-typography {
          margin: 0.25rem 0;
          font-size: 0.75rem;
        }
      }
    }

    h1 {
      width: calc(100% - 48px);
      margin: 0 24px 1.5rem;
      padding: 24px 0 1rem;
    }

    .recharts-responsive-container {
      position: relative;
      margin: 32px auto;
      .select-container {
        top: -32px;
        width: 100%;
        .ant-select {
          min-width: 210px;
        }
      }
      .recharts-legend-wrapper {
        width: 98px !important;
        right: -24px !important;
        .recharts-legend-item {
          margin: 4px 0;
          align-items: start;
          display: flex !important;
          margin-right: 0 !important;
          .recharts-surface {
            border-radius: 2px;
            margin-top: 1px;
          }
          .recharts-legend-item-text {
            max-width: 80px;
            font-size: 13px;
            line-height: 14px;
            white-space: normal;
            display: inline-block;
            span {
              color: var(--velotax-font-color) !important;
            }
          }
        }
      }
    }
  }
`;

export const Content = styled.div`
  .ant-tabs-tabpane {
    display: flex;
    justify-content: center;
  }
  .range {
    display: flex;
    justify-content: space-between;

    div.ant-typography {
      font-size: 13px;
    }
  }
  .isentometro {
    margin-bottom: 5px;
    font-size: 14px;
  }
  .box {
    margin: 50px 0px;
  }
  .overview {
    color: var(--ant-primary-color);
    font-size: 18px;
    font-weight: bold;
  }
  .ant-progress-success-bg {
    background-color: var(--ant-primary-color);
    height: 13px !important;
  }
  .ant-progress-inner {
    height: 13px !important;
  }
  .ant-progress-bg {
    height: 13px !important;
  }
  & > .ant-collapse {
    border: none;
    & > .ant-collapse-item.ativos-categoria {
      margin-bottom: 48px;
      &:last-of-type {
        margin-bottom: 0;
      }
      & > .ant-collapse-header {
        padding: 0;
        align-items: center;
        .ant-collapse-arrow {
          right: 0;
        }
        h2 {
          margin: 0;
          font-weight: 700;
          font-size: 1.125rem;
          line-height: 1.5rem;
          color: var(--ant-primary-color);
        }
        .collapse-title-divider {
          flex-grow: 1;
          display: block;
          margin: 0 16px;
          border-bottom: 2px solid #313131;
        }
        .collapse-title-hidden {
          width: 24px;
          height: 24px;
          display: block;
          visibility: hidden;
        }
      }
      & > .ant-collapse-content {
        border: none;
        background-color: transparent;
        & > .ant-collapse-content-box {
          padding: 24px 0 0;
          & > .ant-collapse {
            border: none;
          }
          & > div {
            font-size: 13px;
          }
        }
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    width: 100%;
    margin-bottom: 0;
    border-radius: 0;
    padding: 32px 24px;

    h1,
    h2 {
      padding: 0;
    }
  }
`;

export const BlurContent = styled.div`
  margin-top: 8px;
  position: relative;
  user-select: none;
  border-radius: 4px;
  border: 2px solid #313131;
  .blur-content {
    opacity: 0.5;
    filter: blur(4px);
  }
  .blur-content-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 100px;
    font-weight: 500;
    font-size: 1.25rem;
    text-align: center;
    row-gap: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: var(--velotax-font-color-light);
  }

  @media only screen and (max-device-width: 812px) {
    .blur-content-title {
      padding: 0 16px;
      font-size: 1rem;
    }
  }
`;

export const Toolbar = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
  margin: 0 auto 1.5rem;
  justify-content: space-between;

  @media only screen and (max-device-width: 812px) {
    width: 100%;
  }
`;

export const IsentCardStyled = styled.div`
  margin-bottom: 4px;
  height: 110px;
  padding: 23px;
  border: none !important;
  border-radius: 4px !important;
  background-color: var(--velotax-background-color-ghost);
  text-align: center;
  gap: 12px;
  flex-direction: column;
  display: flex;

  .overviewTitleIsent {
    color: var(--ant-primary-color);
    font-size: 13px;
  }
`;
