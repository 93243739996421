import { Button, Col, Row, Tabs } from "antd";
import { AiOutlineArrowRight } from "react-icons/ai";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  CurrencyFormItem,
  NumberFormItem,
  RadioFormItem,
} from "../../constants/formItems";
import { validationNumberField } from "../../utils/formValidations";

export interface HelpModal {
  title: React.ReactNode;
  body: React.ReactNode;
}

interface PGBLQuestionsParams {
  data: any;
  setHelpModal: React.Dispatch<React.SetStateAction<HelpModal | undefined>>;
}

interface TabFormItemProps extends PGBLQuestionsParams {
  name: string;
  helpModal: HelpModal;
}

const TabFormItem = ({
  name,
  data,
  setHelpModal,
  helpModal,
}: TabFormItemProps) => ({
  name,
  rules: [],
  onChange: undefined,
  getValueFromEvent: undefined,
  input: (
    <Tabs
      defaultActiveKey={data[name] ?? "mensal"}
      tabBarExtraContent={
        <Button type="text" onClick={() => setHelpModal(helpModal)}>
          <InfoCircleOutlined />
        </Button>
      }
    >
      <Tabs.TabPane tab="Mensal" key="mensal"></Tabs.TabPane>
      <Tabs.TabPane tab="Anual" key="anual"></Tabs.TabPane>
    </Tabs>
  ),
});

const SalarioText = (
  <>
    O valor inserido neste campo deve ser sua remuneração, considerando apenas:
    <br />
    <br />
    <ul style={{ listStyle: "outside" }}>
      <li>
        Trabalhadores CLT: salário, férias, terço de férias (adicionado
        automaticamente caso selecione o formato mensal), adicionais
        insalubridade/periculosidade, adicional noturno, comissões, corretagens,
        horas extras, bolsa de estudos sem isenção e bonificações;
      </li>
      <li>Empresários: pró-labore;</li>
      <li>Autônomos: rendimentos não-assalariados;</li>
      <li>
        MEI: rendimentos tributáveis, com receita recebida a maior que faixa de
        isenção, e pró-labore.
      </li>
    </ul>
    <br />
    Para realizar a estimativa em caso do valor mensal, faça a soma de todos os
    tipos de rendimentos acima mencionados recebidos durante ano e divida por
    12. Obtendo assim média mensal.
    <br />
    <br />
    Atenção: Não devem ser incluídos valores recebidos à título de PLR, 13º
    salário, parcela isenta de aposentadoria, diárias e ajuda de custo, férias e
    terço de férias vendidas, remuneração de stock option, dividendos, FGTS,
    seguro desemprego e indenização pagas pelo empregador em caso de demissão ou
    em caso de acidente de trabalho.
  </>
);

const EducacaoText = (
  <>
    São aceitos gastos com educação profissional (ensino técnico e o
    tecnológico); educação superior, compreendendo os cursos de graduação e de
    pós-graduação (mestrado, doutorado e especialização); ensino médio; ensino
    fundamental e educação infantil. Sendo a dedução limitada ao valor de R$
    3.561,50 por indivíduo.
  </>
);

const SaudeText = (
  <>
    Consideram-se despesas médicas ou de hospitalização os pagamentos efetuados
    a médicos de qualquer especialidade, dentistas, psicólogos, fisioterapeutas,
    terapeutas ocupacionais, fonoaudiólogos, hospitais, e as despesas
    provenientes de exames laboratoriais, serviços radiológicos, aparelhos
    ortopédicos, próteses ortopédicas e dentárias e planos de saúde.
  </>
);

const DependenteText = (
  <>
    A Receita Federal considera como dependentes, para efeito do imposto de
    renda:
    <br />
    1 - companheiro(a) com quem o contribuinte tenha filho ou viva há mais de 5
    anos, ou cônjuge;
    <br />
    2 - filho(a) ou enteado(a), até 21 anos de idade;
    <br />
    3 – filho(a) ou enteado(a) com deficiência, de qualquer idade, quando a sua
    remuneração não exceder as deduções autorizadas por lei (tendo em vista a
    decisão do Supremo Tribunal Federal – STF, na Ação Direta de
    Inconstitucionalidade nº 5.583/DF);
    <br />
    4 - filho(a) ou enteado(a), se ainda estiverem cursando estabelecimento de
    ensino superior ou escola técnica de segundo grau, até 24 anos de idade;
    <br />
    5 - irmão(ã), neto(a) ou bisneto(a), sem arrimo dos pais, de quem o
    contribuinte detenha a guarda judicial, até 21 anos, ou em qualquer idade,
    quando incapacitado física ou mentalmente para o trabalho;
    <br />
    6 - irmão(ã), neto(a) ou bisneto(a), sem arrimo dos pais, com idade de 21
    anos até 24 anos, se ainda estiver cursando estabelecimento de ensino
    superior ou escola técnica de segundo grau, desde que o contribuinte tenha
    detido sua guarda judicial até os 21 anos;
    <br />
    7 - Irmão(ã), neto(a) ou bisneto(a) com deficiência, sem arrimo dos pais,
    do(a) qual o contribuinte detém a guarda judicial, em qualquer idade, quando
    a sua remuneração não exceder as deduções autorizadas por lei (tendo em
    vista a decisão do Supremo Tribunal Federal – STF, na Ação Direta de
    Inconstitucionalidade nº 5.583/DF);
    <br />
    8 - pais, avós e bisavós que, em 2021, tenham recebido rendimentos,
    tributáveis ou não, até R$ 22.847,76;
    <br />
    9 - menor pobre até 21 anos que o contribuinte crie e eduque e de quem
    detenha a guarda judicial;
    <br />
    10 – pessoa absolutamente incapaz, da qual o contribuinte seja tutor ou
    curador.
  </>
);

const PensaoText = (
  <>
    A Receita só aceita despesas alimentícias definidas em acordo judicial ou
    escritura pública. Outras despesas, mesmo que sejam feitas em prol do
    alimentando, não podem ser incluídas.
  </>
);

const AnualMensalText = (
  type:
    | "gastos de saúde"
    | "gastos com educação"
    | "gastos com pensão"
    | "renda bruta"
) => (
  <>
    Caso selecione a opção <strong>"Anual"</strong>, insira o valor total de{" "}
    {type} que possui no ano. Caso selecione a opção <strong>"Mensal"</strong>,
    insira a média de {type} por mês.
  </>
);

export const HelpModalTexts = {
  rendaBrutaMensal: { body: SalarioText, title: "Renda bruta mensal" },
  gastoMensalSaude: { body: SaudeText, title: "Despesas com saúde" },
  gastoMensalEducacao: { body: EducacaoText, title: "Despesas com educação" },
  possuiDependentes: { body: DependenteText, title: "Dependentes" },
  pagaPensaoAlimenticia: { body: PensaoText, title: "Pensão alimentícia" },
  rendaAnualMensal: {
    body: AnualMensalText("renda bruta"),
    title: "Mensal ou anual",
  },
  saudeAnualMensal: {
    body: AnualMensalText("gastos de saúde"),
    title: "Mensal ou anual",
  },
  educacaoAnualMensal: {
    body: AnualMensalText("gastos com educação"),
    title: "Mensal ou anual",
  },
  pensaoAnualMensal: {
    body: AnualMensalText("gastos com pensão"),
    title: "Mensal ou anual",
  },
};

export const pgblQuestions = ({ data, setHelpModal }: PGBLQuestionsParams) => [
  {
    id: "explicativo",
    hideQuestionNumber: true,
    title: (
      <>
        Você pode ser elegível para ter economia com aporte em PGBL, caso se
        enquadre em algum dos casos abaixo:
      </>
    ),
    formItems: [],
    children: (
      <Row className="income-types">
        <Col span={12}>
          <p>
            <span>&#8226;</span> Assalariado (CLT)
          </p>
          <p>
            <span>&#8226;</span> Empresário (recebeu pró-labore)
          </p>
          <p>
            <span>&#8226;</span> Autônomo
          </p>
          <p>
            <span>&#8226;</span> MEI
          </p>
        </Col>
        <Col span={12}>
          <p>
            <span>&#8226;</span> Aposentado (rendimentos acima de R$ 3.807,96
            por mês)
          </p>
          <p>
            <span>&#8226;</span> Servidor público
          </p>
          <p>
            <span>&#8226;</span> Recebeu renda de aluguel
          </p>
        </Col>
      </Row>
    ),
  },
  {
    id: "rendaBrutaMensal",
    title: (
      <>
        Qual sua renda bruta {data.rendaBrutaMensalMode ?? "mensal"}
        ?&nbsp;&nbsp;
        <Button
          type="link"
          className="pgbl-info-button"
          onClick={() => setHelpModal(HelpModalTexts.rendaBrutaMensal)}
        >
          <span>
            Clique aqui e descubra o que é
            <br />
            considerado como renda bruta
          </span>
          <AiOutlineArrowRight />
        </Button>
      </>
    ),
    formItems: [
      TabFormItem({
        data,
        setHelpModal,
        name: "rendaBrutaMensalMode",
        helpModal: HelpModalTexts.rendaAnualMensal,
      }),
      CurrencyFormItem({
        name: "rendaBrutaMensal",
        label: "",
        rules: validationNumberField,
      }),
    ],
  },
  // {
  //   id: "gastoMensalSaude",
  //   title: (
  //     <>
  //       Você possui gastos com saúde?&nbsp;&nbsp;
  //       <Button
  //         type="text"
  //         onClick={() => setHelpModal(HelpModalTexts.gastoMensalSaude)}
  //       >
  //         <InfoCircleOutlined />
  //       </Button>
  //     </>
  //   ),
  //   formItems: [
  //     RadioFormItem({
  //       name: "possuiGastoMensalSaude",
  //       label: "",
  //     }),
  //     ...(data.possuiGastoMensalSaude
  //       ? [
  //           TabFormItem({
  //             data,
  //             setHelpModal,
  //             name: "gastoMensalSaudeMode",
  //             helpModal: HelpModalTexts.saudeAnualMensal,
  //           }),
  //           CurrencyFormItem({
  //             name: "gastoMensalSaude",
  //             label: `Qual valor dos seus gastos ${
  //               data.gastoMensalSaudeMode === "anual" ? "anuais" : "mensais"
  //             } com saúde?`,
  //             rules: validationNumberField,
  //           }),
  //         ]
  //       : []),
  //   ],
  // },
  // {
  //   id: "gastoMensalEducacao",
  //   title: (
  //     <>
  //       Você possui gastos com educação?&nbsp;&nbsp;
  //       <Button
  //         type="text"
  //         onClick={() => setHelpModal(HelpModalTexts.gastoMensalEducacao)}
  //       >
  //         <InfoCircleOutlined />
  //       </Button>
  //     </>
  //   ),
  //   formItems: [
  //     RadioFormItem({
  //       name: "possuiGastoMensalEducacao",
  //       label: "",
  //     }),
  //     ...(data.possuiGastoMensalEducacao
  //       ? [
  //           TabFormItem({
  //             data,
  //             setHelpModal,
  //             name: "gastoMensalEducacaoMode",
  //             helpModal: HelpModalTexts.educacaoAnualMensal,
  //           }),
  //           CurrencyFormItem({
  //             name: "gastoMensalEducacao",
  //             label: `Qual valor dos seus gastos ${
  //               data.gastoMensalEducacaoMode === "anual" ? "anuais" : "mensais"
  //             } com educação?`,
  //             rules: validationNumberField,
  //           }),
  //         ]
  //       : []),
  //   ],
  // },
  {
    id: "possuiDependentes",
    title: (
      <>
        Você possui dependentes?&nbsp;&nbsp;
        <Button
          type="link"
          className="pgbl-info-button"
          onClick={() => setHelpModal(HelpModalTexts.possuiDependentes)}
        >
          <span>
            Clique aqui e descubra quem é
            <br />
            considerado como dependente
          </span>
          <AiOutlineArrowRight />
        </Button>
      </>
    ),
    formItems: [
      RadioFormItem({
        name: "possuiDependentes",
        label: "",
      }),
      ...(data.possuiDependentes
        ? [
            NumberFormItem({
              name: "quantidadeDependente",
              label: "Quantos dependentes você possui?",
              maxLength: 2,
              placeholder: "Ex: 1",
              rules: validationNumberField,
            }),
          ]
        : []),
    ],
  },
  {
    id: "possuiGastoMensalSaudeDependente",
    title: (
      <>
        {data.possuiDependentes
          ? "Você e seus dependentes possuem gastos com saúde?"
          : "Você possui gastos com saúde?"}
        &nbsp;&nbsp;
        <Button
          type="link"
          className="pgbl-info-button"
          onClick={() => setHelpModal(HelpModalTexts.gastoMensalSaude)}
        >
          <span>
            Clique aqui e descubra o que pode
            <br />
            ser considerado como gasto de saúde
          </span>
          <AiOutlineArrowRight />
        </Button>
      </>
    ),
    formItems: [
      RadioFormItem({
        name: "possuiGastoMensalSaudeDependente",
        label: "",
      }),
      ...(data.possuiGastoMensalSaudeDependente
        ? [
            TabFormItem({
              data,
              setHelpModal,
              name: "gastoMensalSaudeDependenteMode",
              helpModal: HelpModalTexts.saudeAnualMensal,
            }),
            CurrencyFormItem({
              className: data.possuiDependentes ? "handle-label" : "",
              name: "gastoMensalSaudeDependente",
              label: `Qual o valor dos seus gastos ${
                data.gastoMensalSaudeDependenteMode === "anual"
                  ? "anuais"
                  : "mensais"
              } ${
                data.possuiDependentes ? "e de todos os dependentes" : ""
              } com saúde?`,
              rules: validationNumberField,
            }),
          ]
        : []),
    ],
  },
  {
    id: "possuiGastoMensalEducacaoDependente",
    title: (
      <>
        {data.possuiDependentes
          ? "Você e seus dependentes possuem gastos com educação?"
          : "Você possui gastos com educação?"}
        &nbsp;&nbsp;
        <Button
          type="link"
          className="pgbl-info-button"
          onClick={() => setHelpModal(HelpModalTexts.gastoMensalEducacao)}
        >
          <span>
            Clique aqui e descubra o que pode
            <br />
            ser considerado como gasto de educação
          </span>
          <AiOutlineArrowRight />
        </Button>
      </>
    ),
    formItems: [
      RadioFormItem({
        name: "possuiGastoMensalEducacaoDependente",
        label: "",
      }),
      ...(data.possuiGastoMensalEducacaoDependente
        ? [
            TabFormItem({
              data,
              setHelpModal,
              name: "gastoMensalEducacaoDependenteMode",
              helpModal: HelpModalTexts.educacaoAnualMensal,
            }),
            CurrencyFormItem({
              className: data.possuiDependentes ? "handle-label" : "",
              name: "gastoMensalEducacaoDependente",
              label: `Qual o valor dos seus gastos ${
                data.gastoMensalEducacaoDependenteMode === "anual"
                  ? "anuais"
                  : "mensais"
              } ${
                data.possuiDependentes ? "e de todos os dependentes" : ""
              } com educação?`,
              rules: validationNumberField,
            }),
          ]
        : []),
    ],
  },
  {
    id: "pagaPensaoAlimenticia",
    title: (
      <>
        Você paga pensão alimentícia?&nbsp;&nbsp;
        <Button
          type="link"
          className="pgbl-info-button"
          onClick={() => setHelpModal(HelpModalTexts.pagaPensaoAlimenticia)}
        >
          <span>
            Clique aqui e descubra o que pode ser
            <br />
            considerado como pensão alimentícia
          </span>
          <AiOutlineArrowRight />
        </Button>
      </>
    ),
    formItems: [
      RadioFormItem({
        name: "pagaPensaoAlimenticia",
        label: "",
      }),
      ...(data.pagaPensaoAlimenticia
        ? [
            TabFormItem({
              data,
              setHelpModal,
              name: "gastoPensaoAlimenticiaMode",
              helpModal: HelpModalTexts.pensaoAnualMensal,
            }),
            CurrencyFormItem({
              className: "handle-label",
              name: "gastoPensaoAlimenticia",
              rules: validationNumberField,
              label: `Qual o valor dos gastos ${
                data.gastoPensaoAlimenticiaMode === "anual"
                  ? "anuais"
                  : "mensais"
              }  que você paga de pensão alimentícia?`,
            }),
          ]
        : []),
    ],
  },
];
