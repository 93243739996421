import { message } from "antd";
import { useRef, useState } from "react";
import Upload, { RcFile } from "antd/lib/upload";
import {
  UploadFile,
  UploadFileStatus,
  UploadProps,
} from "antd/lib/upload/interface";
import { useBroker } from "../../../contexts/BrokerContext";

const isValidFile = (file: UploadFile) => file.type === "application/pdf";
const sameNameFile = (file: UploadFile, files: UploadFile[]) =>
  files.findIndex((item) => item.name === file.name) >= 0;

export const useNotasCorretagem = () => {
  const sameNameFiles = useRef<string[]>([]);
  const [loadingSend, setLoadingSend] = useState(false);
  const [notas, setNotas] = useState<UploadFile<any>[]>([]);
  const [showNotasCorretagemModal, setShowNotasCorretagemModal] =
    useState(false);

  const beforeUploadNota = (file: RcFile) => {
    if (!isValidFile(file)) {
      message.error("Você só pode fazer upload de arquivos pdf");
      return Upload.LIST_IGNORE;
    }
    if (sameNameFile(file, notas)) {
      sameNameFiles.current.push(file.name);
      message.error("Este arquivo já foi adicionado");
      return Upload.LIST_IGNORE;
    }
  };

  const onChangeNotas = ({
    file,
    fileList,
  }: {
    file: UploadFile;
    fileList: UploadFile[];
  }) => {
    if (file && file.status === "removed") return;
    if (!isValidFile(file)) return;
    if (sameNameFiles.current.includes(file.name)) {
      sameNameFiles.current = sameNameFiles.current.filter(
        (name) => name !== file.name
      );
      return;
    }
    setNotas(
      fileList
        .filter((file) => file.status !== "removed")
        .map((file) => ({ ...file, status: "done" as UploadFileStatus }))
    );
  };

  const onRemoveNota = (file: UploadFile) => {
    setNotas((notas) => notas.filter((nota) => nota.uid !== file.uid));
  };

  const sendNotas = () => {
    const formData = new FormData();
    notas.forEach((nota) => {
      formData.append("files", nota.originFileObj as File);
    });
    setLoadingSend(false);
  };

  const uploadProps: UploadProps = {
    fileList: notas,
    multiple: true,
    listType: "text",
    accept: "application/pdf",
    onRemove: onRemoveNota,
    onChange: onChangeNotas,
    beforeUpload: beforeUploadNota,
    customRequest: () => {},
    showUploadList: {
      showRemoveIcon: true,
      showPreviewIcon: false,
      showDownloadIcon: false,
    },
  };

  return {
    sendNotas,
    loadingSend,
    uploadProps,
    showNotasCorretagemModal,
    setShowNotasCorretagemModal,
  };
};
