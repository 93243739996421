import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--velotax-background-color-ghost);
  h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--velotax-font-color-ghost);
  }
  .price {
    display: flex;
    column-gap: 8px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    h4 {
      font-size: 22px;
      margin: 8px 0 !important;
      color: var(--ant-primary-color);
      del,
      span.per-month {
        font-size: 14px;
      }
      del {
        opacity: 0.8;
        color: var(--velotax-font-color);
      }
    }
    .ant-typography-danger {
      font-size: 11px;
      text-align: right;
      max-width: 120px;
    }
  }
  .ant-divider.ant-divider-horizontal {
    margin: 16px 0;
  }
  .other-features,
  .included-features {
    .feature {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-weight: 500;
      }
      svg.fill {
        fill: var(--velotax-font-color);
      }
      svg:not(.fill) {
        stroke: var(--velotax-font-color);
      }
      &.disabled {
        opacity: 0.4;
      }
      .feature-desc {
        display: block;
        font-size: 10px;
        font-weight: 400;
      }
    }
  }
  .other-features {
    margin-bottom: 24px;
  }
`;
