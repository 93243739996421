import { ReactNode } from "react";
import { ButtonBase } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { HiOutlineChevronRight } from "react-icons/hi";
import { isMobile } from "../../utils";
import { CardContainer } from "./style";

interface CardXPProps {
  id: string;
  icon: ReactNode;
  children: ReactNode;
  link?: string;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const Container: React.FC<Pick<CardXPProps, "link">> = ({ children, link }) => {
  return link && link.includes("http") ? (
    <a
      href={link}
      {...(!isMobile()
        ? {
            target: "_blank",
            rel: "noreferrer",
          }
        : {})}
    >
      {children}
    </a>
  ) : (
    <>{children}</>
  );
};

export const CardXP: React.FC<CardXPProps> = ({
  id,
  icon,
  link,
  onClick,
  children,
  disabled,
  className,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (disabled) return;
    if (onClick) {
      onClick();
    } else if (link && !link.includes("http")) {
      navigate(link);
    }
  };

  return (
    <CardContainer id={id} className={className} onClick={handleClick}>
      <ButtonBase disabled={disabled} disableTouchRipple={disabled}>
        <Container link={link}>
          <div className="content">
            <figure>{icon}</figure>
            <div className="content-children">{children}</div>
          </div>
          <HiOutlineChevronRight />
        </Container>
      </ButtonBase>
    </CardContainer>
  );
};
