import { useState } from "react";
import { BiBell } from "react-icons/bi";
import { Badge, Tabs, Typography } from "antd";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { Container, Content } from "./styles";
import AntButton from "../../components/Button";
import { useAuth } from "../../contexts/AuthContext";
import { Proventos } from "../../components/Proventos";
import { Carteira } from "../../components/MeusInvestimentos/index";
import { useNotification } from "../../contexts/NotificationContext";
import { NotificationDrawer } from "../../components/Notification/Notification";
import { Avisos } from "../../components/MeusInvestimentos/Avisos";
import { useBroker } from "../../contexts/BrokerContext";

interface CarteiraProps {}

export const Wallet: React.FC<CarteiraProps> = () => {
  const { notification } = useNotification();
  const { integration } = useBroker();
  const {
    user,
    hasPlan: hasPremiunPlan,
    hasPermissionExterior,
    hasPermissionGeneral,
  } = useAuth();
  const hasPlan =
    (hasPremiunPlan || hasPermissionExterior || hasPermissionGeneral) && !user?.user?.userPlanInfoVelotax?.type?.includes('BASIC');

  const [notificationDrawerOpen, setNotificationDrawerOpen] = useState(false);
  const [hideValues, setHideValues] = useState(false);

  return (
    <Container className="velotax-container">
      <div className="header">
        <Typography.Title level={1} className="page-title">
          Meus investimentos
          <AntButton
            type="link"
            size="large"
            className="hide-values-btn"
            icon={hideValues ? <BsEyeSlash /> : <BsEye />}
            onClick={() => setHideValues((value) => !value)}
          />
        </Typography.Title>
        {hasPlan && (
          <div className="notification-button">
            <Badge
              size="small"
              showZero={false}
              offset={[-12, 48]}
              count={notification?.filter((n) => !n.viewed)?.length || 0}
            >
              <AntButton
                loading={false}
                icon={<BiBell />}
                onClick={() => {
                  setNotificationDrawerOpen(true);
                }}
              />
            </Badge>
          </div>
        )}
      </div>
      <Content className="velotax-content carteira">
        <Avisos />
        {(!integration.integrated && !user.user.isIntegratedExterior) ? (<></>) : (
        <Tabs className="tabs-wallet">
          <Tabs.TabPane tab="INVESTIMENTOS" key="item-1">
            <Carteira hideValues={hideValues} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="PROVENTOS" key="item-2">
            <Proventos hideValues={hideValues} />
          </Tabs.TabPane>
        </Tabs>
        )}
      </Content>
      <NotificationDrawer
        open={notificationDrawerOpen}
        onClose={() => setNotificationDrawerOpen(false)}
      />
    </Container>
  );
};
