import { Button } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import { useEffect, useState, useMemo } from "react";
import { AiFillWarning, AiOutlineUnlock } from "react-icons/ai";
import {
  Col,
  Descriptions,
  Form,
  Input,
  message,
  Modal,
  Row,
  Typography,
} from "antd";
import { formatDate } from "../../../utils";
import HandleTag from "../../../services/handleTag";
import { UserPlans } from "../../../constants/plans";
import { useAuth } from "../../../contexts/AuthContext";
import { apiPayment } from "../../../services/apiPayment";
import { PaymentModal } from "../../../components/PaymentModal";
import apiVeloPro from "../../../services/apiVeloPro";
import { useNavigate } from "react-router-dom";

const KEY_PASS = "DELETAR";

export const MeuPlano: React.FC = () => {
  const navigate = useNavigate();
  const { user, getUserInfo, showUserPlanModal } = useAuth();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [cancelModal, showCancelModal] = useState(false);
  const pendingCancel =
    data?.signature?.useDate > 0 &&
    (!data?.signature?.status || data?.signature?.status === "canceled");

  const [changeCardModal, showChangeCardModal] = useState(false);

  const [formKey] = Form.useForm();
  const [keyPass, setKeyPass] = useState("");

  const planBasicInfo = user?.user?.userPlanInfoVelotax;
  const [isVelopro, setVelopro] = useState(false);
  const [hasCreditCardInfo, setCreditCardInfo] = useState(true);

  const checkPlanVelopro = () => {
    apiVeloPro.get(`/clients/hasPlanActive/${user.user.userId}`).then(({ data }) => {
      if (data?.isPlanVelopro) setVelopro(true);
      else setVelopro(false);
    })
    .catch(() => message.error('Ocorreu um erro, tente novamente mais tarde.'));
    return;
  };

  useEffect(() => {
    checkPlanVelopro();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVelopro]);

  const currentPlan = useMemo(() => {
    const plansTypes: Record<string, string> = {
      VELOTAX_MAIN_CONCIERGE: "Velotax Concierge Anual",
      VELOTAX_MAIN_CONCIERGE_MONTH: "Velotax Concierge Mensal",
      VELOTAX_MAIN_PRO: "Velotax Premium Anual",
      VELOTAX_MAIN_PRO_MONTH: "Velotax Premium Mensal",
      VELOTAX_MAIN_BASIC: "Velotax Basic Anual",
      VELOTAX_MAIN_BASIC_MONTH: "Velotax Basic Mensal",
      XP_BASIC: "XP Basic Anual",
      XP_BASIC_MONTH: "XP Basic Mensal",
      XP_PRO: "XP Premium Anual",
      XP_PRO_MONTH: "XP Premium Mensal",
      XP_CONCIERGE: "XP Concierge Anual",
      XP_CONCIERGE_MONTH: "XP Concierge Mensal",
    };
    const genericTypes: Record<string, string> = {
      BASIC: 'Velotax Basic',
      PRO: 'Velotax Premium',
      CONCIERGE: 'Velotax Concierge'
    }

    const genericPlan = Object.keys(genericTypes).find(key => planBasicInfo?.type?.includes(key))

    if (
      planBasicInfo.type === "VELOTAX_MAIN_PRO" ||
      planBasicInfo.type === "VELOTAX_MAIN_BASIC" ||
      planBasicInfo.type === "VELOTAX_MAIN_PRO_MONTH"
    ) {
      setCreditCardInfo(false);
    }
    return (
      planBasicInfo?.active 
        ? plansTypes[planBasicInfo.type]
          ? planBasicInfo?.currentPlan?.period?.includes('mensal')
            ? plansTypes[planBasicInfo.type].replace('Anual', 'Mensal')
            : plansTypes[planBasicInfo.type]
          : !!genericPlan
            ? genericTypes[genericPlan]
            : 'Gratuito'
        : 'Gratuito'
    );
  }, [planBasicInfo?.active, planBasicInfo?.currentPlan?.period, planBasicInfo.type]);

  const showChangeCardButton = useMemo(
    () =>
      currentPlan !== "Gratuito" &&
      !pendingCancel &&
      data?.signature?.paymentForm !== "pix" &&
      !data?.signature?.fromAccountant,
    [
      currentPlan,
      pendingCancel,
      data?.signature?.fromAccountant,
      data?.signature?.paymentForm,
    ]
  );

  const getData = () => {
    setLoading(true);
    apiPayment
      .get("/user-plan/get-plan-details")
      .then((ret) => {
        setLoading(false);
        setData(ret.data);
      })
      .catch(() => {
        message.error(
          "Houve um erro ao buscar os detalhes contate nosso suporte"
        );
      });
  };

  const cancelPlan = () => {
    HandleTag("60");
    if (keyPass.toLowerCase() !== KEY_PASS.toLowerCase()) {
      message.error(`Digite ${KEY_PASS} corretamente!`);
      return;
    }

    if (isVelopro) {
      apiVeloPro.put(`/clients/cancelRecuring/${user.user.userId}`)
      .then(() => {
        message.success("Plano cancelado");
        showCancelModal(false);
        getUserInfo();
        getData();
      })
      .catch(() => message.error('Ocorreu um erro ao cancelar a recorrência, tente novamente mais tarde.'));
      return;
    }

    apiPayment
      .post("/user-plan/cancel_signature")
      .then((ret) => {
        message.success("Plano cancelado");
        showCancelModal(false);
        getUserInfo();
        getData();
      })
      .catch(() => {
        message.error("Ocorreu um erro. Contate nosso suporte");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const payForm: Record<string, string> = {
    pix: "Pix",
    credit_card: "Cartão de Crédito",
    concierge: "Escritório",
  };

  const planStatus = useMemo(
    () =>
      currentPlan === "Gratuito"
        ? "Ativo"
        : planBasicInfo?.active
        ? "Ativo"
        : "Inativo",
    [planBasicInfo?.active, currentPlan]
  );

  const isActive = useMemo(() => planStatus === "Ativo", [planStatus]);

  const onDeleteCancel = () => {
    showCancelModal(false);
    formKey.resetFields();
    setKeyPass("");
  };

  const contentStyle = {
    fontSize: 15,
    marginBottom: 0,
  };

  return (
    <>
      <h1 className="page-title">Dados do plano</h1>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <LoadingOutlined />{" "}
        </div>
      ) : (
        <>
          <div className="velotax-content plano">
            <Descriptions
              contentStyle={contentStyle}
              title={<h4>{"Plano Atual"}</h4>}
            >
              <Descriptions.Item>{currentPlan}</Descriptions.Item>
            </Descriptions>
            <Descriptions
              contentStyle={{ ...contentStyle }}
              title={<h4>Status do Plano</h4>}
            >
              <Descriptions.Item
                style={{ display: "flex", alignItems: "center" }}
              >
                <div
                  style={{
                    width: 60,
                    textAlign: "center",
                    backgroundColor: isActive
                      ? "var(--velotax-green-amount)"
                      : "var(--velotax-red-prejuizo)",
                    padding: 4,
                    borderRadius: 4,
                    fontWeight: 500,
                    color: "#FFFF",
                  }}
                >
                  {planStatus}
                </div>
                {/* {pendingCancel && (
                  <Tooltip
                    overlayStyle={{ backgroundColor: "#000000", fontSize: 16 }}
                    title={msgProcessingCancel}
                  >
                    <AiFillWarning
                      style={{ marginLeft: 10 }}
                      color="rgb(252, 210, 40)"
                      size={20}
                    />
                  </Tooltip>
                )} */}
              </Descriptions.Item>
            </Descriptions>
            {data?.signature?.next_billing_at &&
              planBasicInfo?.active &&
              !pendingCancel && (
                <Descriptions
                  contentStyle={contentStyle}
                  title={<h4>{"Próxima Cobrança"}</h4>}
                >
                  <Descriptions.Item>
                    {formatDate(data?.signature?.next_billing_at)}
                  </Descriptions.Item>
                </Descriptions>
              )}
            {data?.signature?.start_at && planBasicInfo?.active && (
              <Descriptions
                contentStyle={contentStyle}
                title={<h4>{"Data da Assinatura"}</h4>}
              >
                {data?.signature?.start_at && (
                  <Descriptions.Item>
                    {formatDate(data?.signature?.start_at)}
                  </Descriptions.Item>
                )}
              </Descriptions>
            )}
            {data?.signature?.dueDate && planBasicInfo?.active && (
              <Descriptions
                contentStyle={contentStyle}
                title={<h4>{"Vencimento da Assinatura"}</h4>}
              >
                {data?.signature?.dueDate && (
                  <Descriptions.Item>
                    {formatDate(data?.signature?.dueDate)}
                  </Descriptions.Item>
                )}
              </Descriptions>
            )}
            {data?.signature?.paymentForm && planBasicInfo?.active && (
              <Descriptions
                contentStyle={contentStyle}
                title={<h4>{"Forma de pagamento"}</h4>}
              >
                <Descriptions.Item>
                  {<>{payForm[data?.signature?.paymentForm]}</>}
                </Descriptions.Item>
              </Descriptions>
            )}

            {data?.signature?.paymentForm === "credit_card" &&
              planBasicInfo?.active && hasCreditCardInfo && (
                <Descriptions
                  contentStyle={contentStyle}
                  title={<h4>{"Cartão de cobrança"}</h4>}
                >
                  {data?.signature?.card?.lastFourDigits && (
                    <Descriptions.Item>
                      {
                        <>
                          **** **** **** {data?.signature?.card?.lastFourDigits}
                        </>
                      }
                    </Descriptions.Item>
                  )}
                </Descriptions>
              )}

            {/* {currentPlan === "Gratuito" && (
            <div className="content-button">
              <Button
                onClick={() => {
                  showUserPlanModal(true);
                }}
                size="medium"
                color="secondary"
                variant="contained"
                style={{
                  display: "flex",
                  justifyContent: "right",
                  fontSize: 12,
                }}
              >
                CONTRATAR PLANO
              </Button>
            </div>
          )} */}

            {currentPlan === "Gratuito" && (
              <div className="content-button">
                <Button
                  fullWidth
                  size="large"
                  color="secondary"
                  variant="contained"
                  startIcon={<AiOutlineUnlock size={22} />}
                  onClick={() => {
                    // showUserPlanModal(true);
                    navigate('/planos')
                  }}
                >
                  CONTRATAR PLANO PREMIUM
                </Button>
              </div>
            )}

            {showChangeCardButton && (
              <Row justify="end">
                <Col>
                  <Button
                    color="secondary"
                    variant="contained"
                    style={{ opacity: 0.8 }}
                    onClick={() => {
                      showChangeCardModal(true);
                    }}
                  >
                    Alterar cartão de cobrança
                  </Button>
                </Col>
              </Row>
            )}
          </div>
          {((currentPlan !== "Gratuito" &&
            !pendingCancel &&
            data?.signature?.paymentForm !== "pix") || isVelopro) && (
              <Row justify="end" className="cancelar-plano-container">
                <Col>
                  <Button
                    onClick={() => {
                      HandleTag("59");
                      showCancelModal(true);
                    }}
                    style={{
                      opacity: 0.8,
                      color: "var(--ant-error-color)",
                    }}
                  >
                    Cancelar Recorrência
                  </Button>
                </Col>
              </Row>
            )}
        </>
      )}

      {changeCardModal && (
        <PaymentModal
          hideDarfDownload
          closeModal={() => {
            showChangeCardModal(false);
            getData();
          }}
          changeCardAction
          onSuccessGeneral={() => {
            showChangeCardModal(false);
            getData();
          }}
          planCurrent={UserPlans.find(
            (plan) => plan?.type === user?.user?.userPlanInfoVelotax?.type
          )}
          hidePixButton
          blockListCard={[data?.signature?.card?.id]}
          show={changeCardModal}
          onCancel={() => showChangeCardModal(false)}
          paymentData={{
            type: planBasicInfo.type,
            isPlan: true,
          }}
          callDarf={(value: boolean) => null}
          customInstallments={12}
        />
      )}

      <Modal
        cancelText="Não"
        okText="Cancelar Plano"
        onOk={cancelPlan}
        visible={cancelModal}
        onCancel={onDeleteCancel}
      >
        <p style={{ fontSize: 18 }}>Deseja realmente cancelar o plano ?</p>
        <Row gutter={[16, 16]} align="middle">
          <Col span={10}>
            <AiFillWarning
              size={100}
              color="var(--ant-warning-color)"
              style={{ display: "block", margin: "0 auto" }}
            />
          </Col>
          <Col span={14}>
            <Typography.Paragraph strong style={{ fontSize: "1rem" }}>
              Deseja realmente cancelar seu plano?
            </Typography.Paragraph>
            <Typography.Paragraph>
              <strong>Atenção:</strong> todos os seus dados de impostos,
              inclusive a memória dos cálculos e o histórico dos DARFs pagos,
              serão excluídos definitivamente da plataforma. Você poderá ser
              obrigado pela Receita Federal a apresentar tais informações, caso
              seja questionado.
            </Typography.Paragraph>
          </Col>
          <Col span={24}>
            <Typography style={{ fontSize: "1rem" }}>
              Digite <strong>{KEY_PASS}</strong> para prosseguir:
            </Typography>
          </Col>
          <Col span={24}>
            <Form
              form={formKey}
              onChange={(event: any) => {
                setKeyPass(event.target.value);
              }}
            >
              <Form.Item name="key">
                <Input placeholder={KEY_PASS} />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
