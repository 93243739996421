import { RiDownloadCloudLine } from "react-icons/ri";
import { Button as MaterialButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Col,
  List,
  message,
  Modal,
  Row,
  Select,
  Skeleton,
  Typography,
} from "antd";
import { cards } from "./items";
import apis from "../../services/apis";
import { Card } from "../Exterior/Cards";
import Button from "../../components/Button";
import { Page } from "../../constants/brokers";
import { useAuth } from "../../contexts/AuthContext";
import { UserPlanEnum } from "../../constants/plans";
import { useBroker } from "../../contexts/BrokerContext";
import { BackButton } from "../../components/BackButton";
import { ReportContainer, Container, Content } from "./styles";
import {
  download,
  errorMessage,
  getInitialYear,
  isMobile,
  monthsExtended,
  zero,
} from "../../utils";
import api from "../../services/apiBolsa";
import { ModalConfirmMail } from "../../components/ModalConfirmMail";

interface ReportProps {
  item: Page;
}

const ReportAlertTitle = (
  <Typography
    style={{
      fontSize: "14px",
      display: "flex",
      columnGap: "8px",
      lineHeight: "18px",
      alignItems: "flex-start",
    }}
  >
    <InfoCircleOutlined style={{ fontSize: "16px", marginTop: "2px" }} />{" "}
    <span>
      Os relatórios estão disponíveis somente no plano premium.{" "}
      <strong>Contrate o seu plano.</strong>
    </span>
  </Typography>
);

export const ReportBolsa: React.FC<ReportProps> = ({ item }) => {
  const {
    user,
    showUserPlanModal,
    userPlanModal,
    hasPlan,
    ssoToken,
    hasPermissionGeneral,
  } = useAuth();
  const { state } = useLocation();
  const navigate = useNavigate();

  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const stateYear = (state as any)?.year;
  const initialYear = getInitialYear(currentYear, currentMonth);
  window.history.replaceState({}, document.title);

  const { currentPage } = useBroker();

  const [action, setAction] = useState<string>();

  const [data, setData] = useState<any[]>();
  const [infoModal, toggleModal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [loadingLinks] = useState(false);
  const [year, setYear] = useState(stateYear ?? initialYear);

  const historicFeature = item.features[0];
  const downloadFeature = item.features[1];
  // const sendEmailFeature = item.features[2];
  const [reportData, setReportData] = useState<any>(null);

  const userPlanInfoStatus = useMemo(
    () => user.user?.userPlanInfoVelotax,
    [user.user?.userPlanInfoVelotax]
  );

  const yearOptions = Array.from(Array(initialYear - 2020 + 1))
    .map((y, i) => ({
      value: 2020 + i,
      label: `${2020 + i}`,
    }))
    .reverse();

  const onChangeYear = (value: string) => {
    setYear(parseInt(value));
  };

  const getHistoric = useCallback(() => {
    if (!action) return;
    if (action === "anual-report")
      return setData(
        Array.from(Array(new Date().getFullYear() - 2022))
          .map((y, i) => ({
            year: 2022 + i,
            label: `${2022 + i}`,
          }))
          .reverse()
      );

    setLoading(true);
    (currentPage?.api || apis)
      .get(historicFeature.apiUrl, { params: { year } })
      .then((response) => setData((response.data ?? []).reverse()))
      .catch(() => message.error(errorMessage))
      .finally(() => setLoading(false));
  }, [historicFeature, year, currentPage, action]);

  // const getDowloadLinks = useCallback(
  //   (data: any[], action: string) => {
  //     setLoadingLinks(true);
  //     const dataLinks = data.map((item) =>
  //       !!item._id
  //         ? (currentPage?.api || apis).post(
  //             `${downloadFeature.apiUrl}/${action}`,
  //             {
  //               id: item._id,
  //               year: item.year,
  //               month: item.month,
  //             }
  //           )
  //         : Promise.resolve({ data: item })
  //     );
  //     Promise.allSettled(dataLinks).then((response) => {
  //       setData(
  //         response.map((item, index) =>
  //           item.status === "fulfilled" && item.value.data?.file
  //             ? {
  //                 ...data?.[index],
  //                 url: `data:application/pdf;base64,${item.value.data.file}`,
  //               }
  //             : data?.[index]
  //         )
  //       );
  //       setLoadingLinks(false);
  //     });
  //   },
  //   [currentPage, downloadFeature]
  // );

  // const sendDarfToEmail = (reportId: string) => {
  //   (currentPage?.api || apis)
  //     .get(`${sendEmailFeature.apiUrl}/${reportId}`)
  //     .then(() => {
  //       message.success("O DARF foi enviado para o seu e-mail");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const sendReportMail = useCallback(() => {
    const reportType = reportData.type || 'anual-report'
    api.get(`/darf/report/mail/${reportType}`, {
      params: {
        year: reportData.year,
        month: reportData.month,
        filePath: reportData.filePath,
      }
    }).then(() => {
      message.success("Relatório enviado para seu email.")
    })
    .catch(() => {
      message.error("Houve um erro ao enviar seu relatório!")
    })
  }, [reportData])

  const downloadAction = async (record: any) => {
    const act = record?.action || action;
    setDownloading(true);
    const apiInstance = currentPage?.api || apis;

    const mobileAction = isMobile()
    
    if (mobileAction) {
      message.info("Preparando seu relatório.");
    } else {
      message.info("Iniciando download do seu relatório.");
    }
    apiInstance
      .post(`${downloadFeature.apiUrl}/${act}`, {
        from: "xpinvestimentos",
        id: record._id,
        month: record.month,
        year: record.year,
        sendMail: record.sendMail || mobileAction,
      })
      .then((res) => {
        if (!res.data.fileUrl) {
          return message.error("Houve um erro ao buscar seu relatório!")
        }
        if (!mobileAction) {
          download(res.data.fileUrl);
          setReportData({
            type: act,
            filePath: res.data.filePath,
            month: record.month,
            year: record.year,
          });
        }
        // if (res.data.file) {
        // downloadPDF(res.data.file);
        // }
        if (record.sendMail || mobileAction) {
          message.success("Relatório enviado para o seu e-mail");
        } else {
          message.success("Relatório emitido com sucesso");
        }
      })
      .catch(({ response }) =>
        message.error(response?.data?.message ?? errorMessage)
      )
      .finally(() => {
        setDownloading(false);
        toggleModal(null);
      });
  };

  const checkProcessorAction = (record: any) => {
    setLoading(true);
    let showToast = false;
    toggleModal(record);
    const itvl = setInterval(() => {
      api
        .get(`${downloadFeature.apiUrl}/anual-report?year=${record.year}`)
        .then((res) => {
          const { data } = res;
          const { year, status } = data;
          const isProcessing = record.year === year && status === "processing";
          if (isProcessing && showToast) return;
          if (isProcessing && !showToast) {
            showToast = true;
            return message.success(
              "O seu Informe de Rendimentos está em geração. Aguarde alguns minutos."
            );
          }
          setLoading(false);
          clearInterval(itvl);
        })
        .catch(({ response }) => {
          const { data, status } = response;
          if (status === 401)
            setTimeout(
              () => navigate("/xpinvestimentos/bolsa-integration"),
              2000
            );
          message.error(data?.message ?? errorMessage);
          setLoading(false);
          clearInterval(itvl);
        });
    }, 5000);
  };

  const handlePlanModal = () => {
    showUserPlanModal(!userPlanModal, ReportAlertTitle);
  };

  const handleActionButton = (id: string) => {
    setAction(id);
    // if (!hasPlan) {
    //   handlePlanModal();
    // } else {
    //   setAction(id);
    // }
  };

  useEffect(() => {
    getHistoric();
  }, [getHistoric, year]);

  return (
    <ReportContainer>
      <div className="content">
        <Typography.Title level={1}>Relatórios</Typography.Title>
        <Typography.Paragraph>
            Confira os relatórios{" "}
            {action !== "anual-report" ? "auxiliares" : "anuais"} para a
            declaração do seu IR na bolsa de valores.
        </Typography.Paragraph>

        {!action && (
          <div className="cards">
            {cards.map((item) => (
              <Card
                id={item.id}
                key={item.id}
                disabled={item.id !== "anual-report" && loading}
                icon={
                  (loading || downloading) && item.id === "anual-report" ? (
                    <LoadingOutlined />
                  ) : (
                    item.icon
                  )
                }
                onClick={() => !loading && handleActionButton(item.id)}
              >
                {item.id === "irpf" ? (
                  <>
                    <a
                      {...(!isMobile() ? { target: "_blank" } : {})}
                      href={
                        !process.env.REACT_APP_NODE_ENV?.includes("dev")
                          ? `https://irpf.velotax.com.br/p/sso/${ssoToken}`
                          : `https://irpfdev.velotax.com.br/p/sso/${ssoToken}`
                      }
                    >
                      {" "}
                    </a>
                    {item.content}
                  </>
                ) : (
                  <>
                    {item.content}
                    {item.description && (
                      <div className="description">{item.description}</div>
                      )}
                      </>
                )}
              </Card>
            ))}
          </div>
        )}
        {!action && (
          <BackButton to="/xpinvestimentos/bolsa" className="report-bolsa" />
        )}
        {action && (
          <Container>
            <Content>
              <Typography.Paragraph>Selecione o ano:</Typography.Paragraph>
              {action !== "anual-report" && (
                <Select
                  size="large"
                  options={yearOptions}
                  value={year.toString()}
                  onChange={onChangeYear}
                  placeholder="Selecione o ano"
                  style={{ minWidth: "150px" }}
                />
              )}
              <List
                loading={loading || downloading}
                dataSource={
                  data?.filter(
                    (item) =>
                      action === "anual-report" ||
                      item.year === year ||
                      item.year === currentYear
                  ) ?? []
                }
                itemLayout="horizontal"
                renderItem={(item, index) => (
                  <List.Item
                    actions={[
                      <Button
                        type="text"
                        size="large"
                        href={item.url}
                        disabled={loadingLinks}
                        onClick={
                          () =>
                            action === "anual-report"
                              ? checkProcessorAction(item)
                              : downloadAction(
                                  item
                                ) /*sendDarfToEmail(item._id)*/
                        }
                        download={`Relatório-${item.year}-${zero(
                          item.month
                        )}.pdf`}
                      >
                        Baixar
                        {loadingLinks ? (
                          <LoadingOutlined size={16} />
                        ) : (
                          <RiDownloadCloudLine size={16} />
                        )}
                      </Button>,
                    ]}
                  >
                    <Skeleton title={true} loading={item.loading} active>
                      <List.Item.Meta
                        title={
                          action !== "anual-report"
                            ? `${monthsExtended[item.month - 1]}`
                            : `${item.year}`
                        }
                      />
                    </Skeleton>
                  </List.Item>
                )}
              />
              <Button
                type="link"
                size="large"
                onClick={() => handleActionButton("")}
                style={{ marginTop: "1rem" }}
              >
                Voltar
              </Button>
            </Content>
          </Container>
        )}
        <Modal
          title="Informe de Rendimentos"
          maskClosable={false}
          visible={!!infoModal}
          onCancel={() => {
            toggleModal(null);
          }}
          footer={false}
        >
          <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
            <Col span={24}>
              <MaterialButton
                fullWidth
                size="large"
                color="secondary"
                variant="contained"
                onClick={() => {
                  if (!loading && !downloading) {
                    downloadAction({ ...(infoModal ?? {}), action: "anual-report" });
                  }
                }}
              >
                {loading || downloading ? (
                  <>
                    <LoadingOutlined />
                    &nbsp;{downloading ? "Baixando" : "Gerando"} informe
                  </>
                ) : isMobile() ? (
                  "Enviar informe por email"
                ) : (
                  "Baixar informe"
                )}
              </MaterialButton>
            </Col>
            <Col xs={24} sm={12}>
              <MaterialButton
                fullWidth
                size="small"
                variant="text"
                color="secondary"
                disabled={loading || downloading}
                onClick={() => {
                  if (!loading && !downloading) {
                    navigate("/xpinvestimentos/bolsa-historic");
                  }
                }}
              >
                Ir para calculadora de DARF
              </MaterialButton>
            </Col>
          </Row>
        </Modal>
        <ModalConfirmMail
          closeFn={() => setReportData(null)}
          visible={Boolean(reportData)}
          action={(sendMail) => {
            if (sendMail) sendReportMail();
          }}
        />
      </div>
    </ReportContainer>
  );
};
