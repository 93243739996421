import styled from "styled-components";

export const Container = styled.div`
  .income-report-uploader {
    height: auto;
    display: block;
    margin-bottom: 16px;

    .ant-upload {
      width: 100%;
      height: 200px;

      .upload-text-icon {
        height: 100%;
        cursor: pointer;
        font-size: 20px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        svg {
          fill: var(--ant-primary-color);
        }

        .upload-text-desc {
          font-size: 14px;
          text-align: center;
        }
      }

      .loading-container {
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .anticon {
          width: 40px;
          height: 40px;
          margin-bottom: 12px;

          svg {
            width: 100%;
            height: 100%;
            fill: var(--ant-primary-color);
          }
        }
      }
    }
  }
`;
