import React, { useEffect, useState } from "react";
import { Col, Row, Typography, Spin, message } from "antd";
import Button from "../../Button";
import apis from "../../../services/apis";
import { CopyText } from "../../CopyText";
import { formatCurrency } from "../../../utils";
// import pixLogo from "../../../assets/logo-pix.png";
import { useAuth } from "../../../contexts/AuthContext";
import { apiPayment } from "../../../services/apiPayment";
import { useBroker } from "../../../contexts/BrokerContext";
import { getPlanPrice, planos } from "../../../pages/Planos/planos";

interface IPixPaymentProps {
  paymentData: any;
  handleIdBack: (event: any) => void;
  onSucess?: (event: any) => void;
  backStep?: () => void;
  cupom?: any;
}

const pixTaxesByInsitution: Record<string, number> = {
  "imoveis-velotax": 350,
  "rendimentos-velotax": 9.9,
  "bolsa-velotax": 9.9,
  "exterior-velotax": 9.9,
  "crypto-velotax": 9.9,
};

const PixPaymentStep: React.FC<IPixPaymentProps> = ({
  paymentData,
  handleIdBack,
  onSucess,
  backStep,
  cupom,
}) => {
  const { user, hasPlan } = useAuth();
  const { currentPage } = useBroker();
  const [brCode, setBrCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [qrCodeLink, setQRCodeLink] = useState(null);
  const [qrCodeLinkUrl, setQRCodeLinkUrl] = useState(null);
  const provider = paymentData.isPlan ? "pagarme" : "starkbank";

  useEffect(() => {
    if (provider === "pagarme") {
      callUserPlanPayment();
    } else {
      callDarfPix();
    }
    // eslint-disable-next-line
  }, []);

  // const planPixInfo = {
  //   ...paymentData,
  //   value: 238.8,
  //   oldValue: 310.8,
  // };

  // const priceNew = useMemo(() => {
  //   if (paymentData.isPlan) {
  //    var planPrice = planPixInfo?.value || 0;

  //     if (cupom?.type === "absolute") {
  //       planPrice =
  //         Number(((planPrice - Number(cupom?.discountValue)) / 12).toFixed(2)) *
  //         12;
  //     } else if (cupom?.type === "percent") {
  //       planPrice =
  //         Number(
  //           (
  //             (planPrice - planPrice * Number(cupom?.discountValue / 100)) /
  //             12
  //           ).toFixed(2)
  //         ) * 12;
  //     }

  //     return planPrice;
  //   }
  // }, [
  //   cupom?.discountValue,
  //   cupom?.type,
  //   paymentData.isPlan,
  //   planPixInfo?.value,
  // ]);

  const callUserPlanPayment = () => {
    let url: string = "/user-plan/";
    setLoading(true);
    apiPayment
      .post(url, {
        ...paymentData,
        cupomCode: cupom?.cupomCode,
        paymentForm: "pix",
        from: "XP",
        acceptTerms: true,
        clientInfo: {
          name: user?.user?.name,
          email: user?.user?.email,
          document: user?.user?.cpf,
        },
      })
      .then((res: any) => {
        setLoading(false);
        // handleIdBack(res?.data?.paymentId);
        if (onSucess && typeof onSucess === "function")
          onSucess(res?.data?.paymentId);
        setQRCodeLinkUrl(res?.data?.qrCode);
        setBrCode(res?.data?.br_code);
      })
      .catch((err) => {
        message.error(err.response?.data?.message);
      });
  };

  const callDarfPix = () => {
    let url: string = "/darf";
    setLoading(true);
    (currentPage?.api || apis)
      .post(url, {
        ...paymentData,
        paymentForm: "pix",
        paymentInfo: {
          clientInfo: {
            name: user?.user?.name,
            email: user?.user?.email,
            document: user?.user?.cpf,
          },
        },
      })
      .then((res: any) => {
        setLoading(false);
        handleIdBack(res?.data?.payment?.paymentId);
        setQRCodeLink(res?.data?.qrCode);
        setBrCode(res?.data?.br_code);
      })
      .catch((err) => {
        message.error(err.response?.data?.message);
      });
  };

  // const cutBrCode = () => {
  //   let cut_brcode: any = "";
  //   if (brCode) {
  //     cut_brcode = brCode.split("/");
  //   }

  //   return cut_brcode[0];
  // };

  const plano = planos.find(p => p.type === paymentData.type)
  const price = getPlanPrice(plano!, hasPlan) ?? 25.9;
  const installments = plano?.installments || 1;
  let totalPrice = price * installments;
  if (cupom && cupom.active && cupom.type === "absolute")
    totalPrice -= cupom.discountValue;
  if (cupom && cupom.active && cupom.type === "percent")
    totalPrice -= totalPrice * (cupom.discountValue / 100);
  const planPrice = totalPrice / installments;

  return (
    <Row>
      <Col>
        {/* {paymentData.isPlan && (
          <>
            <Row justify="center">
              <div className="original-price">
                <Typography.Text
                  style={{
                    color: "var(--velotax-font-color-dark)",
                    fontSize: 15,
                  }}
                >
                  <strong>
                    Plano Premium: {formatCurrency(priceNew)} por ano
                  </strong>
                </Typography.Text>
                <br />
                <Typography.Text
                  delete
                  style={{
                    color: "red",
                    fontSize: 12,
                    justifyContent: "right",
                    display: "flex",
                  }}
                  className="per-month"
                >
                  de {formatCurrency(planPixInfo.oldValue)} por ano
                </Typography.Text>
                {cupom?.type && (
                  <Typography.Text
                    style={{
                      fontSize: 12,
                      display: "flex",
                      justifyContent: "right",
                      color: "var(--velotax-font-color-ghost)",
                    }}
                    className="per-month"
                  >
                    cupom de desconto de{" "}
                    {cupom.type === "percent" ? (
                      cupom.discountValue + " %"
                    ) : (
                      <div style={{ marginLeft: 5 }}>
                        {formatCurrency(cupom.discountValue)}
                      </div>
                    )}
                  </Typography.Text>
                )}
              </div>
            </Row>
          </>
        )} */}
        {/* <Row>
          <Col
            style={{
              margin: "auto",
              marginTop: paymentData.isPlan ? -25 : -35,
            }}
          >
            <img
              alt="PIX Logo"
              style={{ width: 150, height: 150 }}
              src={pixLogo}
            />
          </Col>
        </Row> */}
        {/* {provider !== "pagarme" && (
          <Row justify="center">
            <Col
              style={{ fontSize: "14px", color: "var(--ant-primary-color)" }}
            >
              <strong>
                Taxa PIX:{" "}
                {formatCurrency(
                  pixTaxesByInsitution?.[paymentData.institution] || 9.9
                )}
              </strong>
            </Col>
          </Row>
        )} */}
        <Row
          gutter={[16, 16]}
          // style={{ marginTop: paymentData.isPlan ? "-10px" : "30px" }}
        >
          <Col>
            <Typography style={{ color: "var(--velotax-font-color-dark)" }}>
              Abra o aplicativo do banco em que você possui o PIX cadastrado e
              selecione a opção de escanear QR Code ou PIX copia e cola.{" "}
              {paymentData.isPlan && (
                <Col
                  style={{
                    width: "100%",
                    display: "flex",
                    margin: "16px 0",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography.Text
                    strong
                    style={{
                      textAlign: "center",
                      color: "var(--velotax-font-color-dark)",
                    }}
                  >
                    Plano anual
                  </Typography.Text>
                  <Typography.Text
                    style={{
                      textAlign: "center",
                      color: "var(--velotax-font-color-dark)",
                    }}
                  >
                    12x de {formatCurrency(planPrice)} ={" "}
                    {formatCurrency(planPrice * 12)}
                  </Typography.Text>
                </Col>
              )}
            </Typography>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "16px",
              width: "100%",
              // marginTop: -35,
            }}
          >
            {qrCodeLink && (
              <img
                alt="PIX QRCode"
                style={{ width: 190 }}
                src={`data:image/jpeg;base64, ${qrCodeLink}`}
              />
            )}

            {!qrCodeLink && !qrCodeLinkUrl && (
              <Spin spinning={loading} style={{ margin: "32px" }}></Spin>
            )}

            {qrCodeLinkUrl && (
              <img
                alt="PIX QRCode"
                style={{ width: 190 }}
                src={qrCodeLinkUrl}
              />
            )}
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="center">
          {brCode ? (
            <>
              {/* <Col span={24}>
                <Button
                  type="text"
                  size="small"
                  style={{ margin: "0 auto" }}
                  onClick={() => {
                    navigator.clipboard.writeText(brCode);
                    message.success("Pix copiado!");
                  }}
                >
                  <span className="xp-link">Copiar código PIX</span>
                </Button>
              </Col> */}
              <Col span={24}>
                <Typography.Paragraph
                  style={{
                    textAlign: "center",
                    wordBreak: "break-all",
                    fontWeight: 500,
                    fontSize: "11px",
                    maxWidth: "240px",
                    margin: "0 auto",
                    color: "var(--velotax-font-color-dark)",
                  }}
                >
                  <CopyText id="pix-code">{brCode}</CopyText>
                </Typography.Paragraph>
              </Col>
            </>
          ) : (
            ""
          )}
        </Row>
        {/* <Row style={{ marginTop: "25px" }}>
          <Col>
            <Typography.Paragraph style={{ fontSize: 12 }}>
              <strong>Instruções de pagamento por PIX:</strong> Abra o
              aplicativo do banco que você possui o PIX cadastrado e selecione a
              opção de escanear o QR Code.
            </Typography.Paragraph>
          </Col>
        </Row> */}
        {!paymentData.isPlan && (
          <Row style={{ marginTop: "5px" }}>
            <Col>
              <Typography.Paragraph style={{ textAlign: "left", fontSize: 12 }}>
                Taxa de serviço:{" "}
                {formatCurrency(
                  pixTaxesByInsitution?.[paymentData.institution] || 9.9
                )}
              </Typography.Paragraph>
            </Col>
          </Row>
        )}
        <Button
          size="middle"
          type="default"
          onClick={() => backStep && backStep()}
        >
          <span className="xp-link uppercase">Voltar</span>
        </Button>
      </Col>
    </Row>
  );
};

export default PixPaymentStep;
