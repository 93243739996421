import { Form, Select } from "antd";
import React, { KeyboardEvent } from "react";
import ReactInputMask from "react-input-mask";
import { cnpjInputMask, isValidCNPJ } from "../../utils";
import {
  validationCnpj,
  validationFieldRequired,
} from "../../utils/formValidations";

export enum MEIAtividadePrincipalEnum {
  SERVICO = "Serviço",
  COMERCIO_INDUSTRIA = "Comércio e Indústria",
  // COMERCIO_SERVICOS = "Comércio e Serviços",
}

export const MEIAtividadePrincipalValue: {
  [key: number]: MEIAtividadePrincipalEnum;
} = {
  61.6: MEIAtividadePrincipalEnum.COMERCIO_INDUSTRIA,
  65.6: MEIAtividadePrincipalEnum.SERVICO,
  // 66.6: MEIAtividadePrincipalEnum.COMERCIO_SERVICOS,
};

export interface IdentificationData {
  companyCode: string;
  mainActivity: MEIAtividadePrincipalEnum;
}

export const isValidAtividadePrincipal = (value: string) =>
  Object.values(MEIAtividadePrincipalEnum).includes(
    value as MEIAtividadePrincipalEnum
  );

export const validateIdentification = (data: IdentificationData) =>
  isValidCNPJ(data.companyCode) && isValidAtividadePrincipal(data.mainActivity);

interface Question {
  id: string;
  title: React.ReactNode;
  formItems: { name: string; FormItem: React.FC<{ tabIndex?: number }> }[];
}

export const identificationQuestions: Question[] = [
  {
    id: "companyCode",
    title: "Qual é o CNPJ do seu MEI?",
    formItems: [
      {
        name: "companyCode",
        FormItem: ({ tabIndex }) => (
          <Form.Item name="companyCode" rules={validationCnpj}>
            <ReactInputMask
              tabIndex={tabIndex}
              mask={cnpjInputMask}
              className="ant-input ant-input-lg"
            />
          </Form.Item>
        ),
      },
    ],
  },
  {
    id: "mainActivity",
    title: "Qual é a atividade principal do seu MEI?",
    formItems: [
      {
        name: "mainActivity",
        FormItem: ({ tabIndex }) => (
          <Form.Item name="mainActivity" rules={validationFieldRequired}>
            <Select
              tabIndex={tabIndex}
              onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
                if (event.key === "Enter") {
                  event.stopPropagation();
                }
              }}
            >
              <Select.Option value={MEIAtividadePrincipalEnum.SERVICO}>
                {MEIAtividadePrincipalEnum.SERVICO}
              </Select.Option>
              <Select.Option
                value={MEIAtividadePrincipalEnum.COMERCIO_INDUSTRIA}
              >
                {MEIAtividadePrincipalEnum.COMERCIO_INDUSTRIA}
              </Select.Option>
            </Select>
          </Form.Item>
        ),
      },
    ],
  },
];
