import FileSaver from "file-saver";
import { message } from "antd";

export const handleDownloadSheet = async (buffer: any, title: string) => {
  try {
    const data = Uint8Array.from(buffer)
    const blob = new Blob([data], { type: 'attach.contentType' });
    FileSaver.saveAs(blob, `${title}.xlsx`);
  } catch (error: any) {
    message.error(error)
  }
}
