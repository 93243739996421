import { ConfigProvider } from "antd";
import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes as Switch,
  useLocation,
} from "react-router-dom";
import PrivateRoute from "./PrivateRoutes";
import HandleTag from "../services/handleTag";
import OnlyPublicRoute from "./OnlyPublicRoute";
import PageNotFound from "../pages/PageNotFound";
import PageWrapper from "../components/PageWrapper";
import { useBroker } from "../contexts/BrokerContext";
import { PublicWrapper } from "../components/PublicWrapper";

function Routes() {
  const { pathname } = useLocation();
  const { currentBroker, publicRoutes, privateRoutes, brokerRoutes } =
    useBroker();
  const [lastPath, setLastPath] = useState("");

  useEffect(() => {
    if (pathname !== lastPath) {
      setLastPath(pathname);
      if (!lastPath.includes("bolsa") && pathname.includes("bolsa")) {
        HandleTag("47");
      }
      if (!lastPath.includes("exterior") && pathname.includes("exterior")) {
        HandleTag("48");
      }
      if (!lastPath.includes("pgbl") && pathname.includes("pgbl")) {
        HandleTag("90");
      }
    }
  }, [pathname, lastPath]);

  ConfigProvider.config({
    theme: {
      primaryColor: currentBroker?.colors.primary || "#1634ff",
      infoColor: currentBroker?.colors.secondary || "#000058",
      successColor: "#05aa99",
    },
  });

  return (
    <>
      <TextField
        variant="standard"
        style={{ display: "none", position: "fixed", top: 0, right: 0 }}
      />
      <Switch>
        <Route
          path="p"
          element={
            <PublicWrapper>
              <OnlyPublicRoute />
            </PublicWrapper>
          }
        >
          {publicRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
          <Route path="" element={<Navigate to="cadastrar" />} />
          <Route path="*" element={<Navigate to="cadastrar" />} />
        </Route>

        <Route
          path="/"
          element={
            <PageWrapper sideBar={true} header={true} overflow={true}>
              <PrivateRoute />
            </PageWrapper>
          }
        >
          {brokerRoutes}
          {privateRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
          <Route path="" element={<Navigate to="/" />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>

        <Route path="" element={<PageNotFound />} />
        <Route path="*" element={<PageNotFound />} />
      </Switch>
    </>
  );
}

export default Routes;
