import clsx from "clsx";
import { cpfInputMask, formatCurrency } from "../../utils";
import { VscClose } from "react-icons/vsc";
import { useState } from "react";
import backendRendimentos from "../../services/rendimentos";
import { FaRegEdit } from "react-icons/fa";
import NumberFormat from "react-number-format";
import { validationCpf } from "../../utils/formValidations";
import { currencyToNumber } from "../../utils";
import { InfoCircleOutlined } from "@ant-design/icons";
import Button from "../../components/Button";
import { List, Form, message } from "antd";
import {
  CurrencyFormItem,
  MaskFormItem,
  SelectFormItem,
  TextFormItem,
} from "../formItems";
const minDarfPrice = 10;
const RendimentoFormItems = {
  id: TextFormItem({
    name: "id",
    label: "Id",
    hidden: true,
  }),
  tipo: SelectFormItem({
    name: "tipo",
    label: "Tipo",
    placeholder: "Selecione",
    options: [
      { label: "Trabalho Autônomo", value: "Trabalho Autônomo" },
      { label: "Aluguéis", value: "Aluguéis" },
      { label: "Pensão Alimentícia", value: "Pensão Alimentícia" },
      { label: "Exterior", value: "Exterior" },
      { label: "Outros", value: "Outros" },
    ],
  }),
  valorRendimento: CurrencyFormItem({
    name: "valorRendimento",
    label: "Rendimento",
  }),
  cpfPagador: MaskFormItem({
    mask: cpfInputMask,
    rules: validationCpf,
    name: "cpfPagador",
    label: "CPF do pagador",
    hide: ({ data }: any) => data?.tipo !== "Trabalho Autônomo",
    onChange: (changed: any, values: any) =>
      changed.cpfPagador
        ? changed.cpfPagador.replace(/[_.-]/g, "")
        : values.cpfPagador,
  }),
  cpfBeneficiario: MaskFormItem({
    mask: cpfInputMask,
    rules: validationCpf,
    name: "cpfBeneficiario",
    label: "CPF do beneficiário",
    hide: ({ data }: any) => data?.tipo !== "Trabalho Autônomo",
    onChange: (changed: any, values: any) =>
      changed.cpfBeneficiario
        ? changed.cpfBeneficiario.replace(/[_.-]/g, "")
        : values.cpfBeneficiario,
  }),
};

interface IEdit {
  value: any;
  id: string;
  isEditting: boolean;
}

export enum YearResumeStatusFromBackEnum {
  PAYED = "PAYED",
  PENDING = "PENDING",
  REGULAR = "REGULAR",
  NOT_PAYED = "NOT_PAYED",
}

export interface IDarf {
  payed?: boolean;
  emitted?: boolean;
  multa: number;
  juros: number;
  totalAlienado: number;
  totalGanho: number;
  aliquotaDevida: number;
  impostoDevido: number;
  impostoAcumulado: number;
  totalImpostoDevido: number;
  memoriaCalculo: any[];
  regular?: boolean;
  status?: keyof typeof YearResumeStatusFromBackEnum;
}
export interface IDarfBolsa {
  payed?: boolean;
  emitted?: boolean;
  aliquotaComum?: number;
  aliquotaDT?: number;
  aliquotaFII?: number;
  alienacoesComum?: number;
  alienacoesDT?: number;
  alienacoesFII?: number;
  ganhoComum?: number;
  ganhoDT?: number;
  ganhoFII?: number;
  impostoComumFinal?: number;
  impostoDTFinal?: number;
  impostoFIIFinal?: number;
  impostoTotal?: number;
  impostoTotalFinal?: number;
  irrfComum?: number;
  irrfDayTrade?: number;
  irrfFII?: number;
  irrfTotal?: number;
  jurosAux?: number;
  mercadoOpcoesComum?: number;
  mercadoOpcoesDT?: number;
  mercadoOutrosComum?: number;
  mercadoOutrosDT?: number;
  mercadoOutrosFII?: number;
  mercadoVistaComum?: number;
  mercadoVistaDT?: number;
  multaAux?: number;
  memoriaCalculo: any[];
  impostoAcumulado?: number;
  impostoDevido?: number;
  multa?: number;
  juros?: number;
  prejuizoComum?: number;
  prejuizoDayTrade?: number;
  prejuizoFII?: number;
  userPrejuizoComum?: number;
  userPrejuizoDayTrade?: number;
  userPrejuizoFII?: number;
  userIrrfComum?: number;
  userIrrfDayTrade?: number;
  userIrrfFII?: number;
  userIrrfTotal?: number;
  varTotaisComum?: number;
  varTotaisDT?: number;
  varTotaisFII?: number;
}

export const MinPriceModalProps = {
  title: <>DARF abaixo de {formatCurrency(minDarfPrice)}</>,
  content: (
    <div>
      <p>
        O sistema da Receita Federal não permite a emissão de um único DARF com
        valor abaixo de {formatCurrency(minDarfPrice)}.
      </p>
      <p>
        Caso sei DARF tenha ficado com um valor abaixo de{" "}
        {formatCurrency(minDarfPrice)}, o valor será repassado automaticamente
        para o mês seguinte e ficará visível no campo{" "}
        <strong>
          <i>DARFs passados abaixo de {formatCurrency(minDarfPrice)}</i>
        </strong>
        .
      </p>
    </div>
  ),
};

const MultaModalProps = {
  title: "Multa de Atraso",
  content: (
    <div>
      <p>
        O pagamento dos impostos deve ser feito sempre até o último dia útil do
        mês subsequente aos ganhos realizados.
      </p>
      <p>
        Caso você tenha atrasado no pagamento do seu DARF, a sua multa de atraso
        será de 0,33% por dia de atraso (chegando, no máximo, a 20%) sobre o
        valor do principal, calculados a partir do primeiro dia útil depois do
        dia do vencimento do DARF.
      </p>
    </div>
  ),
};

const JurosModalProps = {
  title: "Juros de Atraso",
  content: (
    <div>
      <p>
        O pagamento dos impostos deve ser feito sempre até o último dia útil do
        mês subsequente aos ganhos realizados.
      </p>
      <p>
        Caso você tenha atrasado no pagamento do seu DARF, os seus juros de
        atraso serão equivalentes a taxa Selic acumulada até o mês anterior ao
        do pagamento, mais 1% no mês do pagamento, sobre o valor principal,
        contados a partir do mês subsequente ao vencimento do DARF.
      </p>
    </div>
  ),
};

export const RendimentoFormItemRows = [
  [RendimentoFormItems.id],
  [RendimentoFormItems.tipo],
  [RendimentoFormItems.valorRendimento],
  [RendimentoFormItems.cpfPagador],
  [RendimentoFormItems.cpfBeneficiario],
];

export const ImpostoTotalRendimentos = [
  {
    id: "impostoDevido",
    label: () => "(+) Valor do principal",
    Component: ({ data }: any) => {
      const impostoPrincipal =
        (data?.impostoDevido ?? 0) + (data?.impostoAcumulado ?? 0);

      return formatCurrency(impostoPrincipal);
    },
  },
  {
    id: "impostoDevido",
    label: () => (
      <div
        className="desc-label"
        style={{ fontSize: "12px", paddingLeft: "24px" }}
      >
        (+) Imposto devido
      </div>
    ),
    Component: ({ data }: any) =>
      formatCurrency(Number(data?.impostoDevido || 0)),
  },
  {
    id: "impostoAcumulado",
    label: (onClick: any) => (
      <div
        className="desc-label"
        style={{ fontSize: "12px", paddingLeft: "24px" }}
      >
        (+) DARFs passados abaixo de R$ 10
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => onClick(MinPriceModalProps)}
        />
      </div>
    ),
    Component: ({
      data,
      year,
      month,
      loading,
      disabled,
      setDataBolsa,
      setDataCripto,
      id,
      monthStock,
      user,
    }: any) => {
      const [edit, setEdit] = useState<IEdit>();
      const [editForm] = Form.useForm();
      const handleEditValue = (id: string) => {
        const value = data?.[id as keyof (IDarf | IDarfBolsa)];
        setEdit({ id, isEditting: true, value });
        editForm.setFieldsValue({ [id]: value });
      };

      const handleEditValueCancel = () => {
        setEdit(undefined);
        editForm.resetFields();
      };
      const handleEditValueConfirm = () => {
        if (edit && edit.value >= 0 && edit.value < minDarfPrice) {
          if (id !== "") {
            backendRendimentos
              .put("/rendimentos", {
                _id: id,
                year,
                month: month + 1,
                [edit.id]: edit.value,
              })
              .then(() =>
                (setDataCripto ?? setDataBolsa)?.((data: any) => ({
                  ...data,
                  [edit.id]: edit.value,
                }))
              )
              .catch(() =>
                message.error(
                  "Algo inesperado aconteceu! Tente novamente mais tarde."
                )
              )
              .finally(() => handleEditValueCancel());
          } else {
            backendRendimentos
              .post("/rendimentos", {
                monthStock,
                year,
                month: month + 1,
                juros: data.juros,
                multa: data.multa,
                userCode: user.user.cpf,
                total: data.impostoTotal,
                impostoDevido: data.impostoDevido,
                totalRendimentos: data.totalRendimentos,
                baseTributaria: data.baseTributaria,
                aliquotaDevida: data.aliquotaDevida,
                totalDependentes: data.totalDependentes,
                totalDespesas: data.totalDespesas,
                totalPrevidencia: data.totalPrevidencia,
                totalPensao: data.totalPensao,
                totalDeducoes: data.totalDeducoes,
                [edit.id]: edit.value,
              })
              .then(() =>
                (setDataCripto ?? setDataBolsa)?.((data: any) => ({
                  ...data,
                  [edit.id]: edit.value,
                }))
              )
              .catch(() =>
                message.error(
                  "Algo inesperado aconteceu! Tente novamente mais tarde."
                )
              )
              .finally(() => handleEditValueCancel());
          }
        } else {
          handleEditValueCancel();
        }
      };
      return edit && edit.isEditting && edit.id === "impostoAcumulado" ? (
        <div className="desc-content">
          <Button icon={<VscClose />} onClick={handleEditValueCancel} />
          <Form
            form={editForm}
            onValuesChange={(changed) => {
              const value = currencyToNumber(changed.impostoAcumulado);
              editForm.setFieldsValue({ impostoAcumulado: value });
              setEdit((edit) => ({ ...edit!, value }));
            }}
          >
            <Form.Item
              name="impostoAcumulado"
              rules={[
                {
                  message: `Deve ser menor que ${minDarfPrice}`,
                  validator: (rule, value) =>
                    typeof value === "number" &&
                    value >= 0 &&
                    value < minDarfPrice
                      ? Promise.resolve()
                      : Promise.reject(),
                },
              ]}
            >
              <NumberFormat
                prefix="R$ "
                decimalScale={2}
                disabled={loading}
                decimalSeparator=","
                thousandSeparator="."
                className="ant-input"
                allowNegative={false}
              />
            </Form.Item>
          </Form>
          <Button
            type="primary"
            onClick={handleEditValueConfirm}
            disabled={edit.value < 0 || edit.value >= minDarfPrice}
          >
            Ok
          </Button>
        </div>
      ) : (
        <div
          className={clsx("desc-content", {
            "ml-40": disabled,
          })}
        >
          {formatCurrency(Number(data?.impostoAcumulado || 0))}
          {disabled && (
            <Button
              type="text"
              icon={<FaRegEdit />}
              onClick={() => handleEditValue("impostoAcumulado")}
            />
          )}
        </div>
      );
    },
  },
  {
    id: "multa",
    label: (onClick: any) => (
      <div className="desc-label">
        (+) Multa de atraso{" "}
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => onClick(MultaModalProps)}
        />
      </div>
    ),
    Component: ({ data }: any) =>
      formatCurrency(
        Number(data?.["impostoDevido"] || 0) +
          Number(data?.["impostoAcumulado"] || 0) <
          minDarfPrice
          ? 0
          : Number(data?.["multa"] || 0)
      ),
  },
  {
    id: "juros",
    label: (onClick: any) => (
      <div className="desc-label">
        (+) Juros e/ou encargos de atraso{" "}
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => onClick(JurosModalProps)}
        />
      </div>
    ),
    Component: ({ data }: any) =>
      formatCurrency(
        Number(data?.["impostoDevido"] || 0) +
          Number(data?.["impostoAcumulado"] || 0) <
          minDarfPrice
          ? 0
          : Number(data?.["juros"] || 0)
      ),
  },
];

export const RendimentosImpostosDevidos = [
  {
    id: "totalRendimentos",
    label: () => "Total rendimentos",
    render: (data: any) => formatCurrency(data?.totalRendimentos ?? 0),
  },
  {
    id: "aliquotaDevida",
    label: (onClick: any) => (
      <div className="desc-label">
        (x) Alíquota devida
        <Button
          type="text"
          icon={<InfoCircleOutlined />}
          onClick={() => onClick(AliquotaModalProps)}
        />
      </div>
    ),
    render: (data: any) => `${data?.aliquotaDevida}%`,
  },
  {
    id: "impostoDevido",
    label: () => "Imposto devido",
    render: (data: any) => formatCurrency(data?.impostoDevido ?? 0),
  },
];

const AliquotaModalProps = {
  title: "Alíquota sobre Ganho Carnê-Leão",
  content: (
    <div>
      <p>
        O imposto sobre rendimentos é calculado sobre o total recebido no mês,
        mediante a aplicação da seguinte tabela progressiva mensal:
      </p>
      <List>
        <List.Item>a) Até R$ 1.903,98 não há impostos a pagar;</List.Item>
        <List.Item>
          b) De R$ 1.903,99 até R$ 2.826,65, a alíquota é de 7,5% sobre os
          rendimentos, com parcela a deduzir de R$ 142,80;
        </List.Item>
        <List.Item>
          c) De R$ 2.826,66 até R$ 3.751,05, a alíquota é de 15% sobre os
          rendimentos, com parcela a deduzir de R$ 354,80;
        </List.Item>
        <List.Item>
          d) De R$ 3.751,06 até R$ 4.664,68, a alíquota é de 22,5% sobre os
          rendimentos, com parcela a deduzir de R$ 636,13; e
        </List.Item>
        <List.Item>
          e) Acima de R$ 4.664,68, a alíquota é de 27,5% sobre os rendimentos,
          com parcela a deduzir de R$ 869,36.
        </List.Item>
      </List>
    </div>
  ),
};
