import { Tooltip } from "antd";
import { BiCopy } from "react-icons/bi";
import { RiDivideFill, RiStockLine } from "react-icons/ri";
import { GrTextAlignFull } from "react-icons/gr";
import { AiFillQuestionCircle } from "react-icons/ai";
import { HiOutlineDocumentReport } from "react-icons/hi";
import {
  MdOutlineAlternateEmail,
  MdOutlineAttachMoney,
  MdDone,
} from "react-icons/md";

interface Feature {
  name: string;
  desc?: string;
  icon?: JSX.Element;
  secondaryIcon?: JSX.Element;
  secondaryLabel?: string;
}

export enum PlanEnum {
  BASIC_V1 = "BASIC_V1",
  PRO_V1 = "PRO_V1",
}

export interface Plan {
  type: PlanEnum | UserPlanEnum;
  title: string;
  price: number;
  discount: number;
  originalPrice: number;
  includedFeatures: Feature[];
  otherFeatures: Feature[];
}

export const OtherFeatures: Feature[] = [
  {
    name: "Emissão de relatório anual (DASN-MEI)",
  },
  {
    name: "Emissão de guias mensais (DAS)",
  },
];

export enum UserPlanEnum {
  VELOTAX_MAIN_BASIC = "VELOTAX_MAIN_BASIC",
  VELOTAX_MAIN_BASIC_MONTH = "VELOTAX_MAIN_BASIC_MONTH",
  VELOTAX_MAIN_FREE = "VELOTAX_MAIN_FREE",
  VELOTAX_MAIN_PRO = "VELOTAX_MAIN_PRO",
  VELOTAX_MAIN_PRO_MONTH = "VELOTAX_MAIN_PRO_MONTH",
  VELOTAX_MAIN_CONCIERGE = "VELOTAX_MAIN_CONCIERGE",
  VELOTAX_MAIN_CONCIERGE_MONTH = "VELOTAX_MAIN_CONCIERGE_MONTH",
  XP_BASIC = "XP_BASIC",
  XP_PRO = "XP_PRO",
  XP_CONCIERGE = "XP_CONCIERGE",
  XP_PRO_MONTH = "XP_PRO_MONTH",
}

export interface UserPlan {
  type: UserPlanEnum;
  interval: "Anual" | "Mensal";
  title: string;
  price: number;
  instalments: number;
  discount?: number;
  originalPrice: number;
  includedFeatures: Feature[];
  otherFeatures?: Feature[];
  free?: boolean;
  maskValue?: boolean;
  percentDiscount: number;
  descriptions?: any;
  discountText?: string;
}

const overlayStyle = {
  opacity: 0.9,
  backgroundColor: "#000000",
  fontSize: 14,
  fontWeight: 500,
};

export const UserPlans: UserPlan[] = [
  // {
  //   type: UserPlanEnum.VELOTAX_MAIN_FREE,
  //   title: "Rede",
  //   free: true,
  //   price: 0.00,
  //   originalPrice: 0.00,
  //   percentDiscount: 0,
  //   includedFeatures: [
  //     {
  //       name: "Módulos ilimitados",
  //       icon: <MdOutlineAttachMoney size={18} />,
  //     },
  //     {
  //       name: "Não inclui emissão de DARFs",
  //       icon: <BiCopy size={18} className="fill" />,
  //     },
  //     {
  //       name: "Suporte por e-mail",
  //       icon: <MdOutlineAlternateEmail size={18} className="fill" />,
  //     },
  //   ],
  //   descriptions: [
  //     { label: 'Integração com B3 e corretoras', checked: true, icon: <MdDone size={20} color="var(--ant-success-color)" /> },
  //     { label: 'Cálculos automáticos, com multa e juros', checked: true, icon: <MdDone size={20} color="var(--ant-success-color)" /> },
  //     {
  //       label: 'Emissão de DARF corrente e retroativo',
  //       checked: false, icon: <AiOutlineClose color="var(--ant-error-color)" size={17} />
  //     },
  //     {
  //       label: 'Pagamento dos impostos via PIX ',
  //       checked: false, icon: <AiOutlineClose color="var(--ant-error-color)" size={17} />
  //     },
  //     {
  //       label: 'Parcelamento em 4x no cartão de crédito',
  //       checked: false, icon: <AiOutlineClose color="var(--ant-error-color)" size={17} />
  //     },
  //     { label: 'Suporte com especialista em IR ao vivo', checked: false, icon: <AiOutlineClose color="var(--ant-error-color)" size={17} /> },
  //   ]
  // },
  // {
  //   type: UserPlanEnum.VELOTAX_MAIN_BASIC,
  //   title: "Basic",
  //   price: 29.9,
  //   originalPrice: 79.9,
  //   percentDiscount: 60,
  //   maskValue: true,
  //   includedFeatures: [
  //     {
  //       name: "Módulos ilimitados",
  //       icon: <MdOutlineAttachMoney size={18} />,
  //     },
  //     {
  //       name: "Emissão de DARFs ilimitados",
  //       icon: <BiCopy size={18} className="fill" />,
  //     },
  //     {
  //       name: "Suporte por e-mail",
  //       icon: <MdOutlineAlternateEmail size={18} className="fill" />,
  //     },
  //   ],
  //   descriptions: [
  //     {
  //       label: "Integração com B3 e corretoras",
  //       checked: true,
  //       icon: <MdDone size={20} color="var(--ant-success-color)" />,
  //     },
  //     {
  //       label: "Cálculos automáticos, com multa e juros",
  //       checked: true,
  //       icon: <MdDone size={20} color="var(--ant-success-color)" />,
  //     },
  //     {
  //       label: "Emissão de DARF corrente e retroativo",
  //       info: (
  //         <Tooltip
  //           overlayStyle={overlayStyle}
  //           title="Permite a emissão de forma ilimitada
  //     de DARFs, inclusive para os meses em
  //     atraso"
  //         >
  //           <AiFillQuestionCircle size={20}  />
  //         </Tooltip>
  //       ),
  //       checked: true,
  //       icon: <MdDone size={20} color="var(--ant-success-color)" />,
  //     },
  //     {
  //       label: "Pagamento dos impostos via PIX ",
  //       info: (
  //         <Tooltip
  //           overlayStyle={overlayStyle}
  //           title="Facilita o pagamento dos seus DARFs
  //           aceitando PIX de qualquer banco"
  //         >
  //           <AiFillQuestionCircle size={20}  />
  //         </Tooltip>
  //       ),
  //       checked: true,
  //       icon: <MdDone size={20} color="var(--ant-success-color)" />,
  //     },
  //     {
  //       label: "Parcelamento em 4x no cartão de crédito",
  //       info: (
  //         <Tooltip
  //           overlayStyle={overlayStyle}
  //           title="Permite o parcelamento dos seus
  //         impostos em até 4x no cartão de
  //         crédito"
  //         >
  //           <AiFillQuestionCircle size={20}  />
  //         </Tooltip>
  //       ),
  //       checked: true,
  //       icon: <MdDone size={20} color="var(--ant-success-color)" />,
  //     },
  //     {
  //       label: "Suporte com especialista em IR ao vivo",
  //       checked: false,
  //       icon: <AiOutlineClose color="var(--ant-error-color)" size={17} />,
  //     },
  //   ],
  // },
  {
    type: UserPlanEnum.VELOTAX_MAIN_PRO,
    interval: "Anual",
    title: "PREMIUM",
    price: 19.9,
    originalPrice: 19.9,
    percentDiscount: 0,
    instalments: 12,
    // discountText: "ou 4 meses grátis",
    maskValue: true,
    includedFeatures: [
      {
        name: "Módulos de renda variável e exterior",
        icon: <GrTextAlignFull size={18} />,
      },
      {
        name: "Emissão de DARF ilimitados",
        icon: <BiCopy size={18} />,
        secondaryIcon: (
          <Tooltip
            overlayStyle={overlayStyle}
            title="Permite a emissão ilimitada de DARF, inclusive para os meses em atraso"
          >
            <AiFillQuestionCircle size={20} />
          </Tooltip>
        ),
      },
      {
        name: "Relatórios auxiliares para declaração de IRPF",
        icon: <RiStockLine size={18} />,
      },
      {
        name: "Pagamento de DARF por PIX ou cartão de crédito",
        icon: <MdOutlineAttachMoney size={18} />,
      },
      {
        name: "Parcelamento dos impostos em até 12x",
        icon: <RiDivideFill size={18} />,
      },
    ],
    descriptions: [
      {
        label: "Integração com B3",
        checked: true,
        icon: <MdDone size={20} color="var(--ant-success-color)" />,
      },
      {
        label: "Cálculos automáticos, com multa e juros",
        checked: true,
        icon: <MdDone size={20} color="var(--ant-success-color)" />,
      },
      {
        label: "Integração com XP International",
        checked: true,
        icon: <MdDone size={20} color="var(--ant-success-color)" />,
      },
      // {
      //   label: "Emissão de DARF corrente e retroativo",
      //   info: (
      //     <Tooltip
      //       overlayStyle={overlayStyle}
      //       title="Permite a emissão de forma ilimitada
      //     de DARFs, inclusive para os meses em
      //     atraso"
      //     >
      //       <AiFillQuestionCircle
      //         size={20}

      //       />
      //     </Tooltip>
      //   ),
      //   checked: true,
      //   icon: <MdDone size={20} color="var(--ant-success-color)" />,
      // },
      // {
      //   label: "Pagamento dos impostos via PIX ",
      //   info: (
      //     <Tooltip
      //       overlayStyle={overlayStyle}
      //       title="Facilita o pagamento dos seus DARFs
      //   aceitando PIX de qualquer banco"
      //     >
      //       <AiFillQuestionCircle
      //         size={20}

      //       />
      //     </Tooltip>
      //   ),
      //   checked: true,
      //   icon: <MdDone size={20} color="var(--ant-success-color)" />,
      // },
      // {
      //   label: "Parcelamento em 4x no cartão de crédito",
      //   info: (
      //     <Tooltip
      //       overlayStyle={overlayStyle}
      //       title="Permite o parcelamento dos seus
      //       impostos em até 4x no cartão de
      //       crédito"
      //     >
      //       <AiFillQuestionCircle
      //         size={20}

      //       />
      //     </Tooltip>
      //   ),
      //   checked: true,
      //   icon: <MdDone size={20} color="var(--ant-success-color)" />,
      // },
      // {
      //   label: "Suporte com especialista em IR ao vivo",
      //   info: (
      //     <Tooltip
      //       overlayStyle={overlayStyle}
      //       title="Conte com a ajuda ilimitada e ao vivo
      //     do nosso time de especialistas em IR.
      //     Horário: Seg-Sex, das 8h às 18h"
      //     >
      //       <AiFillQuestionCircle
      //         size={20}

      //       />
      //     </Tooltip>
      //   ),
      //   checked: true,
      //   icon: <MdDone size={20} color="var(--ant-success-color)" />,
      // },
    ],
  },
  // {
  //   type: UserPlanEnum.XP_PRO_MONTH,
  //   title: "PREMIUM",
  //   interval: "Mensal",
  //   price: 29.9,
  //   originalPrice: 29.9,
  //   percentDiscount: 0,
  //   maskValue: true,
  //   includedFeatures: [
  //     {
  //       name: "Módulos de renda variável e exterior",
  //       icon: <GrTextAlignFull size={18} />,
  //     },
  //     {
  //       name: "Emissão de DARF ilimitados",
  //       icon: <BiCopy size={18} />,
  //       secondaryIcon: (
  //         <Tooltip
  //           overlayStyle={overlayStyle}
  //           title="Permite a emissão ilimitada de DARF, inclusive para os meses em atraso"
  //         >
  //           <AiFillQuestionCircle size={20} />
  //         </Tooltip>
  //       ),
  //     },
  //     {
  //       name: "Relatórios auxiliares para declaração de IRPF",
  //       icon: <RiStockLine size={18} />,
  //     },
  //     {
  //       name: "Pagamento de DARF por PIX ou cartão de crédito",
  //       icon: <MdOutlineAttachMoney size={18} />,
  //     },
  //     {
  //       name: "Parcelamento dos impostos em até 12x",
  //       icon: <RiDivideFill size={18} />,
  //     },
  //   ],
  //   descriptions: [
  //     {
  //       label: "Integração com B3",
  //       checked: true,
  //       icon: <MdDone size={20} color="var(--ant-success-color)" />,
  //     },
  //     {
  //       label: "Cálculos automáticos, com multa e juros",
  //       checked: true,
  //       icon: <MdDone size={20} color="var(--ant-success-color)" />,
  //     },
  //     {
  //       label: "Integração com XP International",
  //       checked: true,
  //       icon: <MdDone size={20} color="var(--ant-success-color)" />,
  //     },
  //     // {
  //     //   label: "Emissão de DARF corrente e retroativo",
  //     //   info: (
  //     //     <Tooltip
  //     //       overlayStyle={overlayStyle}
  //     //       title="Permite a emissão de forma ilimitada
  //     //     de DARFs, inclusive para os meses em
  //     //     atraso"
  //     //     >
  //     //       <AiFillQuestionCircle
  //     //         size={20}

  //     //       />
  //     //     </Tooltip>
  //     //   ),
  //     //   checked: true,
  //     //   icon: <MdDone size={20} color="var(--ant-success-color)" />,
  //     // },
  //     // {
  //     //   label: "Pagamento dos impostos via PIX ",
  //     //   info: (
  //     //     <Tooltip
  //     //       overlayStyle={overlayStyle}
  //     //       title="Facilita o pagamento dos seus DARFs
  //     //   aceitando PIX de qualquer banco"
  //     //     >
  //     //       <AiFillQuestionCircle
  //     //         size={20}

  //     //       />
  //     //     </Tooltip>
  //     //   ),
  //     //   checked: true,
  //     //   icon: <MdDone size={20} color="var(--ant-success-color)" />,
  //     // },
  //     // {
  //     //   label: "Parcelamento em 4x no cartão de crédito",
  //     //   info: (
  //     //     <Tooltip
  //     //       overlayStyle={overlayStyle}
  //     //       title="Permite o parcelamento dos seus
  //     //       impostos em até 4x no cartão de
  //     //       crédito"
  //     //     >
  //     //       <AiFillQuestionCircle
  //     //         size={20}

  //     //       />
  //     //     </Tooltip>
  //     //   ),
  //     //   checked: true,
  //     //   icon: <MdDone size={20} color="var(--ant-success-color)" />,
  //     // },
  //     // {
  //     //   label: "Suporte com especialista em IR ao vivo",
  //     //   info: (
  //     //     <Tooltip
  //     //       overlayStyle={overlayStyle}
  //     //       title="Conte com a ajuda ilimitada e ao vivo
  //     //     do nosso time de especialistas em IR.
  //     //     Horário: Seg-Sex, das 8h às 18h"
  //     //     >
  //     //       <AiFillQuestionCircle
  //     //         size={20}

  //     //       />
  //     //     </Tooltip>
  //     //   ),
  //     //   checked: true,
  //     //   icon: <MdDone size={20} color="var(--ant-success-color)" />,
  //     // },
  //   ],
  // },
];

export const Plans: Plan[] = [
  {
    type: PlanEnum.BASIC_V1,
    title: "basic",
    price: 29.9,
    discount: 75,
    originalPrice: 119.9,
    includedFeatures: [
      {
        name: "Emissão de DASN ilimitados",
        icon: <HiOutlineDocumentReport size={18} />,
      },
      {
        name: "Suporte por email",
        icon: <MdOutlineAlternateEmail size={18} className="fill" />,
      },
    ],
    otherFeatures: OtherFeatures.slice(0, 1),
  },
  {
    type: PlanEnum.PRO_V1,
    title: "plus",
    price: 39.9,
    discount: 75,
    originalPrice: 149.9,
    includedFeatures: [
      {
        name: "Emissão de DAS e DASN ilimitados",
        icon: <HiOutlineDocumentReport size={18} />,
      },
      {
        name: "Suporte por email",
        icon: <MdOutlineAlternateEmail size={18} className="fill" />,
      },
    ],
    otherFeatures: OtherFeatures,
  },
];
