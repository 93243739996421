import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const OnlyPublicRoute: React.FC = () => {
  const { pathname } = useLocation();
  const { signed, loadingSession } = useAuth();

  if (loadingSession) {
    return <></>;
  }

  return !signed ? (
    <Outlet />
  ) : pathname === "/p/cadastrar" ? (
    <Navigate to="/xpinvestimentos/bolsa-integration" />
  ) : (
    <Navigate to="/" />
  );
};

export default OnlyPublicRoute;
