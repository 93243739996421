import clsx from "clsx";
import moment from "moment";
import { FaRegEdit } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import NumberFormat from "react-number-format";
import { AiOutlineDelete } from "react-icons/ai";
import { BsCalculator, BsCalendar3 } from "react-icons/bs";
import { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { DatePickerExtraFooter } from "../Darf/Components/DatePickerExtraFooter";
import { StyledTag } from "../Darf/Components/PayTags/styles";
import { DollarCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Typography,
  Row,
  Col,
  DatePicker,
  Space,
  Descriptions,
  Spin,
  Divider,
  InputNumber,
  Collapse,
  List,
  Skeleton,
  Modal,
  Tooltip,
} from "antd";
import MsDarf from "../../services/msDarf";
import apiRendimentos from "../../services/rendimentos";
import Button from "../../components/Button";
import { IMonthStock, Iimpostos } from "./interfaces/index";
import { useAuth } from "../../contexts/AuthContext";
import { useBroker } from "../../contexts/BrokerContext";
import { FormModal } from "../../components/FormModal";
import rendimentosBackend from "../../services/rendimentos";
import { Container, Content, BolsaOperations } from "./styles";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import {
  ImpostoTotalRendimentos,
  RendimentoFormItemRows,
  RendimentosImpostosDevidos,
} from "../../constants/rendimentos";
import {
  formatCurrency,
  monthsExtended,
  antDatePickerLocale,
  maskCpf,
  currencyToNumber,
  download,
} from "../../utils";
import { Page } from "../../constants/brokers";
import {
  DarfModal,
  YearResumeStatusCSSClassEnum,
  YearResumeStatusTextEnum,
  YearResumeStatusColorEnum,
  YearResumeStatusFromBackEnum,
  yearConfigPL4173,
} from "../../constants/darf";
import {
  DatePickerMonthCell,
  YearResume,
} from "../Darf/Components/DatePickerMonthCell";
import { PaymentModal } from "../../components/PaymentModal";
interface DarfBolsaProps {
  item: Page;
  view?: boolean;
  darf?: {
    id: string;
  };
  monthView: number;
  yearView: number;
}

export const Rendimentos: React.FC<DarfBolsaProps> = ({
  view,
  item: itemprops,
  darf,
  monthView,
  yearView,
}: any) => {
  const Dependentes = {
    title: "Dependentes",
    content: (
      <div>
        <p>
          É permitida a dedução de R$ 189,59 por dependente na base de cálculo
          do imposto sobre os rendimentos.
        </p>
        <p>
          As situações que permitem a inclusão de dependentes são as seguintes:
        </p>
        <p>
          - Cônjuge, companheiro(a) com quem o contribuinte tenha filho ou se
          estiver há mais de cinco anos em regime de união estável
        </p>
        <p>
          - Filho(a) ou enteado(a) de até 21 anos de idade ou, em qualquer
          idade, quando incapacitado física ou mentalmente para o trabalho
        </p>
        <p>
          Irmão(ã), neto(a) ou bisneto(a) que não possua suporte dos pais e o
          contribuinte detenha a guarda judicial. O dependente deve ter até 21
          anos de idade ou, em qualquer idade, quando incapacitado física ou
          mentalmente para o trabalho
        </p>
        <p>
          - Irmão(ã), neto(a) ou bisneto(a) que não possua suporte dos pais e o
          contribuinte detenha a guarda judicial, pode ser considerado
          dependente até 24 anos de idade caso esteja cursando ensino superior
          ou escola técnica de segundo grau
        </p>
        <p>
          Pais, avós(ôs) e bisavós(ôs) que, em 2020, tenham recebido rendimentos
          tributáveis ou não, até R$22.847,76
        </p>
        <p>
          - Menor pobre com até 21 anos de idade, que o contribuinte crie e
          eduque e detenha a guarda judicial
        </p>
        <p>
          - Pessoa absolutamente incapaz, de qual o contribuinte seja tutor ou
          curador
        </p>
      </div>
    ),
  };

  const Autonomos = {
    title: "Despesas de Autônomos",
    content: (
      <div>
        <p>
          As seguintes despesas poderão ser deduzidas dos rendimentos recebidos
          de pessoas físicas por autônomos:
        </p>
        <p>Aluguel do escritório/consultório</p>
        <p>- Água do escritório/consultório</p>
        <p>- Contribuições obrigatórias a entidades de classe</p>
        <p>- Condomínio do escritório/consultório</p>
        <p>- Emolumentos pagos a terceiros</p>
        <p>- Cópia e autenticação de documentos</p>
        <p>- Emolumentos pagos a terceiros</p>
        <p>- Energia do escritório/consultório</p>
        <p>- Gás do escritório/consultório </p>
        <p>- IPTU do escritório/consultório quando pago pelo contribuinte</p>
        <p>- ISS</p>
        <p>- Material de conservação e limpeza do escritório/consultório</p>
        <p>- Material de escritório</p>
        <p>
          - Remuneração paga a terceiros, com vínculo empregatício, INSS e FGTS
        </p>
        <p>- Telefone do escritório/consultório</p>
      </div>
    ),
  };

  const Previdencia = {
    title: "Despesas com Previdência",
    content: (
      <div>
        <p>
          Despesas com contribuição para a Previdência Social da União, dos
          Estados, do Distrito Federal e dos Municípios são dedutíveis da base
          de cálculo do imposto sobre rendimentos.
        </p>
        <p>
          As contribuições para as entidades de previdência privada domiciliadas
          no País, bem como as contribuições destinadas ao Fundo de
          Aposentadoria Programada Individual (FAPI), não podem ser deduzidas na
          determinação da base de cálculo mensal. Esses pagamentos são
          dedutíveis apenas na Declaração de Ajuste Anual.
        </p>
      </div>
    ),
  };

  const Pensao = {
    title: "Despesas com Pensão Alimentícia",
    content: (
      <div>
        <p>
          Despesas com alimentos ou pensões, quando em cumprimento de decisão
          judicial ou acordo homologado judicialmente, são dedutíveis da base de
          cálculo do imposto sobre rendimentos.
        </p>
        <p>
          As despesas médicas e as de instrução, pagas em decorrência de decisão
          judicial ou acordo homologado na Justiça, não podem ser deduzidas na
          determinação da base de cálculo mensal. Esses pagamentos são
          dedutíveis apenas na Declaração de Ajuste Anual, obedecido o limite
          anual para os gastos com instrução.
        </p>
      </div>
    ),
  };

  const navigate = useNavigate();
  const { user } = useAuth();
  const { state } = useLocation();
  const yearResumeFeature = itemprops.features[2];
  const today = new Date();
  const queryYear = (state as any)?.year;
  const queryMonth = (state as any)?.month;
  const oldModel = queryYear < yearConfigPL4173;

  const initialMonth = queryMonth ?? monthView ?? today.getMonth();
  const initialYear = queryYear ?? yearView ?? today.getFullYear();
  const minDarfPrice = 10;
  const maxDarfPrice = 5000;
  const defaultValue = moment({ month: initialMonth, year: initialYear });
  const [emitting, setEmitting] = useState(false);
  const { currentBroker } = useBroker();
  const [year, setYear] = useState(initialYear);
  const [month, setMonth] = useState(initialMonth);
  const [item, setItem] = useState<any>({
    valorRendimento: 0,
    tipo: "",
  });
  const [itemIndex, setItemIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRendimentoModal, setShowRendimentoModal] = useState(false);
  const [helpModal, setHelpModal] = useState<any>();
  const [darfModal, setDarfModal] = useState<any>();
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentData, setPaymentData] = useState<any>();
  const [id, setId] = useState<string>("");
  const [gettingYearResume, setGettingYearResume] = useState(false);
  const [monthStock, setMonthStock] = useState<IMonthStock[]>([]);
  const isSaveItens = useRef(false);
  const [yearResume, setYearResume] = useState<YearResume[]>([]);
  const [impostos, setImpostos] = useState({
    totalRendimentos: 0,
    baseTributaria: 0,
    aliquotaDevida: 0,
    impostoDevido: 0,
    impostoTotal: 0,
    totalDeducoes: 0,
    totalDependentes: 0,
    totalDespesas: 0,
    totalPensao: 0,
    totalPrevidencia: 0,
    impostoAcumulado: 0,
    juros: 0,
    multa: 0,
  });

  const { emitted, status } = useMemo(() => {
    if (gettingYearResume) {
    }
    const monthResume = yearResume
      .find((resume) => resume.year === year)
      ?.months.find((m) => m.month - 1 === month);
    return {
      emitted:
        monthResume?.status === YearResumeStatusFromBackEnum.PAYED ||
        monthResume?.status === YearResumeStatusFromBackEnum.NOT_PAYED,
      payed: !!monthResume?.payed,
      status: monthResume?.status,
    };
  }, [yearResume, year, month, gettingYearResume]);

  const handleNavigateToHistoric = () => {
    navigate(`/velotax/autonomos-historico`, { state: { year } });
  };

  const returnRightButton = () => {
    return (
      <Col span={24}>
        {!view && (
          <>
            <Divider />
            <Row justify="end">
              <Col
                span={12}
                style={{
                  display: "flex",
                  flex: "0",
                  justifyContent: "end",
                  paddingRight: "8px",
                }}
              >
                <Button
                  type="text"
                  size="large"
                  disabled={loading || emitting}
                  onClick={handleNavigateToHistoric}
                >
                  Ver no histórico
                </Button>
              </Col>
              {status === YearResumeStatusFromBackEnum.PENDING &&
                impostos.impostoDevido + impostos.impostoAcumulado <
                  minDarfPrice && (
                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <Tooltip
                      title={
                        <>
                          O valor do imposto devido é menor que{" "}
                          {formatCurrency(minDarfPrice)}. Você não precisa
                          emitir um DARF para este mês. Clique aqui para
                          regularizar este mês.
                        </>
                      }
                    >
                      <Button
                        size="large"
                        type="primary"
                        loading={emitting}
                        disabled={loading || emitting}
                        onClick={() => handleRegularize(true)}
                      >
                        Salvar sem emitir DARF
                      </Button>
                    </Tooltip>
                  </Col>
                )}
              {impostos.impostoDevido + impostos.impostoAcumulado >=
                minDarfPrice && (
                <Col
                  span={12}
                  style={{
                    display: "flex",
                    flex: "0",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    size="large"
                    type="primary"
                    loading={emitting}
                    disabled={loading || emitting}
                    onClick={
                      impostos.impostoDevido <= maxDarfPrice
                        ? handleOpenPaymentModal
                        : () => handleOpenDarfModal()
                    }
                  >
                    {impostos.impostoDevido <= maxDarfPrice
                      ? "Pagar DARF"
                      : emitted
                      ? "Emitir novo DARF"
                      : "Emitir DARF"}
                  </Button>
                </Col>
              )}
            </Row>
          </>
        )}
      </Col>
    );
  };

  const buildCalcTaxBody = useCallback(() => {
    return {
      numeroDependentes: impostos.totalDependentes,
      gastosAutonomos: impostos.totalDespesas,
      gastosPrevidencia: impostos.totalPrevidencia,
      gastosPensao: impostos.totalPensao,
    };
  }, [
    impostos.totalDependentes,
    impostos.totalDespesas,
    impostos.totalPensao,
    impostos.totalPrevidencia,
  ]);

  const calcTaxes = useCallback(async () => {
    setLoading(true);
    if (!view && isSaveItens.current) {
      const deducoes = buildCalcTaxBody();
      const response = await rendimentosBackend.post("/rendimentos/calc-tax", {
        ...deducoes,
        rendimentos: monthStock,
        year,
        month: month + 1,
        impostoAcumulado: impostos.impostoAcumulado,
      });
      const { data } = response;
      setImpostos((currentValue) => {
        return {
          ...currentValue,
          aliquotaDevida: data.aliquotaDevida,
          baseTributaria: data.baseTributaria,
          impostoDevido: data.impostoDevido,
          impostoTotal: data.impostoTotal,
          juros: data.juros,
          multa: data.multa,
          totalDeducoes: data.totalDeducoes,
          totalRendimentos: data.totalRendimentos,
        };
      });
    }
    setLoading(false);
  }, [
    view,
    buildCalcTaxBody,
    monthStock,
    year,
    month,
    impostos.impostoAcumulado,
  ]);

  const getYearResume = useCallback(
    (year: number, force?: boolean, url?: string) => {
      setGettingYearResume(true);
      !view &&
        year <= new Date().getFullYear() &&
        year >= currentBroker.initialYear &&
        (force ||
          (!yearResumeFeature.disabled &&
            !yearResume.find((resume) => resume.year === year))) &&
        rendimentosBackend
          .get(yearResumeFeature.apiUrl, { params: { year } })
          .then((response) => {
            const index = yearResume.findIndex(
              (resume) => resume.year === year
            );
            if (index >= 0) {
              setYearResume((yearResume) => {
                yearResume.splice(index, 1, response.data);
                return yearResume;
              });
            } else {
              setYearResume((yearResume) => [...yearResume, response.data]);
            }

            if (url) {
              download(url);
            }
          })
          .catch((err) => console.log(err))
          .finally(() => setGettingYearResume(false));
    },
    [yearResumeFeature, yearResume, view, currentBroker.initialYear]
  );

  const getRendimentos = useCallback(async () => {
    setLoading(true);
    const response = await rendimentosBackend.get(
      `/rendimentos/${user.user.cpf}/${year}/${month + 1}`
    );
    const { data } = response;
    setId(data._id || "");
    setMonthStock(data.monthStock || []);
    const currentImpostos = {
      totalRendimentos: data?.totalRendimentos || 0,
      baseTributaria: data?.baseTributaria || 0,
      aliquotaDevida: data?.aliquotaDevida || 0,
      impostoDevido: data?.impostoDevido || 0,
      totalDeducoes: data?.totalDeducoes || 0,
      totalDependentes: data?.totalDependentes || 0,
      totalDespesas: data?.totalDespesas || 0,
      totalPensao: data?.totalPensao || 0,
      totalPrevidencia: data?.totalPrevidencia || 0,
      juros: data?.juros || 0,
      multa: data?.multa || 0,
      impostoTotal:
        (data.impostoDevido || 0) + (data.juros || 0) + (data.multa || 0),
      impostoAcumulado: data?.impostoAcumulado || 0,
    };
    setImpostos(currentImpostos);
    setLoading(false);
    getYearResume(year, true);
  }, [getYearResume, month, user.user.cpf, year]);

  const getRendimento = useCallback(async () => {
    setLoading(true);
    const response = await rendimentosBackend.get("/darf/getById", {
      params: { id: darf?.id },
    });
    const { data } = response;
    setMonthStock(data.transactions || []);
    const currentImpostos = {
      totalRendimentos: data.totalRendimentos || 0,
      baseTributaria: data.baseTributaria || 0,
      aliquotaDevida: data.aliquotaDevida || 0,
      impostoDevido: data.impostoDevido || 0,
      totalDeducoes: data.totalDeducoes || 0,
      totalDependentes: data.totalDependentes || 0,
      totalDespesas: data.totalDespesas || 0,
      totalPensao: data.totalPensao || 0,
      totalPrevidencia: data.totalPrevidencia || 0,
      juros: data.juros || 0,
      multa: data.multa || 0,
      impostoAcumulado: data.impostoAcumulado || 0,
      impostoTotal:
        (data.impostoDevido || 0) + (data.juros || 0) + (data.multa || 0),
    };
    setImpostos(currentImpostos);
    setLoading(false);
  }, [darf]);

  useEffect(() => {
    if (view && darf) getRendimento();
    else getRendimentos();
  }, [getRendimentos, getRendimento, view, darf]);

  const buildNewImpostos = useCallback(() => {
    return {
      monthStock,
      year,
      month: month + 1,
      juros: impostos.juros,
      multa: impostos.multa,
      userCode: user.user.cpf,
      total: impostos.impostoTotal,
      impostoDevido: impostos.impostoDevido,
      totalRendimentos: impostos.totalRendimentos,
      baseTributaria: impostos.baseTributaria,
      aliquotaDevida: impostos.aliquotaDevida,
      totalDependentes: impostos.totalDependentes,
      totalDespesas: impostos.totalDespesas,
      totalPrevidencia: impostos.totalPrevidencia,
      totalPensao: impostos.totalPensao,
      totalDeducoes: impostos.totalDeducoes,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impostos.baseTributaria, year, month, user]);

  const saveItems = useCallback(async () => {
    setLoading(true);
    if (isSaveItens.current) {
      const newImpostos = buildNewImpostos();
      if (id !== "") {
        await rendimentosBackend.put("/rendimentos/", {
          _id: id,
          ...newImpostos,
        });
      } else {
        const { data } = await rendimentosBackend.post(
          "/rendimentos",
          newImpostos
        );
        setId(data._id);
      }
      getYearResume(year, true);
    }
    if (isSaveItens.current) isSaveItens.current = false;
    setLoading(false);
  }, [id, buildNewImpostos, getYearResume, year]);

  useEffect(() => {
    calcTaxes();
  }, [calcTaxes, monthStock]);

  useEffect(() => {
    saveItems();
  }, [saveItems]);

  const generateDarf = async (pixValue: boolean) => {
    setLoading(true);
    onCloseDarfModal();
    const { data } = await MsDarf.post("/darf", {
      month: month + 1,
      year,
      cpf: user.user.cpf,
      name: user.user.name,
      paymentInfo: {
        clientInfo: {
          document: user.user.cpf,
          email: user.user.email,
          name: user.user.name,
          phone: "",
        },
      },
      insertPix: pixValue,
      valueCharge:
        impostos.impostoDevido +
        impostos.impostoAcumulado +
        impostos.juros +
        impostos.multa,
      institution: "velotax-rendimentos",
      impostoDevido: impostos.impostoDevido,
      totalImpostoDevido: impostos.impostoTotal,
      totalRendimentos: impostos.totalRendimentos,
      baseTributaria: impostos.baseTributaria,
      totalDependentes: impostos.totalDependentes,
      totalDespesas: impostos.totalDespesas,
      totalPrevidencia: impostos.totalPrevidencia,
      totalPensao: impostos.totalPensao,
      totalDeducoes: impostos.totalDeducoes,
      aliquotaDevida: impostos.aliquotaDevida,
      impostoAcumulado: impostos.impostoAcumulado,
      juros: impostos.juros,
      multa: impostos.multa,
      transactions: monthStock,
      from: 18,
    });

    window.location.href = data.darfUrl;
    getYearResume(year, true);
    setLoading(false);
    if (impostos.impostoTotal <= maxDarfPrice) {
      handleOpenPaymentModal();
    }
    goToHistoric();
  };

  const handleRegularize = (regular: boolean) => {
    setLoading(true);
    setEmitting(true);
    apiRendimentos
      .post("transaction/regularize", {
        year,
        month: month + 1,
        regular,
      })
      .then((res) => {
        getYearResume(year, true, res.data.darfUrl);
      })
      .catch((err) => console.log("err", err))
      .finally(() => {
        setEmitting(false);
        setLoading(false);
      });
  };

  const handleCloseItemModal = useCallback(() => {
    setShowRendimentoModal(false);
    unsetItem();
  }, []);

  const addItem = async (data: IMonthStock) => {
    if (!isSaveItens.current) isSaveItens.current = true;
    setMonthStock((currentArray) => [...currentArray, data]);
    getYearResume(year, true);
    handleCloseItemModal();
  };

  const editItem = (data: IMonthStock) => {
    if (!isSaveItens.current) isSaveItens.current = true;
    const aux = [...monthStock];
    aux.splice(itemIndex, 1, data);
    setMonthStock(aux);
    getYearResume(year, true);
    handleCloseItemModal();
  };

  const removeAsset = async () => {
    const aux = [...monthStock];
    aux.splice(itemIndex, 1);
    setMonthStock(aux);
    getYearResume(year, true);
    if (!isSaveItens.current) isSaveItens.current = true;
  };

  const onChangeMonth = (value: moment.Moment | null, dateString: string) => {
    const [month, year] = dateString.split("-");
    setMonth(parseInt(month) - 1);
    setYear(parseInt(year));
  };

  const handleAdd = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    unsetItem();
    setShowRendimentoModal(true);
  };

  const handleEdit = (item: IMonthStock, index: number) => {
    setShowRendimentoModal(true);
    setItemIndex(index);
    setItem(item);
  };

  const handleRemove = (item: IMonthStock, index: number) => {
    setShowDeleteModal(true);
    setItemIndex(index);
    setItem(item);
  };

  const unsetItem = () => {
    setItem(null);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    unsetItem();
  };

  const onCloseHelpModal = () => {
    setHelpModal(undefined);
  };

  const onCloseDarfModal = () => {
    setDarfModal(undefined);
  };

  const goToHistoric = () => {
    navigate("/velotax/autonomos-historico", {
      state: {
        year,
      },
    });
  };

  const handleOpenDarfModal = (value?: boolean) => {
    setDarfModal(
      DarfModal(emitted, onCloseDarfModal, () => generateDarf(value ?? false))
    );
  };

  const handleOpenPaymentModal = () => {
    setPaymentModal(!paymentModal);
  };

  const changeInputState = (variable: string, value: string) => {
    setImpostos((currentValue) => {
      return {
        ...currentValue,
        [variable]:
          variable !== "totalDependentes" ? currencyToNumber(value) : value,
      };
    });
    isSaveItens.current = true;
  };

  useEffect(() => {
    const valueToCharge =
      impostos.impostoDevido +
      impostos.impostoAcumulado +
      impostos.juros +
      impostos.multa;

    const dataToPayment = {
      cpf: user.user.cpf,
      name: user.user.name,
      valueCharge: valueToCharge,
      institution: "velotax-rendimentos",
      impostoDevido: impostos.impostoDevido,
      totalImpostoDevido: impostos.impostoTotal,
      impostoTotalFinal: valueToCharge,
      totalRendimentos: impostos.totalRendimentos,
      baseTributaria: impostos.baseTributaria,
      totalDependentes: impostos.totalDependentes,
      totalDespesas: impostos.totalDespesas,
      totalPrevidencia: impostos.totalPrevidencia,
      totalPensao: impostos.totalPensao,
      totalDeducoes: impostos.totalDeducoes,
      aliquotaDevida: impostos.aliquotaDevida,
      impostoAcumulado: impostos.impostoAcumulado,
      juros: impostos.juros,
      multa: impostos.multa,
      transactions: monthStock,
      year,
      month: month + 1,
    };

    setPaymentData(dataToPayment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [impostos, month, year]);

  return (
    <Container className={clsx({ view })}>
      <Content className={clsx({ view })}>
        <Space direction="vertical" className="full-width">
          <Row gutter={[16, 16]} align="middle">
            <Col xs={24} sm={16}>
              <Typography.Title level={3}>
                <BsCalendar3 />
                {monthsExtended[month]} de {year}
              </Typography.Title>
            </Col>
            {!view ? (
              <>
                <Col xs={24} sm={8}>
                  <DatePicker
                    size="large"
                    picker="month"
                    format="MM-YYYY"
                    disabled={loading}
                    allowClear={false}
                    inputReadOnly={true}
                    onChange={onChangeMonth}
                    suffixIcon={<BsCalendar3 />}
                    defaultValue={defaultValue}
                    locale={antDatePickerLocale}
                    onPanelChange={(e) => getYearResume(e.year())}
                    getPopupContainer={(trigger) => trigger.parentElement!}
                    renderExtraFooter={() =>
                      yearResumeFeature.disabled ? (
                        <></>
                      ) : (
                        <DatePickerExtraFooter />
                      )
                    }
                    disabledDate={(current) =>
                      current &&
                      (current > moment().endOf("month") ||
                        current < moment(`${currentBroker.initialYear}-01-01`))
                    }
                    monthCellRender={(e) => (
                      <DatePickerMonthCell event={e} yearResume={yearResume} />
                    )}
                  />
                </Col>
                {!status ? (
                  <Spin />
                ) : (
                  <>
                    <Typography.Text strong style={{ marginRight: "8px" }}>
                      Situação:
                    </Typography.Text>
                    <StyledTag
                      className={
                        YearResumeStatusCSSClassEnum[status ?? "REGULAR"]
                      }
                      title={YearResumeStatusTextEnum[status ?? "REGULAR"]}
                      color={YearResumeStatusColorEnum[status ?? "REGULAR"]}
                    >
                      {YearResumeStatusTextEnum[status ?? "REGULAR"]}
                    </StyledTag>
                  </>
                )}
              </>
            ) : null}
          </Row>
          <br />
          <Descriptions size="small" bordered layout="horizontal">
            {RendimentosImpostosDevidos.map((description) => (
              <Descriptions.Item
                span={24}
                key={description.id}
                label={description.label(setHelpModal)}
              >
                {loading ? <Spin /> : description.render(impostos)}
              </Descriptions.Item>
            ))}
          </Descriptions>

          <br />

          <Collapse
            ghost
            bordered={false}
            defaultActiveKey={[""]}
            expandIconPosition="right"
          >
            <Collapse.Panel
              key="1"
              header={
                <Typography.Title level={3}>
                  <BsCalculator />
                  Rendimentos
                </Typography.Title>
              }
            >
              {!view ? (
                <Button
                  type="primary"
                  className="add"
                  disabled={loading}
                  onClick={handleAdd}
                  icon={<PlusOutlined />}
                >
                  Adicionar
                </Button>
              ) : null}
              <List
                loading={loading}
                itemLayout="horizontal"
                dataSource={monthStock}
                renderItem={(item, index) => (
                  <List.Item
                    actions={
                      view
                        ? []
                        : [
                            <Button
                              type="text"
                              onClick={() => handleEdit(item, index)}
                              icon={<FaRegEdit size={16} />}
                            />,
                            <Button
                              type="text"
                              onClick={() => handleRemove(item, index)}
                              icon={<AiOutlineDelete size={16} />}
                            />,
                          ]
                    }
                  >
                    <Skeleton title={false} loading={item.loading} active>
                      <List.Item.Meta
                        title={<strong>Rendimento #{index + 1}</strong>}
                        description={
                          <div className="list-description">
                            <div>Tipo: {item.tipo}</div>
                            <div>
                              Valor: {formatCurrency(item.valorRendimento ?? 0)}
                            </div>
                            {item.cpfPagador && (
                              <div>
                                CPF do pagador: {maskCpf(item.cpfPagador)}
                              </div>
                            )}
                            {item.cpfBeneficiario && (
                              <div>
                                CPF do beneficiário:{" "}
                                {maskCpf(item.cpfBeneficiario)}
                              </div>
                            )}
                          </div>
                        }
                      />
                    </Skeleton>
                  </List.Item>
                )}
              />
            </Collapse.Panel>
          </Collapse>

          <br />

          <Collapse
            ghost
            bordered={false}
            defaultActiveKey={[""]}
            expandIconPosition="right"
          >
            <Collapse.Panel
              key="1"
              header={
                <Typography.Title level={3}>
                  <BsCalculator />
                  Deduções Tributárias
                </Typography.Title>
              }
            >
              <BolsaOperations>
                <div className="header-deducoes-tributarias">
                  <span>
                    <InfoCircleOutlined
                      onClick={() => setHelpModal(Dependentes)}
                    />
                    Dependentes
                  </span>

                  <span>
                    <InfoCircleOutlined
                      onClick={() => setHelpModal(Autonomos)}
                    />
                    Gastos Autônomos
                  </span>

                  <span>
                    <InfoCircleOutlined
                      onClick={() => setHelpModal(Previdencia)}
                    />
                    Gastos Previdência
                  </span>

                  <span>
                    <InfoCircleOutlined onClick={() => setHelpModal(Pensao)} />
                    Gastos Pensão
                  </span>
                </div>
                <div className="body-deducoes-tributarias">
                  <div className="row-deducoes-tributarias">
                    <span>
                      <InputNumber
                        min={0}
                        controls={true}
                        defaultValue={0}
                        disabled={view ?? loading}
                        value={impostos.totalDependentes ?? 0}
                        className="ant-input ant-input-sm"
                        onChange={(e: any) => {
                          changeInputState("totalDependentes", e);
                        }}
                      />
                    </span>
                    <span>
                      <NumberFormat
                        prefix="R$ "
                        disabled={view ?? loading}
                        defaultValue={0}
                        decimalScale={2}
                        allowNegative={false}
                        decimalSeparator=","
                        thousandSeparator="."
                        placeholder="R$ 0,00"
                        className="ant-input"
                        value={impostos.totalDespesas}
                        onBlur={(e: any) =>
                          changeInputState("totalDespesas", e.target.value)
                        }
                      />
                    </span>
                    <span>
                      <NumberFormat
                        prefix="R$ "
                        defaultValue={0}
                        decimalScale={2}
                        disabled={view ?? loading}
                        allowNegative={false}
                        decimalSeparator=","
                        thousandSeparator="."
                        placeholder="R$ 0,00"
                        className="ant-input"
                        value={impostos.totalPrevidencia}
                        onBlur={(e: any) =>
                          changeInputState("totalPrevidencia", e.target.value)
                        }
                      />
                    </span>
                    <span>
                      <NumberFormat
                        prefix="R$ "
                        disabled={view ?? loading}
                        defaultValue={0}
                        decimalScale={2}
                        allowNegative={false}
                        decimalSeparator=","
                        thousandSeparator="."
                        placeholder="R$ 0,00"
                        className="ant-input"
                        value={impostos.totalPensao}
                        onBlur={(e: any) =>
                          changeInputState("totalPensao", e.target.value)
                        }
                      />
                    </span>
                  </div>
                </div>
              </BolsaOperations>
            </Collapse.Panel>
          </Collapse>

          <br />

          <Collapse
            ghost
            bordered={false}
            defaultActiveKey={[""]}
            expandIconPosition="right"
          >
            <Collapse.Panel
              key="1"
              header={
                <div className="total-tax-header">
                  <Typography.Title level={3}>
                    <DollarCircleOutlined />
                    Imposto total
                  </Typography.Title>
                  <Typography.Title level={3} className="text-center">
                    {formatCurrency(
                      Number(
                        impostos.impostoTotal + impostos.impostoAcumulado
                      ) ?? 0
                    )}
                  </Typography.Title>
                </div>
              }
            >
              <Descriptions size="small" bordered layout="horizontal">
                {ImpostoTotalRendimentos.map((description: any) => {
                  if(['impostoAcumulado', 'multa', 'juros'].includes(description.id) && !oldModel) return <></>
                  
                  return (
                    <Descriptions.Item
                      span={24}
                      key={description.id}
                      label={description.label(setHelpModal)}
                    >
                      {loading ? (
                        <Spin />
                      ) : description.Component ? (
                        <description.Component
                          data={impostos}
                          year={year}
                          month={month}
                          loading={loading}
                          setDataCripto={setImpostos}
                          disabled={!view}
                          view={view}
                          id={id}
                          monthStock={monthStock}
                          user={user}
                        />
                      ) : (
                        formatCurrency(
                          Number(impostos[description.id as keyof Iimpostos] || 0)
                        )
                      )}
                    </Descriptions.Item>
                  )
                })}
              </Descriptions>
            </Collapse.Panel>
          </Collapse>
          {!view ? (
            <Row>
              <Divider />
              {returnRightButton()}
            </Row>
          ) : null}
        </Space>
      </Content>
      <PaymentModal
        fromDarf
        show={paymentModal}
        onCancel={handleOpenPaymentModal}
        paymentData={paymentData}
        emitting={loading}
        callDarf={(value: boolean) => handleOpenDarfModal(value)}
      />
      <Modal
        footer={null}
        visible={helpModal}
        title={helpModal?.title}
        onCancel={onCloseHelpModal}
      >
        {helpModal?.content}
      </Modal>
      <Modal
        visible={darfModal}
        title={darfModal?.title}
        footer={darfModal?.footer}
        onCancel={onCloseDarfModal}
      >
        {darfModal?.content}
      </Modal>
      <FormModal
        add={addItem}
        edit={editItem}
        itemToEdit={item}
        rows={RendimentoFormItemRows}
        onCancel={handleCloseItemModal}
        visibility={showRendimentoModal}
        title={item ? "Editar rendimento" : "Adicionar rendimento"}
      />
      <DeleteConfirmationModal
        title="Remover rendimentos"
        visibility={showDeleteModal}
        onCancel={handleCloseDeleteModal}
        setVisibility={setShowDeleteModal}
        onOk={() => Promise.resolve(removeAsset())}
        body="Você realmente quer deletar esse ativo?"
      />
    </Container>
  );
};
