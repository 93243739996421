import styled from "styled-components";

export const ReportContainer = styled.div`
  height: 100%;
  display: flex;
  padding: 64px 32px;

  & > .content {
    margin: 0 auto;
    width: 700px;
  }

  h1 {
    width: 700px;
    margin: 0 auto 1.5rem;
    font-weight: 400;
    font-size: 2rem;
    position: relative;
    line-height: 2.5rem;
    padding-bottom: 1rem;
    color: var(--velotax-font-color-light);

    :after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 100px;
      background-color: var(--ant-primary-color);
    }
  }

  h3 {
    display: flex;
    align-items: center;
    column-gap: 16px;
    color: var(--velotax-font-color-light);
    margin-bottom: 0;
  }

  div.ant-typography {
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--velotax-font-color);
  }

  .ant-typography.destak {
    margin: 48px 0;
    display: block;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--velotax-font-color);
    padding-left: 16px;
    border-left: 4px solid var(--ant-primary-color);
  }

  .cards {
    margin: 32px 0 0;
    padding-bottom: 32px;
    width: 100%;
    display: grid;
    row-gap: 1rem;
    column-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);

    & > div {
      width: 100%;
    }

    .card-antd {
      position: relative;

      a {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        color: var(--velotax-font-color);
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    height: auto;
    padding: 24px 0 0;

    & > .content {
      width: 100%;
      h1 {
        width: 100%;
        padding: 0 24px 12px;
        margin: 0 auto 1.5rem;
        :after {
          left: 24px;
        }
      }

      & > div.ant-typography {
        padding: 0 24px;
      }
    }

    .cards {
      width: 100%;
      padding: 0 24px;
      margin: 32px 0;
      grid-template-columns: repeat(2, 1fr);

      & > div {
        width: auto;
        height: 240px;
      }
    }
  }
`;

export const Container = styled.div`
  h1 {
    width: 700px;
    margin: 0 auto 1.5rem;
    font-weight: 400;
    font-size: 2rem;
    position: relative;
    line-height: 2.5rem;
    padding-bottom: 1rem;
    color: var(--velotax-font-color-light);

    :after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 100px;
      background-color: var(--ant-primary-color);
    }
  }

  h2.ant-typography {
    display: flex;
    align-items: center;
    column-gap: 8px;
    width: 700px;
    margin: 0 auto 1.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: var(--velotax-font-color-light);

    &.info {
      align-items: flex-start;
      font-size: 0.875rem;
      line-height: 1.125rem;
      opacity: 0.85;
      margin: 0 auto 2.5rem;
      svg {
        margin-top: 4px;
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    padding: 0;

    h1 {
      width: calc(100% - 48px);
      margin: 0 24px 1.5rem;
      padding: 24px 0 1rem;
    }

    h2.ant-typography {
      width: 100%;
      margin: 0 auto 1.5rem;
      padding: 0 24px;
    }
  }
`;

export const Content = styled.div`
  padding: 32px;
  width: 700px;
  border-radius: 4px;
  background-color: var(--velotax-background-color);

  .ant-list.ant-list-split {
    margin: 24px 0 0;
    padding: 0 16px;
    background-color: var(--velotax-ghost);
    border: 1px solid var(--velotax-ghost);
    border-radius: 4px;
    .ant-list-item-action > li {
      padding: 0;
    }
    .ant-list-item-meta-description {
      opacity: 0.75;
    }
    .ant-list-item-action > li > button {
      font-size: 16px;
      color: var(--ant-primary-color);
    }
    .ant-list-item-meta-title,
    .ant-list-item-meta-description {
      font-size: 16px;
      color: var(--velotax-font-color-light);
    }
    .ant-list-item-action-split {
      opacity: 0;
    }
  }
  .historic-month-status {
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 0;
    color: var(--black);
    display: flex;
    &.payed,
    &.regular {
      background-color: var(--ant-success-color);
    }
    &.pending {
      background-color: var(--ant-error-color);
    }
    &.not-payed {
      background-color: var(--ant-warning-color);
    }
  }
  .ant-btn.ant-btn-text {
    color: var(--ant-primary-color);
  }
  @media only screen and (max-device-width: 812px) {
    padding: 24px 24px 32px;
    border-radius: 0;
    width: 100%;
    .ant-list.ant-list-split {
      .ant-list-item-action {
        align-items: end;
        grid-template-rows: 1fr 0.75fr;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
`;
