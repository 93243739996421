import * as yup from "yup";
import "yup-phone";
import moment from "moment";
import Payment from "payment";
import { validateBr } from "js-brasil";
import { states } from "../constants/others/states";

export const isValidCPF = (cpf: string) => validateBr.cpf(cpf || "");

export const isValidCNPJ = (cnpj: string) => validateBr.cnpj(cnpj || "");

export const isValidRenavam = (renavam?: string) =>
  renavam && validateBr.renavam(renavam);

export const isValidFullName = (name: string) =>
  name.trim().split(" ").length > 1;

export const isValidEmail = (email?: string) =>
  email && yup.string().email().isValidSync(email);

export const isValidPhone = (phone?: string) =>
  phone &&
  yup.string().phone("BR").isValidSync(phone) &&
  phone.replace(/[^0-9\\.]+/g, "").length === 11;

export const isValidDate = (date?: string) =>
  date &&
  /^((0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[012])[/](19|20)[0-9]{2})*$/.test(
    date
  );

export const isDateOnReferenceMonth = (
  month: number,
  year: number,
  date?: string
) => {
  if (date && isValidDate(date)) {
    try {
      const momentDate = moment(date, "DD/MM/YYYY");
      const start = moment(`${year}-${month + 1}`, "YYYY-M", true).startOf("M");
      const end = moment(`${year}-${month + 1}`, "YYYY-M", true).endOf("M");
      return moment(momentDate).isBetween(start, end, undefined, "[]");
    } catch (err) {
      return false;
    }
  }
  return false;
};

export const isNonDateOnFuture = (
  month: number,
  year: number,
  date?: string
) => {
  if (date && isValidDate(date)) {
    try {
      const momentDate = moment(date, "DD/MM/YYYY");
      const end = moment(`${year}-${month + 1}`, "YYYY-M", true).endOf("M");
      return moment(momentDate).isSameOrBefore(end);
    } catch (err) {
      return false;
    }
  }
  return false;
};

export const isValidVoterCard = (voterCard?: string) =>
  voterCard && voterCard.replace(/[._]/g, "").length === 12;

export const isValidCEP = (cep?: string) => cep && validateBr.cep(cep);

export const checkDateExpiration = (limit: string, value: string) => {
  const [day, month, year] = value.split("/");
  const date = new Date(
    Number(year),
    Number(month) - 1,
    Number(day),
    23,
    59,
    59
  );
  return date <= new Date(limit);
};

export const checkDateLimit = (limit: string, value: string) => {
  const [day, month, year] = value.split("/");
  const [yearLimit, monthLimit, dayLimit] = limit.split("-");
  const date = new Date(Number(year), Number(month) - 1, Number(day), 0, 0, 0);
  const dateLimit = new Date(
    Number(yearLimit),
    Number(monthLimit) - 1,
    Number(dayLimit),
    0,
    0,
    0
  );
  return date > dateLimit;
};

export const isValidIPTU = (value?: string) =>
  !value ||
  String(value || "").length === 0 ||
  (String(value || "").length >= 8 && String(value || "").length <= 30);

export const isValidDeclarationReceipt = (value: string) =>
  !value || value.length === 10 || value.length === 12;

export const removeSpecialCharacters = (value: string) => {
  if (!value) return "";
  return value?.normalize("NFD").replace(/[^a-zA-Zs]/g, "");
};

export const isValidStateAddress = (value: string) => {
  return states.includes(value.toUpperCase());
};

export const isValidShelfLife = (value: string) => {
  let currentMonth: number | string | undefined = new Date().getMonth() + 1;
  let currentYear = (new Date().getFullYear() + "")?.substring(2);
  currentMonth = currentMonth < 10 ? `0${currentMonth}` : currentMonth;
  let monthYearTime = `${currentYear}${currentMonth}`;
  let valueTime = value?.split("/").reverse().join().replace(",", "");
  return (
    !!value &&
    (valueTime < monthYearTime ||
      !/\b(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})\b/.test(value))
  );
};

export const isValidCreditCard = (value: string) =>
  Payment.fns.validateCardNumber(value);
