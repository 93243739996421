import { MdDone } from "react-icons/md";
import { VscError } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import { Input, message, ModalProps } from "antd";
import { useEffect, useState, ChangeEvent } from "react";
import {
  LoadingOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { UserPlan } from "./UserPlan/index";
import HandleTag from "../../services/handleTag";
import { UserPlans } from "../../constants/plans";
import { PlanModalStyled } from "./UserPlan/styles";
import { useAuth } from "../../contexts/AuthContext";
import { apiPayment } from "../../services/apiPayment";
import { Content, Loading } from "../PlanModal/styles";
import { PaymentModal } from "../../components/PaymentModal";

interface IUserPlanModal extends ModalProps {
  modalPlans?: any[];
  paymentData?: any;
  closeModal?: any;
}
var getStatus: NodeJS.Timeout;
var blockMultipleReq: NodeJS.Timeout | undefined;
var debounce: NodeJS.Timeout;

export const UserPlanModal: React.FC<IUserPlanModal> = ({
  title,
  modalPlans,
  onCancel,
  paymentData,
  closeModal,
  ...rest
}) => {
  const navigate = useNavigate();
  const { getUserInfo, user, showUserPlanModal } = useAuth();
  const [loading] = useState(false);
  const [cupom, setCupom] = useState();
  const [tabPlanActive] = useState("Anual");
  const [planCurrent, setPlan] = useState<any>();
  const [codeSave, setCode] = useState<string>("");
  const [textCupomErr, setTextCupomErr] = useState("");
  const [paymentModal, setPaymentModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showInputCupom, setShowInputCupom] = useState(false);

  useEffect(() => {
    if (!buttonLoading && codeSave) setButtonLoading(true);
    // eslint-disable-next-line
  }, [codeSave]);

  const handleDeselectPlan = () => {
    setPlan(undefined);
  };

  const handleSuccess = (data: any) => {
    checkWasPaid(data.paymentId, 4);
  };

  // eslint-disable-next-line
  const checkWasPaid = (id: string, delay: number = 2) => {
    let attempts = 0;

    getStatus = setInterval(async () => {
      apiPayment
        .get(`user-plan/plan-info`)
        .then((res) => {
          if (res.data?.active) {
            message.success("Pagamento Confirmado com sucesso!");
            HandleTag("61");
            setPlan(undefined);
            showUserPlanModal(false);
            getUserInfo();
            navigate("/sucesso");
            clearInterval(getStatus);
          }
        })
        .finally(() => {
          attempts += 1;

          if (attempts > 100) {
            clearInterval(getStatus);
          }
        });
    }, delay * 1000);
  };

  useEffect(() => {
    return () => getStatus && clearInterval(getStatus);
  }, [checkWasPaid]);

  useEffect(() => {
    if (planCurrent) {
      showUserPlanModal(true, "Pagamento");
    }
  }, [planCurrent, showUserPlanModal]);

  const checkIfCupomIsValid = (code: string) => {
    if (!buttonLoading) setButtonLoading(true);

    if (blockMultipleReq) {
      setButtonLoading(false);
      return;
    }

    blockMultipleReq = setTimeout(() => {
      blockMultipleReq = undefined;
    }, 2000);

    if (!code) {
      setButtonLoading(false);
      return;
    }

    apiPayment
      .get(`/cupom/valid?code=${code}`)
      .then((ret) => {
        message.success("Cupom adicionado com sucesso");
        setTextCupomErr("valid");
        setCupom(ret.data);
        setButtonLoading(false);
      })
      .catch((err) => {
        message.error(err.response.data.message);
        setTextCupomErr("invalid");
        setButtonLoading(false);
      });
  };

  const handleCupomCode = (event: ChangeEvent<HTMLInputElement>) => {
    let code: string = event.target.value;
    setCode(code);

    if (debounce) clearTimeout(debounce);

    debounce = setTimeout(() => {
      checkIfCupomIsValid(code);
    }, 1500);
  };

  const onKeyPress = (key: any) => {
    if (key.code === "Enter" || key.key === "Enter")
      checkIfCupomIsValid(codeSave);
  };

  return (
    <PlanModalStyled
      footer={null}
      destroyOnClose
      bodyStyle={
        planCurrent && planCurrent.title !== "VELOTAX_MAIN_FREE"
          ? { backgroundColor: "var(--white)" }
          : {}
      }
      title={title ? title : "Contrate o plano"}
      onCancel={(e) => {
        if (buttonLoading) return;

        if (onCancel) onCancel(e);
        setPlan(undefined);
        setCode("");
        setTextCupomErr("");
        setCupom(undefined);
        setShowInputCupom(false);
      }}
      {...rest}
    >
      <Content>
        {loading && (
          <Loading>
            <LoadingOutlined color="var(--ant-primary-color)" size={24} />
          </Loading>
        )}

        {!planCurrent && (
          // <Tabs
          //   onChange={(key) => {
          //     setTextCupomErr("");
          //     setCupom(undefined);
          //     setCode("");
          //     setTabPlanActive(key);
          //   }}
          //   defaultActiveKey={tabPlanActive}
          // >
          <>
            {UserPlans.map((plan: any) => (
              // <Tabs.TabPane tab={plan?.interval} key={plan?.interval}>
              <UserPlan
                key={plan?.interval}
                buttonLoading={buttonLoading}
                codeSave={codeSave}
                setCode={setCode}
                cupomInfo={cupom}
                cupomMsg={textCupomErr}
                showInputCupom={showInputCupom}
                plan={plan}
                handleSelect={() => {
                  if (["VELOTAX_MAIN_PRO", "VELOTAX_MAIN_PRO_MONTH"].includes(plan?.type))
                    setPaymentModal(true);
                  setTextCupomErr("");
                  setCode("");
                  plan && setPlan(plan);
                }}
                checkIfCupomIsValid={checkIfCupomIsValid}
                planBuyed={user?.user?.userPlanInfoVelotax?.type}
              />
              // </Tabs.TabPane>
            ))}
          </>
          // </Tabs>
        )}

        {planCurrent &&
          ["XP_PRO", "XP_PRO_MONTH", "VELOTAX_MAIN_PRO", "VELOTAX_MAIN_PRO_MONTH"].includes(planCurrent?.type) && (
            <PaymentModal
              show={paymentModal}
              onCancel={(e: any) => {
                setPaymentModal(false);
                closeModal(false);
                handleDeselectPlan();
                setPlan(undefined);
              }}
              paymentData={{
                ...paymentData,
                type: planCurrent?.type,
                isPlan: true,
              }}
              callDarf={(value: boolean) => {}}
              planCurrent={planCurrent}
              hideDarfDownload
              hidePixButton={["XP_PRO_MONTH", "VELOTAX_MAIN_PRO_MONTH"].includes(planCurrent?.type)}
              onSucessCreditCard={handleSuccess}
              onSucessPix={checkWasPaid}
              cupom={cupom}
              setCupom={setCupom}
            />
          )}
      </Content>
      {!user?.user?.userPlanInfoVelotax?.type && tabPlanActive !== "Mensal" && (
        <span
          className="click-here"
          onClick={() => {
            setShowInputCupom((show) => {
              if (!show) {
                setCode("");
                setTextCupomErr("");
                setCupom(undefined);
              }
              return !show;
            });
          }}
        >
          {!showInputCupom ? <PlusOutlined color="" /> : <MinusOutlined />}
          Incluir cupom de desconto
        </span>
      )}
      {tabPlanActive !== "Mensal" &&
      !user?.user?.userPlanInfoVelotax?.active &&
      showInputCupom ? (
        <div className="cupom-content">
          <div className="input-content">
            <Input
              onKeyPress={onKeyPress}
              value={codeSave}
              maxLength={16}
              disabled={textCupomErr === "valid"}
              onChange={handleCupomCode}
              size="middle"
              className="input-cupom"
              placeholder="Cupom"
            />
            <div className="icon-cupom">
              {textCupomErr && textCupomErr === "valid" ? (
                <MdDone size={20} color="var(--velotax-green-amount)" />
              ) : textCupomErr === "invalid" ? (
                <VscError size={20} color="var(--velotax-red-prejuizo)" />
              ) : undefined}
            </div>
          </div>
          {!!textCupomErr && (
            <span
              className="cupom-msg"
              style={{
                color:
                  textCupomErr !== "valid"
                    ? "var(--ant-error-color)"
                    : "var(--velotax-green-amount)",
              }}
            >
              {textCupomErr === "valid" ? "Cupom Válido" : "Cupom Inválido"}
            </span>
          )}
        </div>
      ) : undefined}
    </PlanModalStyled>
  );
};
