import React from "react";
import { Col, Row, Typography } from "antd";
import { BsClockHistory } from "react-icons/bs";

interface IPendingScreenProps {}

export const PendingScreen: React.FC<IPendingScreenProps> = () => {
  const getMessage = () =>
    "Aguardando confirmação de pagamento no cartão, assim que confirmado o plano será ativado automaticamente.";

  return (
    <Row justify="center" style={{ marginTop: "64px" }}>
      <Col>
        <BsClockHistory size={72} color="var(--ant-primary-color)" />
      </Col>

      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "48px",
          justifyContent: "left",
        }}
      >
        <Typography.Paragraph
          style={{
            textAlign: "justify",
            fontSize: 16,
            color: "var(--velotax-font-color-dark)",
          }}
        >
          {getMessage()}
        </Typography.Paragraph>
      </Col>
    </Row>
  );
};
