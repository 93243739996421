import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineSavings } from "react-icons/md";
import { Logo } from "../Logo";
import { isMobile } from "../../utils";
import HandleTag from "../../services/handleTag";
import { UserPlanEnum } from "../../constants/plans";
import { useAuth } from "../../contexts/AuthContext";
import { PoweredByVelotax } from "../PoweredByVelotax";
import { useBroker } from "../../contexts/BrokerContext";
import { useB3Integration } from "../../contexts/B3IntegrationContext";
import { ReactComponent as SairIcon } from "../../assets/icons/sidebar/sair.svg";
import { ReactComponent as IrpfIcon } from "../../assets/icons/sidebar/irpf.svg";
import { ReactComponent as ContaIcon } from "../../assets/icons/sidebar/conta.svg";
import {
  ContentButton,
  DrawerSider,
  FeatureButton,
  FeaturePromo,
  LayoutSider,
  Module,
  Modules,
  Settings,
} from "./styles";

interface ISideBarProps {
  pagePath: string;
}

interface ISideBarContainerProps {
  visible: boolean;
  setVisible: Function;
}

const SideBarContainer: React.FC<ISideBarContainerProps> = ({
  children,
  visible,
  setVisible,
}) =>
  isMobile() ? (
    <DrawerSider
      title=""
      width="208px"
      footer={null}
      closeIcon={null}
      placement="left"
      visible={visible}
      onClose={() => setVisible(false)}>
      {children}
    </DrawerSider>
  ) : (
    <LayoutSider theme="light" width="208px">
      {children}
    </LayoutSider>
  );

export interface ItegrationStatus {
  description?: string;
  started?: boolean;
  finished?: boolean;
  error?: boolean;
  alert?: boolean;
  processing?: boolean;
}

const Sidebar: React.FC<ISideBarProps> = ({ pagePath }) => {
  const navigate = useNavigate();
  const { b3Authorized } = useB3Integration();
  const {
    sidebarPages,
    currentBroker,
    xtageIntegration,
  } = useBroker();
  const {
    user,
    hasPlan,
    signOut,
    showDrawer,
    setShowDrawer,
    hasPermissionGeneral,
  } = useAuth();

  const [openKeys, setOpenKeys] = useState([""]);

  const handleNavigate = (path: string) => {
    if (path.includes("http")) {
      window.open(path, "_blank")?.focus();
    } else if (!user.user) {
      setShowDrawer(false);
      const extension: Record<string, string> = {
        bolsa: "-integration",
        cripto: "-integrations",
        exterior: "-integrations",
      };
      navigate(`${path}${extension[path.split("/")[2]]}`);
    } else if (path.includes("http")) {
      window.open(path, "_blank")?.focus();
    } else {
      setShowDrawer(false);
      if (path === `/${currentBroker.path}/bolsa`) {
        if (b3Authorized) {
          navigate(path);
        } else {
          navigate(`/${currentBroker.path}/bolsa-integration`);
        }
      } else if (path === `/${currentBroker.path}/cripto`) {
        if (xtageIntegration) {
          navigate(path);
        } else {
          navigate(`/${currentBroker.path}/cripto-integration`);
        }
      } else {
        navigate(path);
      }
    }
  };

  const sidebarselectedcolor = currentBroker.colors.sidebarSelectedColor;

  useEffect(() => {
    const currentPage = [
      ...sidebarPages,
      {
        path: "/irpf",
        subPages: [],
      },
    ].find(
      (page) =>
        page.path === pagePath ||
        page.subPages?.find((subPage) => subPage.path === pagePath)
    );
    setOpenKeys([currentPage?.path || ""]);
  }, [pagePath, sidebarPages]);

  const DECLARACAO_IRPF = false;

  return (
    <SideBarContainer visible={showDrawer} setVisible={setShowDrawer}>
        <Logo />
        {DECLARACAO_IRPF && (
          <FeaturePromo
            className={clsx({ active: pagePath === "/declaracao-irpf" })}
            onClick={() => {
              handleNavigate("/declaracao-irpf");
            }}>
            <IrpfIcon className="stroke" />
            <span className="title-features">Declaração IRPF</span>
            <FeatureButton type="primary" className="btn-premium">
              DISPONÍVEL
            </FeatureButton>
          </FeaturePromo>
        )}
        <Modules
          mode="inline"
          openKeys={openKeys}
          selectedKeys={[pagePath, ...openKeys]}
          className={clsx("beauty-scrollbar zero-scrollbar", {
            user: !!user.user,
            poweredBy: !currentBroker.hidePoweredBy,
          })}>
          {currentBroker.useCarteira &&
            (currentBroker.hideCalculadoras ||
              user?.user?.visibleCalcs?.includes("carteira")) && (
              <Module
                key="/carteira"
                title="carteira"
                icon={<ContaIcon className="stroke" />}
                onClick={() => handleNavigate("/carteira")}
                sidebarselectedcolor={sidebarselectedcolor}>
                Meus investimentos
              </Module>
            )}
          {user.user &&
            sidebarPages.map((page) => (
              <Module
                key={page.path}
                icon={page.icon}
                title={page.name}
                onClick={() => handleNavigate(page.path)}
                sidebarselectedcolor={sidebarselectedcolor}>
                {page.name}
              </Module>
            ))}
          {currentBroker.usePGBL &&
            (currentBroker.hideCalculadoras ||
              user?.user?.visibleCalcs?.includes("pgbl")) &&
            false && (
              <Module
                key="/pgbl"
                title="Simulador de previdência"
                onClick={() => handleNavigate("/pgbl")}
                sidebarselectedcolor={sidebarselectedcolor}
                icon={<MdOutlineSavings size={24} />}>
                Simulador de previdência
              </Module>
            )}
        </Modules>
        {/* <IntegrationButton /> */}
        <ContentButton
          onClick={() => {
            if (!hasPlan || !hasPlan.concierge) navigate("/planos");
            HandleTag("54");
          }}>
          {!hasPlan && !hasPermissionGeneral ? (
            <div className="plan-sidebar pointer">
              Seu plano: <strong>Gratuito</strong>
            </div>
          ) : user?.user?.userPlanInfoVelotax?.type?.includes('PRO') ||
            user?.user?.userPlanInfoVelotax?.type === UserPlanEnum.XP_PRO ||
            user?.user?.userPlanInfoVelotax?.type === UserPlanEnum.XP_PRO_MONTH ||
            user?.user?.userPlanInfoVelotax?.type === UserPlanEnum.VELOTAX_MAIN_PRO ||
            user?.user?.userPlanInfoVelotax?.type ===
              UserPlanEnum.VELOTAX_MAIN_PRO_MONTH ||
            hasPermissionGeneral ? (
            <div
              className={`plan-sidebar ${
                hasPlan && !hasPlan.concierge ? "pointer" : ""
              }`}>
              Seu plano: <strong>Premium</strong>
            </div>
          ) : user?.user?.userPlanInfoVelotax?.type?.includes('CONCIERGE') ||
          user?.user?.userPlanInfoVelotax?.type === UserPlanEnum.XP_CONCIERGE ||
            user?.user?.userPlanInfoVelotax?.type ===
              UserPlanEnum.VELOTAX_MAIN_CONCIERGE ? (
            <div
              className={`plan-sidebar ${
                hasPlan && !hasPlan.concierge ? "pointer" : ""
              }`}>
              Seu plano: <strong>Concierge</strong>
            </div>
          ) : user?.user?.userPlanInfoVelotax?.type?.includes('BASIC') ||
            user?.user?.userPlanInfoVelotax?.type === UserPlanEnum.XP_BASIC ||
            user?.user?.userPlanInfoVelotax?.type === UserPlanEnum.VELOTAX_MAIN_BASIC ? (
            <div
              className={`plan-sidebar ${
                hasPlan && !hasPlan.concierge ? "pointer" : ""
              }`}>
              Seu plano: <strong>Basic</strong>
            </div>
          ) : undefined}
        </ContentButton>
        {!currentBroker.hidePoweredBy && (
          <PoweredByVelotax className="poweredby-velotax sidebar" />
        )}
        {user.user && (
          <Settings selectedKeys={[pagePath]}>
            <Module
              key="/conta"
              className="conta"
              sidebarselectedcolor={sidebarselectedcolor}
              onClick={() => handleNavigate("/conta")}
              icon={<ContaIcon className="stroke" />}>
              Conta
            </Module>
            <Module
              key="signout"
              className="conta logout"
              sidebarselectedcolor="var(--ant-error-color)"
              icon={<SairIcon width="20px" height="20px" className="sair" />}
              onClick={() => {
                signOut();
              }}>
              Sair
            </Module>
          </Settings>
        )}
      </SideBarContainer>
  );
};

export default Sidebar;
