import styled from "styled-components";

export const Container = styled.div`
  padding: 64px 32px 128px;
  &.view-edit {
    padding: 0;
  }
  &.view {
    padding: 0;
  }

  h1 {
    width: 700px;
    margin: 0 auto 1.5rem;
    font-weight: 400;
    font-size: 2rem;
    position: relative;
    line-height: 2.5rem;
    padding-bottom: 1rem;
    color: var(--velotax-font-color-light);

    :after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 100px;
      background-color: var(--ant-primary-color);
    }
  }

  .juridic-messages-container {
    opacity: 0.8;
    margin: 0 auto;
    padding: 24px 0 0;
    max-width: 700px;

    .message {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1rem;
    }

    p {
      font-size: 0.85rem;
      font-weight: 400;
      text-align: justify;
      line-height: 1.5rem;
      color: var(--velotax-font-color-light);
      margin-bottom: 0;

      i {
        font-weight: bold;
        color: var(--velotax-font-color-light);
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    padding: 0 0 64px;

    h1 {
      max-width: calc(100% - 48px);
      margin: 0 24px 1.5rem;
      padding: 24px 0 1rem;
    }

    .juridic-messages-container {
      margin: 0;
      width: 100%;
      p {
        margin-left: 0;
      }
    }
  }
`;

export const Content = styled.div`
  padding: 32px;
  &.second.view,
  &.second.view-edit {
    padding: 0 32px 32px;
  }
  &.second {
    padding: 32px;
  }
  margin: 0 auto;
  max-width: 700px;
  border-radius: 4px;
  background-color: var(--velotax-background-color);
  h3 {
    display: flex;
    align-items: center;
    font-size: 20px;
    column-gap: 16px;
    color: var(--velotax-font-color-light);
    margin-bottom: 0;
    user-select: none;
  }
  h3.date-title {
    font-size: 24px;
    font-weight: 700;
  }
  .month-chevron {
    width: 32px;
    height: 32px;
    cursor: pointer;
    &.disabled {
      opacity: 0.15;
      cursor: default;
    }
  }
  .desc-label {
    display: flex;
    align-items: center;
    svg {
      fill: var(--ant-primary-color);
    }
  }
  .desc-content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    column-gap: 8px;
    svg {
      fill: var(--ant-primary-color);
    }
    span {
      align-self: center;
    }
    button {
      min-width: 32px;
    }
  }
  .ml-40 {
    margin-left: 40px;
  }
  .add {
    float: right;
    min-width: 128px;
    margin-bottom: 16px;
  }
  .ant-descriptions-view {
    border-radius: 4px !important;
  }
  .ant-descriptions-item-label {
    width: 50%;
    color: var(--velotax-font-color-light) !important;
  }
  .ant-descriptions-item-content {
    text-align: center;
    background-color: #3d3d3d !important;
    span {
      color: var(--velotax-font-color-light) !important;
    }
  }
  .ant-list.ant-list-split {
    clear: both;
    padding: 0 16px;
    background-color: var(--velotax-ghost);
    border: 1px solid var(--velotax-ghost);
    border-radius: 4px;
    .ant-list-item-action > li {
      padding: 0;
    }
  }
  .ant-list-item-action > li,
  .ant-list-item-meta-title,
  .ant-list-item-meta-description {
    color: var(--velotax-font-color-light);
  }
  .ant-list-item-meta-description {
    opacity: 0.75;
  }
  .list-description {
    display: flex;
    flex-direction: column;
  }
  .min-darf-price {
    margin-top: 24px;
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
  .text-center {
    display: block;
    text-align: center;
  }
  .ant-collapse-header {
    padding: 12px 0 !important;
  }
  .ant-collapse-content-box {
    padding: 16px 0 !important;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow
    svg {
    transform: rotate(-90deg);
  }
  .ant-collapse-item-disabled {
    & > .ant-collapse-header {
      cursor: default;
      .ant-collapse-arrow {
        opacity: 0;
      }
    }
  }
  .ant-collapse-header-text {
    width: 100%;
  }
  .total-tax-header {
    width: calc(100% - 48px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.original {
      h3:last-of-type {
        width: 40%;
      }
    }
    h3 {
      margin: 0 0 8px;
    }
  }

  .no-plan-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    z-index: 3;
    height: 100%;
    padding: 8px;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #fff4;
    border-radius: 0 8px 8px 0;
    backdrop-filter: blur(4px);
    border: 1px solid var(--velotax-background-color-ghost);
    border-left: none;
    .ant-btn {
      margin-top: 8px;
    }
  }

  @media only screen and (max-device-width: 812px) {
    width: 100%;
    padding: 24px;
    border-radius: 0;
    &.first {
      padding: 24px;
    }
    &.second {
      padding: 24px !important;
      border-top: 1px solid #313131;
    }
    h3 {
      font-size: 18px;
    }
    .no-plan-container {
      font-size: 14px;
      line-height: 18px;
    }
    .anticon.anticon-right.ant-collapse-arrow {
      top: 24px;
    }
    .desc-content {
      column-gap: 2px;
      flex-direction: row;
      &.is-editting {
        row-gap: 8px;
        flex-direction: column;
        button {
          width: 100%;
        }
      }
      &:not(.is-editting) {
        span {
          line-height: 32px;
        }
        button {
          margin-top: -2px;
        }
      }
      &.ml-40 {
        margin-left: 0;
      }
    }
  }
`;
