import clsx from "clsx";
import moment from "moment";
import { Button } from "@mui/material";
import { FaRegEdit } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { AiOutlineDelete, AiOutlineLock } from "react-icons/ai";
import { BsCalendar3, BsDownload, BsEye } from "react-icons/bs";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useMemo, useState, ReactNode } from "react";
import {
  Col,
  Collapse,
  DatePicker,
  Descriptions,
  List,
  message,
  Modal,
  Row,
  Skeleton,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { Page } from "../../constants/brokers";
import AntButton from "../../components/Button";
import HandleTag from "../../services/handleTag";
import { Container, Content } from "../Darf/styles";
import { useAuth } from "../../contexts/AuthContext";
import { FormModal } from "../../components/FormModal";
import { apiPayment } from "../../services/apiPayment";
import { InterestModal } from "./InterestModalContent";
import { useBroker } from "../../contexts/BrokerContext";
import { BackButton } from "../../components/BackButton";
import apis, { NOT_AUTHORIZED } from "../../services/apis";
import { PaymentModal } from "../../components/PaymentModal";
import { ExteriorModalContent } from "./ExteriorModalContent";
import { IntegrationModal } from "../Darf/Components/IntegrationModal";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import { DatePickerExtraFooter } from "../Darf/Components/DatePickerExtraFooter";
import {
  DatePickerMonthCell,
  YearResume,
} from "../Darf/Components/DatePickerMonthCell";
import {
  antDatePickerLocale,
  download,
  errorMessage,
  formatCurrency,
  monthsExtended,
  numberToPercentageWallet,
} from "../../utils";
import {
  DarfResultDescriptionsExterior,
  ExteriorFormItemRows,
  defaultDarf,
  DarfModal,
  IDarf,
  DarfImpostosDevidosDescriptions,
  minDarfPrice,
  maxDarfPrice,
  YearResumeStatusFromBackEnum,
  IQuotes,
  historicoVendasEmptyText,
  maxPixPayment,
  maxCreditCardPayment,
  yearConfigPL4173,
  ExteriorNewFormItemRows,
} from "../../constants/darf";
import { CurrencyFormItem } from "../../constants/formItems";
import { useDarfExterior } from "./useDarfExterior";
import { ModalConfirmMail } from "../../components/ModalConfirmMail";

interface DarfProps {
  item: Page;
  view?: boolean;
  viewEdit?: boolean;
  closeModal?: () => void;
  darf?: {
    id: string;
    fullPath?: string;
  };
  date?: {
    year?: number;
    month?: number;
  };
}

const PrejuFormItems = {
  userPrejuizoExterior: (disabled: boolean) =>
    CurrencyFormItem({
      disabled,
      name: "prejuizoExterior",
      label: "Prejuízo Exterior",
    }),
};

export const PrejuFormItemRows = (disabled: boolean) => [
  [PrejuFormItems.userPrejuizoExterior(disabled)],
];

export const DarfExterior: React.FC<DarfProps> = ({
  view,
  item,
  date,
  darf,
  viewEdit,
  closeModal,
}) => {
  const { state } = useLocation();
  const { currentBroker, currentPage } = useBroker();
  const [openPrejuizoModal, setOpenPrejuizoModal] = useState(false);
  const navigate = useNavigate()
  const { user, hasPlan: hasPremiunPlan, hasPermissionExterior, hasPermissionGeneral } = useAuth();
  const { getBuyPrice, getFee, getGain, getSellPrice } = useDarfExterior()

  const today = new Date();
  const queryYear = (state as any)?.year as number;
  const queryMonth = (state as any)?.month as number;
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const initialMonth = queryMonth
    ? queryMonth - 1
    : today.getMonth() - 1 >= 0
      ? today.getMonth() - 1
      : 11;
  const initialYear = queryYear
    ? queryYear
    : today.getMonth() - 1 >= 0
      ? today.getFullYear()
      : today.getFullYear() - 1;
  const defaultValue = moment({ month: initialMonth, year: initialYear });
  const currentDate = moment({
    month: currentMonth,
    year: currentYear,
  }).subtract(1, "M");
  window.history.replaceState({}, document.title);

  const year = date?.year ?? initialYear;
  const month = date?.month ?? initialMonth;
  const oldModel = year < yearConfigPL4173;
  const currentDarfDate = new Date(year, month, 15)
  let dateAllowedOnBasic = new Date(today.getFullYear(), today.getMonth() - 1, 1)
  if(user?.user?.userPlanInfoVelotax?.currentPlan?.createdAt) {
    dateAllowedOnBasic = new Date(user?.user?.userPlanInfoVelotax?.currentPlan?.createdAt);
    dateAllowedOnBasic.setMonth(dateAllowedOnBasic.getMonth() - 2)
  }
  const hideOnBasic = (currentDarfDate.getTime() < dateAllowedOnBasic.getTime() && user.user?.userPlanInfoVelotax?.type?.includes('BASIC'))
  const hasPlan = (hasPremiunPlan || hasPermissionExterior || hasPermissionGeneral) && !hideOnBasic;

  const [asset, setAsset] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [, setYear] = useState(initialYear);
  const [, setMonth] = useState(initialMonth);
  const [emitting, setEmitting] = useState(false);
  const [darfModal, setDarfModal] = useState<any>();
  const [helpModal, setHelpModal] = useState<any>();
  const [data, setData] = useState<IDarf>(defaultDarf);
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentData, setPaymentData] = useState<IDarf>();
  const [quotations, setQuotations] = useState<IQuotes>({});
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [movimentType, setMovimentType] = useState<string>("Venda de ativos");
  const [showAssetModal, setShowAssetModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [yearResume, setYearResume] = useState<YearResume[]>([]);
  const [datePickerYear, setDatePickerYear] = useState(initialYear);
  const [gettingYearResume, setGettingYearResume] = useState(false);
  const [loadingDarfButton, setLoadingDarfButton] = useState<boolean>(false);
  const [showNotAuthorizedModal, setShowNotAuthorizedModal] = useState(false);
  const [reportData, setReportData] = useState<any>(null)

  const transactionFeature = item.features[0];
  const taxFeature = item.features[1];
  const yearResumeFeature = item.features[2];

  const memoriaCalculoFiltered = data.memoriaCalculo.filter(
    (el: any) =>
      (el.operation === "SELL" &&
        (el.type === "TRADE" || (el.type === "WITHDRAW" || el.type === "WITHDRAWAL"))) ||
      el.type === "INTEREST" ||
      (el.type === "DIVIDEND" && !oldModel)
  );

  const { emitted } = useMemo(() => {
    if (gettingYearResume) {
    }
    const monthResume = yearResume
      .find((resume) => resume.year === year)
      ?.months.find((m) => m.month - 1 === month);
    return {
      emitted:
        monthResume?.status === YearResumeStatusFromBackEnum.PAYED ||
        monthResume?.status === YearResumeStatusFromBackEnum.NOT_PAYED,
      payed: !!monthResume?.payed,
      status: monthResume?.status,
    };
  }, [yearResume, year, month, gettingYearResume]);

  const getDarf = useCallback(
    (id: string) => {
      view && setLoading(true);
      view &&
        (currentPage?.api || apis)
          .get(`${taxFeature.apiUrl}/${id}`)
          .then((response) => {
            setYear(response.data?.year);
            setMonth(response.data?.month - 1);
            setData({
              ...response.data,
              emitted: true,
            });
          })
          .catch(() => message.error(errorMessage))
          .finally(() => setLoading(false));
    },
    [view, taxFeature.apiUrl, currentPage]
  );

  const getYearResume = useCallback(
    (year: number, force?: boolean, url?: string) => {
      setGettingYearResume(true);
      !view &&
        year <= new Date().getFullYear() &&
        year >= currentBroker.initialYear &&
        (force ||
          (!yearResumeFeature.disabled &&
            !yearResume.find((resume) => resume.year === year))) &&
        (currentPage?.api || apis)
          .get(yearResumeFeature.apiUrl, { params: { year } })
          .then((response) => {
            const index = yearResume.findIndex(
              (resume) => resume.year === year
            );
            if (index >= 0) {
              setYearResume((yearResume) => {
                yearResume.splice(index, 1, response.data);
                return yearResume;
              });
            } else {
              setYearResume((yearResume) => [...yearResume, response.data]);
            }

            if (url) {
              download(url);
            }
          })
          .catch((err) => console.log(err))
          .finally(() => setGettingYearResume(false));
    },
    [
      yearResumeFeature,
      yearResume,
      view,
      currentBroker.initialYear,
      currentPage,
    ]
  );

  const getTaxes = useCallback(() => {
    (!hasPlan || !view) && setLoading(true);
    (!hasPlan || !view) &&
      (currentPage?.api || apis)
        .get(transactionFeature.apiUrl, { params: { month: month + 1, year } })
        .then((response) => {
          setData(response.data);
          setNotAuthorized(false);
        })
        .catch((err) => {
          setData(defaultDarf);
          if (err?.response?.data === NOT_AUTHORIZED) {
            setNotAuthorized(true);
            setShowNotAuthorizedModal(true);
          } else {
            setNotAuthorized(false);
            message.error(errorMessage);
          }
        })
        .finally(() => setLoading(false));
  }, [hasPlan, view, currentPage?.api, transactionFeature.apiUrl, month, year]);

  const getQuotations = useCallback(() => {
    !view &&
      (currentPage?.api || apis)
        .get("/xp/get-cotacoes")
        .then((response) => {
          const { value: cotacoes } = response.data;
          const pojo = {};
          for (let cotacao of cotacoes) {
            const {
              dataHoraCotacao,
              cotacaoCompra: compra,
              cotacaoVenda: venda,
            } = cotacao;
            const [year, month, day] = String(dataHoraCotacao)
              .split("T")[0]
              .split("-");
            Object.assign(pojo, {
              [`${day}/${month}/${year}`]: {
                compra,
                venda,
              },
            });
          }
          setQuotations(pojo);
        })
        .catch((err) => {
          setData(defaultDarf);
          if (err?.response?.data === NOT_AUTHORIZED) {
            setNotAuthorized(true);
            setShowNotAuthorizedModal(true);
          } else {
            setNotAuthorized(false);
            message.error(errorMessage);
          }
        });
    // eslint-disable-next-line
  }, [view]);

  const handleDarf = (justSave?: boolean) => {
    setEmitting(true);
    onCloseDarfModal();
    (currentPage?.api || apis)
      .post(taxFeature.apiUrl, {
        ...data,
        totalImpostoDevido: oldModel ? (data?.totalImpostoDevido ?? 0) : (data?.impostoDevido ?? 0),
        year,
        month: month + 1,
        name: user.user.name,
        memoriaCalculo: [],
        transactions: data.memoriaCalculo,
        paymentInfo: {
          clientInfo: {
            name: user?.user?.name,
            email: user?.user?.email,
            document: user?.user?.cpf,
          },
        },
        insertPix: true,
        justSave,
        payed: false,
        noSendMail: true,
      })
      .then((res) => {
        setData((data) => ({ ...data, emitted: true }));
        getYearResume(year, true, justSave ? "" : res.data.darfUrl);
        setEmitting(false);
        // if (!justSave) {
        //   oldModel
        //   ? message.success("O DARF foi enviado para o seu e-mail")
        //   : message.success("O Relatório foi enviado para o seu e-mail"); 
        // }
        if (res.data.filePath) {
          setReportData({
            year: year,
            month: month,
            filePath: res.data.filePath,
            ...data
          })
        } else {
          closeModal?.();
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || errorMessage);
        setEmitting(false);
      });
  };

  const handleRegularize = (regular: boolean) => {
    setEmitting(true);
    (currentPage?.api || apis)
      .post("transaction/regularize", {
        year,
        month: month + 1,
        regular,
        impostoDevido: data.impostoDevido,
        totalImpostoDevido: data.totalImpostoDevido,
      })
      .then((res) => {
        getYearResume(year, true, res.data.darfUrl);
        getTaxes();
        closeModal?.();
        message.success("Dados salvos");
      })
      .catch(() => message.error(errorMessage))
      .finally(() => setEmitting(false));
  };

  const onChangeMonth = (value: moment.Moment | null, dateString: string) => {
    const [month, year] = dateString.split("-");
    setMonth(parseInt(month) - 1);
    setYear(parseInt(year));
  };

  const unsetAsset = () => {
    setAsset(undefined);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    unsetAsset();
  };

  const handleCloseAssetModal = () => {
    setMovimentType("Venda de ativos");
    setShowAssetModal(false);
    unsetAsset();
  };

  const onCloseHelpModal = () => {
    setHelpModal(undefined);
  };

  const onCloseDarfModal = () => {
    setDarfModal(undefined);
  };

  const handleOpenDarfModal = () => {
    setDarfModal(DarfModal(emitted, onCloseDarfModal, () => handleDarf()));
  };

  const handleOpenPaymentModal = () => {
    setPaymentModal(!paymentModal);
  };

  const handleAdd = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    setShowAssetModal(true);
  };

  const handleEdit = (item: any, index: number) => {
    setShowAssetModal(true);
    const data = moment.utc(item.data.toLocaleString()).format("DD/MM/YYYY");
    let i = 0;
    let key = data;
    while (!quotations[key] && i <= 7) {
      key = moment(key, "DD/MM/YYYY").subtract(1, "days").format("DD/MM/YYYY");
      i++;
    }

    setAsset({
      ...item,
      data,
      cotacaoVenda: quotations[key]?.venda,
      cotacaoCompra: quotations[key]?.compra,
      dataVenda: moment
        .utc(item?.dataVenda?.toLocaleString())
        ?.format("DD/MM/YYYY"),
      dataCompra: moment
        .utc(item?.dataCompra?.toLocaleString())
        ?.format("DD/MM/YYYY"),
    });
  };

  const handleRemove = (item: any, index: number) => {
    setShowDeleteModal(true);
    setAsset(item);
  };

  const addAsset = (asset: any) => {
    setLoading(true);

    const ganho = getGain(asset, oldModel)
    const sellPrice = getSellPrice(asset, oldModel)
    const buyPrice = getBuyPrice(asset, oldModel)
    const fee = getFee(asset, oldModel)

    const dataToCreate = {
      ...{[sellPrice.key]: sellPrice.value},
      ...{[fee.key]: fee.value},
      ...{[ganho.key]: ganho.value},
      ...{[buyPrice.key]: buyPrice.value},
      data: asset.data, // date liquidação venda
      dataCompra: asset.dataCompra, // pegar a cotação
      operation: "SELL", // fixo
      type: "TRADE", // fixo
      ativo: asset.ativo, // Tiker que o usuário colocar (Ativo)
      year,
      month,
      quantidade: asset.quantidade,
      // "saldoBRA": 10000,
      // "saldoEUA": 870.7999999999993,
      // "capitalGainBRA_BRL": 0, (colocar no ganho)
      // "capitalGainEUA_BRL": 0,
      // ganho: asset.ganho, // (capitalGainBRA_BRL)
      cambioVenda: asset.cambioVenda, // (C) o cambio venda na pagina 1 ta na venda
      cambioCompra: asset.cambioCompra, // (C) o cambio venda na pagina 1 ta na venda
      // "cambioReferencia": 0,
      valueOriginEUA: asset.valueOriginEUA, // Origem USA (US$) ??
      movimentType: asset.movimentType
    };

    const dataFeeToCreate = {
      ...{[ganho.key]: ganho.value},
      // ganho: asset.ganhoFee,
      ...{[sellPrice.key]: sellPrice.value},
      valor: asset.valor, // (Valor Bruto (US$))
      ...{[fee.key]: fee.value}, // TODO: VALIDATE THIS
      fee: asset.fee, // (Taxa de Corretagem (US$)) 
      //
      ativo: asset.ativo, // Tiker que o usuário colocar (Ativo)
      data: asset.dataFee,
      dataCompra: asset.dataFee, // date liquidação venda
      operation: "BUY", // fixo
      type: asset.movimentType === 'Dividendos' ? "DIVIDEND" : "INTEREST", // fixo
      year,
      month,
      rendimentoAplicacao: asset.ganhoFee, // (capitalGainBRA_BRL)
      cambioVenda: asset.cambioVendaFee, // (C) o cambio venda na pagina 1 ta na venda
      movimentType: asset.movimentType
    };
    
    (currentPage?.api || apis)
      .post(
        `${transactionFeature.apiUrl}?manual=true`,
        asset.movimentType !== "Venda de ativos"
          ? dataFeeToCreate
          : dataToCreate,
        {
          headers: {
            "x-email": user.user.email,
          },
        }
      )
      .then(() => {
        getTaxes();
        getYearResume(year, true);
      })
      .catch(() => message.error(errorMessage))
      .finally(() => {
        setLoading(false);
        handleCloseAssetModal();
      });
  };

  const editAsset = (data: any) => {
    const ganho = getGain(data);
    const sellPrice = getSellPrice(data, oldModel)
    const buyPrice = getBuyPrice(data, oldModel)
    const fee = getFee(data, oldModel)
      // ...{[sellPrice.key]: sellPrice.value},
      // ...{[fee.key]: fee.value},
      // ...{[ganho.key]: ganho.value},
      // ...{[buyPrice.key]: buyPrice.value},
    const dataToEdit = {
      ...{[ganho.key]: ganho.value}, // (capitalGainBRA_BRL)
      ...{[fee.key]: fee.value},
      fee: data.fee, // (Taxa de Corretagem (US$))
      ...{[sellPrice.key]: sellPrice.value},
      valor: data.valor, // (Valor Bruto (US$))
      ...{[buyPrice.key]: buyPrice.value},
      valueOriginBRA: data.valueOriginBRA, // Origem BRA (US$) ??
      valueOriginEUA: data.valueOriginEUA, // Origem USA (US$) ??
      //
      data: data.data, // date liquidação venda
      dataCompra: data.dataCompra, // pegar a cotação
      operation: "SELL", // fixo
      type: "TRADE", // fixo
      ativo: data.ativo, // Tiker que o usuário colocar (Ativo)
      quantidade: data.quantidade,
      // "saldoBRA": 10000,
      // "saldoEUA": 870.7999999999993,
      // "capitalGainBRA_BRL": 0, (colocar no ganho)
      // "capitalGainEUA_BRL": 0,
      cambioVenda: data.cambioVenda, // (C) o cambio venda na pagina 1 ta na venda
      cambioCompra: data.cambioCompra, // (C) o cambio venda na pagina 1 ta na venda
      // "cambioReferencia": 0,
    };
    setLoading(true);
    (currentPage?.api || apis)
      .put(`${transactionFeature.apiUrl}/${asset._id}`, dataToEdit)
      .then(() => {
        getTaxes();
        getYearResume(year, true);
      })
      .catch(() => message.error(errorMessage))
      .finally(() => {
        setLoading(false);
        handleCloseAssetModal();
      });
  };

  const removeAsset = () => {
    setLoading(true);
    (currentPage?.api || apis)
      .delete(`${transactionFeature.apiUrl}/${asset._id}`)
      .then(() => {
        getTaxes();
        getYearResume(year, true);
      })
      .catch(() => message.error(errorMessage))
      .finally(() => {
        setLoading(false);
        handleCloseDeleteModal();
      });
  };

  const handlePlanModal = () => {
    navigate('/planos')
  };

  const handleSaveMonth = async () => {
    setLoadingDarfButton(true);
    try {
      const retPlanInfo = await apiPayment.get("/user-plan/plan-info");
      const hasExteriorCalcActive = retPlanInfo?.data?.permissions?.["exterior-xpinvestimentos"];

      retPlanInfo.data.active || hasExteriorCalcActive || hasPermissionGeneral
        ? handleDarf(true)
        : handlePlanModal();
    } catch (err) {
      console.error(err);
    }
    setLoadingDarfButton(false);
  };

  const handleEventDarfButton = async () => {
    setLoadingDarfButton(true);
    let retPlanInfo = {
      data: {
        active: false,
        permissions: {
          "exterior-xpinvestimentos": false,
        },
      },
    };
    try {
      retPlanInfo = await apiPayment.get("/user-plan/plan-info");
    } catch (err) {
      console.error(err);
    }
    setLoadingDarfButton(false);

    const hasntPlan = !retPlanInfo.data.active;
    const hasExteriorCalcActive = retPlanInfo.data.permissions["exterior-xpinvestimentos"];

    //@ts-ignore
    hasntPlan && !hasExteriorCalcActive && !retPlanInfo.data.cupomDueDate
      ? handlePlanModal()
      : handleOpenPaymentModal();
  };

  const handleLabelButton: ReactNode = useMemo(() => {
    if(!oldModel) return "Emitir relatório"
    else if (data?.totalImpostoDevido <= maxDarfPrice) return "Pagar DARF";
    else {
      if (emitted) return "Emitir novo DARF";
      else return taxFeature?.buttonLabel;
    }
  }, [data?.totalImpostoDevido, emitted, oldModel, taxFeature?.buttonLabel]);

  const sendReportMail = useCallback(() => {
    const reportType = reportData.type || 'darf';
    if (!reportData) {
      closeModal?.()
      message.error("Darf não encontrado!")
      return;
    }
    (currentPage?.api || apis).get(`/darf/report/mail/${reportType}`, {
      params: {
        year: reportData?.year,
        month: reportData?.month + 1,
        filePath: reportData?.filePath,
        ...reportData,
      }
    }).then(() => {
      message.success("Relatório enviado para seu email.")
    })
    .catch(() => {
      message.error("Houve um erro ao enviar seu relatório!")
    })
    closeModal?.()
  }, [closeModal, currentPage?.api, reportData])

  const downloadAction = (record: any) => {
    (currentPage?.api || apis)
      .get(`/darf/download/${record._id}`)
      .then((res) => {
        if (res.data.url) {
          download(res.data.url);
        }
      });
  };

  const calcReturn = (
    sellPrice: number,
    buyPrice: number,
    quantity: number,
    fee: number,
    ganho?: number
  ) => {
    let ret = 0
    if(ganho) {
      const qtt = quantity ? quantity : 1;
      ret =
      sellPrice && buyPrice
      ? (sellPrice * qtt - (fee || 0)) / (buyPrice * qtt) - 1
      : 0;
    }
    return (
      <strong
        style={{
          color: `${ret >= 0
            ? "var(--velotax-green-amount)"
            : "var(--velotax-red-prejuizo)"
            }`,
        }}
      >
        {ret ? numberToPercentageWallet(ret) : "-"}
      </strong>
    );
  };

  useEffect(() => {
    getTaxes();
  }, [getTaxes, month, year]);

  useEffect(() => {
    getQuotations();
  }, [getQuotations, month, year, item]);

  useEffect(() => {
    if (view && darf) {
      getDarf(darf.id);
    }
  }, [getDarf, view, darf]);

  useEffect(() => {
    getYearResume(year);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const dataToPayment = {
      ...data,
      year,
      month: month + 1,
      name: user.user.name,
      memoriaCalculo: [],
      transactions: data.memoriaCalculo,
    };

    setPaymentData(dataToPayment);
  }, [data.totalImpostoDevido, data, month, user.user.name, year]);

  const impostoCharge = useMemo(() => {
    const taxes = ((data.impostoDevido ?? 0) - (data.irrfExterior ?? 0))
    const isExceedTax = taxes < minDarfPrice;
    const additionalTax = oldModel ? data.multa + data.juros : 0;
    return !view
      ? taxes +
          (isExceedTax ? 0 : additionalTax) 
      : Math.max(
          taxes +
            (isExceedTax ? 0 : additionalTax) ,
          0
        );
  }, [data, view, oldModel]);

  const verifyFromApi = (item = {} as any) => {
    return (Object.values(item).includes(item.saldoBRA) 
      || Object.values(item).includes(item.rendimentoAplicacao)) && oldModel
  }

  return (
    <Container className={clsx({ view, "view-edit": viewEdit })}>
      <Content className={clsx("first", { view, "view-edit": viewEdit })}>
        <Space direction="vertical" className="full-width">
          <Row gutter={[16, 16]} align="middle" justify="space-between">
            <Col xs={24} sm={16}>
              <Typography.Title level={3} className="date-title">
                <BsCalendar3 />
                {!view && !viewEdit && (
                  <>
                    {currentBroker.initialYear === year && month === 0 ? (
                      <div className="month-chevron empty" />
                    ) : (
                      <BiChevronLeft
                        className="month-chevron"
                        onClick={() => {
                          if (month === 0) {
                            setYear((year) => {
                              const newYear = year - 1;
                              getYearResume(newYear);
                              setDatePickerYear(newYear);
                              return newYear;
                            });
                            setMonth(11);
                          } else {
                            setMonth((month) => month - 1);
                          }
                        }}
                      />
                    )}
                  </>
                )}
                {monthsExtended[month]} de {year}
                {!view && !viewEdit && (
                  <>
                    {currentYear === year && currentMonth - 1 === month ? (
                      <div className="month-chevron empty" />
                    ) : (
                      <BiChevronRight
                        className="month-chevron"
                        onClick={() => {
                          if (month === 11) {
                            setYear((year) => {
                              const newYear = year + 1;
                              getYearResume(newYear);
                              setDatePickerYear(newYear);
                              return newYear;
                            });
                            setMonth(0);
                          } else {
                            setMonth((month) => month + 1);
                          }
                        }}
                      />
                    )}
                  </>
                )}
              </Typography.Title>
            </Col>
            {!view && !viewEdit && (
              <Col xs={24} sm={8}>
                <DatePicker
                  size="large"
                  picker="month"
                  format="MM-YYYY"
                  disabled={loading}
                  allowClear={false}
                  inputReadOnly={true}
                  onChange={onChangeMonth}
                  suffixIcon={<BsCalendar3 />}
                  defaultValue={defaultValue}
                  locale={antDatePickerLocale}
                  value={moment({ year: datePickerYear, month })}
                  onSelect={(e) => {
                    if (e.month() === month && year !== datePickerYear) {
                      onChangeMonth(e, `${month + 1}-${datePickerYear}`);
                    }
                  }}
                  onPanelChange={(e) => {
                    getYearResume(e.year());
                    setDatePickerYear(e.year());
                  }}
                  getPopupContainer={(trigger) => trigger.parentElement!}
                  renderExtraFooter={() =>
                    yearResumeFeature.disabled ? (
                      <></>
                    ) : (
                      <DatePickerExtraFooter />
                    )
                  }
                  disabledDate={(current) =>
                    current &&
                    (current > currentDate.endOf("month") ||
                      current < moment(`${currentBroker.initialYear}-01-01`) ||
                      current < moment(`2020-01-01`))
                  }
                  monthCellRender={(e) => (
                    <DatePickerMonthCell event={e} yearResume={yearResume} />
                  )}
                />
              </Col>
            )}
          </Row>
        </Space>
      </Content>

      <Content className={clsx("second", { view, "view-edit": viewEdit })}>
        <Space direction="vertical" className="full-width">
          {hasPlan && view && darf?.fullPath && (
            <Row justify="end">
              <Col>
                <Button
                  variant="text"
                  color="secondary"
                  style={{ marginTop: "-32px" }}
                  onClick={() => downloadAction(darf)}
                  startIcon={
                    <BsDownload
                      size={16}
                      style={{
                        fill: "var(--ant-primary-color)",
                      }}
                    />
                  }
                >
                  BAIXAR DARF
                </Button>
              </Col>
            </Row>
          )}

          <div style={{ position: "relative" }}>
            {(!hasPlan || hideOnBasic) && (
              <div className="no-plan-container">
                <Typography>
                  Contrate o plano premium para ver os impostos devidos
                </Typography>
                <AntButton
                  type="primary"
                  icon={<AiOutlineLock size={18} />}
                  onClick={() => {
                    handlePlanModal();
                    closeModal?.();
                  }}
                >
                  PREMIUM
                </AntButton>
              </div>
            )}
            <Descriptions size="small" bordered column={1}>
              {DarfImpostosDevidosDescriptions.map((description) => {
                if (description.id === 'prejuizoExterior' && oldModel) return null

                return (
                  <Descriptions.Item
                    key={description.id}
                    label={description.label(
                      setHelpModal,
                      year
                      // () => {setOpenPrejuizoModal(true)}
                    )}
                  >
                    {loading ? (
                      <Spin />
                    ) : description.render ? (
                      description.render(data, view, year)
                    ) : (
                      formatCurrency(
                        Number(data[description.id as keyof IDarf] || 0)
                      )
                    )}
                  </Descriptions.Item>
                )
              })}
            </Descriptions>
          </div>

          <br />
          <Collapse
            ghost
            bordered={false}
            expandIconPosition="right"
            defaultActiveKey={!hasPlan ? ["1"] : []}
          >
            <Collapse.Panel
              key="1"
              header={
                <Typography.Title level={3}>
                  Vendas realizadas no mês
                </Typography.Title>
              }
            >
              {!notAuthorized && !view && (
                <Button
                  className="add"
                  color="secondary"
                  variant="contained"
                  disabled={loading}
                  onClick={handleAdd}
                  startIcon={<PlusOutlined />}
                >
                  Adicionar
                </Button>
              )}
              <List
                loading={loading}
                itemLayout="horizontal"
                locale={{
                  emptyText: historicoVendasEmptyText,
                }}
                dataSource={
                  hideOnBasic ? [] :
                  memoriaCalculoFiltered
                }
                renderItem={(item, index) => (
                  <List.Item
                    actions={
                      notAuthorized || view
                        ? []
                        : [
                          <AntButton
                            type="text"
                            onClick={() => handleEdit(item, index)}
                            icon={
                              verifyFromApi(item) || (item.type !== 'TRADE' && !oldModel) ? (
                                <BsEye size={18} />
                              ) : (
                                <FaRegEdit size={18} />
                              )
                            }
                          />,
                          <AntButton
                            type="text"
                            onClick={() => handleRemove(item, index)}
                            icon={<AiOutlineDelete size={18} />}
                          />,
                        ]
                    }
                    >
                      {oldModel ? <Skeleton title={false} loading={item.loading} active>
                        <List.Item.Meta
                          title={
                            <strong>{item.ativoTranslated ? item.ativoTranslated : item.ativo}</strong>
                          }
                          description={
                            <div className="list-description">
                              <div>
                                Data de liquidação:{" "}
                                {moment
                                  .utc(item?.data?.toLocaleString())
                                  .format("DD/MM/YYYY")}
                              </div>
                              <div>
                                {item.type !== "INTEREST"
                                  ? "Ganho"
                                  : "Rendimento total"}
                                :{" "}
                                <strong>
                                  {formatCurrency(
                                    item.type === "INTEREST"
                                      ? item.rendimentoAplicacao
                                      : getGain(item, oldModel).value
                                  )}
                                </strong>
                              </div>
                              {view && (
                                <>
                                  <div>
                                    {item.type !== "INTEREST"
                                      ? "Valor de venda"
                                      : "Valor bruto"}
                                    :{" "}
                                    <strong>
                                      {formatCurrency(getSellPrice(item).value, "US$ ")}
                                    </strong>
                                  </div>
                                  <div>
                                    Taxa de corretagem:{" "}
                                    <strong>
                                      {formatCurrency(getFee(item).value, "US$ ")}
                                    </strong>
                                  </div>
                                  <div>
                                    Cotação dólar:{" "}
                                    <strong>{item.cambioVenda}</strong>
                                  </div>
                                  {item.type !== "INTEREST" && (
                                    <>
                                      <div>
                                        Valor de compra (Origem BRA):{" "}
                                        <strong>
                                          {formatCurrency(
                                            item.valueOriginBRA,
                                            "US$ "
                                          )}
                                        </strong>
                                      </div>
                                      <div>
                                        Valor de compra (Origem EUA):{" "}
                                        <strong>
                                          {formatCurrency(
                                            item.valueOriginEUA,
                                            "US$ "
                                          )}
                                        </strong>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          }
                        />
                      </Skeleton> : <Skeleton title={false} loading={item.loading} active>
                      <List.Item.Meta
                        title={
                          <strong>{item.ativoTranslated ? item.ativoTranslated : item.ativo}</strong>
                        }
                        description={
                          <div className="list-description">
                            <div>
                              Data:{" "}
                              {moment
                                .utc(item?.data?.toLocaleString())
                                .format("DD/MM/YYYY")}
                            </div>
                            {item.type === "TRADE" && <div>Quantidade: {item.quantidade}</div>}
                            <div>
                              {item.type === "TRADE"
                                ? "Ganho"
                                : "Rendimento total"}
                              :{" "}
                              <strong>
                                {formatCurrency(
                                  item.type !== "TRADE"
                                    ? item.rendimentoAplicacao
                                    : getGain(item, oldModel).value
                                )}
                              </strong>
                            </div>
                            {item.type === 'TRADE' && <div>
                              Retorno: {calcReturn(
                                getSellPrice(item, oldModel).value,
                                getBuyPrice(item, oldModel).value,
                                item.quantidade,
                                getFee(item, oldModel).value,
                                getGain(item, oldModel).value
                              )}
                            </div>}
                          </div>
                        }
                      />
                    </Skeleton>}
                    </List.Item>
                  )}
                />
              </Collapse.Panel>
            </Collapse>

          {hasPlan && <br />}

          {hasPlan && (
            <div
              className="total-tax-header original"
              style={{ width: "100%" }}
            >
              <Typography.Title level={3}>Imposto total</Typography.Title>
              <Typography.Title level={3} className="text-center">
                {loading ? (
                  <Spin style={{ marginRight: "32px" }} />
                ) : (
                  formatCurrency(Math.max(impostoCharge, 0))
                )}
              </Typography.Title>
            </div>
          )}

          {hasPlan && (
            <Descriptions size="small" bordered column={1}>
              {DarfResultDescriptionsExterior.map((description, i) => {
                if(['impostoAcumulado', 'multa', 'juros'].includes(description.id) && !oldModel) return <></>
                return (
                  <Descriptions.Item
                    key={description.id}
                    label={description.label(setHelpModal)}
                    style={{
                      display: 
                        i === 3 ? "none"
                        : i === 4 && oldModel ? "none"
                        : "table-cell",
                    }}
                  >
                    {loading ? (
                      <Spin />
                    ) : description.Component ? (
                      <description.Component
                        data={data}
                        year={year}
                        month={month}
                        loading={loading}
                        setDataCripto={setData}
                        url={transactionFeature.apiUrl}
                        disabled={!notAuthorized && !view}
                        view={view}
                      />
                    ) : (
                      formatCurrency(
                        Number(data[description.id as keyof IDarf] || 0)
                      )
                    )}
                  </Descriptions.Item>
                )
              })}
            </Descriptions>
          )}

          {!notAuthorized && !view && (
            <>
              <Row
                justify="end"
                gutter={[16, 16]}
                className="column-reverse"
                style={{ marginTop: "16px" }}
              >
                {!(month === currentMonth && year === currentYear) &&
                  oldModel &&
                  (data.impostoDevido + data.impostoAcumulado - (data?.irrfExterior ?? 0) ?? 0) <
                  minDarfPrice &&
                  memoriaCalculoFiltered?.length > 0 && (
                    <Col xs={24}>
                      <br />
                      <Tooltip
                        title={
                          <>
                            O valor do imposto devido é menor que{" "}
                            {formatCurrency(minDarfPrice)}. Você não precisa
                            emitir um DARF para este mês.
                          </>
                        }
                      >
                        <Button
                          fullWidth
                          size="large"
                          color="secondary"
                          variant="contained"
                          disabled={loading || emitting}
                          onClick={() => handleRegularize(true)}
                          startIcon={emitting && <LoadingOutlined />}
                        >
                          Salvar sem emitir DARF
                        </Button>
                      </Tooltip>
                    </Col>
                  )}
                {(((data.impostoDevido + data.impostoAcumulado - (data?.irrfExterior ?? 0)) >= minDarfPrice) || (!oldModel && memoriaCalculoFiltered.length > 0 )) && (
                  <Col xs={24}>
                    <br />
                    <Tooltip
                      getPopupContainer={(target) => target.parentElement!}
                      title={
                        month === currentMonth && year === currentYear && oldModel
                          ? "Aguarde o encerramento do mês para pagar seu DARF"
                          : ""
                      }
                    >
                      <Button
                        fullWidth
                        size="large"
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                          oldModel 
                          ? handleEventDarfButton()
                          : handleDarf()
                        }}
                        startIcon={
                          (emitting || loadingDarfButton) && <LoadingOutlined />
                        }
                        disabled={
                          loading ||
                          emitting ||
                          loadingDarfButton ||
                          (month === currentMonth && year === currentYear && oldModel)
                        }
                      >
                        {handleLabelButton}
                      </Button>{" "}
                    </Tooltip>
                  </Col>
                )}
              </Row>
              {/* {!oldModel && (<Button onClick={() => handleDarf(false)} style={{color: "red"}}>TESTE</Button>)} */}
              {(!(month === currentMonth && year === currentYear) && oldModel) && (
                <Row
                  justify="end"
                  align="middle"
                  gutter={[16, 16]}
                  style={{ marginTop: "16px" }}
                >
                  <Col>
                    <Button
                      size="small"
                      variant="text"
                      color="secondary"
                      onClick={handleSaveMonth}
                      disabled={loading || emitting}
                      startIcon={emitting && <LoadingOutlined />}
                    >
                      Salvar apenas
                    </Button>
                  </Col>
                </Row>
              )}
            </>
          )}

          {!view && (
            <>
              <IntegrationModal
                visible={showNotAuthorizedModal}
                onCancel={() => setShowNotAuthorizedModal(false)}
              />
              <div className="juridic-messages-container">
                <div className="message">
                  <p>
                    (1) Os cálculos de imposto de renda foram realizados levando
                    em consideração somente as operações de compra e venda
                    realizadas na XP International. Caso você tenha realizado
                    operações com ativos no exterior em outras corretoras,
                    realize as alterações manualmente.
                  </p>
                </div>
                <div className="message">
                  <p>
                    (2) Caso você tenha realizado a transferência de custódia de
                    outras corretoras de ativos no exterior para a XP
                    International, realize manualmente a inserção do custo médio
                    de compra dos respectivos ativos transferidos.
                  </p>
                </div>
                {/* <div className="message">
              <p>
                (3) O Usuário é exclusivamente responsável pela conferência e
                validação das informações utilizadas na apuração do imposto
                devido, conforme{" "}
                <a
                  rel="noreferrer"
                  href="https://velotax-termos-e-condicoes.s3.us-east-1.amazonaws.com/Termos%20de%20Uso%20-%20Velotax%20v1.pdf"
                  target="_blank"
                >
                  Termos e Condições Gerais de Uso.
                </a>
              </p>
            </div> */}
              </div>
            </>
          )}
          {!view && !viewEdit && (
            <BackButton
              to="/xpinvestimentos/exterior"
              className="darf-exterior"
            />
          )}
        </Space>
      </Content>
      <Modal
        footer={null}
        visible={helpModal}
        title={helpModal?.title}
        onCancel={onCloseHelpModal}
      >
        {helpModal?.content}
      </Modal>
      <Modal
        visible={darfModal}
        title={darfModal?.title}
        footer={darfModal?.footer}
        onCancel={onCloseDarfModal}
        zIndex={1001}
      >
        {darfModal?.content}
      </Modal>
      <FormModal
        add={addAsset}
        edit={editAsset}
        itemToEdit={asset}
        visibility={showAssetModal}
        onCancel={handleCloseAssetModal}
        hideFooter={
          asset &&
          (Object.values(asset).includes(asset?.saldoBRA) ||
            Object.values(asset).includes(asset?.rendimentoAplicacao))
          && oldModel
        }
        rows={
          asset && (
            (Object.values(asset).includes(asset.saldoBRA) &&
            asset.saldoBRA !== 0 && oldModel) ||
            (!oldModel && asset.type !== 'TRADE')
          )
            ? []
            : oldModel ? ExteriorFormItemRows(
              month,
              year,
              quotations,
              editAsset,
              asset,
              movimentType,
              setMovimentType
            ) : ExteriorNewFormItemRows(
              month,
              year,
              quotations,
              editAsset,
              asset,
              movimentType,
              setMovimentType,
              false,
            )
        }
        title={
          asset
            ? asset.type !== "TRADE"
              ? "Movimentação"
              : (Object.values(asset).includes(asset.saldoBRA) && oldModel)
              ? asset.ativoTranslated || asset.ativo
              : oldModel ? "Editar movimentação"
              : asset.ativoTranslated || asset.ativo
            : "Adicionar movimentação"
        }
        children={
          !asset ? null : asset.type !== "TRADE" ? (
            <InterestModal asset={asset} />
          ) : (
            Object.values(asset).includes(asset.saldoBRA) && oldModel && (
              <ExteriorModalContent asset={asset} />
            )
          )
        }
      />
      {/* <Modal title="Editar prejuízos a compensar" visible={openPrejuizoModal} 
        onOk={() => {setOpenPrejuizoModal(false)}} 
        onCancel={() => {setOpenPrejuizoModal(false)}}>
        <Typography.Title level={5}>Prejuízo a compensar</Typography.Title>
        <Input title="Prejuízo"/>
      </Modal> */}
      <FormModal
        title="Editar prejuízos a compensar"
        visibility={openPrejuizoModal}
        add={() => {}}
        edit={() => {}}
        onCancel={() => {setOpenPrejuizoModal(false)}}
        itemToEdit={{}}
        rows={PrejuFormItemRows(false)}
      />
      <PaymentModal
        item={item}
        fromDarf
        show={paymentModal}
        selectedYear={year}
        emitting={emitting}
        maxPixValue={maxPixPayment}
        closeDarfModal={closeModal}
        onCancel={handleOpenPaymentModal}
        maxCCValue={maxCreditCardPayment}
        disablePixOption={data?.totalImpostoDevido > maxPixPayment}
        callDarf={() => {
          HandleTag("67");
          handleOpenDarfModal();
        }}
        disableCreditCardOption={
          data?.totalImpostoDevido > maxCreditCardPayment
        }
        paymentData={{
          ...paymentData,
          impostoTotalFinal: data.totalImpostoDevido + data.impostoAcumulado,
        }}
      />
      <DeleteConfirmationModal
        title="Remover ativo"
        visibility={showDeleteModal}
        onCancel={handleCloseDeleteModal}
        setVisibility={setShowDeleteModal}
        onOk={() => Promise.resolve(removeAsset())}
        body="Você realmente quer deletar esse ativo?"
      />
      <ModalConfirmMail
          visible={Boolean(reportData)}
          closeFn={() => setReportData(null)}
          action={(sendMail) => {
            if (sendMail) {
              sendReportMail()
            }
          }}
        />
    </Container>
  );
};
