import clsx from "clsx";
import { BiCheck, BiX } from "react-icons/bi";
import { Divider, Row, Typography } from "antd";
import Button from "../../Button";
import { Container } from "./styles";
import { formatCurrency } from "../../../utils";
import { OtherFeatures, Plan as IPlan } from "../../../constants/plans";

interface PlanProps {
  plan: IPlan;
  buyed: boolean;
  handleSelect: React.MouseEventHandler;
}

export const Plan: React.FC<PlanProps> = ({ plan, buyed, handleSelect }) => {
  return (
    <Container>
      <Typography.Title level={3}>{plan.title}</Typography.Title>
      <div className="price">
        <Typography.Title level={4}>
          {formatCurrency(plan.price)} <span className="per-month">/ mês</span>
          <br />
          <Typography.Text delete>
            {formatCurrency(plan.originalPrice)}{" "}
            <span className="per-month">/ mês</span>
          </Typography.Text>
        </Typography.Title>
        <Typography.Text type="danger" strong>
          {plan.discount}% de desconto no plano anual
        </Typography.Text>
      </div>
      <div className="included-features">
        {plan.includedFeatures.map((feature) => (
          <div className="feature" key={feature.name}>
            <span>{feature.name}</span>
            <span>{feature.icon}</span>
          </div>
        ))}
      </div>
      <Divider />
      <div className="other-features">
        {OtherFeatures.map((feature) => (
          <div
            key={feature.name}
            className={clsx("feature", {
              disabled:
                plan.otherFeatures.findIndex((f) => feature.name === f.name) <
                0,
            })}
          >
            <span>
              {feature.name}
              {feature.desc && (
                <span className="feature-desc">{feature.desc}</span>
              )}
            </span>
            <span>
              {plan.otherFeatures.findIndex((f) => feature.name === f.name) <
                0 ? (
                <BiX size={18} />
              ) : (
                <BiCheck size={18} color="var(--ant-success-color)" />
              )}
            </span>
          </div>
        ))}
      </div>
      <Row justify="end">
        {!buyed ? (
          <Button type="primary" size="large" onClick={handleSelect}>
            Selecionar
          </Button>
        ) : (
          <Button disabled type="text" size="large" onClick={handleSelect}>
            Plano Selecionado!
          </Button>
        )}
      </Row>
    </Container>
  );
};