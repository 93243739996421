import _ from "lodash";
import moment from "moment";
import * as XLSX from "xlsx";
import { useState } from "react";
import { useEffect } from "react";
import { BsDownload } from "react-icons/bs";
import { Select, Tooltip, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { AiOutlineLock, AiOutlineSearch } from "react-icons/ai";
import Loading from "../Loading";
import { ChartBar } from "../ChartBar";
import { BlurContent } from "./styles";
import api from "../../services/apiBolsa";
import AntButton from "../../components/Button";
import HandleTag from "../../services/handleTag";
import { ProventosModal } from "../ProventosModal";
import apiVeloPro from "../../services/apiVeloPro";
import { useAuth } from "../../contexts/AuthContext";
import { ContainerProventos, Content } from "./styles";
import { ReportTypeDrawer } from "../ReportType/ReportType";
import { download, downloadPDF, isMobile } from "../../utils";
import { useNotification } from "../../contexts/NotificationContext";
import { useNavigate } from "react-router-dom";

export const Proventos = ({ hideValues }: any) => {
  const { getNotification } = useNotification();

  const {
    user,
    showUserPlanModal,
    hasPlan: hasPremiunPlan,
    hasPermissionExterior,
    hasPermissionGeneral,
  } = useAuth();

  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [proventos, setProventos] = useState<any[]>([]);
  const [monthProventos, setMonthProventos] = useState<any>({});
  const [loadingDownload, setLoadingDownload] = useState(false);
  // const [appliedFilters, setAppliedFilters] = useState<any>({});
  const [proventosLoading, setProventosLoading] = useState<any>({});
  const [loadingReport, setLoadingReport] = useState<boolean>(false);
  const [typeProventos, setTypeProventos] = useState<any>("provento");
  const [reportDrawerProventos, setReportDrawerProventos] = useState(false);
  const [yearProventos, setYearProventos] = useState<any>(
    new Date().getFullYear()
  );

  const hasPlan =
    (hasPremiunPlan || hasPermissionExterior || hasPermissionGeneral)
    && !user?.user?.userPlanInfoVelotax?.type?.includes('BASIC');

  useEffect(() => {
    setProventosLoading(true);
    api
      .get(`/xpinvestimentos/get-proventos`, {
        params: { year: yearProventos },
      })
      .then((res) => {
        if (!res?.data?.proventos) setProventos([])
        setProventos(res?.data?.proventos);
        if (res?.data?.updated) {
          getNotification();
        }
      })
      .finally(() => setProventosLoading(false));
  }, [user?.user?.cpf, getNotification, yearProventos]);

  const monthReport = (data?: any, index?: any) => {
    setOpenModal(true);
    if (data) {
      setMonthProventos(data);
    } else {
      const date = new Date();
      const y = date.getFullYear();
      const m = date.toLocaleString("pt-BR", { month: "short" });
      const name = `${m[0].toUpperCase()}${m.substring(1, 3)}`;
      setMonthProventos({ name });
      setYearProventos(y);
    }
  };

  const serializeDataExcel = (data: any) => {
    return data
      .filter(
        (el: any) => yearProventos === Number(moment(el?.date).format("Y"))
      )
      .map((el: any) => {
        return {
          Produto: el?.code,
          Pagamento: moment(el?.date).format("DD/MM/YYYY"),
          Tipo: _.capitalize(el?.corporateActionTypeDescription),
          Instituição: el?.corretora,
          "Qtde.": el?.quantity || "-",
          "Preço unit.":
            el?.corporateActionTypeDescription.toLowerCase() === "dividendo" ||
            el?.corporateActionTypeDescription.toLowerCase() === "dividendos" ||
            el?.corporateActionTypeDescription.toLowerCase() === "cupom"
              ? "-"
              : "R$ " +
                el?.unitPrice?.toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }),
          "Valor líquido":
            "R$ " +
            el?.grossAmount?.toLocaleString("pt-br", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
        };
      });
  };

  const downloadExcel = (data: any) => {
    const serializedData = serializeDataExcel(data);
    setLoadingReport(true);
    api
      .post("/xpinvestimentos/wallet/report-proventos", {
        data: serializedData,
      })
      .then(() => {
        if (!isMobile()) {
          const worksheet = XLSX.utils.json_to_sheet(serializedData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Proventos");
          XLSX.writeFile(workbook, "Proventos.xlsx");
        }
      })
      .finally(() => {
        message.success("O relatório foi enviado para o seu e-mail");
        setLoadingReport(false);
      });
  };

  const downloadRelatorio = async (type: string, item: any) => {
    try {
      setLoadingDownload(true);
      if (type === "pdf") {
        let fileUrl = "";
        let file = "";
        const escritorioId = null;
        const newProventos = proventos.map((el: any) => {
          return {
            code: el.code || "",
            date: moment(el?.date).format("DD/MM/YYYY"),
            corporateActionTypeDescription: _.capitalize(
              el?.corporateActionTypeDescription
            ),
            corretora: el?.corretora,
            quantity: el?.quantity || "-",
            unitPrice: el?.unitPrice,
            grossAmount: el?.grossAmount,
          };
        });
        const ret = await apiVeloPro.post(
          "/clients/downloadRelatorioProventos",
          {
            proventos: newProventos,
            escritorioId: escritorioId,
            user: user?.user
          }
        );
        if (ret) {
          fileUrl = ret?.data?.fileUrl;
          file = ret?.data?.file;
        }
        if (fileUrl && !isMobile()) {
          download(fileUrl);
        } else if (file) {
          downloadPDF(file, "Proventos");
        }
        message.success("O relatório foi enviado para o seu e-mail");
      } else if (type === "xlsx") {
        downloadExcel(proventos);
      }
      setLoadingDownload(false);
    } catch (err: any) {
      setLoadingDownload(false);
      message.error(
        "Não foi possível baixar o relatório de Proventos. Tente novamente mais tarde"
      );
    }
  };

  const existsProvCurrentMonth = () => {
    const currentMonth = (new Date().getMonth() + 1);
    return (proventos.some((p: any) => {
      if ((p?.date?.split('-')[1] == currentMonth.toString())) return true;
    }))
  }

  return (
    <Content className="velotax-container" style={{ padding: "0px" }}>
      <Content className="velotax-content">
        <ContainerProventos>
          {!hasPlan ? (
            proventosLoading ? (
              <Loading />
            ) : (
              <BlurContent hasPlan={hasPlan}>
                <div className="blur-content-title">
                  Contrate o plano premium para ver a lista completa de
                  proventos
                  <AntButton
                    type="primary"
                    icon={<AiOutlineLock size={18} />}
                    onClick={() => {
                      navigate('/planos')
                      HandleTag("54");
                    }}
                  >
                    PREMIUM
                  </AntButton>
                </div>
                <ChartBar
                  monthReport={monthReport}
                  proventos={proventos}
                  yearProventos={yearProventos}
                  typeProventos={typeProventos}
                  hideValues={hideValues}
                />
              </BlurContent>
            )
          ) : (
            <>
              <div className="filter-proventos">
                <Select
                  value={yearProventos}
                  disabled={proventosLoading}
                  defaultValue={yearProventos}
                  onChange={(e) => setYearProventos(e)}
                  options={[
                    {
                      value: new Date().getFullYear() + 1,
                      label: new Date().getFullYear() + 1,
                    },
                    {
                      value: new Date().getFullYear(),
                      label: new Date().getFullYear(),
                    },
                    {
                      value: new Date().getFullYear() - 1,
                      label: new Date().getFullYear() - 1,
                    },
                    {
                      value: new Date().getFullYear() - 2,
                      label: new Date().getFullYear() - 2,
                    },
                    {
                      value: new Date().getFullYear() - 3,
                      label: new Date().getFullYear() - 3,
                    },
                  ]}
                />
                <Select
                  value={typeProventos}
                  defaultValue="provento"
                  disabled={proventosLoading}
                  onChange={(e) => setTypeProventos(e)}
                  options={[
                    {
                      label: "Por tipo de provento",
                      value: "provento",
                    },
                    {
                      label: "Por corretora",
                      value: "corretora",
                    },
                    {
                      label: "Por data de recebimento",
                      value: "data",
                    },
                  ]}
                />
                <div className="proventos-btns">
                  {existsProvCurrentMonth() && 
                    <AntButton
                      type="text"
                      size="large"
                      onClick={() => monthReport()}
                      icon={<AiOutlineSearch className="fill" />}
                      disabled={proventosLoading || loadingReport}
                    >
                      Ver detalhes
                    </AntButton>
                  }
                  <Tooltip
                    placement="topRight"
                    autoAdjustOverflow={true}
                    title="Baixar Relatório de proventos"
                    getPopupContainer={(target) => target.parentElement!}
                  >
                    <AntButton
                      type="text"
                      size="large"
                      onClick={() => {
                        setReportDrawerProventos(true);
                      }}
                      disabled={proventosLoading || loadingReport}
                      icon={
                        loadingReport ? <LoadingOutlined /> : <BsDownload />
                      }
                    >
                      Baixar
                    </AntButton>
                  </Tooltip>
                </div>
              </div>
              {proventosLoading ? (
                <Loading />
              ) : (
                <ChartBar
                  monthReport={monthReport}
                  proventos={proventos}
                  yearProventos={yearProventos}
                  typeProventos={typeProventos}
                  hideValues={hideValues}
                />
              )}
              <ProventosModal
                open={openModal}
                setOpenModal={setOpenModal}
                month={monthProventos.name}
                monthProventos={monthProventos}
                proventos={proventos}
                yearProventos={yearProventos}
                downloadExcel={downloadExcel}
                setYearProventos={setYearProventos}
                proventosLoading={proventosLoading}
                setMonthProventos={setMonthProventos}
              />
            </>
          )}
        </ContainerProventos>
      </Content>
      <ReportTypeDrawer
        loading={loadingDownload}
        open={reportDrawerProventos}
        onClose={() => setReportDrawerProventos(false)}
        setType={(type: string) => downloadRelatorio(type, proventos)}
      />
    </Content>
  );
};
