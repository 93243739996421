import { Typography } from "antd";
import { SelectBoxes } from "./SelectBoxes";
import { Container, Content, CalculatorsSelect } from "./styles";

export const Settings: React.FC = () => {
  return (
    <Container>
      <Content>
        <Typography.Title level={1}>Configurações</Typography.Title>
        <CalculatorsSelect>
          <Typography.Title level={2}>
            Selecione as calculadoras que você deseja utilizar
          </Typography.Title>
          <SelectBoxes data={[]} loading={false} saveModules={() => {}} />
        </CalculatorsSelect>
      </Content>
    </Container>
  );
};
