import { Form, message } from "antd";
import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";
import { AuthContainer } from "../../styles/LoginSignUp/authContainer";
import {
  validationPassConfirmation,
  validationPassword,
} from "../../utils/formValidations";

interface IData {
  pass: string;
  passConfirmation: string;
}

export const ChangePassword: React.FC = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [form] = Form.useForm<IData>();
  const [loading, setLoading] = useState(false);
  const [fieldsError, setFieldsError] = useState<{ [key: string]: string[] }>(
    {}
  );

  const onFinish = async (data: IData) => {
    try {
      setLoading(true);

      await api.post(`/user/change-password/${token}`, data);
      message.success("Senha alterada com sucesso!", 5);

      setTimeout(() => {
        setLoading(false);
        navigate("/p/login");
      }, 500);
    } catch (err: any) {
      if (err?.response?.status === 400) {
        message.error(err.response?.data?.message || "Ocorreu um erro");
      }
      setLoading(false);
      return;
    }
  };

  const onFieldsChange = () => {
    setFieldsError(
      form
        .getFieldsError()
        .reduce((acc, cur) => ({ ...acc, [cur.name?.[0]]: cur.errors }), {})
    );
  };

  return (
    <AuthContainer className="beauty-scrollbar">
      <div className="auth-content">
        <div className="form-container">
          <h1>Alterar senha</h1>
          <Form
            form={form}
            size="large"
            onFinish={onFinish}
            onFieldsChange={onFieldsChange}
          >
            <Form.Item name="pass" rules={validationPassword}>
              <TextField
                label="Senha"
                type="password"
                variant="standard"
                error={fieldsError["pass"]?.length > 0}
              />
            </Form.Item>

            <Form.Item
              name="passConfirmation"
              rules={validationPassConfirmation}
            >
              <TextField
                type="password"
                variant="standard"
                label="Confirmar senha"
                error={fieldsError["passConfirmation"]?.length > 0}
              />
            </Form.Item>

            <div className="buttons">
              <Button
                size="large"
                type="submit"
                color="secondary"
                variant="contained"
                className="full-width"
                disabled={loading}
                startIcon={loading && <LoadingOutlined />}
              >
                Enviar
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </AuthContainer>
  );
};
