import { Select, Typography } from "antd";
import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { DividendosModal } from "./modal";
import { monthList } from "./dataSourceList";
import backendBolsa from "../../services/apiBolsa";
import { BolsaOperations, ModalStyled } from "./styles";
import { useBroker } from "../../contexts/BrokerContext";
import { BackButton } from "../../components/BackButton";
import { DividendosTabs } from "../../constants/dividendos";

export const DividendosBolsa = () => {
  const { state } = useLocation();
  const { currentBroker } = useBroker();
  const today = new Date();
  const currentYear = today.getFullYear();
  const initialYear = state?.year ?? currentYear;
  window.history.replaceState({}, document.title);

  const [data, setData] = useState<any>([]);
  const [item, setItem] = useState<any>({});
  const [index, setIndex] = useState<any>({});
  const [year, setYear] = useState(initialYear);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const yearOptions = Array.from(
    Array(new Date().getFullYear() - currentBroker.initialYear + 1)
  )
    .map((y, i) => ({
      value: currentBroker.initialYear + i,
      label: `${currentBroker.initialYear + i}`,
    }))
    .reverse();

  const getHistoric = useCallback(() => {
    setLoading(true);
    backendBolsa
      .get(`/dividendos?year=${year}`)
      .then(async ({ data }) => setData(data))
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, [year]);

  const onChangeYear = (value: string) => {
    setYear(parseInt(value));
  };

  useEffect(() => {
    getHistoric();
  }, [getHistoric, year]);

  return (
    <div className="velotax-container">
      <Typography.Title level={1} className="page-title">
        Dividendos recebidos na bolsa de valores
      </Typography.Title>
      <div className="velotax-content">
        <Select
          size="large"
          options={yearOptions}
          value={year.toString()}
          onChange={onChangeYear}
          placeholder="Selecione o ano"
          style={{ minWidth: "150px" }}
          getPopupContainer={(target) => target.parentElement}
        />
        <BolsaOperations>
          <div className="sub-header">
            <span className="show dividendos-header">Mês</span>
            <span className="show dividendos-header">Rendimento Bruto</span>
            <span className="show dividendos-header"></span>
          </div>
          {monthList.map((item, index: number) => {
            return DividendosTabs(
              item,
              index,
              data,
              loading,
              setShowModal,
              setItem,
              setIndex,
              true
            ).map((container: any, rowIndex: any) => (
              <div key={rowIndex} className={container.class}>
                {container.tabs.map((item: any, colIndex: any) => (
                  <span key={colIndex} className="show">
                    {item.content}
                  </span>
                ))}
              </div>
            ));
          })}
        </BolsaOperations>
      </div>
      <BackButton to="/xpinvestimentos/bolsa" className="darf-bolsa" />
      <br />
      <br />
      <ModalStyled
        closable
        width={660}
        footer={null}
        destroyOnClose
        visible={showModal}
        onCancel={() => setShowModal(false)}
        title={`${monthList[index]?.monthName ?? ""} de ${year}`}
      >
        <DividendosModal item={item} year={year} />
      </ModalStyled>
    </div>
  );
};
