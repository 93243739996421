import { Result, Row } from "antd";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/Button";
import { ReactComponent as Veloicon } from "../../assets/velotax/veloicon.svg";

export const Container = styled.div`
  height: 100vh;
  background-color: var(--ant-info-color);
  .ant-result-subtitle,
  .ant-result-title {
    color: var(--white);
  }
  svg.error * {
    fill: var(--ant-error-color);
  }
`;

const PageNotFound: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Result
        icon={<Veloicon />}
        title="Página não encontrada"
        subTitle="Esta página que você visitou não existe"
        extra={
          <Row justify="center">
            <Button size="large" type="primary" onClick={() => navigate("/")}>
              Voltar para o app
            </Button>
          </Row>
        }
      />
    </Container>
  );
};

export default PageNotFound;
