import styled from "styled-components";

export const Container = styled.div`
  .page-title.conta {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    button {
      display: flex;
      align-items: center;
      height: 48px;
      padding: 0 24px;
      background-color: var(--velotax-background-color);
      svg {
        fill: var(--ant-primary-color);
      }
    }
  }
  p {
    width: 700px;
    margin: 0 auto 0.3rem;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--velotax-font-color-light);

    span {
      cursor: pointer;
      font-weight: 500;
      color: var(--ant-primary-color);
    }
  }

  h4 {
    color: var(--velotax-font-color-light);
  }

  .ant-descriptions:last-of-type {
    .ant-descriptions-item {
      padding-bottom: 0;
    }
  }

  .ant-descriptions {
    h4,
    .ant-descriptions-item-content {
      color: var(--velotax-font-color-dark);
    }
    .ant-descriptions-header {
      margin-bottom: 0px;
    }
  }

  .excluir-conta-container {
    width: 700px;
    margin: 16px auto 0 auto;
  }

  .parametrizar-custos-container {
    width: 700px;
    margin: 0px auto;
    button { 
      color: var(--ant-primary-color);
      background-color: var(--velotax-background-color);
      padding: 0.5rem 1rem;
      transition: .3s;
      
      &:hover{
        color: var(--ant-primary-color-hover);
        transition: .3s;
      }

      svg {
        margin-right: 0.5rem;
        transform: translateY(-1px);
      }
    }
  }

  .cancelar-plano-container {
    width: 700px;
    margin: -32px auto 32px;
  }

  .content-button {
    display: flex;
    margin: 16px auto 0;
    align-items: center;
    justify-content: right;
  }

  .velotax-content.conta {
    background-color: var(--white);
  }

  @media only screen and (max-device-width: 812px) {
    .page-title.conta {
      margin-bottom: 80px;
      button {
        left: 0;
        width: 100%;
        bottom: -64px;
        position: absolute;
      }
    }
    p {
      margin: 0 24px 24px;
      width: calc(100% - 48px);
    }
    .excluir-conta-container {
      width: 100%;
      padding: 0 8px;
    }
    .cancelar-plano-container {
      width: 100%;
      padding: 0 8px;
      margin: -12px auto 24px;
    }
  }
`;
