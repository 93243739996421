import { Button, ButtonProps } from "antd";
import styled, { css } from "styled-components";

export interface IButtonProps extends ButtonProps {
  typed?: "underlined";
}

export const ButtonStyled = styled(Button)<IButtonProps>`
  &.ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    border: 0;
    font-weight: 500;
    column-gap: 8px;
  }
  &[disabled] {
    border-color: var(--velotax-ghost) !important;
    color: var(--velotax-font-color-ghost) !important;
    background-color: var(--velotax-ghost) !important;
  }
  ${(props) =>
    props.type === "primary" &&
    css`
      color: var(--black);
      svg {
        fill: var(--black);
      }
      :focus,
      :hover {
        color: var(--black);
      }
    `}

  ${(props) =>
    props.size === "large" &&
    css`
      height: 56px;
    `}
  
  ${(props) =>
    props.disabled &&
    css`
      svg {
        fill: var(--velotax-font-color-ghost) !important;
      }
    `}

  ${(props) =>
    props.typed === "underlined" &&
    css`
      color: var(--white);
      border-bottom: 1px solid var(--ant-primary-color);
      :focus,
      :hover {
        border-bottom: 1px solid var(--ant-primary-color-active);
      }
    `}
`;
