import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 64px 32px 128px;
  @media only screen and (max-device-width: 812px) {
    padding: 0;
  }
`;
export const Content = styled.div`
  padding: 32px;
  min-width: 40vw;
  max-width: 500px;
  border-radius: 16px;
  background-color: var(--velotax-background-color);

  h2 {
    font-size: 24px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 4px;
      top: 0;
      left: -32px;
      height: 100%;
      background-color: var(--ant-primary-color);
    }
  }
  .last-update {
    letter-spacing: 1px;
  }
  div.ant-typography {
    font-size: 18px;
  }
  .ant-typography {
    color: var(--velotax-font-color);
  }
  @media only screen and (max-device-width: 812px) {
    padding: 24px;
    border-radius: 0;
    min-height: calc(100vh - 64px);
    h2 {
      &::before {
        left: -24px;
      }
    }
  }
`;
