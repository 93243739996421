import React from "react";
import { Upload } from "antd";
import { UploadProps } from "antd/lib/upload";
import { LoadingOutlined } from "@ant-design/icons";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { Container } from "./styles";

export interface IUploadProps extends UploadProps {
  loading?: boolean;
  descText?: string;
  customText?: string;
  tabIndex?:number;
}

const UploadVelotax: React.FC<IUploadProps> = ({
  loading,
  descText,
  disabled,
  customText = "Arraste ou clique",
  ...props
}) => {
  return (
    <Container>
      <Upload
        disabled={loading || disabled}
        className="income-report-uploader"
        {...props}
      >
        {loading ? (
          <div className="loading-container">
            <LoadingOutlined />
            <span>Isso pode demorar alguns minutos, aguarde</span>
          </div>
        ) : (
          <div className="upload-text-icon">
            <AiOutlineCloudUpload size={28} />
            {customText}
            {descText && <span className="upload-text-desc">{descText}</span>}
          </div>
        )}
      </Upload>
    </Container>
  );
};

export default UploadVelotax;
