import { Typography } from "antd";
import { HiCheck } from "react-icons/hi";
import { useNavigate } from "react-router";
import { Container } from "./styles";
import Button from "../../components/Button";

export const Sucesso = () => {
  const navigate = useNavigate();
  return (
    <Container className="velotax-container">
      <div className="velotax-content velotax-content-transparent">
        <div className="success">
          <HiCheck />
        </div>
        <Typography.Title level={1}>Sucesso!</Typography.Title>
        <Typography.Paragraph>
          Agora você já consegue acessar todos os módulos da plataforma e emitir
          DARFs ilimitados sem custo adicional.
        </Typography.Paragraph>
        <Button
          size="large"
          type="primary"
          onClick={() => {
            navigate("/carteira");
          }}
        >
          Continuar
        </Button>
        {/* <div className="expert-xp">
          <Typography.Text>
            Você ganhou um cupom de desconto para adquirir seu ingresso Expert
            XP 2023.
          </Typography.Text>
          <Typography.Text>
            Acesse{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.expertxp.com.br/"
            >
              www.expertxp.com.br
            </a>{" "}
            e utilize o cupom <strong>"VelotaxNaExpertXP"</strong>.
          </Typography.Text>
        </div> */}
      </div>
    </Container>
  );
};
