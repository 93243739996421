import styled from "styled-components";

export const IRPFBannerContainer = styled.div`
  display: none;
  align-items: center;
  background-color: #000058;
  padding: 16px 16px 16px 320px;
  user-select: none;
  &.show {
    display: flex;
  }
  h1 {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0 16px 0 0;
    color: var(--white);
  }
  .banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 680px;
    min-width: 680px;
    .ant-btn-primary {
      height: 48px;
      color: #fff;
      border-radius: 8px;
      border-color: #1634ff;
      background-color: #1634ff;
      padding-top: 6.4px !important;
      :hover {
        color: #fff;
        border-color: #4060ff;
        background-color: #4060ff;
      }
      :active {
        border-color: #000cb3;
        background-color: #000cb3;
      }
    }
  }
  .ant-btn-text {
    width: 48px;
    height: 48px;
    display: flex;
    margin-left: 16px;
    align-items: center;
    justify-content: center;
    svg {
      width: 24px;
      height: 24px;
      fill: #fff !important;
    }
  }
  @media only screen and (max-device-width: 812px) {
    position: relative;
    padding: 16px 8px 16px 24px;
    h1 {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    .banner {
      margin: 0;
      max-width: calc(100% - 52px);
      min-width: calc(100% - 52px);
    }
    .ant-btn-text {
      margin-left: 4px;
    }
  }
`;