import clsx from "clsx";
import { Tooltip } from "antd";
import { PayDarfButtonContainer } from "./styles";

interface PayDarfButtonProps {
  checked?: boolean;
  onChange?: () => void;
  checkedChildren?: React.ReactNode;
  unCheckedChildren?: React.ReactNode;
}

export const PayDarfButton: React.FC<PayDarfButtonProps> = ({
  checked,
  onChange,
  checkedChildren,
  unCheckedChildren,
}) => {
  return (
    <Tooltip
      title={checked ? "" : "Clique para sinalizar se o imposto foi pago"}
    >
      <PayDarfButtonContainer className={clsx({ checked })} onClick={onChange}>
        {checked ? checkedChildren : unCheckedChildren}
      </PayDarfButtonContainer>
    </Tooltip>
  );
};
