import { Col, Row } from "antd";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { useNotasCorretagem } from "./useNotasCorretagem";
import { useBroker } from "../../../contexts/BrokerContext";
import UploadVelotax from "../../../components/UploadVelotax";

interface NotasCorretagemProps {}

export const NotasCorretagem: React.FC<NotasCorretagemProps> = () => {
  const navigate = useNavigate();
  const { xtageIntegration } = useBroker();
  const { sendNotas, loadingSend, uploadProps } = useNotasCorretagem();

  return (
    <>
      <UploadVelotax loading={loadingSend} {...uploadProps} />
      <Row justify="end" gutter={[16, 16]}>
        {xtageIntegration && (
          <Col>
            <Button
              size="large"
              color="secondary"
              variant="text"
              onClick={() => navigate("/xpinvestimentos/cripto")}
            >
              Voltar
            </Button>
          </Col>
        )}
        <Col>
          <Button
            size="large"
            color="secondary"
            variant="contained"
            disabled={loadingSend}
            onClick={() => sendNotas()}
          >
            Enviar
          </Button>
        </Col>
      </Row>
    </>
  );
};
