import styled from "styled-components";

export const Container = styled.nav`
  display: none;
  bottom: 0px;
  width: 100%;
  height: 64px;
  position: fixed;
  background-color: var(--velotax-background-color);
  border-top: 1px solid var(--velotax-background-color-ghost);

  .hidden {
    display: none;
  }

  .ant-menu {
    width: 100%;
    display: flex;
    &.ant-menu-horizontal {
      border-bottom: none;
    }
    .ant-menu-item {
      width: calc(100% / 5);
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .ant-menu-title-content {
        opacity: 0.8;
        margin: 6px 0 0;
        font-size: 13px;
        line-height: 13px;
        color: var(--velotax-font-color);
      }
      .ant-menu-item-icon {
        width: 24px;
        height: 24px;
        opacity: 0.7;
        fill: var(--velotax-font-color);
      }
      &.ant-menu-item-active {
        .ant-menu-title-content {
          opacity: 1;
          font-weight: 500;
          color: var(--ant-primary-color);
        }
        .ant-menu-item-icon {
          opacity: 1;
          fill: var(--ant-primary-color);
        }
      }
      &.finalizar {
        .ant-menu-title-content {
          margin: 4px 0 0;
        }
        .ant-menu-item-icon {
          width: 26px;
          height: 26px;
        }
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    display: flex;
  }
`;
