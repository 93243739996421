import { Button } from "@mui/material";
import { Col, Modal, Row } from "antd";

export function ModalConfirmMail({
  action,
  closeFn,
  visible,
}: {
  action: (sendMail: boolean) => void;
  visible: boolean;
  closeFn: () => void;
}) {
  function handleClick(send: boolean) {
    action(send);
    closeFn();
  }

  return (
    <Modal
      title="Enviar por e-mail"
      visible={visible}
      onCancel={() => handleClick(false)}
      closable={true}
      footer={
        <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
          <Col xs={24} sm={12}>
            <Button
              fullWidth
              size="large"
              color="secondary"
              variant="contained"
              onClick={() => {
                handleClick(true);
              }}>
              Sim
            </Button>
          </Col>
          <Col xs={24} sm={12}>
            <Button
              fullWidth
              size="large"
              color="secondary"
              variant="contained"
              onClick={() => {
                handleClick(false);
              }}>
              Não
            </Button>
          </Col>
        </Row>
      }>
      <Row>
        <Col>Você deseja receber também por e-mail o documento?</Col>
      </Row>
    </Modal>
  );
}
