import { Divider, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

export enum MeiReportDasStatusEnum {
  EMITTED = "EMITTED",
  REQUESTED = "REQUESTED",
}

export enum MeiReportStatusTextEnum {
  EMITTED = "Emitido",
  REQUESTED = "Solicitado",
}

export enum MeiDasStatusTextEnum {
  EMITTED = "Guia Emitida",
  REQUESTED = "Guia Solicitada",
}

export enum MeiDasStatusColorEnum {
  EMITTED = "var(--ant-success-color)",
  REQUESTED = "var(--ant-primary-color)",
}

export const DasHelpModal = {
  title: "Lista de guias MEI (DAS)",
  content: (
    <div>
      <Typography.Paragraph>
        Confirme manualmente sempre que um DAS for pago com sucesso. Esta
        funcionalidade te ajuda a manter o controle dos seus pagamentos
        realizados.
      </Typography.Paragraph>
      <Divider />
      <Typography.Text strong italic>
        <InfoCircleOutlined />
        &nbsp; Atenção: a confirmação de pagamento é feita manualmente pelo
        usuário. O Velotax não realiza este monitoramento automaticamente.
      </Typography.Text>
    </div>
  ),
};
