import styled, { css } from "styled-components";

export const Container = styled.div`
  height: calc(100vh - 64px);
  overflow: hidden;
`;

export const QuestionContainer = styled.div<{ width?: string | number }>`
  display: flex;
  height: 100%;
  overflow-y: auto;
  align-items: center;
  flex-direction: column;
  h2 {
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.5rem;
    position: relative;
    color: var(--velotax-text-color);
    margin: 64px auto 0;
    padding: 0 0 1rem;
    ${({ width }) =>
      width
        ? css`
            width: ${width} !important;
          `
        : ""}
    &::after {
      content: "";
      left: 0;
      bottom: 0;
      width: 100px;
      height: 2px;
      position: absolute;
      background-color: var(--ant-primary-color);
    }
  }

  @media only screen and (max-width: 812px) {
    padding: 32px 0 0;
    h2 {
      width: 100% !important;
      padding: 0 24px 12px;
      margin: 0;
      &::after {
        left: 24px;
      }
    }
  }
`;

export const Question = styled.div<{ width?: string | number }>`
  height: fit-content;
  padding: 32px;
  margin: 24px 0 96px;
  border-radius: 4px;
  background-color: var(--velotax-background-color);
  border: 1px solid var(--velotax-background-color-ghost);
  ${({ width }) =>
    width
      ? css`
          width: ${width} !important;
        `
      : ""}
  h2 {
    font-size: 24px;
    padding-bottom: 12px;
    font-weight: 400;
    position: relative;
    color: var(--velotax-text-color);
    margin: 32px 0 0;
    &::after {
      content: "";
      left: 0;
      bottom: 0;
      width: 100px;
      height: 2px;
      position: absolute;
      background-color: var(--ant-primary-color);
    }
  }
  @media only screen and (min-width: 1025px) {
    width: 48%;
  }
  @media only screen and (max-width: 1024px) {
    width: 72% !important;
    padding: 32px;
    h2 {
      &::before {
        left: -32px;
      }
    }
  }
  @media only screen and (max-width: 812px) {
    width: 100% !important;
    padding: 24px 24px 48px;
    border: none;
    border-radius: 0;
    margin: 24px 0 0;
    h2 {
      padding: 0 0 12px;
      &::before {
        left: -24px;
      }
    }
    .ant-descriptions {
      .ant-descriptions-item-content {
        font-size: 12px;
      }
      .ant-descriptions-item-label {
        width: 30%;
        font-size: 12px;
      }
    }
  }
`;
