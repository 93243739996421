import { Modal } from "antd";
import styled from "styled-components";

export const PlanModalStyled = styled(Modal)`
  min-width: 760px;
  .ant-modal-body {
    padding: 32px !important;
  }
  @media only screen and (max-width: 812px) {
    width: calc(100vw - 32px);
    min-width: unset;
  }
`;

export const Content = styled.div`
  display: flex;
  column-gap: 16px;
  position: relative;
  justify-content: center;
  flex-direction: row;
  @media only screen and (max-width: 812px) {
    row-gap: 16px;
    flex-direction: column;
  }
`;

export const Loading = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 8px;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: var(--velotax-shadow-default);
`;
