import styled from "styled-components";
import GovBrIcon from "../../assets/govbr.svg";

export const Container = styled.div`
  min-height: 100%;
  padding: 64px 32px;
  background-color: var(--velotax-ghost);

  @media only screen and (max-device-width: 812px) {
    padding: 0;
    background-color: var(--velotax-background-color);
  }
`;

export const Content = styled.div`
  padding: 48px;
  width: fit-content;
  max-width: 720px;
  margin: 0 auto;
  border-radius: 16px;
  border: 1px solid var(--velotax-background-color-ghost);
  background-color: var(--velotax-background-color);

  h1 {
    display: flex;
    align-items: center;
    margin: 0 0 24px;
    font-size: 22px;
    line-height: 30px;
    font-weight: bold;
    position: relative;
    color: var(--velotax-font-color);

    .gov-logo {
      width: 64px;
      height: 40px;
      margin-left: 8px;
      display: inline-block;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url(${GovBrIcon});
    }

    button {
      display: inline-flex;
      height: 32px !important;
      svg {
        width: 20px;
        height: 20px;
      }
    }

    &::before {
      content: "";
      top: 0;
      width: 4px;
      left: -48px;
      height: 100%;
      position: absolute;
      background-color: var(--ant-primary-color);
    }
  }

  h5 {
    margin-bottom: 24px;
  }

  a.ant-btn.ant-btn-primary.ant-btn-lg {
    padding-top: 6.4px !important;
  }

  div.ant-typography {
    font-size: 1rem;
  }

  .upload-dec-info {
    margin: 24px 0 16px;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: var(--ant-primary-color);
    svg {
      width: 20px;
      height: 20px;
      margin: 0 0 -2px 4px;
      fill: var(--ant-primary-color);
    }
  }

  .ant-divider {
    margin: 24px 0 0;
  }

  .skip-btn {
    padding: 0;
    margin: 0 0 -16px auto;
    svg {
      margin: 0;
    }
  }

 

  .ant-steps {
    margin-top: 24px;
    .ant-steps-item:nth-of-type(3),
    .ant-steps-item:nth-of-type(4) {
      .ant-steps-item-container {
        .ant-steps-item-content {
          padding-bottom: 48px;
        }
      }
    }
   
    .ant-steps-item {
      
      &.ant-steps-item-wait {
        .ant-steps-item-title {
          opacity: 0.8;
        }
      }
      &.ant-steps-item-active {
        .ant-steps-item-title {
          background-color: red;
          font-weight: 500;
          color: var(--velotax-font-color) !important;
        }
      }
      .ant-steps-item-container {
        .ant-steps-item-content {
          padding-bottom: 24px;
          .ant-steps-item-title {
          }
        }
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    width: 100%;
    border: none;
    border-radius: 0;
    padding: 32px 24px 96px;
    h1 {
      font-size: 1.2rem;
      letter-spacing: -0.35px;
      .gov-logo {
        width: 58px;
        height: 38px;
        margin-left: 6px;
      }
      button {
        margin-top: -4px;
        height: 40px !important;
      }
    }
    .skip-btn {
      bottom: 24px;
      font-size: 13px;
      position: fixed;
      margin: 0;
    }
  }
`;

export const DecUploadContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  .dec-icon {
    width: 48px;
    height: 48px;
    font-size: 24px;
    margin: 0 0 16px;
    border-radius: 8px;
    color: var(--ant-primary-color);
    border: 2px solid var(--ant-primary-color);
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    svg {
      margin-top: -4px;
    }
    &:before {
      content: "";
      height: 2px;
      bottom: 8px;
      position: absolute;
      width: calc(100% - 24px);
      background-color: var(--ant-primary-color);
    }
  }
  span.ant-typography {
    font-size: 16px;
    font-weight: 500;
    max-width: 320px;
    color: var(--ant-primary-color);
  }
`;
