import clsx from "clsx";
import moment from "moment";
import { Button } from "@mui/material";
import { FaRegEdit } from "react-icons/fa";
import { BsCalendar3, BsDownload } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { AiOutlineDelete, AiOutlineLock } from "react-icons/ai";
import { useCallback, useEffect, useMemo, useState, ReactNode } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Col,
  Collapse,
  DatePicker,
  Descriptions,
  List,
  message,
  Modal,
  Row,
  Skeleton,
  Space,
  Spin,
  Tooltip,
  Typography,
} from "antd";
import { Container, Content } from "./styles";
import { Page } from "../../constants/brokers";
import AntButton from "../../components/Button";
import { useAuth } from "../../contexts/AuthContext";
import { FormModal } from "../../components/FormModal";
import { apiPayment } from "../../services/apiPayment";
import { useBroker } from "../../contexts/BrokerContext";
import apis, { NOT_AUTHORIZED } from "../../services/apis";
import { PaymentModal } from "../../components/PaymentModal";
import { IntegrationModal } from "./Components/IntegrationModal";
import { DatePickerExtraFooter } from "./Components/DatePickerExtraFooter";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import {
  DatePickerMonthCell,
  YearResume,
} from "./Components/DatePickerMonthCell";
import {
  antDatePickerLocale,
  download,
  errorMessage,
  formatCurrency,
  monthsExtended,
} from "../../utils";
import {
  DarfResultDescriptions,
  DarfFormItemRows,
  defaultDarf,
  DarfModal,
  IDarf,
  DarfImpostosDevidosDescriptions,
  minDarfPrice,
  maxDarfPrice,
  YearResumeStatusFromBackEnum,
  yearConfigPL4173,
} from "../../constants/darf";

interface DarfProps {
  item: Page;
  view?: boolean;
  viewEdit?: boolean;
  closeModal?: () => void;
  darf?: {
    id: string;
    fullPath?: string;
  };
  date?: {
    year?: number;
    month?: number;
  };
}

export const Darf: React.FC<DarfProps> = ({
  item,
  view,
  date,
  darf,
  viewEdit,
  closeModal,
}) => {
  const {
    user,
    hasPlan: hasPremiunPlan,
    showUserPlanModal,
    userPlanModal,
  } = useAuth();
  const hasPlan = hasPremiunPlan;

  const navigate = useNavigate();
  const { state } = useLocation();
  const { currentBroker, currentPage } = useBroker();
  const today = new Date();
  const queryYear = (state as any)?.year as number;
  const queryMonth = (state as any)?.month as number;
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const maxPixPayment = 10000;
  const maxCreditCardPayment = 10000;

  const initialMonth = queryMonth
    ? queryMonth - 1
    : today.getMonth() - 1 >= 0
    ? today.getMonth() - 1
    : 11;
  const initialYear = queryYear
    ? queryYear
    : today.getMonth() - 1 >= 0
    ? today.getFullYear()
    : today.getFullYear() - 1;
  const defaultValue = moment({ month: initialMonth, year: initialYear });
  const currentDate = moment({
    month: currentMonth,
    year: currentYear,
  }).subtract(1, "M");
  window.history.replaceState({}, document.title);

  const year = date?.year ?? initialYear;
  const month = date?.month ?? initialMonth;
  const oldModel = year < yearConfigPL4173;

  const [asset, setAsset] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [emitting, setEmitting] = useState(false);
  const [darfModal, setDarfModal] = useState<any>();
  const [helpModal, setHelpModal] = useState<any>();
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentData, setPaymentData] = useState<IDarf>();
  const [, setYear] = useState(initialYear);
  const [, setMonth] = useState(initialMonth);
  const [data, setData] = useState<IDarf>(defaultDarf);
  const [showAssetModal, setShowAssetModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [yearResume, setYearResume] = useState<YearResume[]>([]);
  const [gettingYearResume, setGettingYearResume] = useState(false);
  const [datePickerYear, setDatePickerYear] = useState(initialYear);
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [showNotAuthorizedModal, setShowNotAuthorizedModal] = useState(false);
  const [loadingDarfButton, setLoadingDarfButton] = useState<boolean>(false);

  const transactionFeature = item.features[0];
  const taxFeature = item.features[1];
  const yearResumeFeature = item.features[2];

  // const userPlanInfoStatus = useMemo(
  //   () => user.user.userPlanInfoVelotax,
  //   [user.user.userPlanInfoVelotax]
  // );

  const { emitted } = useMemo(() => {
    if (gettingYearResume) {
    }
    const monthResume = yearResume
      .find((resume) => resume.year === year)
      ?.months.find((m) => m.month - 1 === month);
    return {
      emitted:
        monthResume?.status === YearResumeStatusFromBackEnum.PAYED ||
        monthResume?.status === YearResumeStatusFromBackEnum.NOT_PAYED,
      payed: !!monthResume?.payed,
      status: monthResume?.status,
    };
  }, [yearResume, year, month, gettingYearResume]);

  const getDarf = useCallback(
    (id: string) => {
      view && setLoading(true);
      view &&
        (currentPage?.api || apis)
          .get(`${taxFeature.apiUrl}/${id}`)
          .then((response) => {
            setYear(response.data?.year);
            setMonth(response.data?.month - 1);
            setData({
              ...response.data,
              emitted: true,
            });
          })
          .catch(() => message.error(errorMessage))
          .finally(() => setLoading(false));
    },
    [view, taxFeature.apiUrl, currentPage]
  );

  const getYearResume = useCallback(
    (year: number, force?: boolean, url?: string) => {
      setGettingYearResume(true);
      !view &&
        year <= new Date().getFullYear() &&
        year >= currentBroker.initialYear &&
        (force ||
          (!yearResumeFeature.disabled &&
            !yearResume.find((resume) => resume.year === year))) &&
        (currentPage?.api || apis)
          .get(yearResumeFeature.apiUrl, { params: { year } })
          .then((response) => {
            const index = yearResume.findIndex(
              (resume) => resume.year === year
            );
            if (index >= 0) {
              setYearResume((yearResume) => {
                yearResume.splice(index, 1, response.data);
                return yearResume;
              });
            } else {
              setYearResume((yearResume) => [...yearResume, response.data]);
            }

            if (url) {
              download(url);
            }
          })
          .catch((err) => console.log(err))
          .finally(() => setGettingYearResume(false));
    },
    [
      yearResumeFeature,
      yearResume,
      view,
      currentBroker.initialYear,
      currentPage,
    ]
  );

  const getTaxes = useCallback(() => {
    (!hasPlan || !view) && setLoading(true);
    (!hasPlan || !view) &&
      (currentPage?.api || apis)
        .get(transactionFeature.apiUrl, { params: { month: month + 1, year } })
        .then((response) => {
          setData(response.data);
          setNotAuthorized(false);
        })
        .catch((err) => {
          setData(defaultDarf);
          if (err?.response?.data === NOT_AUTHORIZED) {
            setNotAuthorized(true);
            setShowNotAuthorizedModal(true);
          } else {
            setNotAuthorized(false);
            message.error(errorMessage);
          }
        })
        .finally(() => setLoading(false));
  }, [view, currentPage?.api, transactionFeature.apiUrl, month, year, hasPlan]);

  const handleDarf = (justSave?: boolean) => {
    setEmitting(true);
    onCloseDarfModal();
    (currentPage?.api || apis)
      .post(taxFeature.apiUrl, {
        ...data,
        year,
        month: month + 1,
        name: user.user.name,
        memoriaCalculo: [],
        transactions: data.memoriaCalculo,
        paymentInfo: {
          clientInfo: {
            name: user?.user?.name,
            email: user?.user?.email,
            document: user?.user?.cpf,
          },
        },
        insertPix: true,
        justSave,
        payed: false,
      })
      .then((res) => {
        setData((data) => ({ ...data, emitted: true }));
        getYearResume(year, true, justSave ? "" : res.data.darfUrl);
        setEmitting(false);
        closeModal?.();
        if (!justSave) {
          message.success("O DARF foi enviado para o seu e-mail");
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || errorMessage);
        setEmitting(false);
      });
  };

  const handleRegularize = (regular: boolean) => {
    setEmitting(true);
    (currentPage?.api || apis)
      .post("transaction/regularize", {
        year,
        month: month + 1,
        regular,
        impostoDevido: data.impostoDevido,
        totalImpostoDevido: data.totalImpostoDevido,
      })
      .then((res) => {
        getYearResume(year, true, res.data.darfUrl);
        getTaxes();
        message.success("Dados salvos");
        handleNavigateToHistoric();
      })
      .catch(() => message.error(errorMessage))
      .finally(() => setEmitting(false));
  };

  const onChangeMonth = (value: moment.Moment | null, dateString: string) => {
    const [month, year] = dateString.split("-");
    setMonth(parseInt(month) - 1);
    setYear(parseInt(year));
  };

  const unsetAsset = () => {
    setAsset(undefined);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    unsetAsset();
  };

  const handleCloseAssetModal = () => {
    setShowAssetModal(false);
    unsetAsset();
  };

  const onCloseHelpModal = () => {
    setHelpModal(undefined);
  };

  const onCloseDarfModal = () => {
    setDarfModal(undefined);
  };

  const handleOpenDarfModal = () => {
    setDarfModal(DarfModal(emitted, onCloseDarfModal, () => handleDarf()));
  };

  const handleOpenPaymentModal = () => {
    setPaymentModal(!paymentModal);
  };

  const handleAdd = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    setShowAssetModal(true);
  };

  const handleEdit = (item: any, index: number) => {
    setShowAssetModal(true);
    setAsset({
      ...item,
      data: moment.utc(item.data.toLocaleString()).format("DD/MM/YYYY"),
      dataVenda: moment
        .utc(item?.dataVenda?.toLocaleString())
        ?.format("DD/MM/YYYY"),
      dataCompra: moment
        .utc(item?.dataCompra?.toLocaleString())
        ?.format("DD/MM/YYYY"),
    });
  };

  const handleRemove = (item: any, index: number) => {
    setShowDeleteModal(true);
    setAsset(item);
  };

  const addAsset = (asset: any) => {
    setLoading(true);
    (currentPage?.api || apis)
      .post(`${transactionFeature.apiUrl}?manual=true`, asset, {
        headers: {
          "x-email": user.user.email,
        },
      })
      .then(() => {
        getTaxes();
        getYearResume(year, true);
      })
      .catch(() => message.error(errorMessage))
      .finally(() => {
        setLoading(false);
        handleCloseAssetModal();
      });
  };

  const editAsset = (data: any) => {
    setLoading(true);
    (currentPage?.api || apis)
      .put(`${transactionFeature.apiUrl}/${data.id}`, data)
      .then(() => {
        getTaxes();
        getYearResume(year, true);
      })
      .catch(() => message.error(errorMessage))
      .finally(() => {
        setLoading(false);
        handleCloseAssetModal();
      });
  };

  const removeAsset = () => {
    setLoading(true);
    (currentPage?.api || apis)
      .delete(`${transactionFeature.apiUrl}/${asset.id}`)
      .then(() => {
        getTaxes();
        getYearResume(year, true);
      })
      .catch(() => message.error(errorMessage))
      .finally(() => {
        setLoading(false);
        handleCloseDeleteModal();
      });
  };

  const handlePlanModal = () => {
    navigate('/planos')
  };

  const handleSaveMonth = async () => {
    setLoadingDarfButton(true);
    try {
      const retPlanInfo = await apiPayment.get("/user-plan/plan-info");
      const hasExteriorCalcActive =
        retPlanInfo?.data?.permissions?.["crypto-xpinvestimentos"];

      retPlanInfo.data.active || hasExteriorCalcActive
        ? handleDarf(true)
        : handlePlanModal();
    } catch (err) {
      console.error(err);
    }
    setLoadingDarfButton(false);
  };

  const handleEventDarfButton = async () => {
    setLoadingDarfButton(true);
    let retPlanInfo = {
      data: {
        active: false,
      },
    };
    try {
      retPlanInfo = await apiPayment.get("/user-plan/plan-info");
    } catch (err) {
      console.error(err);
    }
    setLoadingDarfButton(false);

    !retPlanInfo.data.active ? handlePlanModal() : handleOpenPaymentModal();
  };

  const handleLabelButton: ReactNode = useMemo(() => {
    // if (!userPlanInfoStatus) return "Assinar Plano Emissão de DARF";

    if (data?.totalImpostoDevido <= maxDarfPrice) return "Pagar DARF";
    else {
      if (emitted) return "Pagar DARF";
      else return taxFeature?.buttonLabel;
    }
  }, [data?.totalImpostoDevido, emitted, taxFeature?.buttonLabel]);

  const downloadAction = (record: any) => {
    (currentPage?.api || apis)
      .get(`/darf/download/${record._id}`)
      .then((res) => {
        if (res.data.url) {
          download(res.data.url);
        }
      });
  };

  const handleNavigateToHistoric = () => {
    navigate(item.settings?.historicUrl ?? `/${currentBroker.path}/historic`, {
      state: { year },
    });
  };

  useEffect(() => {
    getTaxes();
  }, [getTaxes, month, year]);

  useEffect(() => {
    if (view && darf) {
      getDarf(darf.id);
    }
  }, [getDarf, view, darf]);

  useEffect(() => {
    getYearResume(year);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const dataToPayment = {
      ...data,
      year,
      month: month + 1,
      name: user.user.name,
      memoriaCalculo: [],
      transactions: data.memoriaCalculo,
    };

    setPaymentData(dataToPayment);
  }, [data.totalImpostoDevido, data, month, user.user.name, year]);

  const impostoCharge = useMemo(() => {
    const taxes = (data.impostoDevido ?? 0) + (data.impostoAcumulado ?? 0)
    const isExceedTax = taxes < minDarfPrice;
    const additionalTax = oldModel ? data.multa + data.juros : 0;
    return !view
      ? taxes +
          (isExceedTax ? additionalTax : 0)
      : Math.max(
        taxes +
            (isExceedTax ? additionalTax : 0),
          0
        );
  }, [data, view, oldModel]);

  return (
    <Container className={clsx({ view, "view-edit": viewEdit })}>
      <Content className={clsx("first", { view, "view-edit": viewEdit })}>
        <Space direction="vertical" className="full-width">
          <Row gutter={[16, 16]} align="middle" justify="space-between">
            <Col xs={24} sm={16}>
              <Typography.Title level={3} className="date-title">
                <BsCalendar3 />
                {!view && !viewEdit && (
                  <>
                    {currentBroker.initialYear === year && month === 0 ? (
                      <div className="month-chevron empty" />
                    ) : (
                      <BiChevronLeft
                        className="month-chevron"
                        onClick={() => {
                          if (month === 0) {
                            setYear((year: number) => {
                              const newYear = year - 1;
                              getYearResume(newYear);
                              setDatePickerYear(newYear);
                              return newYear;
                            });
                            setMonth(11);
                          } else {
                            setMonth((month) => month - 1);
                          }
                        }}
                      />
                    )}
                  </>
                )}
                {monthsExtended[month]} de {year}
                {!view && !viewEdit && (
                  <>
                    {currentYear === year && currentMonth - 1 === month ? (
                      <div className="month-chevron empty" />
                    ) : (
                      <BiChevronRight
                        className="month-chevron"
                        onClick={() => {
                          if (month === 11) {
                            setYear((year) => {
                              const newYear = year + 1;
                              getYearResume(newYear);
                              setDatePickerYear(newYear);
                              return newYear;
                            });
                            setMonth(0);
                          } else {
                            setMonth((month) => month + 1);
                          }
                        }}
                      />
                    )}
                  </>
                )}
              </Typography.Title>
            </Col>
            {!view && !viewEdit && (
              <Col xs={24} sm={8}>
                <DatePicker
                  size="large"
                  picker="month"
                  format="MM-YYYY"
                  disabled={loading}
                  allowClear={false}
                  inputReadOnly={true}
                  onChange={onChangeMonth}
                  suffixIcon={<BsCalendar3 />}
                  defaultValue={defaultValue}
                  locale={antDatePickerLocale}
                  value={moment({ year: datePickerYear, month })}
                  onSelect={(e) => {
                    if (e.month() === month && year !== datePickerYear) {
                      onChangeMonth(e, `${month + 1}-${datePickerYear}`);
                    }
                  }}
                  onPanelChange={(e) => {
                    getYearResume(e.year());
                    setDatePickerYear(e.year());
                  }}
                  getPopupContainer={(trigger) => trigger.parentElement!}
                  renderExtraFooter={() =>
                    yearResumeFeature.disabled ? (
                      <></>
                    ) : (
                      <DatePickerExtraFooter />
                    )
                  }
                  disabledDate={(current) =>
                    current &&
                    (current > currentDate.endOf("month") ||
                      current < moment(`${currentBroker.initialYear}-01-01`) ||
                      current < moment(`2020-01-01`))
                  }
                  monthCellRender={(e) => (
                    <DatePickerMonthCell event={e} yearResume={yearResume} />
                  )}
                />
              </Col>
            )}
          </Row>
        </Space>
      </Content>
      <Content className={clsx("second", { view, "view-edit": viewEdit })}>
        <Space direction="vertical" className="full-width">
          {hasPlan && view && darf?.fullPath && (
            <Row justify="end">
              <Col>
                <Button
                  variant="text"
                  color="secondary"
                  style={{ marginTop: "-32px" }}
                  onClick={() => downloadAction(darf)}
                  startIcon={
                    <BsDownload
                      size={16}
                      style={{
                        fill: "var(--ant-primary-color)",
                      }}
                    />
                  }
                >
                  BAIXAR DARF
                </Button>
              </Col>
            </Row>
          )}

          <div style={{ position: "relative" }}>
            {!hasPlan && (
              <div className="no-plan-container">
                <Typography>
                  Contrate o plano premium para ver os impostos devidos
                </Typography>
                <AntButton
                  type="primary"
                  icon={<AiOutlineLock size={18} />}
                  onClick={() => {
                    handlePlanModal();
                    closeModal?.();
                  }}
                >
                  PREMIUM
                </AntButton>
              </div>
            )}

            <Descriptions size="small" bordered column={1}>
              {DarfImpostosDevidosDescriptions.map((description) => (
                <Descriptions.Item
                  key={description.id}
                  label={description.label(setHelpModal)}
                >
                  {loading ? (
                    <Spin />
                  ) : description.render ? (
                    description.render(data, view)
                  ) : (
                    formatCurrency(
                      Number(data[description.id as keyof IDarf] || 0)
                    )
                  )}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </div>

          <br />

          <Collapse
            ghost
            bordered={false}
            defaultActiveKey={!hasPlan ? ["1"] : []}
            expandIconPosition="right"
            {...(!hasPlan ? { collapsible: "disabled" } : {})}
          >
            <Collapse.Panel
              key="1"
              header={
                <Typography.Title level={3}>
                  Vendas realizadas no mês
                </Typography.Title>
              }
            >
              {!notAuthorized && !view && (
                <Button
                  className="add"
                  color="secondary"
                  variant="contained"
                  disabled={loading}
                  onClick={handleAdd}
                  startIcon={<PlusOutlined />}
                >
                  Adicionar
                </Button>
              )}
              <List
                loading={loading}
                itemLayout="horizontal"
                dataSource={data.memoriaCalculo}
                renderItem={(item, index) => (
                  <List.Item
                    actions={
                      notAuthorized || view
                        ? []
                        : [
                            <AntButton
                              type="text"
                              onClick={() => handleEdit(item, index)}
                              icon={<FaRegEdit size={18} />}
                            />,
                            <AntButton
                              type="text"
                              onClick={() => handleRemove(item, index)}
                              icon={<AiOutlineDelete size={18} />}
                            />,
                          ]
                    }
                  >
                    <Skeleton title={false} loading={item.loading} active>
                      <List.Item.Meta
                        title={
                          <strong>
                            {item.ativoTranslated
                              ? item.ativoTranslated
                              : item.ativo}
                          </strong>
                        }
                        description={
                          <div className="list-description">
                            <div>
                              Data de liquidação:{" "}
                              {moment
                                .utc(item?.data?.toLocaleString())
                                .format("DD/MM/YYYY")}
                            </div>
                            {/* <div>
                              Data de liquidação:{" "}
                              <strong>
                                {formatCurrency(
                                  item.ganho ??
                                    item.quantidade *
                                      (item.precoVenda - item.custoCompra) -
                                      item.taxas ??
                                    0
                                )}
                              </strong>
                            </div> */}
                            {item.quantidade && (
                              <div>Quantidade: {item.quantidade}</div>
                            )}
                            {view && (
                              <>
                                <div>
                                  Valor de venda:{" "}
                                  <strong>
                                    {formatCurrency(item.precoVenda)}
                                  </strong>
                                </div>
                                <div>
                                  Valor de compra:{" "}
                                  <strong>
                                    {formatCurrency(item.custoCompra)}
                                  </strong>
                                </div>
                                <div>
                                  Taxas:{" "}
                                  <strong>{formatCurrency(item.taxas)}</strong>
                                </div>
                              </>
                            )}
                            <div>Ganho: {formatCurrency(item.ganho)}</div>
                          </div>
                        }
                      />
                    </Skeleton>
                  </List.Item>
                )}
              />
            </Collapse.Panel>
          </Collapse>

          {hasPlan && <br />}

          {hasPlan && (
            <div
              className="total-tax-header original"
              style={{ width: "100%" }}
            >
              <Typography.Title level={3}>Imposto total</Typography.Title>
              <Typography.Title level={3} className="text-center">
                {loading ? (
                  <Spin style={{ marginRight: "32px" }} />
                ) : (
                  formatCurrency(impostoCharge)
                )}
              </Typography.Title>
            </div>
          )}

          {hasPlan && (
            <Descriptions size="small" bordered column={1}>
              {DarfResultDescriptions.map((description, i) => {
                if(['impostoAcumulado', 'multa', 'juros'].includes(description.id) && !oldModel) return <></>
                return (
                  <Descriptions.Item
                    key={description.id}
                    label={description.label(setHelpModal)}
                    style={{
                      display: i === 3 ? "none" : "table-cell",
                    }}
                  >
                    {loading ? (
                      <Spin />
                    ) : description.Component ? (
                      <description.Component
                        data={data}
                        year={year}
                        month={month}
                        loading={loading}
                        setDataCripto={setData}
                        url={transactionFeature.apiUrl}
                        disabled={!notAuthorized && !view}
                        view={view}
                      />
                    ) : (
                      formatCurrency(
                        Number(data[description.id as keyof IDarf] || 0)
                      )
                    )}
                  </Descriptions.Item>
                )
              })}
            </Descriptions>
          )}

          {!notAuthorized && !view && (
            <>
              <Row
                justify="end"
                gutter={[16, 16]}
                className="column-reverse"
                style={{ marginTop: "16px" }}
              >
                {!(month === currentMonth && year === currentYear) &&
                  (data.impostoDevido + data.impostoAcumulado ?? 0) <
                    minDarfPrice &&
                  data.memoriaCalculo.filter(
                    (el: any) =>
                      (el.operation === "SELL" &&
                        (el.type === "TRADE" || el.type === "WITHDRAW")) ||
                      el.type === "INTEREST"
                  )?.length > 0 && (
                    <Col xs={24}>
                      <br />
                      <Tooltip
                        title={
                          <>
                            O valor do imposto devido é menor que{" "}
                            {formatCurrency(minDarfPrice)}. Você não precisa
                            emitir um DARF para este mês.
                          </>
                        }
                      >
                        <Button
                          fullWidth
                          size="large"
                          color="secondary"
                          variant="contained"
                          disabled={loading || emitting}
                          onClick={() => handleRegularize(true)}
                          startIcon={emitting && <LoadingOutlined />}
                        >
                          Salvar sem emitir DARF
                        </Button>
                      </Tooltip>
                    </Col>
                  )}
                {data.impostoDevido + data.impostoAcumulado >= minDarfPrice && (
                  <Col xs={24}>
                    <br />
                    <Tooltip
                      getPopupContainer={(target) => target.parentElement!}
                      title={
                        month === currentMonth && year === currentYear
                          ? "Aguarde o encerramento do mês para pagar seu DARF"
                          : ""
                      }
                    >
                      <Button
                        fullWidth
                        size="large"
                        color="secondary"
                        variant="contained"
                        onClick={handleEventDarfButton}
                        startIcon={
                          (emitting || loadingDarfButton) && <LoadingOutlined />
                        }
                        disabled={
                          loading ||
                          emitting ||
                          loadingDarfButton ||
                          (month === currentMonth && year === currentYear)
                        }
                      >
                        {handleLabelButton}
                      </Button>{" "}
                    </Tooltip>
                  </Col>
                )}
              </Row>
              {!(month === currentMonth && year === currentYear) && (
                <Row
                  justify="end"
                  align="middle"
                  gutter={[16, 16]}
                  style={{ marginTop: "16px" }}
                >
                  <Col>
                    <Button
                      size="small"
                      variant="text"
                      color="secondary"
                      onClick={handleSaveMonth}
                      disabled={loading || emitting}
                      startIcon={emitting && <LoadingOutlined />}
                    >
                      Salvar apenas
                    </Button>
                  </Col>
                </Row>
              )}
            </>
          )}
          {!view && false && (
            <>
              <IntegrationModal
                visible={showNotAuthorizedModal}
                onCancel={() => setShowNotAuthorizedModal(false)}
              />
              <div className="juridic-messages-container">
                <div className="message">
                  <p>
                    (1) Os cálculos de imposto de renda foram realizados levando
                    em consideração somente as operações de compra e venda
                    realizadas na Xtage. Caso você tenha realizado operações com
                    criptoativos em outras corretoras, realize as alterações
                    manualmente.
                  </p>
                </div>
                <div className="message">
                  <p>
                    (2) Caso você tenha realizado a transferência de custódia de
                    outras corretoras de criptoativos para a Xtage, realize
                    manualmente a inserção do custo médio de compra dos
                    respectivos ativos transferidos.
                  </p>
                </div>
                <div className="message">
                  <p>
                    (3) O Usuário é exclusivamente responsável pela conferência
                    e validação das informações utilizadas na apuração do
                    imposto devido, conforme{" "}
                    <a
                      rel="noreferrer"
                      href="https://velotax-termos-e-condicoes.s3.us-east-1.amazonaws.com/Termos%20de%20Uso%20-%20Velotax%20v1.pdf"
                      target="_blank"
                    >
                      Termos e Condições Gerais de Uso.
                    </a>
                  </p>
                </div>
              </div>
            </>
          )}
        </Space>
      </Content>

      <Modal
        footer={null}
        visible={helpModal}
        title={helpModal?.title}
        onCancel={onCloseHelpModal}
      >
        {helpModal?.content}
      </Modal>
      <Modal
        visible={darfModal}
        title={darfModal?.title}
        footer={darfModal?.footer}
        onCancel={onCloseDarfModal}
        zIndex={1001}
      >
        {darfModal?.content}
      </Modal>
      <FormModal
        add={addAsset}
        edit={editAsset}
        itemToEdit={asset}
        visibility={showAssetModal}
        onCancel={handleCloseAssetModal}
        rows={DarfFormItemRows(month, year)}
        title={asset ? "Editar ativo" : "Adicionar ativo"}
      />
      <PaymentModal
        item={item}
        fromDarf
        show={paymentModal}
        emitting={emitting}
        disablePixOption={data?.totalImpostoDevido > maxPixPayment}
        disableCreditCardOption={
          data?.totalImpostoDevido > maxCreditCardPayment
        }
        onCancel={handleOpenPaymentModal}
        paymentData={{
          ...paymentData,
          impostoTotalFinal: data.totalImpostoDevido + data.impostoAcumulado,
        }}
        callDarf={() => {
          // HandleTag("67");
          handleOpenDarfModal();
        }}
        maxPixValue={maxPixPayment}
        maxCCValue={maxCreditCardPayment}
        selectedYear={year}
      />
      <DeleteConfirmationModal
        title="Remover ativo"
        visibility={showDeleteModal}
        onCancel={handleCloseDeleteModal}
        setVisibility={setShowDeleteModal}
        onOk={() => Promise.resolve(removeAsset())}
        body="Você realmente quer deletar esse ativo?"
      />
    </Container>
  );
};
