import styled from "styled-components";

export const LogoStyled = styled.div`
  height: 96px;
  background-color: var(--velotax-background-color);
  .ant-image {
    width: 100%;
    height: 96px;
    display: flex;
    align-items: center;
    img {
      width: auto;
      height: 48px;
      margin: 0 auto;
    }
  }

  @media only screen and (max-device-width: 812px) {
    height: 64px;
    #velo-xp.ant-image {
      height: 64px;
      justify-content: center;
      img {
        margin: 0 !important;
        width: 44px !important;
        height: 40px !important;
      }
    }
  }
`;
