import React, { createContext, useContext, useEffect, useState } from "react";

import PermissaoAssessorModal from "../components/PermissaoAssessorModal";
import { useAuth } from "./AuthContext";
import apiVeloPro from "../services/apiVeloPro";
import { message } from "antd";

interface IRequireAccessContext {
  checkPermissionUser: () => void;
  changePermission: (
    token: string,
    authorize?: boolean,
    preventNotification?: boolean
  ) => void;
  escritorioLiberados: any;
  assessoresLiberados: any;
  solicitantesAcesso: any;
  loading: boolean;
}

export const RequireAccessContext = createContext<IRequireAccessContext>(
  {} as IRequireAccessContext
);

export const RequireAccessProvider: React.FC = ({ children }) => {
  const [escritorioLiberados, setEscritorioLiberados] = useState<any[]>([]);
  const [assessoresLiberados, setAssessoresLiberados] = useState<any[]>([]);
  const [solicitantesAcesso, setSolicitantesAcesso] = useState<any[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const solicitacaoModal = solicitantesAcesso.find(
    (s) => s.notified === false && s.isAuthorized === false
  );

  const checkPermissionUser = () => {
    const { cpf } = user.user;

    setLoading(true);
    apiVeloPro
      .get(`/clients/checkpermission?cpf=${cpf}`)
      .then(({ data }) => {
        setEscritorioLiberados(data?.escritorioId || []);
        setAssessoresLiberados(data?.assessorId || []);

        const listRequiredAccess: any = [];
        for (const solicitantes of data?.code || []) {
          const escritorio = data?.escritorioId?.find(
            (e: any) => e._id === solicitantes?.escritorioId
          );

          const assessor = data?.assessorId?.find((e: any) => {
            return e.escritorioId === solicitantes?.escritorioId;
          });

          listRequiredAccess.push({
            ...solicitantes,
            escritorio,
            assessor,
          });
        }
        setSolicitantesAcesso(listRequiredAccess);
        setShowModal(listRequiredAccess.length > 0 ? true : false);
      })
      .catch(() =>
        message.error("Ocorreu um erro, tente novamente mais tarde.")
      )
      .finally(() => setLoading(false));
  };

  const changePermission = (
    token: string,
    authorize?: boolean,
    preventNotification?: boolean
  ) => {
    setLoading(true);
    apiVeloPro
      .post("/clients/changePermission", {
        cpf: user.user.cpf,
        token,
        authorize,
        preventNotification,
      })
      .finally(() => checkPermissionUser());
  };
  const allowAccessOfficeAssessor = ({ token }: { token: string }) => {
    // apiVeloPro.post("/clients/allowAccessOfficeAssessor", {
    //   cpf: user.user.cpf,
    //   token,
    // });
  };

  const handleOk = ({ token }: { token: string }) => {
    allowAccessOfficeAssessor({ token });
    setShowModal(false);
  };
  const handleCancel = () => setShowModal(false);

  useEffect(() => {
    if (user.user) {
      checkPermissionUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.user]);

  return (
    <RequireAccessContext.Provider
      value={{
        changePermission,
        checkPermissionUser,
        assessoresLiberados,
        escritorioLiberados,
        solicitantesAcesso,
        loading,
      }}
    >
      {children}
      {solicitacaoModal && (
        <PermissaoAssessorModal
          dataContent={{ code: solicitacaoModal, user: user.user }}
          onCancel={handleCancel}
          onOk={handleOk}
          visible={showModal}
        />
      )}
    </RequireAccessContext.Provider>
  );
};

export const useRequireAccess = (): IRequireAccessContext =>
  useContext(RequireAccessContext);
