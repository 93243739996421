import { useEffect } from "react";
import { Typography } from "antd";
import { Container, Content } from "./styles";
import { Page } from "../../constants/brokers";
// import { NotasCorretagem } from "./NotasCorretagem";
import { useAuth } from "../../contexts/AuthContext";
import { useBroker } from "../../contexts/BrokerContext";
import { BackButton } from "../../components/BackButton";
import { B3IntegrationButtons } from "./B3IntegrationButtons";
import { useB3Integration } from "../../contexts/B3IntegrationContext";

interface IntegrationProps {
  item: Page;
}

export const IntegrationBolsa: React.FC<IntegrationProps> = () => {
  const { integration } = useBroker();
  const { interval, handleIntegrate } = useB3Integration();
  const { user, b3Authorized, cameFromRegister, setCameFromRegister } =
    useAuth();

  useEffect(() => {
    if (
      user.user &&
      !b3Authorized &&
      !interval.current &&
      cameFromRegister.bolsa &&
      integration.key !== "unset"
    ) {
      setCameFromRegister((data) => ({ ...data, bolsa: false }));
      handleIntegrate();
    }
  }, [
    interval,
    user.user,
    b3Authorized,
    handleIntegrate,
    integration.key,
    setCameFromRegister,
    cameFromRegister.bolsa,
  ]);

  return (
    <Container>
      <Typography.Title level={1} className="page-title">
        {b3Authorized
          ? "Conectado com sua conta na B3"
          : "Conecte com sua conta na B3"}
      </Typography.Title>
      <Content>
        <Typography.Paragraph className="destak">
          Seus impostos são calculados automaticamente após a conexão
        </Typography.Paragraph>
        <B3IntegrationButtons showIntegrationButton={true} />
      </Content>
      {/* <NotasCorretagem /> */}
      <div className="juridic-messages-container max-width">
        {/* <div className="message">
          <p>
            (1) A Receita Federal admite a dedução dos custos de corretagem na
            apuração do ganho líquido em operações de renda variável.
            Entretanto, não há obrigatoriedade do usufruto do benefício da
            dedução por parte dos investidores (Instrução Normativa RFB nº
            1.585, de 31 de agosto de 2015, art. 56, § 3º). As informações
            advindas somente da integração com a B3 não consideram o benefício
            da dedução dos custos de corretagem da XP, incluindo somente as
            tarifas de liquidação, registro, termo/opções e emolumentos da B3.
            Caso deseje descontar os seus custos de corretagem dos ganhos
            tributáveis, você pode adicionar manualmente as taxas de corretagem
            em cada operação no campo “Taxas” ou carregar suas notas de
            corretagem.
          </p>
        </div> */}
        <BackButton to="/xpinvestimentos/bolsa" className="integration-bolsa" />
      </div>
    </Container>
  );
};
