import clsx from "clsx";
import moment from "moment";
import { BiCheck } from "react-icons/bi";
import { GrUpdate } from "react-icons/gr";
import { BsDownload } from "react-icons/bs";
import { AiOutlineLock } from "react-icons/ai";
import { Fragment, useEffect, useMemo, useState } from "react";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Col,
  Collapse,
  Modal,
  Row,
  Typography,
  message,
  Select,
  Tooltip,
  Checkbox,
} from "antd";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
} from "recharts";
import Button from "../../components/Button";
import { DrawerModal } from "../DrawerModal";
import apiBolsa from "../../services/apiBolsa";
import AntButton from "../../components/Button";
import HandleTag from "../../services/handleTag";
import apiVeloPro from "../../services/apiVeloPro";
import { useAuth } from "../../contexts/AuthContext";
import apiExterior from "../../services/apiExterior";
import { ReportTypeDrawer } from "../ReportType/ReportType";
import { Posicao, useCarteira } from "../../contexts/CarteiraContext";
import { chartColors, getProviderColor } from "../../utils/walletCharts";
import { BlurContent, Container, Content, IsentCardStyled } from "./styles";
import { ReactComponent as FiltroIcon } from "../../assets/icons/filtro.svg";
import { getNomeCorretora, serializePatrimonio } from "../../utils/patrimonio";
import {
  vendasIsentas,
  vendasTotaisExt,
} from "../../pages/HistoricBolsa/utils";
import {
  ExpandIcon,
  HidenValue,
  LucroPrejuizo,
  PosicaoCard,
} from "./PosicaoCard";
import {
  download,
  downloadPDF,
  formatCurrency,
  isMobile,
  numberToCurrency,
} from "../../utils";
import { useNavigate } from "react-router-dom";

type ChartType = "classe" | "posicoes" | "corretora";

interface CarteiraProps {
  hideValues: boolean;
}

const Months: any = {
  0: "Janeiro",
  1: "Fevereiro",
  2: "Março",
  3: "Abril",
  4: "Maio",
  5: "Junho",
  6: "Julho",
  7: "Agosto",
  8: "Setembro",
  9: "Outubro",
  10: "Novembro",
  11: "Dezembro",
};

export const Carteira: React.FC<CarteiraProps> = ({ hideValues }: any) => {
  const {
    user,
    showUserPlanModal,
    hasPlan: hasPremiunPlan,
    hasPermissionExterior,
    hasPermissionGeneral,
  } = useAuth();
  const hasPlan =
    (hasPremiunPlan || hasPermissionExterior || hasPermissionGeneral)
    && !user?.user?.userPlanInfoVelotax?.type?.includes('BASIC');
  const { carteira, lastUpdate, loading, getCarteira, getPatrimonios, updateFilter, posicoesFilter, setPosicoesFilter } =
    useCarteira();

  const navigate = useNavigate();
  const [helpModal, setHelpModal] = useState<any>();
  const [loadingReport, setLoadingReport] = useState(false);
  const [chartType, setChartType] = useState<ChartType>("classe");
  const [classesAtivosChart, setClassesAtivosChart] = useState<any>();
  const [corretorasAtivosChart, setCorretorasAtivosChart] = useState<any>();
  const [ativosChart, setAtivosChart] = useState<any>();
  const [vendasBolsa, setVendasBolsa] = useState<any>(0);
  const [corretoras, setCorretoras] = useState<string[]>([]);
  const [corretorasSemFiltro, setCorretorasSemFiltro] = useState<string[]>([]);
  const [vendasExterior, setVendasExterior] = useState<any>(0);
  const [checkboxCorretoras, setCheckboxCorretoras] = useState<string[]>([]);
  const [selectedCorretoras, setSelectedCorretoras] = useState<string[]>([]);
  const [reportDrawerWallet, setReportDrawerWallet] = useState(false);
  const [vendasRestantesBolsa, setVendasRestantesBolsa] = useState<any>(0);
  const [filterCorretoraAtivo, setFilterCorretoraAtivo] = useState<string[]>(
    []
  );
  const [vendasRestantesExterior, setVendasRestantesExterior] =
    useState<any>(0);
  const [showCorretoraFilterModal, setShowCorretoraFilterModal] =
    useState(false);
  const [showPosicoesFilterModal, setShowPosicoesFilterModal] = useState(false);
  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  const [, setDetailedActiveKeys] = useState<string[]>([]);
  const [detailedLoadingIndex, setDetailedLoadingIndex] = useState<
    number | undefined
  >(undefined);
  const [detailedPositions, setDetailedPositions] = useState<any[]>([]);

  const checkAll = corretoras.length === checkboxCorretoras.length;
  const indeterminate =
    checkboxCorretoras.length > 0 &&
    checkboxCorretoras.length < corretoras.length;

  const onCloseHelpModal = () => setHelpModal(undefined);

  const largeChart =
    [
      ...(chartType === "classe"
        ? classesAtivosChart || []
        : chartType === "corretora"
          ? corretorasAtivosChart || []
          : ativosChart || []),
    ].length > 7;

  const carteiraCategorizada = useMemo(
    () =>
      (carteira?.posicoesSemFiltro || []).reduce(
        (acc, cur) => ({
          ...acc,
          [cur.categoria]: [...(acc[cur.categoria] || []), cur],
        }),
        {} as { [key: string]: Posicao[] }
      ) || {},
    [carteira.posicoesSemFiltro]
  );
  const carteiraCategorizadaFiltered = useMemo(
    () =>
      (carteira?.posicoes || []).reduce(
        (acc, cur) => ({
          ...acc,
          [cur.categoria]: [...(acc[cur.categoria] || []), cur],
        }),
        {} as { [key: string]: Posicao[] }
      ) || {},
    [carteira.posicoes]
  );

  const patrimonioTotalFiltrado = useMemo(
    () =>
      carteira?.posicoes?.reduce(
        (acc, cur) =>
          filterCorretoraAtivo.includes(cur.corretora)
            ? acc + cur.posicao
            : acc,
        0
      ) || 0,
    [carteira?.posicoes, filterCorretoraAtivo]
  );

  const retornoFiltrado = useMemo(
    () =>
      carteira?.posicoes?.reduce(
        (acc, cur) =>
          filterCorretoraAtivo.includes(cur.corretora)
            ? {
                lucroPrejuizoDiario:
                  acc.lucroPrejuizoDiario + cur.lucroPrejuizoDiario,
                lucroPrejuizoDiarioPercentualNumerator:
                  acc.lucroPrejuizoDiarioPercentualNumerator +
                  (cur.categoria === "TesouroDireto"
                    ? 0
                    : cur.resumo?.lucroPrejuizoDiarioPercentualNumerator),
                lucroPrejuizoDiarioPercentualDenominator:
                  acc.lucroPrejuizoDiarioPercentualDenominator +
                  (cur.categoria === "TesouroDireto"
                    ? 0
                    : cur.resumo?.lucroPrejuizoDiarioPercentualDenominator),
                lucroPrejuizoTotal:
                  acc.lucroPrejuizoTotal + cur.lucroPrejuizoTotal,
                lucroPrejuizoTotalPercentualNumerator:
                  acc.lucroPrejuizoTotalPercentualNumerator +
                  cur.resumo?.lucroPrejuizoTotalPercentualNumerator,
                lucroPrejuizoTotalPercentualDenominator:
                  acc.lucroPrejuizoTotalPercentualDenominator +
                  cur.resumo?.lucroPrejuizoTotalPercentualDenominator,
              }
            : acc,
        {
          lucroPrejuizoDiario: 0,
          lucroPrejuizoDiarioPercentualNumerator: 0,
          lucroPrejuizoDiarioPercentualDenominator: 0,
          lucroPrejuizoTotal: 0,
          lucroPrejuizoTotalPercentualNumerator: 0,
          lucroPrejuizoTotalPercentualDenominator: 0,
        }
      ) || {
        lucroPrejuizoDiario: 0,
        lucroPrejuizoDiarioPercentualNumerator: 0,
        lucroPrejuizoDiarioPercentualDenominator: 0,
        lucroPrejuizoTotal: 0,
        lucroPrejuizoTotalPercentualNumerator: 0,
        lucroPrejuizoTotalPercentualDenominator: 0,
      },
    [carteira?.posicoes, filterCorretoraAtivo]
  );

  const onChangeActiveKeys = (keys: string | string[]) => {
    const isOpenEvent = keys.length > activeKeys.length;
    const lastOpened = keys[keys.length - 1];

    setActiveKeys(keys as string[]);
    let codigo =
      lastOpened?.replaceAll(" ", "").split("-")[3] ||
      lastOpened?.replaceAll(" ", "").split("-")[2];
    let market =
      lastOpened?.replaceAll(" ", "").split("-")[0] ||
      lastOpened?.replaceAll(" ", "").split("-")[1];
    let index = lastOpened?.replaceAll(" ", "").split("-")[1];
    if (
      lastOpened.includes("Imobiliário") ||
      lastOpened.includes("Traded") ||
      lastOpened.includes("Brazilian")
    ) {
      codigo = lastOpened?.replaceAll(" ", "").replace("-", "").split("-")[2];
      index = lastOpened?.replaceAll(" ", "").replace("-", "").split("-")[1];
    }

    if (lastOpened && isOpenEvent) {
      setDetailedActiveKeys((detailedActiveKeys) => {
        if (
          !detailedActiveKeys.includes(lastOpened) &&
          keys.length > detailedActiveKeys.length
        ) {
          setDetailedLoadingIndex(Number(index));
          apiBolsa
            .get(`/xpinvestimentos/detalied/${codigo}`, {
              params: {
                exterior:
                  market.toLowerCase() === "exterior" ? true : undefined,
              },
            })
            .then(({ data }: any) => {
              setDetailedPositions((positions) => [
                ...positions,
                {
                  index,
                  codigo: codigo,
                  details: data,
                },
              ]);
              return [...detailedActiveKeys, lastOpened];
            })
            .finally(() => {
              setDetailedLoadingIndex(undefined);
            });
        }
        return detailedActiveKeys;
      });
    }
  };

  useEffect(() => {
    getCarteira();
  }, [getCarteira]);

  useEffect(() => {
    apiBolsa
      .get("/velotax/historic", {
        params: {
          month: new Date().getMonth() + 1,
        },
      })
      .then((res) => {
        const isentas = vendasIsentas(res?.data);
        setVendasBolsa(isentas);
        setVendasRestantesBolsa(20000 - isentas);
      });
  }, []);

  useEffect(() => {
    apiExterior
      .get("/velotax/exterior/historic", {
        params: {
          month: new Date().getMonth() + 1,
        },
      })
      .then((res) => {
        const isentas = vendasTotaisExt(res?.data)
        setVendasExterior(isentas);
        setVendasRestantesExterior(35000 - isentas);
      });
  }, []);

  useEffect(() => {
    const { classesAtivos, patrimonio, posicoes, corretoras, corretorasSemFiltro } =
      serializePatrimonio(carteira, selectedCorretoras);
    setClassesAtivosChart(classesAtivos);
    setCorretorasAtivosChart(patrimonio);
    setAtivosChart(posicoes);
    setCorretoras(corretoras);
    setCorretorasSemFiltro(corretorasSemFiltro)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, selectedCorretoras]);

  const downloadAction = async (type: string) => {
    try {
      setLoadingReport(true);
      if (type === "pdf") {
        const queryCorretoras =
          filterCorretoraAtivo?.length > 0
            ? `?corretoras=${JSON.stringify(filterCorretoraAtivo).replace(
                /\]|"|\[/g,
                ""
              )}`
            : "";
        const res = await apiBolsa.get(
          `/xpinvestimentos/wallet/report${queryCorretoras}`
        );
        if (res.data.file && !isMobile()) {
          downloadPDF(res.data.file, "Meus investimentos");
        } else if (res.data.fileUrl && !isMobile()) {
          download(res.data.fileUrl);
        }
        message.success("O relatório foi enviado para o seu e-mail");
      } else if (type === "xlsx") {
        if (filterCorretoraAtivo?.length > 0) {
          const res = await apiVeloPro.post(
            "/clients/downloadRelatorioPatrimonioXlsx",
            {
              username: user.user.cpf,
              corretoras: filterCorretoraAtivo,
            }
          );
          if (!isMobile()) download(res?.data?.fileUrl);
          message.success("O relatório foi enviado para o seu e-mail");
        } else {
          const data = await getPatrimonios(user?.user?.cpf);
          if (!isMobile()) download(data.fileUrl);
          message.success("O relatório foi enviado para o seu e-mail");
        }
      }
    } catch (err: any) {
      message.error(
        `Não foi possível baixar o relatório de investimentos. Tente novamente mais tarde`
      );
    } finally {
      setLoadingReport(false);
    }
  };

  const renderLegend = (value: string, entry: any) => {
    return <span>{value}</span>;
  };

  const renderIsentCard = (categoria: string) => {
    if (categoria === "Ações") {
      const vendas = categoria === "Ações" ? vendasBolsa : vendasExterior;
      const restante =
        categoria === "Ações" ? vendasRestantesBolsa : vendasRestantesExterior;
      const limit = categoria === "Ações" ? 20000 : 35000;
      const tipVendas = vendas
        ? `R$ ${String(
            vendas.toLocaleString("pt-br", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          )}`
        : "R$ 0,00";
      const complete = vendas ? (vendas / limit) * 100 : 0;

      return (
        <IsentCardStyled>
          {restante <= 0 ? (
            <Typography.Text className="overviewTitleIsent">
              O seu limite de vendas isentas foi atingido neste mês
            </Typography.Text>
          ) : (
            <Typography.Text className="overviewTitleIsent">
              Você ainda possui{" "}
              <span style={{ fontWeight: "700" }}>
                {formatCurrency(restante)}{" "}
              </span>{" "}
              em vendas isentas em{" "}
              <span style={{ fontWeight: "700" }}>
                {Months[new Date().getMonth()]} {new Date().getFullYear()}
              </span>
            </Typography.Text>
          )}
          <div className="progress_container">
            <Tooltip
              title={`Vendas no mês: ${tipVendas}`}
              getPopupContainer={(target) => target.parentElement!}
            >
              <div
                className="loading_bar"
                style={{
                  width: `${complete >= 100 ? 100 : complete}%`,
                  borderBottomRightRadius: complete >= 100 ? "50px" : 0,
                  borderTopRightRadius: complete >= 100 ? "50px" : 0,
                }}
              >
                <div className="progress_container_text_align_center">
                  {vendas > 5000 ? formatCurrency(vendas) : ""}
                </div>
              </div>
            </Tooltip>
          </div>
          {/* </Tooltip> */}
        </IsentCardStyled>
      );
    }
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    payload,
  }: any) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    let fill = chartColors.find(
      (colors) => colors.background === payload.fill
    )?.label;
    if (payload.fill === "#FCC102") fill = "#5a4709";

    return (
      <text
        x={x}
        y={y}
        fill={fill}
        textAnchor="middle"
        dominantBaseline="central"
        fontSize={10}
        style={{ margin: "10px" }}
      >
        {percent * 100 > 5 && `${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <Container>
      {/* <Typography.Title level={1} className="page-title">
                Meus investimentos
                {hasPlan && (
                    <AntButton
                        type="link"
                        size="large"
                        className="download-report-btn"
                        icon={!loadingReport ? <BsDownload /> : <LoadingOutlined />}
                        onClick={() => downloadAction()}
                    />
                )}
                <AntButton
                    type="link"
                    size="large"
                    className="hide-values-btn"
                    icon={hideValues ? <BsEyeSlash /> : <BsEye />}
                />
            </Typography.Title> */}
      {/* <Toolbar>
        <StatusBar />
      </Toolbar> */}
      <Content className="velotax-content">
        <div className="container-btn">
          <AntButton
            type="text"
            size="large"
            icon={<FiltroIcon />}
            className="download-report-btn filter-btn"
            onClick={() => setShowCorretoraFilterModal(true)}
          >
            Filtrar
          </AntButton>
          {hasPlan && <Tooltip
            title="Baixar Relatório de investimentos"
            getPopupContainer={(target) => target.parentElement!}
          >
            <AntButton
              type="text"
              size="large"
              className="download-report-btn"
              icon={loadingReport ? <LoadingOutlined /> : <BsDownload />}
              onClick={() => setReportDrawerWallet(true)}
            />
          </Tooltip>}
        </div>
        <Row align="middle" className="patrimonio">
          <Col xs={24} sm={14}>
            <Typography.Title level={2}>Investimento total:</Typography.Title>
            <Typography.Title level={1} style={{ color: "white" }}>
              {loading ? (
                <LoadingOutlined />
              ) : !hideValues ? (
                formatCurrency(
                  filterCorretoraAtivo.length > 0
                    ? patrimonioTotalFiltrado
                    : carteira.patrimonioTotal
                )
              ) : (
                <HidenValue />
              )}
            </Typography.Title>
          </Col>
          <Col xs={24} sm={10} className="lucro-prejuizo">
            <Row>
              <Col xs={12} sm={24}>
                <Typography.Paragraph>
                  Retorno diário:{" "}
                  {loading ? (
                    <LoadingOutlined />
                  ) : !hideValues ? (
                    <LucroPrejuizo
                      n={
                        filterCorretoraAtivo.length > 0
                          ? retornoFiltrado.lucroPrejuizoDiario
                          : carteira.lucroPrejuizoDiario
                      }
                      p={
                        filterCorretoraAtivo.length > 0
                          ? retornoFiltrado.lucroPrejuizoDiarioPercentualNumerator /
                            retornoFiltrado.lucroPrejuizoDiarioPercentualDenominator
                          : carteira.lucroPrejuizoDiarioPercentual
                      }
                    />
                  ) : (
                    <HidenValue block strong />
                  )}
                </Typography.Paragraph>
              </Col>
              <Col xs={12} sm={24}>
                <Typography.Paragraph>
                  Retorno total:{" "}
                  {loading ? (
                    <LoadingOutlined />
                  ) : !hideValues ? (
                    <LucroPrejuizo
                      n={
                        filterCorretoraAtivo.length > 0
                          ? retornoFiltrado.lucroPrejuizoTotal
                          : carteira.lucroPrejuizoTotal
                      }
                      p={
                        filterCorretoraAtivo.length > 0
                          ? retornoFiltrado.lucroPrejuizoTotalPercentualNumerator /
                            retornoFiltrado.lucroPrejuizoTotalPercentualDenominator
                          : carteira.lucroPrejuizoTotalPercentual
                      }
                    />
                  ) : (
                    <HidenValue block strong />
                  )}
                </Typography.Paragraph>
              </Col>
            </Row>
          </Col>
        </Row>
        <ReportTypeDrawer
          open={reportDrawerWallet}
          loading={loadingReport}
          setType={(type: string) => downloadAction(type)}
          onClose={() => setReportDrawerWallet(false)}
        />
      </Content>
      <Content className={clsx("velotax-content", { largeChart })}>
        {loading ? (
          <Row justify="center">
            <LoadingOutlined />
          </Row>
        ) : carteira?.posicoes?.length > 0 ? (
          <>
            {!loading ? (
              <ResponsiveContainer width="100%">
                <>
                  <PieChart
                    {...(isMobile()
                      ? {
                          width: 300,
                          height: 260,
                        }
                      : {
                          width: 240,
                          height: 240,
                        })}
                  >
                    <Legend
                      align="right"
                      layout="vertical"
                      iconType="square"
                      verticalAlign="middle"
                      formatter={renderLegend}
                    />
                    <Pie
                      cx={isMobile() ? 100 : 115}
                      cy="50%"
                      dataKey="value"
                      fill="#1634FF"
                      innerRadius={isMobile() ? 40 : 50}
                      outerRadius={isMobile() ? 100 : 120}
                      labelLine={false}
                      legendType="circle"
                      label={renderCustomizedLabel}
                      data={
                        chartType === "classe"
                          ? classesAtivosChart
                          : chartType === "posicoes"
                          ? ativosChart
                          : corretorasAtivosChart
                      }
                    >
                      {(chartType === "classe"
                        ? classesAtivosChart
                        : chartType === "posicoes"
                        ? ativosChart
                        : corretorasAtivosChart
                      )?.map((entry: any, index: number) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={getProviderColor(entry.name, index)}
                        />
                      ))}
                    </Pie>
                    <RechartsTooltip
                      formatter={(value, _, { payload }) => {
                        return `${numberToCurrency(Number(value))} (${String(
                          payload.percentage
                        ).replace(".", ",")}%)`;
                      }}
                    />
                  </PieChart>
                  <div className="select-container">
                    <Select
                      size="large"
                      value={chartType}
                      onChange={(value) => setChartType(value)}
                      getPopupContainer={(target) => target.parentElement!}
                    >
                      <Select.Option value="classe">
                        Por classe de ativo
                      </Select.Option>
                      <Select.Option value="corretora">
                        Por corretora
                      </Select.Option>
                      <Select.Option value="posicoes">Por ativo</Select.Option>
                    </Select>
                  </div>
                </>
              </ResponsiveContainer>
            ) : undefined}
            {!hasPlan ? (
              <>
                <Collapse
                  expandIconPosition="right"
                  expandIcon={({ isActive }) => ExpandIcon({ isActive })}
                >
                  {carteira.posicoes.slice(0, 1).map((posicao, index) => (
                    <Fragment key={index}>
                      {PosicaoCard({
                        key: index.toString(),
                        posicao,
                        hideValues,
                        setHelpModal,
                      })}
                    </Fragment>
                  ))}
                </Collapse>
                <BlurContent>
                  <Collapse
                    collapsible="disabled"
                    className="blur-content"
                    expandIconPosition="right"
                    expandIcon={({ isActive }) => ExpandIcon({ isActive })}
                  >
                    {Array.from(Array(3).keys()).map((index) => (
                      <Fragment key={index}>
                        {PosicaoCard({
                          key: index.toString(),
                          hideValues,
                          setHelpModal,
                          posicao: {
                            lucroPrejuizoDiario: 0.01,
                          },
                        })}
                      </Fragment>
                    ))}
                  </Collapse>
                  <div className="blur-content-title">
                    Contrate o plano premium para ver a lista completa de ativos
                    <AntButton
                      type="primary"
                      icon={<AiOutlineLock size={18} />}
                      onClick={() => {
                        // showUserPlanModal(true);
                        navigate('/planos')
                        HandleTag("54");
                      }}
                    >
                      PREMIUM
                    </AntButton>
                  </div>
                </BlurContent>
              </>
            ) : (
              <Collapse
                expandIconPosition="right"
                defaultActiveKey={Object.keys(carteiraCategorizadaFiltered)}
                expandIcon={({ isActive }) => ExpandIcon({ isActive })}
              >
                {(filterCorretoraAtivo.length > 0
                  ? Object.keys(carteiraCategorizadaFiltered).filter(
                    (categoria) =>
                      carteiraCategorizadaFiltered[categoria].filter((item: any) =>
                        filterCorretoraAtivo.includes(item?.corretora)
                      ).length > 0
                  )
                  : Object.keys(carteiraCategorizadaFiltered)
                ).map((categoria) => (
                  <Collapse.Panel
                    key={categoria}
                    className="ativos-categoria"
                    header={
                      <>
                        <Typography.Title level={2}>
                          {categoria === "TesouroDireto"
                            ? "Tesouro Direto"
                            : categoria}
                        </Typography.Title>
                        <span className="collapse-title-divider" />
                        <span className="collapse-title-hidden" />
                      </>
                    }
                  >
                    <Collapse
                      activeKey={activeKeys}
                      expandIconPosition="right"
                      expandIcon={({ isActive }) => ExpandIcon({ isActive })}
                      onChange={onChangeActiveKeys}
                    >
                      {renderIsentCard(categoria)}
                      {(filterCorretoraAtivo.length > 0
                        ? carteiraCategorizadaFiltered[categoria].filter((item: any) =>
                          filterCorretoraAtivo.includes(item?.corretora)
                        )
                        : carteiraCategorizadaFiltered[categoria]
                      )
                        .sort(
                          (a, b) =>
                            ((b.categoria === "TesouroDireto"
                              ? b.precoAtual
                              : b.posicao) || 0) -
                            ((a.categoria === "TesouroDireto"
                              ? a.precoAtual
                              : a.posicao) || 0)
                        )
                        .map((posicao, index) => (
                          <Fragment key={`${categoria}-${index}`}>
                            {PosicaoCard({
                              key: `${categoria}-${index}-${posicao.codigo}`,
                              posicao,
                              hideValues,
                              setHelpModal,
                              cotacaoVenda:
                                categoria === "Exterior" &&
                                carteira.cotacaoVenda > 0
                                  ? carteira.cotacaoVenda
                                  : undefined,
                              details: detailedPositions?.find(
                                (el: any) =>
                                  el.codigo === posicao.codigo &&
                                  Number(el.index) === index
                              )?.details,
                              loading: detailedLoadingIndex === index,
                            })}
                          </Fragment>
                        ))}
                    </Collapse>
                  </Collapse.Panel>
                ))}
              </Collapse>
            )}
          </>
        ) : (
          <Typography className="list-empty" style={{ textAlign: "center" }}>
            Nenhuma posição encontrada
          </Typography>
        )}

        <Typography.Text className="last-update">
          <AntButton
            type="link"
            size="small"
            loading={loading}
            icon={loading ? <LoadingOutlined /> : <GrUpdate />}
            onClick={() => getCarteira(true)}
            className="button-refresh-wallet"
          />
          Atualizado em{" "}
          {moment(lastUpdate).utc().local().format("DD/MM/YYYY à[s] HH:mm")}
        </Typography.Text>

        {hasPlan && carteira.posicoes?.length > 0 && (
          <ul className="messages">
            <li>
              <InfoCircleOutlined />
              As posições são atualizadas em até quatro dias úteis após sua
              negociação (D+4)
            </li>
            <li>
              <InfoCircleOutlined />
              Bonds e/ou operações fora da bolsa de valores não são apresentadas
              na listagem acima.
            </li>
            {/* <li>
              <InfoCircleOutlined />
              Os preços dos ativos custodiados no exterior são convertidos para
              reais pela cotação do dólar de compra fixada pelo Banco Central do
              Brasil (PTAX) na data do último fechamento.
            </li>
            <li>
              <InfoCircleOutlined />O custo médio de compra de ativos no
              exterior é apresentado para fins ilustrativos de acompanhamento de
              carteira apenas. Para cálculo de imposto de renda, utilizar
              valores de aquisição considerando a origem dos recursos para
              cálculo de ganho de capital.
            </li> */}
          </ul>
        )}
      </Content>
      <Modal
        footer={null}
        visible={helpModal}
        title={helpModal?.title}
        onCancel={onCloseHelpModal}
      >
        {helpModal?.content}
      </Modal>
      <DrawerModal
        className="filter-modal"
        title="Filtrar por corretora"
        visible={showCorretoraFilterModal}
        onCancel={() => {
          setShowCorretoraFilterModal(false);
        }}
        bodyStyle={{paddingTop: '1rem'}}
        footer={
          <Button
            block
            size="large"
            type="primary"
            icon={<BiCheck />}
            onClick={() => {
              setShowCorretoraFilterModal(false);
              setSelectedCorretoras(checkboxCorretoras);
              setFilterCorretoraAtivo(
                checkboxCorretoras.map((corretora: string) =>
                  getNomeCorretora({ shortName: corretora })
                )
              );
              updateFilter(posicoesFilter || [])
            }}
          >
            Confirmar
          </Button>
        }
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Typography.Text strong>Corretoras</Typography.Text>
              </Col>
              <Col span={24}>
                <Checkbox
                  checked={checkAll}
                  indeterminate={indeterminate}
                  className="corretoras-allcheck"
                  onChange={(e: any) => {
                    setPosicoesFilter([])
                    setCheckboxCorretoras(e.target.checked ? corretorasSemFiltro : []);
                  }}
                >
                  Todas as corretoras
                </Checkbox>
                <Checkbox.Group
                  value={checkboxCorretoras}
                  onChange={(e: any) => {
                    setPosicoesFilter([])
                    setCheckboxCorretoras(e);
                  }}
                >
                  <Row gutter={[4, 4]}>
                    {corretorasSemFiltro.map((corretora, i) => (
                      <Col span={24} key={i}>
                        <Checkbox value={corretora}>{corretora}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="ant-modal-header" style={{padding: '8px 0', margin: '2rem 0'}}>
          <div className="ant-modal-title">
            Filtrar por posições
          </div>
        </div>
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%', margin: '1rem 0' }}
          placeholder="Filtrar posições"
          value={posicoesFilter}
          onChange={(e) => {
            setCheckboxCorretoras([])
            setPosicoesFilter(e)
          }}
          options={Object.keys(carteiraCategorizada).map((categoria) => ({
            label: categoria,
            options: carteiraCategorizada[categoria].map((c: any) => ({
              label: c.codigo,
              value: c.codigo,
            }))
          }))}
        />
      </DrawerModal>
    </Container>
  );
};
