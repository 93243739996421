import { Divider, Typography } from "antd";

export const CompaniesExemptList = {
    title: "Lista de empresas isentas",
    content: (<div>
        <Typography.Paragraph>
            <strong>
                Lista de ações que, se vendidas com lucro até o dia 31/12/2023, 
                são isentas de Impostos de Renda com base na lei 13.043, de 13 de novembro de 2014:
            </strong>
        </Typography.Paragraph>
        <Typography.Paragraph>
            - NUTRIPLANT (NUTR3)<br />
            - PETRORIO (PRIO3)*<br />
            - GENERAL SHOPPING (GSHP3)<br />
            - POMIFRUTAS (FRTA3)<br />
            - CR2 EMPREENDIMENTOS IMOBILIÁRIOS (CRDE3)<br />
            - SINQIA (SQIA3)*<br />
            - BRASIL AGRO (AGRO3)<br />
            - PRINER (PRNR3)
        </Typography.Paragraph>        
        <Divider />
        <Typography.Text strong italic>
            * Válido para ações compradas antes do follow-on. A Sinqia e a Petrorio 
            fizeram uma oferta de ações em setembro de 2019 e fevereiro de 2021, 
            respectivamente. Caso você tenha comprado essas ações antes das datas das 
            respectivas ofertas, você tem direito a isenção de imposto de renda sobre seus ganhos.
        </Typography.Text>
    </div>)
};