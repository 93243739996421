import styled from "styled-components";

export const Content = styled.div`
  // display: flex;
  // column-gap: 16px;
  // position: relative;
  // justify-content: center;
  // flex-direction: row;
  // @media only screen and (max-width: 812px) {
  //   row-gap: 16px;
  //   flex-direction: column;
  // }
`;
