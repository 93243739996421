import styled from "styled-components";

export const CardContainer = styled.div`
  width: 160px;
  height: 180px;
  border-radius: 8px;
  transition: opacity 0.3s ease-in-out;
  background-color: var(--velotax-background-color);
  cursor: pointer;
  position: relative;
  box-shadow: 0px 10px 20px 4px rgba(0, 0, 0, 0.2);

  &.disabled {
    cursor: default;
    :before {
      content: "Em breve!";
      top: 6px;
      left: -6px;
      position: absolute;
      color: #000;
      width: auto;
      height: auto;
      padding: 4px 8px;
      font-size: 12px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--ant-primary-color);
    }
  }

  &,
  a,
  .MuiButtonBase-root {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 8px;
  }

  a,
  .MuiButtonBase-root {
    width: 100%;
    height: 100%;
    padding: 16px;
  }

  figure {
    margin: 0 0 4px;

    svg {
      width: 32px;
      height: 32px;
      &:not(.no-fill) {
        fill: var(--ant-primary-color);
      }
    }
  }

  .ant-btn.ant-btn-primary.btn-premium {
    top: 16px;
    right: -6px;
  }

  &#irpf {
    box-shadow: none;
    animation-name: irpf-card;
    animation-duration: 1.75s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  @keyframes irpf-card {
    0% {
      box-shadow: 0px 0px 8px 4px var(--ant-primary-color-outline);
    }
    50% {
      box-shadow: 0px 0px 1px 1px var(--ant-primary-color-outline);
    }
    100% {
      box-shadow: 0px 0px 8px 4px var(--ant-primary-color-outline);
    }
  }

  .content {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: var(--velotax-font-color-light);

    .ant-typography {
      color: inherit;
      position: relative;
      text-align: left;
      text-transform: uppercase;

      .available-until {
        position: absolute;
        bottom: -24px;
        left: 8px;
        width: calc(100% - 16px);
        opacity: 0.8;
        font-weight: 300;
        font-size: 0.5625rem;
        letter-spacing: 0.25px;
        line-height: 11px;
        text-align: center;
      }
    }

    .description {
      left: 16px;
      bottom: 16px;
      position: absolute;
      opacity: 0.7;
      font-size: 13px;
      font-weight: 300;
      max-width: calc(100% - 32px);
      text-align: left;
      .ant-typography {
        text-transform: none;
      }

      .warning {
        transform: translateY(4px);
        color: var(--ant-warning-color);
        margin-right: 4px;
        font-size: 16px;
      }
    }
  }

  &.add {
    background-color: var(--velotax-background-color-ghost);
    border: 2px solid var(--velotax-background-color);
    svg {
      fill: var(--velotax-font-color-ghost);
    }
    .content {
      color: var(--velotax-font-color-ghost);
    }
  }
`;
