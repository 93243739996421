import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useBroker } from "../contexts/BrokerContext";

interface IPrivateRouteProps {}

const PrivateRoute: React.FC<IPrivateRouteProps> = () => {
  const { currentBroker } = useBroker();
  const { pathname, search } = useLocation();
  const { signed, loadingSession, user } = useAuth();
  const visibleCalcs = user?.user?.visibleCalcs ? user?.user?.visibleCalcs : [];

  if (loadingSession) {
    return <></>;
  }

  if (signed) {
    if (
      !currentBroker.hideCalculadoras &&
      visibleCalcs.length === 0 &&
      pathname !== "/calculadoras"
    ) {
      return <Navigate to="/calculadoras" />;
    } else {
      return <Outlet />;
    }
  } else {
    if (pathname === "/conta" && search === "?redirect=true") {
      return <Navigate to="/p/login?redirect=true" />;
    } else if (pathname === "/xpinvestimentos/exterior-integration") {
      return <Navigate to="/p/login" />;
    } else {
      return <Navigate to="/p/cadastrar" />;
    }
  }
};

export default PrivateRoute;
