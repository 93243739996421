import { AxiosInstance } from "axios";
import { GiProfit } from "react-icons/gi";
import { VscHistory } from "react-icons/vsc";
import { AiOutlineApi } from "react-icons/ai";
import { BiTransferAlt } from "react-icons/bi";
import { RiFileList3Line } from "react-icons/ri";
// import { BsCurrencyBitcoin } from "react-icons/bs";
// import { HiOutlineCalculator } from "react-icons/hi";
import { MdSwapVerticalCircle } from "react-icons/md";
import XP_logo from "../../assets/xp.svg";
import apiBolsa from "../../services/apiBolsa";
import apiExterior from "../../services/apiExterior";
import XP_logo_black from "../../assets/xp-black.svg";
import { ReactComponent as BolsaIcon } from "../../assets/icons/sidebar/bolsa.svg";
import { ReactComponent as CriptoIcon } from "../../assets/icons/sidebar/cripto.svg";
import { ReactComponent as ExteriorIcon } from "../../assets/icons/sidebar/exterior.svg";

interface HTML {
  favicon: string;
  title: string;
}

interface Input {
  name: string;
  label: string;
  placeholder: string;
  rules: any[];
}

interface Feature {
  apiUrl: string;
  title?: string;
  inputs?: Input[];
  disabled?: boolean;
  buttonLabel?: string;
}

export interface Page {
  calcId?: string;
  path: string;
  title: React.ReactNode;
  name: string;
  header: boolean;
  sidebar: boolean;
  features: Feature[];
  component?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  disabledIfIntegrated?: boolean;
  disabledIfNotIntegrated?: boolean;
  settings?: any;
  subPages?: Page[];
  showOnSidebar?: boolean;
  api?: AxiosInstance;
  legacyHistory?: string;
  premium?: boolean;
  integratedOnly?: boolean;
}

export interface Broker {
  id: string;
  dns: string;
  name: string;
  dark: boolean;
  link: string;
  path: string;
  apiUrl: string;
  html: HTML;
  pages: Page[];
  colors: { [key: string]: string };
  initialYear: number;
  icon: any;
  iconStyle?: any;
  iconAlternative?: any;
  iconAlternativeStyle?: any;
  disabled?: boolean;
  defaultPath?: string;
  defaultProviderExistsPath?: string;
  useSSO?: boolean;
  useSettings?: boolean;
  hidePoweredBy?: boolean;
  hideCalculadoras?: boolean;
  hideJuridicMessage?: boolean;
  usePGBL?: boolean;
  useCarteira?: boolean;
  useTransferenciaCustodia?: boolean;
}

export const brokers: Broker[] = [
  {
    id: "xp-investimentos",
    name: "XP Investimentos",
    dns: "xpi-bolsa-dev.velotax.com.br",
    path: "xpinvestimentos",
    apiUrl: "/bitcoin-trade",
    link: "https://www.xpi.com.br",
    dark: true,
    useSSO: true,
    usePGBL: true,
    useCarteira: true,
    useSettings: false,
    useTransferenciaCustodia: true,
    hideCalculadoras: true,
    hideJuridicMessage: true,
    colors: {
      primary: "#fbc105",
      secondary: "#222",
      sidebarSelectedColor: "#fbc105",
      sidebarSelectedColorDark: "#ffffff",
    },
    html: {
      favicon: XP_logo_black,
      title: "XP Investimentos | Imposto de Renda by Velotax",
    },
    icon: XP_logo_black,
    iconAlternative: XP_logo,
    iconStyle: {
      width: "auto",
      height: "40px",
    },
    iconAlternativeStyle: {
      width: "128px",
      height: "128px",
    },
    initialYear: 2020,
    hidePoweredBy: false,
    defaultPath: "/xpinvestimentos/bolsa-integration",
    defaultProviderExistsPath: "/xpinvestimentos/bolsa-historic",
    pages: [
      {
        calcId: "bolsa",
        header: true,
        sidebar: true,
        integratedOnly: true,
        path: "/xpinvestimentos/bolsa",
        name: "Bolsa de valores",
        icon: <BolsaIcon className="stroke" />,
        title: "",
        api: apiBolsa,
        showOnSidebar: true,
        features: [
          {
            apiUrl: "/xpinvestimentos/transaction",
          },
          {
            apiUrl: "/darf",
            buttonLabel: "Emitir DARF",
          },
          {
            apiUrl: "/darf/year",
          },
          {
            apiUrl: "/xpinvestimentos/prejuizos",
          },
          {
            apiUrl: "/xpinvestimentos/irrf",
          },
        ],
        component: "Bolsa",
        subPages: [
          // {
          //   header: true,
          //   sidebar: true,
          //   component: "DarfBolsa",
          //   name: "Calculadora",
          //   icon: (
          //     <HiOutlineCalculator
          //       size={24}
          //       className="no-fill"
          //       color="var(--ant-primary-color)"
          //     />
          //   ),
          //   title: "",
          //   settings: {
          //     historicUrl: "/xpinvestimentos/bolsa-historic",
          //     integrationPath: "/xpinvestimentos/bolsa-integration",
          //     showNavigateToIntegrationButton: true,
          //   },
          //   features: [
          //     {
          //       apiUrl: "/xpinvestimentos/transaction",
          //     },
          //     {
          //       apiUrl: "/darf",
          //       buttonLabel: "Emitir DARF",
          //     },
          //     {
          //       apiUrl: "/darf/year",
          //     },
          //     {
          //       apiUrl: "/xpinvestimentos/prejuizos",
          //     },
          //     {
          //       apiUrl: "/xpinvestimentos/irrf",
          //     },
          //   ],
          // },
          {
            premium: false,
            integratedOnly: true,
            disabled: false,
            header: true,
            sidebar: true,
            component: "ReportBolsa",
            path: "/xpinvestimentos/bolsa-report",
            name: "Relatórios",
            title: "",
            icon: (
              <RiFileList3Line
                size={24}
                className="no-fill"
                color="var(--ant-primary-color)"
              />
            ),
            features: [
              {
                apiUrl: "/darf/report/transactions",
              },
              {
                apiUrl: "/darf/report/download",
              },
              {
                apiUrl: "/darf/report/sendEmail",
              },
            ],
          },
          {
            header: true,
            sidebar: true,
            integratedOnly: true,
            component: "HistoricBolsa",
            path: "/xpinvestimentos/bolsa-historic",
            name: "Histórico",
            title: "",
            icon: (
              <VscHistory
                size={24}
                className="no-fill"
                color="var(--ant-primary-color)"
              />
            ),
            features: [
              {
                apiUrl: "/xpinvestimentos/historic",
              },
              {
                apiUrl: "/darf/pay",
              },
              {
                apiUrl: "/darf/download",
              },
            ],
          },
          {
            premium: true,
            integratedOnly: true,
            header: true,
            sidebar: true,
            component: "DividendosBolsa",
            path: "/xpinvestimentos/bolsa-dividendos",
            name: "Dividendos",
            title: "",
            icon: <GiProfit size={24} className="no-fill" />,
            settings: {
              darfComponent: "HistoricBolsa",
              darfUrl: "/velotax/bolsa-historic",
            },
            features: [
              {
                apiUrl: "/xpinvestimentos/historic",
              },
            ],
          },
          {
            header: true,
            sidebar: true,
            component: "IntegrationBolsa",
            path: "/xpinvestimentos/bolsa-integration",
            name: "Integração com B3",
            title: "",
            icon: (
              <AiOutlineApi
                size={24}
                className="no-fill"
                color="var(--ant-primary-color)"
              />
            ),
            features: [
              {
                apiUrl: "/xpinvestimentos/integrate",
                buttonLabel: "Integrar",
                title:
                  "Clique para autorizar que a Velotax possa acessar suas informações",
              },
              {
                apiUrl:
                  "https://b3Investidor.b2clogin.com/b3Investidor.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_FINTECH&client_id=069ecfdf-7471-48eb-be82-d50799c99e5c&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fwww.investidor.b3.com.br&scope=openid&response_type=code&prompt=login",
                buttonLabel: "Permita o nosso app acessar suas transações!",
              },
              {
                apiUrl: "/xpinvestimentos/verify-user",
              },
            ],
          },
          {
            integratedOnly: true,
            header: true,
            sidebar: true,
            component: "BolsaManual",
            path: "/xpinvestimentos/bolsa-insert-manual",
            name: "Posição 31/12/2019",
            title: "",
            icon: (
              <MdSwapVerticalCircle
                size={24}
                className="no-fill"
                color="var(--ant-primary-color)"
              />
            ),
            features: [],
          },
          {
            integratedOnly: true,
            header: true,
            sidebar: true,
            component: "DoacoesHeranca",
            path: "/xpinvestimentos/bolsa-doacoes-heranca",
            name: "Doações e heranças",
            title: "",
            icon: (
              <MdSwapVerticalCircle
                size={24}
                className="no-fill"
                color="var(--ant-primary-color)"
              />
            ),
            features: [],
          },
        ],
      },
      {
        calcId: "exterior",
        header: true,
        sidebar: true,
        name: "Ativos no exterior",
        path: "/xpinvestimentos/exterior",
        component: "Exterior",
        icon: <ExteriorIcon className="stroke" />,
        title: "",
        api: apiExterior,
        showOnSidebar: true,
        // legacyHistory: "https://www.velotax.com.br/historico/exterior",
        features: [],
        subPages: [
          // {
          //   header: true,
          //   sidebar: true,
          //   component: "DarfExterior",
          //   name: "Calculadora",
          //   icon: (
          //     <HiOutlineCalculator
          //       size={24}
          //       className="no-fill"
          //       color="var(--ant-primary-color)"
          //     />
          //   ),
          //   title: "",
          //   settings: {
          //     integrationUrl: "/xpinvestimentos/exterior-integrations",
          //     historicUrl: "/xpinvestimentos/exterior-historic",
          //     showNavigateToIntegrationButton: true,
          //   },
          //   features: [
          //     {
          //       apiUrl: "/transaction",
          //     },
          //     {
          //       apiUrl: "/darf",
          //       buttonLabel: "Emitir DARF",
          //     },
          //     {
          //       apiUrl: "/darf/year",
          //     },
          //   ],
          // },
          {
            premium: false,
            disabled: false,
            header: true,
            sidebar: true,
            component: "ReportExterior",
            path: "/xpinvestimentos/exterior-report",
            name: "Relatórios",
            title: "",
            icon: (
              <RiFileList3Line
                size={24}
                className="no-fill"
                color="var(--ant-primary-color)"
              />
            ),
            features: [
              {
                apiUrl: "/darf/report/transactions",
              },
              {
                apiUrl: "/darf/report/download",
              },
              {
                apiUrl: "/darf/report/sendEmail",
              },
              {
                apiUrl: "/velotax/exterior/historic"
              },
              {
                apiUrl: "/darf"
              }
            ],
          },
          {
            header: true,
            sidebar: true,
            component: "Historic",
            path: "/xpinvestimentos/exterior-historic",
            name: "Histórico",
            title: "",
            icon: (
              <VscHistory
                size={24}
                className="no-fill"
                color="var(--ant-primary-color)"
              />
            ),
            features: [
              {
                apiUrl: "/velotax/exterior/historic",
              },
              {
                apiUrl: "/darf/pay",
              },
              {
                apiUrl: "/darf/download",
              },
            ],
          },
          {
            premium: true,
            header: true,
            sidebar: true,
            component: "DividendosExterior",
            path: "/xpinvestimentos/exterior-dividendos",
            name: "Dividendos",
            title: "",
            icon: (
              <GiProfit
                size={24}
                className="no-fill"
                color="var(--ant-primary-color)"
              />
            ),
            features: [
              {
                apiUrl: "/velotax/exterior/historic",
              },
            ],
          },
          // {
          //   disabled: false,
          //   header: true,
          //   sidebar: true,
          //   component: "IntegrationExteriorXP",
          //   path: "/xpinvestimentos/exterior-integration",
          //   name: "Integração com XP",
          //   title: "",
          //   icon: (
          //     <AiOutlineApi
          //       size={24}
          //       className="no-fill"
          //       color="var(--ant-primary-color)"
          //     />
          //   ),
          //   features: [],
          // },
          {
            disabled: false,
            header: true,
            sidebar: true,
            component: "TransferenciaCustodia",
            path: "/xpinvestimentos/transferencia-custodia",
            name: "Transferência de custódia",
            title: "",
            icon: (
              <BiTransferAlt
                size={24}
                className="no-fill"
                color="var(--ant-primary-color)"
              />
            ),
            features: [],
          },
          // {
          //   header: true,
          //   sidebar: true,
          //   component: "IntegrationExterior",
          //   path: "/xpinvestimentos/exterior-integrations",
          //   name: "Integrações",
          //   title: "",
          //   icon: <AiOutlineApi size={24} className="no-fill" />,
          //   features: [],
          // },
        ],
      },
      // {
      //   header: true,
      //   calcId: "cripto",
      //   sidebar: true,
      //   name: "Criptoativos",
      //   path: "/xpinvestimentos/cripto",
      //   icon: <CriptoIcon className="stroke" />,
      //   title: "Investimento em Criptoativos",
      //   api: apiCrypto,
      //   // legacyHistory: "https://www.velotax.com.br/historico/crypto",
      //   showOnSidebar: true,
      //   features: [],
      //   component: "Crypto",
      //   subPages: [
      //     // {
      //     //   premium: true,
      //     //   integratedOnly: true,
      //     //   disabled: false,
      //     //   header: true,
      //     //   sidebar: true,
      //     //   component: "ReportBolsa",
      //     //   path: "/xpinvestimentos/bolsa-report",
      //     //   name: "Relatórios",
      //     //   title: "",
      //     //   icon: (
      //     //     <RiFileList3Line
      //     //       size={24}
      //     //       className="no-fill"
      //     //       color="var(--ant-primary-color)"
      //     //     />
      //     //   ),
      //     //   features: [
      //     //     {
      //     //       apiUrl: "/darf/report/transactions",
      //     //     },
      //     //     {
      //     //       apiUrl: "/darf/report/download",
      //     //     },
      //     //     {
      //     //       apiUrl: "/darf/report/sendEmail",
      //     //     },
      //     //   ],
      //     // },
      //     {
      //       header: true,
      //       sidebar: true,
      //       integratedOnly: true,
      //       component: "Historic",
      //       path: "/xpinvestimentos/cripto-historic",
      //       name: "Histórico",
      //       title: "",
      //       icon: (
      //         <VscHistory
      //           size={24}
      //           className="no-fill"
      //           color="var(--ant-primary-color)"
      //         />
      //       ),
      //       features: [
      //         {
      //           apiUrl: "/xpinvestimentos/historic",
      //         },
      //         {
      //           apiUrl: "/darf/pay",
      //         },
      //         {
      //           apiUrl: "/darf/download",
      //         },
      //       ],
      //     },
      //     {
      //       header: true,
      //       sidebar: true,
      //       component: "IntegrationCrypto",
      //       path: "/xpinvestimentos/cripto-integration",
      //       name: "Integração com B3",
      //       title: "",
      //       icon: (
      //         <AiOutlineApi
      //           size={24}
      //           className="no-fill"
      //           color="var(--ant-primary-color)"
      //         />
      //       ),
      //       features: [
      //         {
      //           apiUrl: "/xpinvestimentos/integrate",
      //           buttonLabel: "Integrar",
      //           title:
      //             "Clique para autorizar que a Velotax possa acessar suas informações",
      //         },
      //         {
      //           apiUrl:
      //             "https://b3Investidor.b2clogin.com/b3Investidor.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_FINTECH&client_id=069ecfdf-7471-48eb-be82-d50799c99e5c&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fwww.investidor.b3.com.br&scope=openid&response_type=code&prompt=login",
      //           buttonLabel: "Permita o nosso app acessar suas transações!",
      //         },
      //         {
      //           apiUrl: "/xpinvestimentos/verify-user",
      //         },
      //       ],
      //     },
      //   ],
      // },
      // {
      //   header: true,
      //   sidebar: true,
      //   calcId: "autonomos",
      //   name: "Autônomos/Aluguel",
      //   path: "/xpinvestimentos/autonomos",
      //   title: "Autônomos e Aluguel",
      //   api: msDarf,
      //   icon: <BsBriefcase size={24} className="no-fill" />,
      //   features: [],
      //   legacyHistory: "https://www.velotax.com.br/rendimentos/historico",
      //   subPages: [
      //     {
      //       header: true,
      //       sidebar: true,
      //       component: "Rendimentos",
      //       path: "/velotax/autonomos",
      //       name: "Calculadora",
      //       icon: <HiOutlineCalculator size={24} className="no-fill" />,
      //       title: "Histórico de impostos em Rendimentos",
      //       features: [
      //         {
      //           apiUrl: "/transaction",
      //         },
      //         {
      //           apiUrl: "/darf",
      //           buttonLabel: "Emitir DARF",
      //         },
      //         {
      //           apiUrl: "/darf/year",
      //         },
      //       ],
      //     },
      //     {
      //       header: true,
      //       sidebar: true,
      //       component: "Historic",
      //       path: "/velotax/autonomos-historico",
      //       name: "Histórico",
      //       icon: <RiFileList3Line size={24} className="no-fill" />,
      //       title: "Histórico de impostos em Rendimentos",
      //       features: [
      //         {
      //           apiUrl: "/rendimentos/historic",
      //         },
      //         {
      //           apiUrl: "/darf/pay",
      //         },
      //         {
      //           apiUrl: "/darf/download",
      //         },
      //       ],
      //     },
      //   ],
      // },
      // {
      //   header: true,
      //   sidebar: true,
      //   features: [],
      //   name: "MEI",
      //   title: "MEI",
      //   calcId: "mei",
      //   path:
      //     process.env.REACT_APP_MEI_FRONT_URL ?? "https://mei.velotax.com.br",
      //   icon: (
      //     <MeiIcon
      //       width="28px"
      //       height="28px"
      //       style={{ marginLeft: "-4px" }}
      //       fill="var(--velotax-font-color-ghost)"
      //     />
      //   ),
      // },
    ],
  },
];
