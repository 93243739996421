import React, { forwardRef } from "react";
import { TextField } from "@mui/material";
import NumberFormat from "react-number-format";
import { Col, Form, FormInstance, Row } from "antd";
import cep from "../../../../../services/cep";
import { getMuiInputCss } from "../../../../../utils";
import {
  validationCep,
  validationFieldRequired,
  validationStateAddress,
} from "../../../../../utils/formValidations";

interface IAddressFormProps {
  form: FormInstance;
  setLoading: (loading: boolean) => void;
  setIsValidAddressForm: (isValidAddressForm: boolean) => void;
}

const AddressForm: React.FC<IAddressFormProps> = ({
  form,
  setLoading,
  setIsValidAddressForm,
}) => {
  const getAddressSuggestion = (value: string) => {
    if (value?.length === 8) {
      setLoading(true);
      cep
        .get(`${value}/json`)
        .then((res) => {
          const data = res.data;

          if (data.cep) {
            form.setFieldsValue({
              street: data.logradouro,
              neighborhood: data.bairro,
              city: data.localidade,
              state: data.uf,
            });
          } else {
            throw Error();
          }
        })
        .catch(() => {
          form.setFieldsValue({
            street: undefined,
            neighborhood: undefined,
            city: undefined,
            state: undefined,
            number: undefined,
          });
        })
        .finally(() => {
          onFieldsChange();
          setLoading(false);
        });
    }
  };

  const onFieldsChange = () => {
    form
      .validateFields()
      .then(() => setIsValidAddressForm(true))
      .catch(() => setIsValidAddressForm(false));
  };

  const CEPInput = forwardRef((props, ref) => (
    <NumberFormat
      {...props}
      mask="_"
      format="#####-###"
      defaultValue={(props as any).value}
      className={`MuiInputBase-input MuiInput-input ${getMuiInputCss()}`}
      onValueChange={(value) => getAddressSuggestion(value?.value)}
    ></NumberFormat>
  ));

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        onChange={onFieldsChange}
      >
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="cep" rules={validationCep}>
              <TextField
                label="CEP"
                variant="standard"
                InputProps={{
                  inputComponent: CEPInput as any,
                }}
                InputLabelProps={{ shrink: true }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="street" rules={validationFieldRequired}>
              <TextField
                label="Logradouro"
                variant="standard"
                InputLabelProps={{ shrink: true }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="neighborhood" rules={validationFieldRequired}>
              <TextField
                label="Bairro"
                variant="standard"
                InputLabelProps={{ shrink: true }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="number" rules={validationFieldRequired}>
              <TextField
                label="Número"
                variant="standard"
                InputLabelProps={{ shrink: true }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="city" rules={validationFieldRequired}>
              <TextField
                label="Cidade"
                variant="standard"
                InputLabelProps={{ shrink: true }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="state" rules={validationStateAddress}>
              <TextField
                label="Estado"
                variant="standard"
                inputProps={{ maxLength: 2 }}
                InputLabelProps={{ shrink: true }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddressForm;
