import styled from "styled-components";

export const Container = styled.div`
  &.velotax-container {
    .velotax-content {
      width: 1032px;
      h1.page-title {
        font-size: 28px;
        margin-bottom: 1rem;
        margin-left: 0;
        :after {
          /* display: none; */
        }
      }
      .subtitle {
        font-size: 16px;
        color: var(--velotax-font-color);
      }
      .trustpilot-trustbox-widget {
        margin: 32px 0 32px -16px;
        width: calc(100% + 32px);
      }
      .selos {
        display: flex;
        margin: 0 0 48px;
        .selo {
          display: flex;
          padding: 0 24px;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          width: calc(100% / 3);
          .ant-typography {
            display: block;
            margin-top: 8px;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            .anticon {
              margin-left: 5px;
              vertical-align: -0.2em;
            }
            strong {
              display: block;
              font-weight: 700;
              color: white;
            }
          }
          &.trustpilot {
            .excelente {
              display: block;
              font-size: 18px;
              font-weight: 700;
              margin: 0 0 8px;
            }
            .avaliacoes {
              display: flex;
              height: 24px;
              font-size: 16px;
              margin: 16px 0 10px;
              align-items: center;
              justify-content: center;
              fill: white;
              svg {
                margin: 0 0 2px 0;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    &.velotax-container {
      .velotax-content {
        width: 100%;
        .selos {
          flex-direction: column;
          .selo {
            width: 100%;
            padding: 12px 24px;
          }
        }
      }
    }
  }
`;

export const PlanosContainer = styled.div`
  display: flex;
  justify-content: center;
  .plan {
    padding: 32px;
    overflow: hidden;
    margin-left: 8px;
    position: relative;
    border-radius: 8px;
    width: calc((100% - 16px) / 3);
    border: 2px solid var(--light-gray);
    background-color: white;
    :first-of-type {
      margin-left: 0;
    }
    .plan-name {
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      text-align: center;
      margin-bottom: 24px;
      color: var(--velotax-font-color-dark);
    }
    .plan-price {
      text-align: center;
      position: relative;
      font-size: 24px;
      font-weight: 700;
      line-height: 20px;
      margin-bottom: 4px;
      color: var(--velotax-font-color-dark);
      .plan-price-main {
        font-size: 48px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -1.92px;
      }

      .plus {
        margin-right: 4px;
        position: absolute;
        left: 2.5rem;
        bottom: 0;
      }
    }
    .plan-price-desc {
      display: block;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      margin-bottom: 2rem;
      color: var(--velotax-font-color-dark);
    }
    .plan-for {
      width: 128px;
      display: block;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
      margin: 16px auto 24px;
      color: var(--velotax-font-color-ghost);
    }
    .plan-includes {
      display: block;
      margin: 24px 0 0;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: var(--velotax-font-color-dark);
    }
    .plan-benefits {
      .plan-benefit {
        display: flex;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        margin-top: 2px;
        color: var(--velotax-font-color-ghost);
        svg {
          width: 16px;
          height: 16px;
          min-width: 16px;
          min-height: 16px;
          margin-right: 4px;
        }
        svg.check {
          fill: var(--ant-success-color);
        }
        .info {
          width: 12px;
          height: 12px;
          margin: 2px 0 0 4px;
          color: var(--velotax-font-color-dark);
          cursor: pointer;
          svg {
            width: 12px;
            height: 12px;
            min-width: 12px;
            min-height: 12px;
            margin-right: 0;
          }
        }
        .ant-tooltip-inner {
          background-color: rgba(0, 0, 0, 0.9);
        }
      }
    }
    .plan-popular {
      top: 0;
      right: 0;
      width: 150px;
      height: 20px;
      display: flex;
      font-size: 10px;
      font-weight: 700;
      line-height: 16px;
      position: absolute;
      color: var(--white);
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      background-color: var(--ant-primary-color);
      transform: rotateZ(45deg) translateX(45px) translateY(-12px);
    }
  }
  @media only screen and (max-device-width: 812px) {
    flex-direction: column;
    .plan {
      width: 100%;
      margin: 0 0 8px;
    }
  }
`;
