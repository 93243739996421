import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Divider, message, Modal, Typography } from "antd";
import apis from "../../../../services/apis";
import Button from "../../../../components/Button";
import { useAuth } from "../../../../contexts/AuthContext";
import { useBroker } from "../../../../contexts/BrokerContext";
import { errorMessage, monthsExtended } from "../../../../utils";

interface IntegrationModalProps {
  visible: boolean;
  onCancel: () => void;
}

export const IntegrationModal: React.FC<IntegrationModalProps> = ({
  visible,
  onCancel,
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { currentBroker, integration, handleIntegrationInit, currentPage } =
    useBroker();

  const integrationDate = new Date(integration.lastUpdate);

  const handleIntegrate = () => {
    if (integration.key) {
      setLoading(true);
      (currentPage?.api || apis)
        .post(`${currentBroker.apiUrl}/integrate`, {
          key: integration.key,
          email: user.user.email,
        })
        .then(() => {
          setLoading(false);
          handleIntegrationInit();
        })
        .catch((err) => {
          setLoading(false);
          if (err?.response?.data?.message?.includes("Aguarde")) {
            message.error(err?.response?.data?.message);
          } else {
            message.error(errorMessage);
          }
        });
    } else {
      navigate(`/${currentBroker.path}/integration`);
    }
  };

  return (
    <Modal
      footer={null}
      visible={visible}
      onCancel={onCancel}
      title="Faça a integração novamente"
    >
      <Typography>
        A sua última integração foi feita no mês de{" "}
        {monthsExtended[integrationDate.getMonth()]} de{" "}
        {integrationDate.getFullYear()}. Para gerenciar operações e gerar DARFs
        dos meses seguintes ao da última integração, você precisa integrar
        novamente.
      </Typography>
      <Divider />
      <Button
        size="large"
        type="primary"
        loading={loading}
        disabled={loading}
        onClick={handleIntegrate}
        style={{ marginLeft: "auto" }}
      >
        Integrar
      </Button>
    </Modal>
  );
};
