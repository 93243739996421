import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Col, Divider, Form, Input, message, Modal, Row } from "antd";
import Button from "../../Button";
import apis from "../../../services/apiExterior";
import { useAuth } from "../../../contexts/AuthContext";
// import passfolioLogo from "../../../assets/passfolio-logo.png";
import { validationFieldRequired } from "../../../utils/formValidations";
import { IntegrationData } from "../interfaces";

interface IPassfolioIntegrationModalProps {
  visible: boolean;
  dependentId?: string;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setLoadingIntegration: Dispatch<SetStateAction<boolean>>;
  integrationData?: IntegrationData;
}

const PassfolioIntegrationModal: React.FC<IPassfolioIntegrationModalProps> = ({
  visible,
  setVisible,
  setLoadingIntegration,
  integrationData,
}) => {
  const { user } = useAuth();
  const [form] = Form.useForm();
  const [integrationParams, setParams] = useState({
    key: "",
    userId: "",
    secret: "",
    token: "",
  });

  useEffect(() => {
    if (integrationData && visible) {
      form.setFieldsValue({
        key: integrationData.key,
        secret: integrationData.secret,
        userId: integrationData.userId,
      });
    }
  }, [form, integrationData, visible]);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const onFinish = (values: any) => {
    setLoadingIntegration(true);
    apis
      .post(
        "/passfolio/authenticate",
        {
          ...values,
        },
        {
          headers: {
            "x-api-provider": "exterior-velotax",
          },
        }
      )
      .then((response) => {
        const { data } = response;
        const { token, authenticatorId } = data;
        const { key, secret } = values;

        setParams({ key, secret, userId: authenticatorId, token });
      })
      .catch((err) => {
        if (err.response?.data?.message) {
          message.error(
            err.response?.data?.message ||
              "Algo deu errado, tente novamente mais tarde"
          );
        }
        setLoadingIntegration(false);
      });
  };

  const onClick = (values: any) => {
    setLoadingIntegration(true);
    setVisible(false);
    apis
      .post(
        "/passfolio/integrate",
        {
          ...values,
          ...integrationParams,
          email: user.user.email,
        },
        {
          headers: {
            "x-api-provider": "exterior-velotax",
          },
        }
      )
      .catch((err) => {
        if (err.response?.data?.message) {
          message.error(
            err.response?.data?.message ||
              "Algo deu errado, tente novamente mais tarde"
          );
        }
        setParams({
          key: "",
          userId: "",
          secret: "",
          token: "",
        });
        setLoadingIntegration(false);
      });
  };

  return (
    <Modal
      footer={null}
      destroyOnClose
      visible={visible}
      onCancel={onCancel}
      maskClosable={false}
      title={
        <span style={{ display: "flex", alignItems: "center" }}>
          <img
            src="https://web.xpi.com.br/wp-content/themes/xpi-acquisition-xp/assets/images/footer/logo-xpi-preto.svg"
            alt="Foxbit logo"
            style={{ height: "32px", marginRight: "16px" }}
          />
          Integrar com a XP
        </span>
      }
    >
      <Form
        form={form}
        layout="vertical"
        onReset={onCancel}
        onFinish={onFinish}
      >
        <Form.Item
          name="code"
          rules={!integrationParams.key ? [] : validationFieldRequired}
          hidden={!integrationParams.key}
        >
          <Input placeholder="Código" size="large" />
        </Form.Item>
        <Form.Item
          name="key"
          rules={validationFieldRequired}
          hidden={!!integrationParams.key}
        >
          <Input placeholder="Email" size="large" />
        </Form.Item>
        <Form.Item
          name="secret"
          rules={validationFieldRequired}
          hidden={!!integrationParams.key}
        >
          <Input placeholder="Senha" size="large" type="password" />
        </Form.Item>
        <Divider />
        <Row justify="space-between">
          <Col>
            <Form.Item>
              <Button size="large" htmlType="reset">
                Cancelar
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button
                size="large"
                htmlType="submit"
                type="primary"
                hidden={!!integrationParams.key}
              >
                Logar
              </Button>
              <Button
                size="large"
                htmlType="button"
                type="primary"
                hidden={!integrationParams.key}
                onClick={() => {
                  onClick(form.getFieldsValue());
                }}
              >
                Integrar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default PassfolioIntegrationModal;
