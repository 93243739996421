import { Typography } from "antd";
import { Container, Content } from "./styles";
import { NotasCorretagem } from "./NotasCorretagem";
import { useB3Integration } from "../../contexts/B3IntegrationContext";
import { NotasHistoricoModal } from "../IntegrationBolsa/NotasHistoricoModal";

interface IntegrationCryptoProps {}

export const IntegrationCrypto: React.FC<IntegrationCryptoProps> = () => {
  const {
    notas: {
      setShowHistoricoNotasCorretagemModal,
      getHistoricoNotasCorretagem,
    },
  } = useB3Integration();
  return (
    <Container className="velotax-container">
      <Typography.Title level={1} className="page-title">
        Carregar notas de corretagem
      </Typography.Title>
      <Content className="velotax-content">
        <NotasCorretagem />
      </Content>
      <Typography.Link
        style={{
          display: "block",
          margin: "32px auto",
          textAlign: "center",
          textDecoration: "underline",
          color: "var(--ant-primary-color)",
        }}
        onClick={() => {
          setShowHistoricoNotasCorretagemModal(true);
          getHistoricoNotasCorretagem({ crypto: true, filters: {} });
        }}
      >
        Histórico de notas
      </Typography.Link>
    </Container>
  );
};
