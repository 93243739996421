import { Col, Row } from "antd";
import Button from "../../../../Button";
import React, { Dispatch, SetStateAction } from "react";

interface IFooterProps {
  currentStep: number;
  handleProceed: () => void;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  typePurchase: string;
  hideTerms: boolean;
  backtoRoot?: any;
  selectedCardId?: string;
}

const Footer: React.FC<IFooterProps> = ({
  currentStep,
  setCurrentStep,
  handleProceed,
  typePurchase,
  hideTerms,
  selectedCardId,
  backtoRoot,
}) => {
  return (
    <>
      {/*<Row style={{ marginTop: "5px" }}>
         {currentStep !== 2 && !hideTerms && (
          <Col xs={24} sm={24} md={24}>
            <Typography.Paragraph style={{ textAlign: "center", fontSize: 12 }}>
              Ao realizar o pagamento você concorda com as{" "}
            </Typography.Paragraph>
          </Col>
        )} 
      </Row>*/}
      <Row
        align="middle"
        gutter={[16, 16]}
        justify="space-between"
        style={{ marginTop: "10px" }}
      >
        <Col xs={24} sm={24} md={24}>
          <Row gutter={[8, 8]} justify="space-between">
            {currentStep !== 3 && currentStep !== 4 && (
              <Col span={24}>
                <Button
                  size="large"
                  type="primary"
                  style={{width: '100%', color: 'black', fontWeight: 'bold'}}
                  color="secondary"
                  onClick={handleProceed}
                >
                  {currentStep === 1
                    ? "PROSSEGUIR"
                    : typePurchase === "UNIQUE"
                    ? "FINALIZAR PAGAMENTO"
                    : "FINALIZAR COMPRA"}
                </Button>
              </Col>
            )}
            {currentStep <= 2 && (
              <Col span={24}>
                <Button
                  size="large"
                  type="link"
                  onClick={() => {
                    if (currentStep === 1) {
                      backtoRoot();
                      return;
                    }

                    setCurrentStep((step) => step - 1);
                  }}
                >
                  <span className="xp-link uppercase">Voltar</span>
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Footer;
