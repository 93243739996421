import clsx from "clsx";
import { Typography } from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { Dispatch, SetStateAction } from "react";
import { isMobile } from "../../../utils";
import { IRPFBannerContainer } from "./styles";
import Button from "../../../components/Button";
import { useAuth } from "../../../contexts/AuthContext";

interface IRPFBannerProps {
  active: boolean;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

export const IRPFBanner: React.FC<IRPFBannerProps> = ({ show, setShow }) => {
  const { ssoToken, irpfBannerActive } = useAuth();

  return (
    <IRPFBannerContainer className={clsx({ show })}>
      <div className="banner">
        <Typography.Title level={1}>
          Declare seu IRPF {new Date().getFullYear()} com o Velotax
        </Typography.Title>
        {irpfBannerActive ? (
          <Button
            size="large"
            type="primary"
            href={
              !process.env.REACT_APP_URL?.includes("dev")
                ? `https://irpf.velotax.com.br/p/sso/${ssoToken}`
                : `https://irpfdev.velotax.com.br/p/sso/${ssoToken}`
            }
            {...!isMobile() ? { target: "_blank" } : {}}
          >
            Clique aqui
          </Button>
        ) : (
          <Button type="primary" size="large" style={{ cursor: "default" }}>
            Em breve!
          </Button>
        )}
      </div>
      <Button
        type="text"
        size="large"
        icon={<AiOutlineClose />}
        onClick={() => {
          setShow(false);
        }}
      />
    </IRPFBannerContainer>
  );
};
