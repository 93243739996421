import styled from "styled-components";

export const Container = styled.div`
  padding: 64px 32px 128px;
  @media only screen and (max-device-width: 812px) {
    padding: 0 0 64px;
  }
  &.view {
    padding: 0;
  }

  .juridic-messages-container {
    opacity: 0.8;
    padding: 32px;
    margin: 0 auto;
    max-width: 600px;
    border-radius: 16px;

    .message {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1rem;
    }

    p {
      font-size: 0.85rem;
      font-weight: 400;
      text-align: justify;
      line-height: 1.4;
      color: var(--velotax-font-color);
      margin-bottom: 0;
      margin-left: 0.5rem;

      i {
        font-weight: bold;
        color: var(--velotax-font-color);
      }
    }
  }
`;

export const Content = styled.div`
  padding: 32px;
  &.view {
    padding: 0;
  }
  margin: 0 auto;
  max-width: 600px;
  border-radius: 16px;
  background-color: var(--velotax-background-color);
  h3 {
    display: flex;
    align-items: center;
    column-gap: 16px;
    color: var(--velotax-font-color);
    margin-bottom: 0;
  }
  .desc-label {
    display: flex;
    align-items: center;
    svg {
      fill: var(--ant-primary-color);
    }
  }
  .desc-content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    column-gap: 8px;
    svg {
      fill: var(--ant-primary-color);
    }
    span {
      align-self: center;
    }
    button {
      min-width: 32px;
    }
  }
  .ml-40 {
    margin-left: 40px;
  }
  .add {
    min-width: 128px;
    margin: 0 0 16px auto;
  }
  .ant-descriptions-item-label {
    width: 60%;
  }
  .ant-descriptions-item-content {
    text-align: center;
  }
  .ant-list.ant-list-split {
    padding: 0 16px;
    background-color: var(--velotax-ghost);
    border: 1px solid var(--velotax-ghost);
    border-radius: 8px;
    .ant-list-item-action > li {
      padding: 0;
    }
  }
  .ant-list-item-action > li,
  .ant-list-item-meta-title,
  .ant-list-item-meta-description {
    color: var(--velotax-font-color);
  }
  .ant-list-item-meta-description {
    opacity: 0.75;
  }
  .list-description {
    display: flex;
    flex-direction: column;
  }
  .min-darf-price {
    margin-top: 24px;
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
  .text-center {
    display: block;
    text-align: center;
  }
  .ant-collapse-header {
    padding: 12px 0 !important;
  }
  .ant-collapse-content-box {
    padding: 16px 0 !important;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow
    svg {
    transform: rotate(-90deg);
  }
  .ant-collapse-header-text {
    width: 100%;
  }
  .total-tax-header {
    width: calc(100% - 48px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      margin: 0;
    }
  }

  h5 {
    margin: 0px;
  }

  @media only screen and (max-device-width: 812px) {
    padding: 24px;
    border-radius: 0;
    h3 {
      font-size: 18px;
    }
    .anticon.anticon-right.ant-collapse-arrow {
      top: 24px;
    }
    .desc-content {
      row-gap: 8px;
      flex-direction: column;
      button {
        width: 100%;
      }
      &.ml-40 {
        margin-left: 0;
      }
    }
  }
`;

export const BolsaOperations = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  border-radius: 8px;
  color: var(--velotax-font-color);
  background-color: var(--velotax-ghost);

  div.body-deducoes-tributarias {
    display: flex;
    flex-direction: column;
    padding: 0 8px;
    border-radius: 0 8px 8px 0;
    border: 1px solid var(--velotax-ghost);
    background-color: var(--velotax-background-color);
    span {
      height: 48px;
      display: flex;
      font-weight: 700;
      align-items: center;
      column-gap: 8px;
    }
  }

  div.header-deducoes-tributarias {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    border-radius: 8px 0 0 8px;
    background-color: var(--velotax-ghost);
    span {
      height: 48px;
      display: flex;
      font-weight: 700;
      align-items: center;
      column-gap: 8px;
      border-top: none;
      + span {
        border-top: 1px solid var(--velotax-background-color-ghost);
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    grid-template-columns: 1.6fr 1fr;
    span {
      font-size: 12px;
      button {
        max-width: 24px;
        min-width: 24px;
      }
    }
  }
`;
