import moment from "moment";
import { BsDownload } from "react-icons/bs";
import { AiOutlineFileSync } from "react-icons/ai";
import { useCallback, useEffect, useState } from "react";
import {
  Col,
  Divider,
  Form,
  List,
  message,
  Row,
  Select,
  Skeleton,
  Typography,
} from "antd";
import apis from "../../services/apis";
import Button from "../../components/Button";
import { Page } from "../../constants/brokers";
import { useAuth } from "../../contexts/AuthContext";
import { download, errorMessage } from "../../utils";
import { useBroker } from "../../contexts/BrokerContext";
import { Container, Content, ConfirmModal } from "./styles";

interface AnnualReportProps {
  item: Page;
}

export const AnnualReport: React.FC<AnnualReportProps> = ({ item }) => {
  const { user } = useAuth();
  const [form] = Form.useForm();
  const { currentBroker, currentPage } = useBroker();
  const [reports, setReports] = useState<any[]>([]);
  const [loadingReports, setLoadingReports] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { apiUrl } = item.settings;
  const { initialYear } = currentBroker;

  const yearOptions = Array.from(Array(new Date().getFullYear() - initialYear))
    .map((y, i) => ({ value: initialYear + i, label: `${initialYear + i}` }))
    .reverse();

  const handleModal = () => {
    setShowModal((show) => !show);
  };

  const downloadAction = (item: any, index: number) => {
    if (item.fileLink) {
      download(item.fileLink);
    }
  };

  const getReports = useCallback(() => {
    setLoadingReports(true);
    (currentPage?.api || apis)
      .get(apiUrl)
      .then((response: any) => setReports(response.data))
      .catch(() => message.error(errorMessage))
      .finally(() => setLoadingReports(false));
  }, [apiUrl, currentPage]);

  const handleReport = (data: any) => {
    setLoading(true);
    (currentPage?.api || apis)
      .post(`${apiUrl}/${data.year}`, { name: user.user.name })
      .then(() => {
        handleModal();
        getReports();
      })
      .catch(() => message.error(errorMessage))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getReports();
  }, [getReports]);

  return (
    <Container>
      <Content>
        <Typography.Title level={2}>Gerencie seus relatórios</Typography.Title>
        <Typography.Paragraph>
          Selecione um ano para emitir seu informe.
        </Typography.Paragraph>
        <Typography.Paragraph>
          Após a emissão, o relatório será enviado para o seu email de cadastro
          e também ficará disponível nesta página.
        </Typography.Paragraph>
        <br />
        <Form
          form={form}
          size="large"
          layout="vertical"
          onFinish={handleReport}
        >
          <Row gutter={[16, 16]} align="top">
            <Col xs={24} sm={14}>
              <Form.Item
                name="year"
                rules={[
                  { required: true, message: "O ano deve ser informado" },
                ]}
              >
                <Select
                  placeholder="Selecione o ano"
                  options={yearOptions}
                  getPopupContainer={(trigger) => trigger.parentElement}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={10}>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                className="full-width"
                loading={loading}
                icon={<></>}
              >
                Emitir informe
              </Button>
            </Col>
          </Row>
        </Form>
        <Divider />
        <Typography.Title level={3}>
          <AiOutlineFileSync size={22} />
          Relatórios emitidos
        </Typography.Title>
        <List
          dataSource={reports}
          itemLayout="horizontal"
          loading={loadingReports}
          renderItem={(item, index) => (
            <List.Item
              actions={[
                <Button
                  type="text"
                  size="large"
                  onClick={() => downloadAction(item, index)}
                  icon={<BsDownload size={16} />}
                />,
              ]}
            >
              <Skeleton title={false} loading={item.loading} active>
                <List.Item.Meta
                  title={`Ano: ${item.year}`}
                  description={`Data da solicitação: ${moment(
                    item.createdAt
                  ).format("DD/MM/YYYY")} às ${moment(item.createdAt).format(
                    "HH:mm"
                  )}`}
                />
              </Skeleton>
            </List.Item>
          )}
        />
        <ConfirmModal
          closable
          footer={null}
          destroyOnClose
          keyboard={false}
          visible={showModal}
          onCancel={handleModal}
          title="Informe emitido com sucesso!"
        >
          <Typography.Paragraph className="p">
            Você receberá um email com o informe de bens e direitos.{" "}
            <strong>Aguarde!</strong>
          </Typography.Paragraph>
        </ConfirmModal>
      </Content>
    </Container>
  );
};
