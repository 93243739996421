import { message } from "antd";
import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";
import { Container } from "./styles";
import Button from "../../components/Button";
import { useAuth } from "../../contexts/AuthContext";
import { apiPayment } from "../../services/apiPayment";
import veloLogo from "../../assets/velotax/velologo.svg";

const SSO: React.FC = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { setLocalStorageWithAuthData, setLoadingSession, showUserPlanModal } =
    useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .post("/session/sso", {
        token,
      })
      .then(async (res) => {
        message.success("Logado com sucesso!");
        let retPlanInfo = await apiPayment.get("/user-plan/plan-info", {
          headers: {
            authorization: `Bearer ${res?.data?.token}`,
          },
        });
        setLoading(false);
        setLocalStorageWithAuthData({
          ...(res.data || {}),
          userPlanInfoVelotax: retPlanInfo?.data || {},
        });
        const query = new URLSearchParams(search);
        const redirect = query.get("redirect");
        setTimeout(() => {
          setLoadingSession(false);
          if (redirect === "pagamento") {
            setTimeout(() => {
              showUserPlanModal(true, "Contrate o plano");
              setTimeout(() => {
                document.querySelector<HTMLElement>(".checkbox-terms")?.click();
                document
                  .querySelector<HTMLElement>(
                    ".ant-modal-body .ant-btn-primary"
                  )
                  ?.click();
              }, 100);
            }, 100);
          } else if (redirect === 'exterior-report') {
            navigate("/xpinvestimentos/exterior")
          }
        }, 500);
      })
      .catch((err) => {
        message.error("Algo deu errado, tente novamente mais tarde.");
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [token, search]);

  return (
    <Container>
      <div className="sso-wrapper">
        <img src={veloLogo} alt="velologo" />
        <div className="sso-area">
          {loading ? (
            <>
              <h4>Logando...</h4>
              <LoadingOutlined size={20} />
            </>
          ) : (
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              className="full-width"
              onClick={() => navigate("/p/login")}
            >
              Tentar fazer login manualmente
            </Button>
          )}
        </div>
      </div>
    </Container>
  );
};

export default SSO;
