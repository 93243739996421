import styled from "styled-components";

export const Container = styled.div`
  .velotax-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .success {
      width: 80px;
      height: 80px;
      display: flex;
      margin-top: 64px;
      position: relative;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      background-color: var(--ant-primary-color);
      :after,
      :before {
        content: "";
        position: absolute;
        border-radius: 50%;
        background-color: var(--ant-primary-color);
      }
      :before {
        top: -16px;
        left: -16px;
        width: 112px;
        height: 112px;
        opacity: 0.1;
      }
      :after {
        top: -28px;
        left: -28px;
        width: 136px;
        height: 136px;
        opacity: 0.08;
      }
      svg {
        width: 40px;
        height: 40px;
        fill: var(--white);
      }
    }

    h1 {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      font-style: normal;
      margin: 48px 0 8px;
      letter-spacing: -0.24px;
      color: var(--velotax-font-color-light);
    }

    div.ant-typography {
      font-size: 16px;
      font-weight: 400;
      max-width: 450px;
      line-height: 24px;
      font-style: normal;
      text-align: center;
      margin-bottom: 40px;
      color: var(--velotax-font-color-light);
    }

    .ant-btn-primary {
      width: 200px;
      margin-bottom: 64px;
    }

    .expert-xp {
      padding: 16px 24px;
      border-radius: 8px;
      margin-bottom: 24px;
      background-color: var(--white);
      .ant-typography {
        display: block;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: var(--velotax-font-color-dark);
        text-shadow: 0px 0px var(--velotax-font-color-dark);
        a {
          text-decoration: underline;
          text-shadow: 0px 0px var(--ant-primary-color);
        }
        strong {
          font-style: italic;
        }
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    .velotax-content {
      .success {
        margin-top: 32px;
      }
      div.ant-typography {
        margin-bottom: 32px;
      }
      .ant-btn-primary {
        margin-bottom: 32px;
      }
    }
  }
`;
