import { Col, Row } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { HomeContainer } from "./styles";
import { useBroker } from "../../contexts/BrokerContext";
import { useB3Integration } from "../../contexts/B3IntegrationContext";

export const Home = () => {
  const navigate = useNavigate();
  const { b3Authorized } = useB3Integration();
  const { allPaths, pathBackup, integration, currentBroker } = useBroker();

  useEffect(() => {
    // NÃO APAGAR O CÓDIGO: Lógica de redirecionamento comentada por conta do IRPF.
    if (integration.lastUpdate !== "") {
      if (allPaths.includes(pathBackup)) {
        if (pathBackup?.includes("bolsa")) {
          if (b3Authorized) {
            navigate(pathBackup ?? "/carteira");
          } else {
            navigate(`/${currentBroker.path}/bolsa-integration`);
          }
        } else {
          navigate(pathBackup ?? "/carteira");
        }
      } else {
        navigate("/carteira");
      }
    }
    // navigate("/declaracao-irpf");
  }, [
    allPaths,
    navigate,
    pathBackup,
    b3Authorized,
    currentBroker?.path,
    integration?.lastUpdate,
  ]);

  return (
    <HomeContainer>
      <Row justify="center" style={{ width: "100%" }}>
        <Col>
          <LoadingOutlined />
        </Col>
      </Row>
    </HomeContainer>
  );
};
