import { Button } from "@mui/material";
import { FaRegEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { useState, useEffect, useCallback } from "react";
import {
  Button as AntButton,
  Checkbox,
  Col,
  Form,
  message,
  Modal,
  Row,
  Typography,
} from "antd";
import apiBolsa from "../../services/apiBolsa";
import { HelpModal, pgblQuestions } from "./items";
import { Container, Content, Question } from "./styles";
import { errorMessage, formatCurrency } from "../../utils";
import HandleTag from "../../services/handleTag";

interface ResumeData {
  m: number;
  o: number;
  completa: { valorRestituicao: number };
  simplificada: { valorRestituicao: number };
}

interface PGBLSimulatorProps {}

export const PGBLSimulator: React.FC<PGBLSimulatorProps> = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [resume, setResume] = useState<ResumeData>();
  const [helpModal, setHelpModal] = useState<HelpModal>();
  const [loading, setLoading] = useState(false);
  const [editting, setEditting] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [loadingGet, setLoadingGet] = useState(false);
  const [autorizacao, setAutorizacao] = useState(false);
  const [backupValues, setBackupValues] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [questions, setQuestions] = useState(
    pgblQuestions({ data: {}, setHelpModal })
  );

  const getResume = useCallback(() => {
    setLoadingGet(true);
    apiBolsa
      .get("/xpinvestimentos/pgblCalculate")
      .then((response) => {
        const { resumoSimulacao, ...data } = response.data;
        if (resumoSimulacao) {
          const formData = {
            ...data,
            possuiGastoMensalSaude: !!data.gastoMensalSaude,
            possuiGastoMensalEducacao: !!data.gastoMensalEducacao,
            possuiDependentes: !!data.quantidadeDependente,
            possuiGastoMensalSaudeDependente: !!data.gastoMensalSaudeDependente,
            possuiGastoMensalEducacaoDependente:
              !!data.gastoMensalEducacaoDependente,
          };
          form.setFieldsValue(formData);
          setQuestions(pgblQuestions({ data: formData, setHelpModal }));
          setResume(resumoSimulacao);
          setEditting(false);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoadingGet(false);
      });
  }, [form]);

  const handleEdit = () => {
    setEditting(true);
    setAutorizacao(false);
    setCurrentQuestion(0);
  };

  // const openModal = () => {
  //     setLoading(true);
  //     apiBolsa
  //       .post("/xpinvestimentos/pgblCalculate/sendMail")
  //       .then(() => {
  //         setShowModal(true);
  //       })
  //       .catch(() => {})
  //       .finally(() => setLoading(false));
  //   }
  // };

  const calculate = () => {
    autorizacao &&
      formValidate(1)
        .then(() => {
          setLoading(true);
          const data = form.getFieldsValue();
          apiBolsa
            .post("/xpinvestimentos/pgblCalculate", {
              ...data,
              rendaBrutaMensalMode: data.rendaBrutaMensalMode ?? "mensal",
              gastoMensalSaudeMode: data.gastoMensalSaudeMode ?? "mensal",
              gastoMensalEducacaoMode: data.gastoMensalEducacaoMode ?? "mensal",
              gastoMensalEducacaoDependenteMode:
                data.gastoMensalEducacaoDependenteMode ?? "mensal",
              gastoMensalSaudeDependenteMode:
                data.gastoMensalSaudeDependenteMode ?? "mensal",
              gastoPensaoAlimenticiaMode:
                data.gastoPensaoAlimenticiaMode ?? "mensal",
              // ...(!data.possuiGastoMensalSaude ? { gastoMensalSaude: 0 } : {}),
              // ...(!data.possuiGastoMensalEducacao
              //   ? { gastoMensalEducacao: 0 }
              //   : {}),
              ...(!data.possuiDependentes ? { quantidadeDependente: 0 } : {}),
              ...(!data.possuiGastoMensalSaudeDependente
                ? { gastoMensalSaudeDependente: 0, gastoMensalSaude: 0 }
                : { gastoMensalSaude: data.gastoMensalSaudeDependente }),
              ...(!data.possuiGastoMensalEducacaoDependente
                ? { gastoMensalEducacaoDependente: 0, gastoMensalEducacao: 0 }
                : { gastoMensalEducacao: data.gastoMensalEducacaoDependente }),
              ...(!data.pagaPensaoAlimenticia
                ? { gastoPensaoAlimenticia: 0 }
                : {}),
            })
            .then((response) => {
              setResume(response.data);
              setEditting(false);
            })
            .catch(() => {
              message.error(errorMessage);
            })
            .finally(() => {
              setLoading(false);
            });
        })
        .catch(() => {});
  };

  const formValidate = (n: 1 | -1) =>
    form.validateFields(
      n === 1
        ? questions[currentQuestion].formItems.map(
            (formItem) => formItem.name ?? ""
          )
        : []
    );

  const formNavigate = (n: 1 | -1) => {
    if (n === -1 && currentQuestion === 0) {
      navigate("/");
    } else if (n === 1 && currentQuestion === questions.length - 1) {
      calculate();
    } else {
      formValidate(n)
        .then(() => {
          setCurrentQuestion((current) => current + n);
        })
        .catch(() => {});
    }
  };

  const onValuesChange = (changedValues: any, values: any) => {
    const inputs = questions.map((question) => question.formItems).flat();
    let data = {
      ...values,
      ...inputs
        .filter((input) => input.onChange)
        .reduce(
          (acc, cur) => ({
            ...acc,
            [cur.name]: cur.onChange?.(changedValues, { ...values, ...acc }),
          }),
          {}
        ),
    };
    if (changedValues.possuiDependentes === false) {
      const backupKeys = [
        "gastoMensalSaudeDependente",
        "gastoMensalEducacaoDependente",
        "possuiGastoMensalSaudeDependente",
        "possuiGastoMensalEducacaoDependente",
      ];
      const backupValues = Object.keys(values)
        .filter((key) => backupKeys.includes(key))
        .reduce((pre, cur) => ({ ...pre, [cur]: values[cur] }), {});
      setBackupValues(backupValues);
    }
    if (
      changedValues.possuiDependentes === true &&
      Object.keys(backupValues).length > 0
    ) {
      data = { ...data, ...backupValues };
      setBackupValues({});
    }
    const changedKeys = Object.keys(changedValues);
    const updateKeys = [
      "pagaPensaoAlimenticia",
      "possuiGastoMensalSaude",
      "possuiGastoMensalEducacao",
      "possuiGastoMensalSaudeDependente",
      "possuiGastoMensalEducacaoDependente",
      "possuiDependentes",
      "rendaBrutaMensalMode",
      "gastoMensalSaudeMode",
      "gastoMensalEducacaoMode",
      "gastoMensalSaudeDependenteMode",
      "gastoMensalEducacaoDependenteMode",
      "gastoPensaoAlimenticiaMode",
    ];
    const mustUpdate =
      changedKeys.filter((key) => updateKeys.includes(key)).length > 0;
    if (mustUpdate) {
      setQuestions(pgblQuestions({ data, setHelpModal }));
    }
    const dataEntries = Object.entries(data);
    form.setFields(dataEntries.map(([name, value]) => ({ name, value })));
    form.resetFields(
      dataEntries
        .filter(([, value]) => value === undefined || value === null)
        .map(([name]) => name)
    );
  };

  useEffect(() => {
    getResume();
  }, [getResume]);

  return (
    <Container>
      <Content>
        <Typography.Title level={1}>
          {!resume || !editting || (editting && currentQuestion !== 0) ? (
            <>Simule sua economia ao fazer aporte em PGBL.</>
          ) : (
            <>
              Descubra qual o perfil de quem pode ter economia com aporte em
              PGBL
            </>
          )}
        </Typography.Title>
        {(!resume || !editting || (editting && currentQuestion !== 0)) && (
          <Typography.Paragraph>
            Estime a sua economia no imposto de renda ao investir em previdência
            (PGBL) pela XP e optar pela declaração completa no próximo exercício
            de IR.
          </Typography.Paragraph>
        )}
        {loadingGet ? (
          <Row justify="center" align="middle" className="loading-get">
            <Col>
              <LoadingOutlined />
            </Col>
          </Row>
        ) : !resume || editting ? (
          <Form size="large" form={form} onValuesChange={onValuesChange}>
            {questions.map((question, index, array) => (
              <Question key={question.id} show={index === currentQuestion}>
                {!question.hideQuestionNumber && (
                  <span className="question-number">
                    {index}/{array.length - 1}
                  </span>
                )}
                <Typography className="question-title">
                  {question.title}
                </Typography>
                {question.children}
                {question.formItems.map((formItem) => (
                  <Form.Item
                    key={formItem.name}
                    name={formItem.name}
                    rules={formItem.rules}
                    getValueFromEvent={formItem.getValueFromEvent}
                  >
                    {formItem.input}
                  </Form.Item>
                ))}
                {array.length - 1 === index && (
                  <Checkbox
                    value={autorizacao}
                    onChange={() => setAutorizacao((value) => !value)}
                  >
                    Ao aceitar, você autoriza a coleta e o compartilhamento de
                    seus dados pessoais pela Velotax junto à XP, que realizará o
                    tratamento das informações obtidas através do Simulador com
                    a finalidade de otimizar e personalizar a oferta de produtos
                    e serviços do Grupo XP Inc.
                  </Checkbox>
                )}
                <Row
                  gutter={[8, 8]}
                  justify="space-between"
                  className="question-buttons"
                >
                  <Col>
                    <Button
                      size="large"
                      variant="outlined"
                      onClick={() => formNavigate(-1)}
                    >
                      {index === 0 ? "Cancelar" : "Anterior"}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      size="large"
                      color="secondary"
                      variant="contained"
                      startIcon={loading && <LoadingOutlined />}
                      onClick={() => {
                        if (array.length - 1 === index && !autorizacao) {
                          message.warning(
                            "Você precisa aceitar nossa autorização de compartilhamento de dados para poder prosseguir com a simulação"
                          );
                        } else {
                          formNavigate(1);
                        }
                        if (array.length -1 === index && autorizacao) {
                          HandleTag("91")
                        }
                      }}
                    >
                      {loading
                        ? ""
                        : index === 0
                        ? "Começar agora"
                        : array.length - 1 === index
                        ? "Finalizar"
                        : "Próximo"}
                    </Button>
                  </Col>
                </Row>
              </Question>
            ))}
            {currentQuestion === 1 && (
              <Row className="renda-container" gutter={[8, 8]}>
                <Col span={24}>
                  (1) Consulta válida para rendas sujeitas a tabela progressiva
                  de IR (IN RFB N° 1756/2017).
                </Col>
                <Col span={24}>
                  (2) Valores de dedução de IR com base na IN RFB nº 2.065, de
                  24 de fevereiro de 2022.
                </Col>
                <Col span={24}>
                  (3) Cálculo realizado com base na tabela progressiva de INSS
                  do Anexo II da PI MTP nº 12/2022.
                </Col>
              </Row>
            )}
          </Form>
        ) : (
          <>
            <Question show={true} className="resume-container">
              <Row gutter={[0, 0]}>
                <AntButton
                  type="text"
                  onClick={handleEdit}
                  className="resume-edit-button"
                  icon={<FaRegEdit size={20} />}
                />
                <Col span={24}>
                  <Typography className="question-title">
                    Diagnóstico:
                  </Typography>
                </Col>
                {resume.o > 0 ? (
                  <>
                    <Col span={24}>
                      <Typography.Paragraph>
                        Você pode investir até um limite de{" "}
                        <strong style={{ whiteSpace: "nowrap" }}>
                          {formatCurrency(resume?.m)}
                        </strong>{" "}
                        em PGBL.
                      </Typography.Paragraph>
                    </Col>
                    <Col span={24}>
                      <Row
                        justify="space-between"
                        className="restituicao"
                        gutter={[2, 2]}
                      >
                        <Col span={12}>
                          <Typography.Paragraph>
                            <div>
                              Restituição <br /> completa (a)
                            </div>
                            <strong style={{ whiteSpace: "nowrap" }}>
                              {formatCurrency(
                                resume?.completa?.valorRestituicao
                              )}
                            </strong>
                          </Typography.Paragraph>
                        </Col>
                        <Col span={12}>
                          <Typography.Paragraph>
                            <div>
                              Restituição <br /> simplificada (b)
                            </div>
                            <strong style={{ whiteSpace: "nowrap" }}>
                              {formatCurrency(
                                resume?.simplificada?.valorRestituicao
                              )}
                            </strong>
                          </Typography.Paragraph>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Typography.Paragraph>
                        Portanto, sua economia no IR na forma de declaração
                        completa será de até{" "}
                        <strong style={{ whiteSpace: "nowrap" }}>
                          {formatCurrency(resume?.o)}
                        </strong>{" "}
                        no ano (a - b).
                      </Typography.Paragraph>
                    </Col>
                  </>
                ) : (
                  <Col span={24}>
                    <Typography.Paragraph>
                      Para o seu perfil, investir em VGBL pode ser uma melhor
                      opção.
                    </Typography.Paragraph>
                  </Col>
                )}
                <Col span={24}>
                  <Button
                    fullWidth
                    size="large"
                    color="secondary"
                    variant="contained"
                    startIcon={loading && <LoadingOutlined />}
                    href="https://experiencia.xpi.com.br/previdencia/#/"
                  >
                    Simular investimento
                  </Button>
                </Col>
                <Col span={24} className="assessor-text">
                  Em caso de dúvidas, pergunte ao seu assessor.
                </Col>
              </Row>
            </Question>
            {resume.o > 0 && (
              <Row className="renda-container" gutter={[8, 8]}>
                <Col span={24}>
                  (1) A economia indicada é baseada na comparação entre a
                  declaração feita de forma completa e desconto simplificado
                </Col>
                <Col span={24}>
                  (2) A dedução de imposto de renda por aplicação de PGBL
                  somente é válido para contribuintes do INSS.
                </Col>
                <Col span={24}>
                  (3) Os valores simulados são para fins ilustrativos apenas,
                  não englobando a possibilidade de perda no investimento em
                  PGBL
                </Col>
              </Row>
            )}
          </>
        )}

        <Modal
          footer={null}
          title="Obrigado!"
          visible={showModal}
          onCancel={() => setShowModal(false)}
        >
          O seu contato será enviado para um assessor da XP. Aguarde.
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setShowModal(false)}
            style={{
              display: "flex",
              marginLeft: "auto",
              marginTop: "32px",
            }}
          >
            Finalizar
          </Button>
        </Modal>
        <Modal
          footer={null}
          visible={!!helpModal}
          title={helpModal?.title}
          onCancel={() => setHelpModal(undefined)}
        >
          {helpModal?.body}
        </Modal>
      </Content>
    </Container>
  );
};
