import { Form } from "antd";
import React, { useState } from "react";
import ReactInputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import { cpfInputMask } from "../../utils";
import { useAuth } from "../../contexts/AuthContext";
import { useBroker } from "../../contexts/BrokerContext";
import { AuthContainer } from "../../styles/LoginSignUp/authContainer";
import {
  validationCpf,
  validationFieldRequired,
} from "../../utils/formValidations";

interface IData {
  cpf: string;
  pass: string;
}

const MaskCPF = React.forwardRef<HTMLElement>((props, ref) => (
  <ReactInputMask {...props} mask={cpfInputMask} />
));

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm<IData>();
  const { handleSignIn, loading } = useAuth();
  const { getIntegrationStatus } = useBroker();
  const [fieldsError, setFieldsError] = useState<{ [key: string]: string[] }>(
    {}
  );

  const onFinish = async (data: IData) => {
    try {
      await handleSignIn(data);
      getIntegrationStatus({ firstTime: true });
    } catch (err: any) {
      return;
    }
  };

  const onValuesChange = (changed: any, values: any) => {
    const cpf = changed.cpf ? changed.cpf.replace(/[_.-/-]/g, "") : values.cpf;
    form.setFieldsValue({
      ...values,
      cpf,
    });
  };

  const onFieldsChange = () => {
    setFieldsError(
      form
        .getFieldsError()
        .reduce((acc, cur) => ({ ...acc, [cur.name?.[0]]: cur.errors }), {})
    );
  };

  const handleNavigate = () => {
    navigate("/p/cadastrar");
  };

  return (
    <AuthContainer className="beauty-scrollbar">
      <div className="auth-content">
        <div className="form-container">
          <h1>Acesse sua conta</h1>
          <Form
            form={form}
            size="large"
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            onFieldsChange={onFieldsChange}
          >
            <Form.Item name="cpf" rules={validationCpf}>
              <TextField
                label="CPF"
                variant="standard"
                error={fieldsError["cpf"]?.length > 0}
                InputProps={{ inputComponent: MaskCPF as any }}
              />
            </Form.Item>

            <Form.Item name="pass" rules={validationFieldRequired}>
              <TextField
                label="Senha"
                type="password"
                variant="standard"
                error={fieldsError["pass"]?.length > 0}
              />
            </Form.Item>

            <div className="buttons">
              <Button
                size="large"
                type="submit"
                color="secondary"
                variant="contained"
                className="full-width"
                disabled={loading}
                startIcon={loading && <LoadingOutlined />}
              >
                Entrar
              </Button>
            </div>
            <div className="bottom-acessar">
              <Button
                type="button"
                disabled={loading}
                style={{ fontSize: "11px" }}
                onClick={() => navigate("/p/forgot-password")}
              >
                <span className="xp-link">Esqueci minha senha</span>
              </Button>
            </div>
          </Form>

          <div className="footer">
            Ainda não possui uma conta?
            <Button onClick={handleNavigate}>
              <span className="xp-link">Começar</span>
            </Button>
          </div>
        </div>
      </div>
    </AuthContainer>
  );
};
