import { Checkbox, Row } from "antd";
import { IoMdGlobe } from "react-icons/io";
import { BiHomeAlt } from "react-icons/bi";
import { IconBaseProps } from "react-icons";
import { RiStockLine } from "react-icons/ri";
import React, { useEffect, useState } from "react";
import { BsCurrencyBitcoin } from "react-icons/bs";
import { LoadingOutlined } from "@ant-design/icons";
import { Container } from "./styles";

interface IProps {
  data: string[];
  saveModules: (
    modules: string[],
    moduleName: string,
    deleted: boolean
  ) => void;
  loading: any;
}

interface ISelect {
  id: string;
  icon: IconBaseProps;
  label: string;
}

const VelotaxCalculatorsSelect: ISelect[] = [
  {
    id: "bolsa",
    icon: <RiStockLine size={36} />,
    label: "Investimentos na Bolsa de Valores",
  },
  {
    id: "cripto",
    icon: <BsCurrencyBitcoin width="36px" height="36px" />,
    label: "Investimentos em Criptoativos",
  },
  {
    id: "exterior",
    icon: <IoMdGlobe size={36} />,
    label: "Investimentos no exterior",
  },
  {
    id: "autonomos",
    icon: <BiHomeAlt size={36} />,
    label: "Autônomos e Aluguel",
  },
];

export const SelectBoxes: React.FC<IProps> = ({
  data = {},
  saveModules,
  loading,
}) => {
  const [values, setValues] = useState<string[]>((data || []) as string[]);

  useEffect(() => {
    if (Array.isArray(data)) {
      setValues(data);
    }
  }, [data]);

  const handleSetValue = (
    array: string[],
    moduleName: string,
    deleted: boolean
  ) => {
    setValues(array);
    saveModules(array, moduleName, deleted);
  };

  const handleChange = (value: string): void => {
    if (values.includes(value)) {
      handleSetValue(
        values.filter((vl) => vl !== value),
        value,
        true
      );
    } else {
      handleSetValue([...values, value], value, false);
    }
  };

  return (
    <Container>
      {VelotaxCalculatorsSelect.map((op: any, index: number) => (
        <Row style={{ marginBottom: "8px" }} key={op.id + index}>
          {loading?.[op.id] === true ? (
            <div className="loading-wrapper">
              <LoadingOutlined />
              <div
                className={`checkbox-wrapper ${
                  loading?.[op.id] === true ? "loading" : ""
                }`}
              >
                {op.icon && op.icon}
                <div className="label">{op.label}</div>
              </div>
            </div>
          ) : (
            <Checkbox
              disabled={Object.keys(loading).some(
                (key: string) => loading[key] === true
              )}
              checked={values.includes(op.id)}
              onChange={() => handleChange(op.id)}
            >
              <div className="checkbox-wrapper">
                {op.icon && op.icon}
                <div className="label">{op.label}</div>
              </div>
            </Checkbox>
          )}
        </Row>
      ))}
    </Container>
  );
};
