import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 64px 32px 128px;

  div.ant-typography {
    font-size: 0.875rem !important;
  }

  h1 {
    width: 700px;
    margin: 0 auto 1.5rem;
    font-weight: 400;
    font-size: 2rem;
    position: relative;
    line-height: 2.5rem;
    padding-bottom: 1rem;
    color: var(--velotax-font-color-light);

    :after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 100px;
      background-color: var(--ant-primary-color);
    }
  }

  @media only screen and (max-device-width: 812px) {
    padding: 0;

    h1 {
      width: calc(100% - 48px);
      width: calc(100% - 48px);
      padding: 24px 0 16px;
      margin: 0 24px 1.5rem;
    }
  }
`;
export const Content = styled.div`
  padding: 32px;
  width: 700px;
  border-radius: 4px;
  background-color: var(--velotax-background-color);

  .last-update {
    display: block;
    font-size: 11px;
    letter-spacing: 1px;
    margin-bottom: 16px;
  }
  div.ant-typography {
    font-size: 18px;
  }
  .ant-typography {
    color: var(--velotax-font-color);
    text-align: justify;
  }
  @media only screen and (max-device-width: 812px) {
    padding: 24px;
    border-radius: 0;
    width: 100%;
    h2 {
      &::before {
        left: -24px;
      }
    }
  }
`;
