import styled from "styled-components";

export const Container = styled.div`
  .ant-collapse {
    border: none;

    .ant-collapse-item {
      margin-bottom: 8px;
      border-radius: 4px;
      background-color: var(--velotax-ghost-white);
      border: 2px solid var(--velotax-ghost-white);
      &.ant-collapse-item-active {
        .ant-collapse-header {
          border-radius: 4px 4px 0 0;
          background-color: var(--velotax-background-color);
          .ant-typography {
            color: var(--ant-primary-color);
            button {
              svg {
                fill: var(--ant-primary-color);
              }
            }
          }
        }
      }
      &.ant-collapse-item-disabled {
        .ant-collapse-header {
          cursor: pointer;
        }
      }
      &.collapse-skip-btn {
        .ant-spin-nested-loading {
          width: 100%;
        }
        .ant-collapse-header {
          padding: 0;
          .collapse-skip-btn-content {
            padding: 24px 32px;
          }
        }
      }
      .ant-collapse-header {
        border-radius: 4px;
        padding: 24px 32px;
        background-color: var(--velotax-ghost-white);
        .ant-typography {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 1rem;
          font-weight: bold;
          line-height: 32px;
          color: var(--velotax-font-color-dark);
          & > div {
            display: flex;
            align-items: center;
          }
          button {
            display: inline-flex;
            height: 32px !important;
            svg {
              width: 20px;
              height: 20px;
              fill: var(--velotax-font-color-dark);
            }
          }
          .opcao-recomendada {
            padding: 2px 4px;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1rem;
            color: var(--white);
            white-space: nowrap;
            border-radius: 4px;
            display: flex;
            align-items: center;
            background-color: var(--ant-success-color);
            svg {
              margin-right: 4px;
            }
          }
        }
        .sub-title {
          font-size: 0.8125rem;
          color: var(--ant-primary-color);
        }
      }
      .ant-collapse-content {
        border: none;
        border-radius: 0 0 8px 8px;
        background-color: var(--velotax-background-color);
        .ant-collapse-content-box {
          padding: 16px 16px 32px;
          .ant-steps-item-title {
            margin-top: 0.25rem;
            /* font-size: 0.875rem; */
            line-height: 1.5rem;
          }
          .ant-btn {
            font-size: 14px;
          }
        }
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    .ant-collapse {
      .ant-collapse-item {
        &.collapse-skip-btn {
          .ant-collapse-header {
            .collapse-skip-btn-content {
              padding: 8px 16px;
              .ant-typography {
                font-size: 0.95rem;
                line-height: 1.25rem;
                flex-direction: column;
                align-items: flex-start;
              }
              .sub-title {
                margin-top: 4px;
                font-size: 0.75rem;
              }
            }
          }
        }
        .ant-collapse-header {
          padding: 8px 16px;
          .ant-typography {
            font-size: 0.95rem;
            line-height: 1.5rem;
            flex-direction: column;
            align-items: flex-start;
          }
        }
      }
    }
  }
`;
