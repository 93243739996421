import { Rule } from "antd/lib/form";
import {
  checkDateExpiration,
  isDateOnReferenceMonth,
  isNonDateOnFuture,
  isValidCEP,
  isValidCNPJ,
  isValidCPF,
  isValidCreditCard,
  isValidDate,
  isValidDeclarationReceipt,
  isValidEmail,
  isValidFullName,
  isValidIPTU,
  isValidPhone,
  isValidRenavam,
  isValidShelfLife,
  isValidStateAddress,
  isValidVoterCard,
  months,
} from ".";

const dataLimite = "2021-12-31 23:59:59";

export const validationCpf: Rule[] = [
  {
    required: true,
    validator: (_: any, value: string) =>
      !value || !isValidCPF(value)
        ? Promise.reject(new Error("Informe um CPF válido"))
        : Promise.resolve(),
  },
];

export const validationCnpj: Rule[] = [
  {
    validator: (_: any, value: string) =>
      !value || !isValidCNPJ(value)
        ? Promise.reject(new Error("Informe um CNPJ válido"))
        : Promise.resolve(),
  },
];

export const validationCnpjNotRequired: Rule[] = [
  {
    validator: (_: any, value: string) =>
      value && !isValidCNPJ(value)
        ? Promise.reject(new Error("Informe um CNPJ válido"))
        : Promise.resolve(),
  },
];

export const validationFullName: Rule[] = [
  {
    validator: (rule, value) =>
      !value || !isValidFullName(value)
        ? Promise.reject(
            new Error("Seu nome deve possuir duas palavras ou mais")
          )
        : Promise.resolve(),
  },
];

export const validationEmail: Rule[] = [
  {
    validator: (rule, value) =>
      !value || !isValidEmail(value)
        ? Promise.reject(new Error("Informe um email válido"))
        : Promise.resolve(),
  },
];

export const validationPhone: Rule[] = [
  {
    validator: (rule, value) =>
      !value || isValidPhone(value)
        ? Promise.resolve()
        : Promise.reject(new Error("Informe um telefone válido")),
  },
];

export const validationPhoneRequired: Rule[] = [
  {
    required: true,
    validator: (rule, value) =>
      !value || !isValidPhone(value)
        ? Promise.reject(new Error("Informe um telefone válido"))
        : Promise.resolve(),
  },
];

export const validationDateNotRequired: Rule[] = [
  {
    validator: (rule, value) =>
      !value || isValidDate(value)
        ? Promise.resolve()
        : Promise.reject(new Error("Informe uma data válida")),
  },
];

export const validationDate: Rule[] = [
  {
    required: true,
    validator: (rule, value) =>
      !isValidDate(value)
        ? Promise.reject(new Error("Informe uma data válida"))
        : Promise.resolve(),
  },
];

export const validationDateIsOnReferenceMonth: (
  month: number,
  year: number
) => Rule[] = (month, year) => [
  {
    validator: (_: any, value: string) =>
      isDateOnReferenceMonth(month, year, value)
        ? Promise.resolve()
        : Promise.reject(
            new Error(
              `Informe uma data válida do mês de ${months[month]} de ${year}`
            )
          ),
  },
];

export const validationDateIsOnReferenceMonthCompra: Rule[] = [
  {
    validator: async (_: any, value: string) => {
      if (!value) return Promise.reject(new Error(`Informe uma data válida`));
      const today = new Date();
      const pastLimit = today.getFullYear() - 6;
      const [day, month, year] = value.split("/");
      const date = new Date(Number(year), Number(month) - 1, Number(day));
      return value.length < 10 || (value.length === 10 && !isValidDate(value))
        ? Promise.reject(new Error(`Informe uma data válida`))
        : Number(year) < pastLimit
        ? Promise.reject(new Error(`Informe um ano superior a ${pastLimit}`))
        : date.getTime() > today.getTime()
        ? Promise.reject(new Error("Não é permitida uma data futura"))
        : Promise.resolve();
    },
  },
];

export const validationDateIsOnFuture: (
  month: number,
  year: number
) => Rule[] = (month, year) => [
  {
    validator: (_: any, value: string) =>
      isNonDateOnFuture(month, year, value)
        ? Promise.resolve()
        : Promise.reject(
            new Error(
              `Informe uma data menor ou igual ao mês de ${months[month]} de ${year}`
            )
          ),
  },
];

export const validationIPTU: Rule[] = [
  {
    validator: (rule, value) =>
      isValidIPTU(value)
        ? Promise.resolve()
        : Promise.reject(
            new Error("O número do IPTU precisa ter entre 8 e 30 caracteres.")
          ),
  },
];

export const validationDateNotRequired2021: Rule[] = [
  {
    validator: (rule, value) =>
      value && !isValidDate(value)
        ? Promise.reject(new Error("Informe uma data válida"))
        : value && !checkDateExpiration(dataLimite, value)
        ? Promise.reject(new Error("Informe uma data até 31/12/2021"))
        : Promise.resolve(),
  },
];

export const validationDate2021: Rule[] = [
  {
    required: true,
    validator: (rule, value) =>
      !isValidDate(value)
        ? Promise.reject(new Error("Informe uma data válida"))
        : !checkDateExpiration(dataLimite, value)
        ? Promise.reject(new Error("Informe uma data até 31/12/2021"))
        : Promise.resolve(),
  },
];

export const validationVoterCard: Rule[] = [
  {
    validator: (rule, value) =>
      !value || isValidVoterCard(value)
        ? Promise.resolve()
        : Promise.reject(new Error("Informe um Título de Eleitor válido")),
  },
];

export const validationRenavam: Rule[] = [
  {
    validator: (rule, value) =>
      !value || isValidRenavam(value)
        ? Promise.resolve()
        : Promise.reject(new Error("Informe um Renavam válido")),
  },
];

export const validationFieldRequired: Rule[] = [
  {
    required: true,
    message: "Este campo é obrigatório",
  },
];

export const validationNumberPercentageField: Rule[] = [
  ...validationFieldRequired,
  {
    min: 0.0001,
    type: "number",
    message: "O valor deve ser maior que 0,0001",
  },
  {
    max: 100,
    type: "number",
    message: "O valor deve ser menor que 100 (cem)",
  },
];

export const validationNumberPercentageFieldPositive: Rule[] = [
  ...validationFieldRequired,
  {
    min: 0,
    type: "number",
    message: "O valor deve estar entre 0 (zero) e 100 (cem)",
  },
  {
    max: 100,
    type: "number",
    message: "O valor deve estar entre 0 (zero) e 100 (cem)",
  },
];

export const validationNumberField: Rule[] = [
  ...validationFieldRequired,
  {
    min: 0.01,
    type: "number",
    message: "O valor deve ser maior que 0 (zero)",
  },
];

export const validationNumberAllowNegativeField: Rule[] = [
  ...validationFieldRequired,
  {
    type: "number",
    message: "Não é um número válido",
  },
];

export const validationNumberFieldPositive: Rule[] = [
  ...validationFieldRequired,
  {
    min: 0,
    type: "number",
    message: "O valor deve ser positivo",
  },
];

export const validationQuantityCryptoField: Rule[] = [
  ...validationFieldRequired,
  {
    min: 0,
    type: "number",
    message: "O valor deve ser positivo",
  },
];

export const validationDeclarationReceipt: Rule[] = [
  {
    validator: (rule, value) =>
      isValidDeclarationReceipt(value)
        ? Promise.resolve()
        : Promise.reject(
            new Error("O número da declaração deve ter 10 ou 12 dígitos")
          ),
  },
];

export const validationStateAddress: Rule[] = [
  {
    required: true,
    validator: (rule, value) =>
      value && isValidStateAddress(value)
        ? Promise.resolve()
        : Promise.reject(new Error("Informe um Estado válido")),
  },
];

export const validationCardShelfLife: Rule[] = [
  {
    required: true,
    validator: (rule, value) =>
      isValidShelfLife(value)
        ? Promise.reject(new Error("Informe uma data válida"))
        : Promise.resolve(),
  },
];

export const validationCep: Rule[] = [
  {
    required: true,
    validator: (rule, value) =>
      isValidCEP(value ?? "")
        ? Promise.resolve()
        : Promise.reject(new Error("Informe um CEP válido")),
  },
];

export const validationAmexCVV: Rule[] = [
  {
    required: true,
    validator: (rule, value) =>
      (value?.replace(/[_]/g, "") ?? "")?.length === 4
        ? Promise.resolve()
        : Promise.reject(new Error("Insira um código de segurança válido")),
  },
];

export const validationCVV: Rule[] = [
  {
    required: true,
    validator: (rule, value) =>
      (value?.replace(/[_]/g, "") ?? "")?.length === 3
        ? Promise.resolve()
        : Promise.reject(new Error("Insira um código de segurança válido")),
  },
];

export const validationPasswordNotRequired: Rule[] = [
  { min: 6, message: "A senha deve ter pelo menos 6 caracteres" },
];

export const validationPassword: Rule[] = [
  { required: true, message: "Informe uma senha" },
  { min: 6, message: "A senha deve ter pelo menos 6 caracteres" },
];

export const validationPassConfirmation: Rule[] = [
  ({ getFieldValue }) => ({
    validator: (_: any, value: string) =>
      value === getFieldValue("pass")
        ? Promise.resolve()
        : Promise.reject(new Error("As senhas não coincidem")),
  }),
];

export const validationCreditCardNumber: Rule[] = [
  {
    validator: (rule, value: string) =>
      isValidCreditCard(value?.replace(/[^0-9\\.]+/g, ""))
        ? Promise.resolve()
        : Promise.reject(new Error("Número de cartão inválido")),
  },
];
