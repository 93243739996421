import styled from "styled-components";
import { DrawerModal } from "../../components/DrawerModal";

export const ModalStyled = styled(DrawerModal)`
  &.ant-modal {
    div.ant-modal-content {
      background-color: var(--white);
      .ant-modal-close {
        top: 0;
        right: 0;
      }
      .ant-modal-close-icon {
        color: var(--ant-primary-color);
      }
      .ant-modal-header {
        padding: 48px 64px 24px 48px;
        background-color: var(--white);
        .ant-modal-title {
          font-size: 24px;
          color: var(--velotax-font-color-dark);
        }
      }
      .ant-modal-body {
        padding: 8px 48px 48px;
      }
    }
  }
  .ant-table {
    width: 100%;
  }
  .mobile-list {
    display: none;
  }
  .table-total {
    padding: 16px;
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    justify-content: space-between;
    h3 {
      margin: 0;
      font-size: 14px;
    }
    span.ant-typography {
      font-weight: 600;
      width: 116px;
    }
    &.exterior {
      margin-bottom: 16px;
      span.ant-typography {
        width: calc(30% - 22px);
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    &.ant-drawer {
      .ant-drawer-content-wrapper {
        .ant-drawer-content {
          background-color: var(--white) !important;
          .ant-drawer-wrapper-body {
            .ant-drawer-header {
              .ant-drawer-header-title {
                .ant-drawer-title {
                  color: var(--velotax-font-color-dark) !important;
                }
              }
            }
          }
        }
      }
    }
    .ant-table {
      display: none;
    }
    .mobile-list {
      width: 100%;
      display: flex;
      margin: 24px 0 0;
      flex-direction: column;
      .list-item {
        width: 100%;
        padding: 16px 24px;
        border-top: 1px solid #e8e8e8;
        :last-of-type {
          border-bottom: 1px solid #e8e8e8;
        }
        p {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 4px;
        }
        .list-item-label {
          width: 48px;
          font-weight: 500;
          display: inline-block;
        }
        .list-item-value {
          width: calc(100% - 48px);
          display: inline-block;
        }
      }
      .list-empty {
        padding: 16px 0;
        margin-top: -24px;
        text-align: center;
        background-color: #e8e8e8;
      }
      &.exterior {
        .list-item-label {
          width: 100px;
        }
        .list-item-value {
          width: calc(100% - 100px);
        }
      }
    }
    .table-total {
      padding: 24px;
      span.ant-typography {
        width: auto !important;
        text-align: right;
      }
    }
  }
`;

export const BolsaOperations = styled.div`
  margin-top: 20px;
  border-radius: 8px;
  color: var(--velotax-font-color-light);
  background-color: var(--velotax-ghost);
  border: 1px solid var(--velotax-background-color-ghost);

  div.strong span {
    font-weight: 700 !important;
  }
  div.opacity {
    opacity: 0.8;
  }

  span.dividendos-header {
    background-color: var(--velotax-background-color-ghost) !important;
    font-weight: 700 !important;
  }
  div.header,
  div.sub-header,
  div.row {
    display: grid;
    grid-template-columns: 0.7fr 1fr 0.5fr;
    span {
      text-align: center;
      justify-content: center;
    }
  }

  div.sub-header,
  div.row {
    height: auto;
    min-height: 48px;
    border-top: none;
    & + div.sub-header {
      border-top: 1px solid var(--velotax-background-color-ghost);
    }
    & > span + span {
      background-color: var(--velotax-background-color);
    }
    & > span {
      display: flex;
      align-items: center;
      border-left: none;
    }
    &:last-of-type > span:last-of-type {
      border-radius: 0 0 8px 0;
    }
  }

  div.header {
    padding: 16px;
    border-radius: 8px 8px 0 0;
    background-color: var(--velotax-background-color-ghost);
    span {
      font-weight: 700;
    }
    button {
      display: none;
    }
  }

  div.sub-header {
    font-size: 14px;
    & > span {
      font-weight: 300;
    }
  }

  div.row {
    opacity: 0.85;
    font-size: 13px;
    border-top: 1px solid var(--velotax-background-color-ghost);
  }

  @media only screen and (max-device-width: 812px) {
    div.sub-header {
      font-size: 12px;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  &,
  h4,
  .ant-typography {
    color: var(--velotax-font-color-dark);
  }
  .MuiButtonBase-root {
    height: 40px;
    margin: -60px 0 32px auto;
    border: 1px solid #e8e8e8;
    border-radius: 24px !important;
    text-transform: none !important;
    .MuiButton-startIcon {
      width: 18px;
      height: 18px;
      display: flex;
      margin-right: 6px;
      align-items: center;
      svg {
        fill: var(--ant-primary-color);
      }
    }
  }
  @media only screen and (max-device-width: 812px) {
    .MuiButtonBase-root {
      width: 60%;
      margin: -16px 0 0 0;
    }
  }
`;
