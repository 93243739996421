import { Typography } from "antd";
// import { GiProfit } from "react-icons/gi";
import { AiOutlineApi } from "react-icons/ai";
// import { RiFileList3Line } from "react-icons/ri";
import { HiOutlineCalculator } from "react-icons/hi";
// import { MdSwapVerticalCircle } from "react-icons/md";
// import { isMobile } from "../../utils";

export const itensCrypto = [
  {
    premium: true,
    id: "darf",
    link: "/xpinvestimentos/cripto-historic",
    content: <Typography>Calculadora de DARF</Typography>,
    description: <Typography>Cálculo de IR e emissão de DARF</Typography>,
    icon: (
      <HiOutlineCalculator
        className="no-fill"
        color="var(--ant-primary-color)"
      />
    ),
  },
  // {
  //   premium: true,
  //   id: "anual-report",
  //   link: "/xpinvestimentos/bolsa-report",
  //   content: <Typography>Informe de rendimentos (2022)</Typography>,
  //   description: <Typography>Relatório para declaração do IRPF</Typography>,
  //   icon: (
  //     <RiFileList3Line className="no-fill" color="var(--ant-primary-color)" />
  //   ),
  // },
  // {
  //   premium: true,
  //   id: "report",
  //   link: "/xpinvestimentos/cripto-report",
  //   content: (
  //     <Typography>
  //       Relatórios {!isMobile() && "(padrão Receita Federal)"}
  //     </Typography>
  //   ),
  //   icon: (
  //     <RiFileList3Line className="no-fill" color="var(--ant-primary-color)" />
  //   ),
  //   description: (
  //     <Typography>Relatórios auxiliares para declaração de IR</Typography>
  //   ),
  // },
  // {
  //   id: "dividendos",
  //   link: "/xpinvestimentos/bolsa-dividendos",
  //   premium: true,
  //   content: <Typography>Dividendos</Typography>,
  //   description: <Typography>Gestão dos dividendos recebidos</Typography>,
  //   icon: <GiProfit className="no-fill" color="var(--ant-primary-color)" />,
  // },
  // {
  //   premium: true,
  //   id: "insert-manual",
  //   link: "/xpinvestimentos/bolsa-insert-manual",
  //   content: <Typography>Posição 31/12/2019</Typography>,
  //   icon: (
  //     <MdSwapVerticalCircle
  //       className="no-fill"
  //       color="var(--ant-primary-color)"
  //     />
  //   ),
  // },
  {
    id: "integration",
    premium: true,
    link: "/xpinvestimentos/cripto-integration",
    content: <Typography>Notas de corretagem</Typography>,
    description: <Typography>Carregar notas de corretagem</Typography>,

    icon: <AiOutlineApi className="no-fill" color="var(--ant-primary-color)" />,
  },
];
