import { Form, message } from "antd";
import React, { useState } from "react";
import ReactInputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import api from "../../services/api";
import { cpfInputMask } from "../../utils";
import { validationCpf } from "../../utils/formValidations";
import { AuthContainer } from "../../styles/LoginSignUp/authContainer";

interface IData {
  cpf: string;
}

const MaskCPF = React.forwardRef<HTMLElement>((props, ref) => (
  <ReactInputMask {...props} mask={cpfInputMask} />
));

export const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm<IData>();
  const [loading, setLoading] = useState(false);
  const [fieldsError, setFieldsError] = useState<{ [key: string]: string[] }>(
    {}
  );

  const onFinish = async (data: IData) => {
    try {
      setLoading(true);

      const res = await api.post("/user/forgot-password", { cpf: data.cpf });
      res.data?.message?.includes("@")
        ? message.success(res.data?.message)
        : message.success(
            "Um email com um link foi enviado para recuperar sua senha!",
            5
          );

      setTimeout(() => {
        setLoading(false);
        navigate("/p/login");
      }, 500);
    } catch (err: any) {
      if (err?.response?.status === 400) {
        message.error(err.response?.data?.message || "Ocorreu um erro");
      }
      setLoading(false);
      return;
    }
  };

  const onValuesChange = (changed: any, values: any) => {
    const cpf = changed.cpf ? changed.cpf.replace(/[_.-/-]/g, "") : values.cpf;
    form.setFieldsValue({
      cpf,
    });
  };

  const onFieldsChange = () => {
    setFieldsError(
      form
        .getFieldsError()
        .reduce((acc, cur) => ({ ...acc, [cur.name?.[0]]: cur.errors }), {})
    );
  };

  const handleNavigateCreateAccount = () => {
    navigate("/p/cadastrar");
  };

  const handleNavigateLogin = () => {
    navigate("/p/login");
  };

  return (
    <AuthContainer className="beauty-scrollbar">
      <div className="auth-content">
        <div className="form-container">
          <h1>Recupere sua senha</h1>
          <Form
            form={form}
            size="large"
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            onFieldsChange={onFieldsChange}
          >
            <Form.Item name="cpf" rules={validationCpf}>
              <TextField
                label="CPF"
                variant="standard"
                error={fieldsError["cpf"]?.length > 0}
                InputProps={{ inputComponent: MaskCPF as any }}
              />
            </Form.Item>

            <div className="buttons">
              <Button
                size="large"
                type="submit"
                color="secondary"
                variant="contained"
                className="full-width"
                disabled={loading}
                startIcon={loading && <LoadingOutlined />}
              >
                Recuperar
              </Button>
            </div>
            <div className="bottom-acessar">
              <Button
                type="button"
                onClick={handleNavigateLogin}
                style={{ fontSize: "11px" }}
              >
                <span className="xp-link">Voltar para o login</span>
              </Button>
            </div>
          </Form>

          <div className="footer">
            Ainda não possui uma conta?
            <Button type="button" onClick={handleNavigateCreateAccount}>
              <span className="xp-link">Começar</span>
            </Button>
          </div>
        </div>
      </div>
    </AuthContainer>
  );
};
