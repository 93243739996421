import { Button } from "@mui/material";
import { Col, Modal, Row } from "antd";

export function ModalConfirmWaitIntegration({
  action,
  closeFn,
  visible,
}: {
  action: (ok: boolean) => void;
  visible: boolean;
  closeFn: () => void;
}) {
  function handleClick(send: boolean) {
    action(send);
    closeFn();
  }

  return (
    <Modal
      title="Integração em andamento"
      visible={visible}
      onCancel={() => handleClick(false)}
      closable={true}
      footer={
        <Row
        gutter={[16, 16]}
        style={{
          marginBottom: "16px",
          paddingLeft: "18px",
          paddingRight: "18px",
        }}>
          <Button
            fullWidth
            size="large"
            color="secondary"
            variant="contained"
            onClick={() => {
              handleClick(true);
            }}>
            Entendido
          </Button>
        </Row>
      }>
      <Row>
        <Col>
          Estamos processando um grande número de informações.
        </Col>
      </Row>
      <Row>
        <Col>
          <p>Assim que concluirmos, nós avisaremos por e-mail.</p>
        </Col>
      </Row>
    </Modal>
  );
}
