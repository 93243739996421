import { useEffect, useRef } from "react";

declare global {
  interface Window {
    Trustpilot: any;
  }
}

export const TrustPilotTrustBox = () => {
  const ref = useRef(null);
  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <div
      ref={ref}
      className="trustpilot-trustbox-widget"
      data-locale="pt-BR"
      data-template-id="54ad5defc6454f065c28af8b"
      data-businessunit-id="61c22f4a09429cb13ef90643"
      data-style-height="240px"
      data-style-width="100%"
      data-theme="dark"
      data-stars="5"
      data-review-languages="pt"
    >
      <a
        href="https://br.trustpilot.com/review/velotax.com.br"
        target="_blank"
        rel="noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
};

export const TrustPilotMicroReviewCount = () => {
  const ref = useRef(null);
  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale="pt-BR"
      data-template-id="5419b6a8b0d04a076446a9ad"
      data-businessunit-id="61c22f4a09429cb13ef90643"
      data-style-height="24px"
      data-style-width="100%"
      data-theme="dark"
      data-style-alignment="center"
    >
      <a
        target="_blank"
        rel="noreferrer"
        href="https://br.trustpilot.com/review/velotax.com.br"
      >
        Trustpilot
      </a>
    </div>
  );
};
