import styled from "styled-components";

export const BolsaManualContainer = styled.div`
  height: 100%;
  overflow-y: hidden;
  h2 {
    margin: 0 0 0 0 !important;
  }
  .beauty-scrollbar{
    margin-top: 64px;
  }
`;
