import { Collapse, Image, Typography } from "antd";
import { DrawerModal, DrawerModalProps } from "../../components/DrawerModal";
import xpIntegrationExample from "../../assets/integracao_xp_exterior_fundo_branco.gif";

export const ModalIntegrationExterior: React.FC<DrawerModalProps> = (props) => (
  <DrawerModal
    closable
    maskClosable={false}
    title="Relatórios indisponíveis"
    {...props}
  >
    <Typography.Paragraph>
      Os seus relatórios estão indisponíveis para download. Isso pode ocorrer
      por alguns motivos:
    </Typography.Paragraph>
    <ol style={{ marginLeft: "16px" }}>
      <li>Você não realizou transações com a sua conta XP International; ou</li>
      <li>
        Você ainda não permitiu a conexão entre XP e Velotax dentro do
        aplicativo XP
      </li>
    </ol>
    <Typography.Paragraph strong>
      Caso você ainda não tenha permitido a conexão, siga o passo a passo
      abaixo.
    </Typography.Paragraph>

    <Collapse
      className="collapse"
      style={{ border: "1px solid var(--velotax-font-color)" }}
    >
      <Collapse.Panel
        key="1"
        header={
          <Typography.Text strong style={{ fontSize: "1rem" }}>
            Como conectar Velotax e XP International?
          </Typography.Text>
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            margin: "2rem 0",
          }}
        >
          <Image width={430} src={xpIntegrationExample} />
        </div>
        <Typography.Title level={5} style={{ fontSize: "14px" }}>
          Instruções
        </Typography.Title>
        <ul style={{ fontSize: "13px" }}>
          <li>
            <strong>Passo 1</strong>. Acesse a aba de{" "}
            <strong>Investimento Global</strong> dentro do aplicativo da XP
          </li>
          <li>
            <strong>Passo 2</strong>. Deslize a tela para baixo e clique em{" "}
            <strong>Imposto de Renda e DARF</strong>
          </li>
          <li>
            <strong>Passo 3</strong>. Clique em{" "}
            <strong>Calcular Imposto de Renda</strong> e aceite os termos
          </li>
        </ul>
      </Collapse.Panel>
    </Collapse>
  </DrawerModal>
);
