import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  .velotax-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 64px 32px 32px;

    & > .ant-typography {
      width: 700px;
      display: block;
      color: var(--velotax-font-color-light);
      &.destak {
        font-weight: 700;
        font-size: 1rem;
        padding-left: 16px;
        line-height: 1.5rem;
        margin: 32px auto 24px;
        border-left: 4px solid var(--ant-primary-color);
      }
    }

    .page-subtitle {
      width: 700px;
      column-gap: 8px;
      font-Size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      margin: 0 auto 2.5rem;
      color: var(--velotax-font-color-light);
    }

    .velotax-content {
      width: 700px;
      padding: 48px;
      margin: 0 auto;
      border-radius: 4px;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 88, 0.08);
      background-color: var(--velotax-background-color);

      &.plano {
        margin-bottom: 48px;
        background-color: var(--white);
      }

      &.velotax-content-transparent {
        padding: 0;
        border: none;
        box-shadow: none;
        margin: 24px auto 0;
        background-color: transparent;
      }

      &.historic {
        .anticon.anticon-loading.anticon-spin {
          width: 16px !important;
          height: 16px !important;
          svg {
            width: 16px !important;
            height: 16px !important;
          }
        }
      }

      h2.subtitle-lined {
        display: flex;
        white-space: nowrap;
        align-items: center;
        width: 100%;
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.5rem;
        margin: 2.5rem 0 1.5rem;
        span {
          margin-right: 16px;
        }
        &:after {
          content: "";
          height: 2px;
          flex-grow: 1;
          background-color: #313131;
        }
      }
    }
  }

  h1.page-title {
    width: 700px;
    font-size: 2rem;
    font-weight: 400;
    position: relative;
    line-height: 2.5rem;
    padding-bottom: 1rem;
    margin: 0 auto 1.5rem;
    color: var(--velotax-font-color-light);

    :after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 100px;
      background-color: var(--ant-primary-color);
    }
  }

  .ant-btn.ant-btn-primary.btn-premium {
    z-index: 1;
    right: -6px;
    font-size: 13px;
    position: absolute;
    top: calc(50% - 16px);
    border-radius: 4px 4px 0 4px;
    svg {
      width: 15px;
      height: 15px;
    }
    :after {
      content: "";
      width: 0;
      height: 0;
      right: -1px;
      bottom: -10px;
      position: absolute;
      border-left: 0 solid transparent;
      border-right: 6px solid transparent;
      border-top: 10px solid var(--ant-primary-color-active);
    }
  }

  .btn-contratar-plano-historic {
    top: 0;
    right: 0;
    height: 40px;
    position: absolute;
  }

  @media only screen and (max-device-width: 812px) {
    .velotax-container {
      padding: 0;
      min-height: 100%;

      .page-title-outside {
        width: 100%;
        font-size: 1.5rem;
        line-height: 2rem;
        padding: 24px 24px 0;
      }

      & > .ant-typography {
        width: 100%;
        &.destak {
          margin: 16px 0;
          font-size: 14px;
          line-height: 20px;
          padding-left: 20px;
        }
      }

      .velotax-content {
        width: 100%;
        padding: 24px 24px 64px;
        border-radius: 0;
        box-shadow: none;

        &.plano {
          margin-bottom: 24px;
        }

        &.velotax-content-transparent {
          padding: 24px;
          margin: 0 auto;
          border-top: 1px solid var(--velotax-background-color-ghost);
        }
      }
    }

    h1.page-title {
      font-size: 1.5rem;
      line-height: 2rem;
      padding-bottom: 0.5rem;
      padding: 24px 0 1rem;
      margin: 0 24px 1.5rem;
      width: calc(100% - 48px) !important;
    }

    h2.subtitle-lined {
      padding: 0 !important;
      font-size: 1.125rem;
      line-height: 1.5rem;
      margin: 3rem 0 1.5rem;
    }

    .page-subtitle {
      width: 100%;
      padding: 0 24px;
    }

    .ant-btn.ant-btn-primary.btn-premium {
      right: -6px;
      font-size: 11px;
      svg {
        width: 13px;
        height: 13px;
      }
    }

    .ant-btn.ant-btn-primary.btn-contratar-plano-historic {
      display: none;
    }
  }
`;
