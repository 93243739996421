import { message } from "antd";
import apiBolsa from "../services/apiBolsa";
import apiExterior from "../services/apiExterior";

export const sendEmailSheet = async (type: string, orders: any, dolarQuote: any, user: any, title: string) => {
  try {
    const api = (type == 'bolsa') ? apiBolsa : apiExterior;
    api
      .post("/dividendos/sheet-email", {
        orders: orders,
        dolarQuote: dolarQuote,
        title: title,
        user: user
      })
      .then(() => {
        message.success("Planilha enviada por e-mail!");
      })
      .catch((err) => {
        message.error(err?.response?.data?.message);
      })
      .finally(() => {
        return;
      });
  } catch (error: any) {
    message.error(error)
  }
}
