import { Button } from "@mui/material";
import { AiOutlineArrowDown } from "react-icons/ai";
import { B3AuthURL } from "../../../utils";
import b3Logo from "../../../assets/b3-logo-white.svg";
import { useB3Integration } from "../../../contexts/B3IntegrationContext";
import IntegrationButton from "../../../components/IntegrationComponents/IntegrationButton";

interface B3IntegrationButtonsProps {
  showIntegrationButton?: boolean;
}

export const B3IntegrationButtons: React.FC<B3IntegrationButtonsProps> = ({
  showIntegrationButton,
}) => {
  const {
    openWithLink,
    b3Authorized,
    handleIntegrate,
    loadingIntegration,
    loadingAuthorization,
    checkingAuhtorization,
  } = useB3Integration();

  return (
    <>
      {showIntegrationButton && (
        <>
          <IntegrationButton
            logo={b3Logo}
            imgHeight={true}
            b3Link={B3AuthURL}
            alt="Integrar com a B3"
            integrado={b3Authorized}
            setIntegrationModalVisible={() => {}}
            handleClick={() => handleIntegrate()}
            isB3LinkRedirect={openWithLink}
            loading={
              checkingAuhtorization ||
              loadingIntegration ||
              loadingAuthorization
            }
          />
          <br />
        </>
      )}
      <Button
        fullWidth
        size="large"
        color="secondary"
        variant="contained"
        onClick={() => handleIntegrate()}
        startIcon={<AiOutlineArrowDown size={18} />}
        disabled={loadingIntegration || loadingAuthorization}
        {...(openWithLink ? { href: B3AuthURL } : {})}
      >
        Conectar com a B3
      </Button>
    </>
  );
};
