import { Checkbox, Form } from "antd";
import React, { useState } from "react";
import ReactInputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import {
  Button,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { cpfInputMask } from "../../utils";
import { useAuth } from "../../contexts/AuthContext";
import { AuthContainer } from "../../styles/LoginSignUp/authContainer";
import {
  validationCpf,
  validationEmail,
  validationFullName,
  validationPassword,
} from "../../utils/formValidations";

interface IData {
  email: string;
  pass: string;
  passConfirmation: string;
  cpf: string;
  name: string;
}

interface IProps {
  className?: string;
}

const MaskCPF = React.forwardRef<HTMLElement>((props, ref) => (
  <ReactInputMask {...props} mask={cpfInputMask} />
));

const Cadastro: React.FC<IProps> = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm<IData>();
  const { handleSignUp, loading } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [fieldsError, setFieldsError] = useState<{ [key: string]: string[] }>(
    {}
  );

  const onFinish = (data: IData) => {
    handleSignUp(data, {});
  };

  const onValuesChange = (changed: any, values: any) => {
    const cpf = changed.cpf ? changed.cpf.replace(/[_.-/-]/g, "") : values.cpf;
    form.setFieldsValue({
      ...values,
      cpf,
    });
  };

  const onFieldsChange = () => {
    setFieldsError(
      form
        .getFieldsError()
        .reduce((acc, cur) => ({ ...acc, [cur.name?.[0]]: cur.errors }), {})
    );
  };

  return (
    <AuthContainer className="beauty-scrollbar">
      <div className="auth-content">
        <div className="form-container">
          <h1>Faça seu cadastro</h1>
          <Form
            form={form}
            size="large"
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            onFieldsChange={onFieldsChange}
          >
            <Form.Item name="name" rules={validationFullName}>
              <TextField
                variant="standard"
                label="Nome completo"
                error={fieldsError["name"]?.length > 0}
              />
            </Form.Item>

            <Form.Item name="cpf" rules={validationCpf}>
              <TextField
                label="CPF"
                variant="standard"
                error={fieldsError["cpf"]?.length > 0}
                InputProps={{ inputComponent: MaskCPF as any }}
              />
            </Form.Item>

            <Form.Item name="email" rules={validationEmail}>
              <TextField
                variant="standard"
                type="email"
                label="Email"
                error={fieldsError["email"]?.length > 0}
              />
            </Form.Item>

            <Form.Item name="pass" rules={validationPassword}>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="input-password">Senha</InputLabel>
                <Input
                  id="input-password"
                  type={showPassword ? "text" : "password"}
                  error={fieldsError["pass"]?.length > 0}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="exibir senha"
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => setShowPassword((show) => !show)}
                        edge="end"
                      >
                        {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Form.Item>

            <Form.Item
              name="policiesAccepted"
              getValueFromEvent={(e) => e.target.checked}
            >
              <Checkbox>
                <span>
                  Li, entendi e estou de acordo com os{" "}
                  <a
                    href="https://velotax-termos-e-condicoes.s3.us-east-1.amazonaws.com/Termos%20de%20Uso%20-%20Velotax%20v1.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Termos de Uso
                  </a>
                  ,{" "}
                  <a
                    href="https://velotax-termos-e-condicoes.s3.us-east-1.amazonaws.com/Pol%C3%ADtica%20de%20Privacidade%20-%20Velotax%2020.01.22%20v1.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Política de Privacidade
                  </a>{" "}
                  e{" "}
                  <a
                    href="https://velotax-termos-e-condicoes.s3.us-east-1.amazonaws.com/POL%C3%8DTICA%20DE%20COOKIES%20-%20VELOTAX_vEE%20v1.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Política de Cookies
                  </a>{" "}
                  do Velotax
                </span>
              </Checkbox>
            </Form.Item>

            <div className="buttons">
              <Button
                size="large"
                type="submit"
                color="secondary"
                variant="contained"
                className="full-width"
                disabled={loading}
                startIcon={loading && <LoadingOutlined />}
              >
                Começar
              </Button>
            </div>
          </Form>

          <div className="footer">
            Já possui uma conta?
            <Button
              type="button"
              disabled={loading}
              onClick={() => navigate("/p/login")}
            >
              <span className="xp-link">Entrar</span>
            </Button>
          </div>
        </div>
      </div>
    </AuthContainer>
  );
};

export default Cadastro;
