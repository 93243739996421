import { Typography } from "antd";
import { useEffect, useState } from "react";
import XP_logo from "../../assets/xp.svg";
import { Container, Content } from "./styles";
import { Page } from "../../constants/brokers";
import HandleTag from "../../services/handleTag";
import apiExterior from "../../services/apiExterior";
import { BackButton } from "../../components/BackButton";
import IntegrationButton from "../../components/IntegrationComponents/IntegrationButton";

interface IntegrationExteriorProps {
  item: Page;
}

export const IntegrationExteriorXP: React.FC<IntegrationExteriorProps> = () => {
  const [loading, setLoading] = useState(false);
  const [integrated, setIntegrated] = useState(false);

  const integrate = (data = {}, authorized = false) => {
    HandleTag("50");
    window.open("https://www.youtube.com/watch?v=uXIDDlJRRUk", "_blank");
  };

  useEffect(() => {
    setLoading(true);
    apiExterior
      .get("/xp/integration-status")
      .then((response) => {
        setIntegrated(response.data?.isIntegrated ?? false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Container>
      <Typography.Title level={1}>Faça sua integração</Typography.Title>
      <Content>
        <IntegrationButton
          logo={XP_logo}
          imgHeight={true}
          loading={loading}
          handleClick={integrate}
          integrado={integrated}
          alt="Integrar com a XP International"
          setIntegrationModalVisible={() => {}}
        />
        <br />
        <Typography.Paragraph>
          Caso você ainda não tenha realizado a conexão com a sua conta na XP
          International,{" "}
          <a
            target="__blank"
            href="https://www.youtube.com/watch?v=uXIDDlJRRUk"
          >
            clique aqui
          </a>{" "}
          e siga o passo a passo.
        </Typography.Paragraph>
        <BackButton
          to="/xpinvestimentos/exterior"
          className="integration-exterior"
        />
      </Content>
    </Container>
  );
};
