import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_EXTERIOR_URL || "http://localhost:8889",
  headers: {
    "ngrok-skip-browser-warning": "69420",
    "x-api-provider": "exterior-xpinvestimentos",
  },
});

export default api;
