import { Radio } from "antd";
import Masked from "react-input-mask";
import { forwardRef, ReactNode } from "react";
import NumberFormat from "react-number-format";
import { InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { getMuiInputCss, isMobile } from "../../utils";

interface InputParams {
  label?: ReactNode;
  disabled?: boolean;
  placeholder?: string;
  maxLength?: number;
  className?: string;
}

interface MaskInputParams extends InputParams {
  mask?: string;
}

interface NumberInputParams extends InputParams {
  prefix?: string;
  decimalScale?: number;
  allowNegative?: boolean;
}

interface SelectInputParams extends InputParams {
  defaultValue?: any;
  options?: { label: ReactNode; value: any }[];
  value?: any;
}

interface RadioInputParams extends SelectInputParams {}

export const TextInput = ({ label, placeholder, disabled }: InputParams) => (
  <TextField
    variant="standard"
    label={label}
    disabled={disabled}
    placeholder={placeholder}
    InputLabelProps={{
      shrink: true,
    }}
  />
);

export const SelectLabled = ({
  options,
  label,
  disabled,
  value,
  defaultValue,
  ...rest
}: SelectInputParams & {
  onChange?: Function;
  value?: any;
  defaultValue?: any;
}) => {
  const handleChange = (e: any) => {
    rest.onChange?.(e.target.value);
  };

  return (
    <>
      <InputLabel id={`id-label-${label?.toString()}`}>{label}</InputLabel>
      <Select
        label={label}
        variant="standard"
        disabled={disabled}
        onChange={handleChange}
        value={value ?? ""}
        defaultValue={defaultValue}
        labelId={`id-label-${label?.toString()}`}
      >
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export const SelectInput = (props: SelectInputParams) => {
  return <SelectLabled {...props} />;
};

export const NumberInput = ({
  label,
  maxLength,
  className,
  prefix = "",
  disabled = false,
  decimalScale = 2,
  placeholder = "",
  allowNegative = false,
}: NumberInputParams) => {
  const NumberFormatCustom = forwardRef((props, ref) => (
    <NumberFormat
      {...props}
      prefix={prefix}
      getInputRef={ref}
      disabled={disabled}
      decimalSeparator=","
      thousandSeparator="."
      decimalScale={decimalScale}
      allowNegative={allowNegative}
      maxLength={maxLength}
    />
  ));

  return (
    <TextField
      label={label}
      variant="standard"
      className={className}
      disabled={disabled}
      placeholder={placeholder}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
      }}
      InputLabelProps={disabled || isMobile() ? { shrink: true } : {}}
    />
  );
};

export const MaskInput = ({
  label,
  mask = "",
  placeholder = "",
  disabled = false,
}: MaskInputParams) => {
  const MaskedCustom = forwardRef((props, ref) => (
    <Masked
      {...props}
      mask={mask}
      className={`MuiInputBase-input MuiInput-input ${getMuiInputCss()}`}
    />
  ));

  return (
    <TextField
      label={label}
      variant="standard"
      disabled={disabled}
      placeholder={placeholder}
      InputProps={{
        inputComponent: MaskedCustom as any,
      }}
      InputLabelProps={{ shrink: true }}
    />
  );
};

export const RadioInput = ({ options, disabled }: RadioInputParams) => {
  return (
    <Radio.Group>
      {(
        options || [
          { value: true, label: "Sim" },
          { value: false, label: "Não" },
        ]
      ).map((option) => (
        <Radio key={option.value} value={option.value} disabled={disabled}>
          {option.label}
        </Radio>
      ))}
    </Radio.Group>
  );
};
