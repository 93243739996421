import { Header } from "./Header";
import { Container } from "./styles";

export const PublicWrapper: React.FC = ({ children }) => {
  return (
    <Container>
      <Header />
      {children}
    </Container>
  );
};
