import { BsDownload } from "react-icons/bs";
import { BiHelpCircle } from "react-icons/bi";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import {
  Col,
  List,
  message,
  Modal,
  Row,
  Select,
  Skeleton,
  Typography,
} from "antd";
import apis from "../../services/apis";
import Button from "../../components/Button";
import { Page } from "../../constants/brokers";
import { PageInfo } from "../../components/PageInfo";
import { PlanModal } from "../../components/PlanModal";
import { PlanEnum, Plans } from "../../constants/plans";
import { useBroker } from "../../contexts/BrokerContext";
import { ConfirmModal, Container, Content } from "./styles";
import { StyledTag } from "../Darf/Components/PayTags/styles";
import { MEIAtividadePrincipalValue } from "../../constants/identification";
import {
  DasHelpModal,
  MeiDasStatusColorEnum,
  MeiDasStatusTextEnum,
  MeiReportDasStatusEnum,
} from "../../constants/das";
import {
  download,
  errorMessage,
  formatDateHour,
  monthsExtended,
} from "../../utils";

interface MeiDasResponse {
  year: number;
  month: number;
  path?: string;
  price?: number;
  payed?: boolean;
  issueDate?: string;
  requestDate?: string;
  inssBeneficiary?: boolean;
  status?: MeiReportDasStatusEnum;
  _id?: string;
}

interface MeiDasProps {
  item: Page;
}

export const MeiDas: React.FC<MeiDasProps> = ({ item }) => {
  const initialYear = new Date().getFullYear();
  const {
    currentBroker,
    integration: { plans },
    currentPage,
  } = useBroker();

  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState(initialYear);
  const [showModal, setShowModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [data, setData] = useState<Array<MeiDasResponse>>();
  const [showPlanModal, setShowPlanModal] = useState(false);

  const { apiUrl } = item.settings;

  const yearOptions = Array.from(
    Array(new Date().getFullYear() - currentBroker.initialYear + 1)
  )
    .map((y, i) => ({
      value: currentBroker.initialYear + i,
      label: `${currentBroker.initialYear + i}`,
    }))
    .reverse();

  const dasMonths = useMemo(() => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    return Array.from(Array(year === currentYear ? currentMonth + 1 : 12)).map(
      (item, index) => ({
        year,
        month: index + 1,
      })
    );
  }, [year]);

  const mainActivity = useMemo(() => {
    const index = data?.findIndex((item) => item.price && item.price > 0);
    const keys = Object.keys(MEIAtividadePrincipalValue).map((key) =>
      Number(key)
    );
    return data &&
      data.length > 0 &&
      typeof index === "number" &&
      index >= 0 &&
      typeof data[index].price === "number" &&
      keys.includes(data[index].price!)
      ? MEIAtividadePrincipalValue[data[index].price!]
      : "";
  }, [data]);

  const getDasList = useCallback(() => {
    setLoading(true);
    (currentPage?.api || apis)
      .get<MeiDasResponse[]>(apiUrl, { params: { year } })
      .then((response) =>
        setData(
          dasMonths.map(
            (item) =>
              (response.data ?? []).find(
                (responseItem) => responseItem.month === item.month
              ) ?? item
          )
        )
      )
      .catch(() => message.error(errorMessage))
      .finally(() => setLoading(false));
  }, [apiUrl, year, dasMonths, currentPage]);

  const pay = useCallback(
    (payed: boolean, item: any) => {
      setLoading(true);
      (currentPage?.api || apis)
        .put(`${apiUrl}/${item._id}`, { payed })
        .then(() => {
          setData((data) =>
            (data || []).map((das) => ({
              ...das,
              payed: item._id === das._id ? payed : das.payed,
            }))
          );
        })
        .catch(() => message.error(errorMessage))
        .finally(() => setLoading(false));
    },
    [apiUrl, currentPage]
  );

  const downloadAction = (item: MeiDasResponse, index: number) => {
    if (item.path) {
      download(item.path);
    }
  };

  const handleEmit = (item: MeiDasResponse) => {
    setLoading(true);
    (currentPage?.api || apis)
      .post(apiUrl, {
        ...item,
        mainActivity,
        inssBeneficiary: !!item.inssBeneficiary,
      })
      .then(() => {
        getDasList();
        handleModal();
      })
      .catch(() => message.error(errorMessage))
      .finally(() => {
        setLoading(false);
      });
  };

  const checkIfPayed = (item: MeiDasResponse) => {
    if (plans?.name === PlanEnum.PRO_V1 && plans?.status === "active") {
      handleEmit(item);
    } else {
      setShowPlanModal(true);
    }
  };

  const inssAction = (item: MeiDasResponse) => {
    setData((data) =>
      data?.map((das) =>
        das.month === item.month
          ? {
              ...das,
              inssBeneficiary: !das.inssBeneficiary,
            }
          : das
      )
    );
  };

  const payAction = (value: boolean, record: any) => {
    pay(value, record);
  };

  const handleModal = () => {
    setShowModal((show) => !show);
  };

  const handleHelpModal = () => {
    setShowHelpModal((show) => !show);
  };

  const onChangeYear = (value: string) => {
    setYear(parseInt(value));
  };

  useEffect(() => {
    getDasList();
  }, [getDasList, year]);

  return (
    <Container>
      <PageInfo
        title="Emita suas guias mensais MEI (DAS)"
        subtitle="As guias mensais do MEI devem ser emitidas e pagas todo mês, mesmo sem faturamento."
      />
      <Content>
        <Typography.Title level={1}>Lista de Guias (DAS) </Typography.Title>
        <Row align="middle">
          <Col xs={22} span={23}>
            <Typography.Title level={2}>
              Mantenha o pagamento das suas guias mensais do MEI em dia.
            </Typography.Title>
          </Col>
          <Col xs={2} span={1}>
            <Button
              type="text"
              onClick={handleHelpModal}
              icon={<BiHelpCircle size={20} />}
            />
          </Col>
          <Col span={24}>
            <Typography.Text italic strong>
              Obs.: Marque se você é Beneficiário do INSS antes de emitir a
              guia.
            </Typography.Text>
          </Col>
        </Row>

        <br />

        <Typography.Paragraph>Selecione o ano:</Typography.Paragraph>
        <Select
          size="large"
          options={yearOptions}
          value={year.toString()}
          onChange={onChangeYear}
          placeholder="Selecione o ano"
          style={{ minWidth: "150px" }}
          getPopupContainer={(trigger) => trigger.parentElement}
        />

        <List
          loading={loading}
          dataSource={data ?? []}
          itemLayout="horizontal"
          renderItem={(item, index) => (
            <List.Item
              actions={[
                item.status && (
                  <>
                    Pago?
                    <Button
                      type="text"
                      size="large"
                      onClick={() => payAction(!item.payed, item)}
                      icon={
                        item.payed ? (
                          <CheckCircleOutlined className="success-icon" />
                        ) : (
                          <CloseCircleOutlined className="error-icon" />
                        )
                      }
                    />
                  </>
                ),
                item.status && item.path && (
                  <Button
                    type="text"
                    size="large"
                    onClick={() => downloadAction(item, index)}
                    icon={<BsDownload size={16} />}
                  />
                ),
                !item.status && (
                  <>
                    Beneficiário INSS?
                    <Button
                      type="text"
                      size="large"
                      onClick={() => inssAction(item)}
                      icon={
                        item.inssBeneficiary ? (
                          <CheckCircleOutlined className="success-icon" />
                        ) : (
                          <CloseCircleOutlined className="error-icon" />
                        )
                      }
                    />
                  </>
                ),
                !item.status && (
                  <Button onClick={() => checkIfPayed(item)} type="primary">
                    Emitir guia
                  </Button>
                ),
              ]}
            >
              <Skeleton title={false} loading={loading} active>
                <List.Item.Meta
                  title={
                    <Row gutter={[16, 16]} align="middle">
                      <Col>
                        {monthsExtended[item.month - 1]} de {item.year}
                      </Col>
                      {item.status && (
                        <Col>
                          <StyledTag color={MeiDasStatusColorEnum[item.status]}>
                            {MeiDasStatusTextEnum[item.status]}
                          </StyledTag>
                        </Col>
                      )}
                    </Row>
                  }
                  description={
                    <>
                      {/* {item.price ? formatCurrency(item.price) : "-"} */}
                      {item.issueDate && <br />}
                      {item.issueDate &&
                        `Emissão: ${formatDateHour(item.issueDate)}`}
                      {item.requestDate && <br />}
                      {item.requestDate &&
                        `Solicitação: ${formatDateHour(item.requestDate)}`}
                    </>
                  }
                />
              </Skeleton>
            </List.Item>
          )}
        />
      </Content>
      <Modal
        footer={null}
        visible={showHelpModal}
        onCancel={handleHelpModal}
        title={DasHelpModal?.title}
      >
        {DasHelpModal?.content}
      </Modal>
      <ConfirmModal
        closable
        footer={null}
        destroyOnClose
        keyboard={false}
        visible={showModal}
        onCancel={handleModal}
        title="Guia (DAS) solicitada com sucesso!"
      >
        <Typography.Paragraph className="p">
          Em até <strong>48h úteis</strong> seu DAS será emitido e enviado para
          o seu email de cadastro. <strong>Aguarde!</strong>
        </Typography.Paragraph>
      </ConfirmModal>
      <PlanModal
        visible={showPlanModal}
        title="Contrate o plano para realizar a emissão"
        modalPlans={[Plans.find((plan) => plan.type === PlanEnum.PRO_V1)!]}
        onCancel={() => {
          setShowPlanModal(false);
        }}
      />
    </Container>
  );
};
