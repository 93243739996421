import React from "react";
import { AuthProvider } from "./AuthContext";
import { BrokerProvider } from "./BrokerContext";
import { HistoryProvider } from "./HistoryContext";
import { CarteiraProvider } from "./CarteiraContext";
import { B3IntegrationProvider } from "./B3IntegrationContext";
import { RequireAccessProvider } from "./RequireAccessContext";
import { TransferenciaCustodiaProvider } from "./TransferenciaCustodiaContext";
import { UploadDecProvider } from "./UploadDecContext";
import { OldTransactionsProvider } from "./OldTransactionsContext";
import { NotificationProvider } from "./NotificationContext";
import { WebSocketProvider } from "./Notification.context";

const ContextWrapper: React.FC = ({ children }) => {
  return (
    <AuthProvider>
      <WebSocketProvider>
        <CarteiraProvider>
          <NotificationProvider>
            <BrokerProvider>
              <HistoryProvider>
                <B3IntegrationProvider>
                  <UploadDecProvider>
                    <OldTransactionsProvider>
                      <TransferenciaCustodiaProvider>
                        <RequireAccessProvider>{children}</RequireAccessProvider>
                      </TransferenciaCustodiaProvider>
                    </OldTransactionsProvider>
                  </UploadDecProvider>
                </B3IntegrationProvider>
              </HistoryProvider>
            </BrokerProvider>
          </NotificationProvider>
        </CarteiraProvider>
      </WebSocketProvider>
    </AuthProvider>
  );
};

export default ContextWrapper;
