import styled from "styled-components";

export const HeaderContainer = styled.header`
  z-index: 1;
  display: flex;
  column-gap: 32px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  padding: 16px 48px;
  top: 0;
  position: fixed;
  border-bottom: 1px solid transparent;
  background-color: var(--velotax-background-color);

  h1 {
    margin: 0;

    img {
      height: 40px;
    }
  }

  .buttons {
    display: flex;
    column-gap: 8px;

    .xp-link {
      color: var(--white);
    }
  }

  @media only screen and (max-device-width: 812px) {
    padding: 16px 24px;
  }
`;
