import { Typography } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  padding: 64px 32px 128px;

  h1 {
    width: 700px;
    margin: 0 auto 1.5rem;
    font-weight: 400;
    font-size: 2rem;
    position: relative;
    line-height: 2.5rem;
    padding-bottom: 1rem;
    color: var(--velotax-font-color-light);

    :after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 100px;
      background-color: var(--ant-primary-color);
    }
  }

  h2.ant-typography {
    display: flex;
    align-items: center;
    column-gap: 8px;
    width: 700px;
    margin: 0 auto 1.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: var(--velotax-font-color-light);

    &.info {
      align-items: flex-start;
      font-size: 0.875rem;
      line-height: 1.125rem;
      opacity: 0.85;
      margin: 0 auto 2.5rem;
      svg {
        margin-top: 4px;
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    padding: 0;

    h1 {
      width: calc(100% - 48px);
      margin: 0 24px 1.5rem;
      padding: 24px 0 1rem;
    }

    h2.ant-typography {
      width: 100%;
      margin: 0 auto 1.5rem;
      padding: 0 24px;
    }
  }
`;

export const Content = styled.div`
  padding: 32px;
  margin: 0 auto;
  width: 700px;
  border-radius: 4px;
  background-color: var(--velotax-background-color);

  .ant-list.ant-list-split {
    margin: 0;
    padding: 0 16px;
    background-color: var(--velotax-ghost);
    border: 1px solid var(--velotax-ghost);
    border-radius: 4px;
    .ant-list-item-action > li {
      padding: 0;
    }
    .ant-list-item-meta-description {
      opacity: 0.75;
    }
    .ant-list-item-action > li,
    .ant-list-item-meta-title,
    .ant-list-item-meta-description {
      color: var(--velotax-font-color);
    }
    .ant-list-item-action-split {
      opacity: 0;
    }
  }
  .assessor-status {
    font-weight: 500;
    padding: 1px 8px;
    border-radius: 0px;
    color: var(--black);
    display: flex;
    &.true {
      background-color: var(--ant-success-color);
    }
    &.false {
      background-color: var(--ant-error-color);
    }
    &.awaiting {
      background-color: var(--ant-warning-color);
    }
  }
  @media only screen and (max-device-width: 812px) {
    width: 100%;
    padding: 24px;
    border-radius: 0;
  }
`;

export const Field = styled(Typography.Text)`
  font-weight: 500;
  color: white;
`;

export const Value = styled.span`
  font-weight: 300;
  margin-left: 0.5rem;
`;
