import { Typography } from "antd";
import { Container } from "./styles";

interface PageInfoProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
}

export const PageInfo: React.FC<PageInfoProps> = ({ title, subtitle }) => {
  return (
    <Container>
      <Typography.Title level={1}>{title}</Typography.Title>
      {subtitle && <Typography.Paragraph>{subtitle}</Typography.Paragraph>}
    </Container>
  );
};
