import styled from "styled-components";

export const CardContainer = styled.div`
  width: 100%;
  height: 64px;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  border-bottom: 1px solid var(--velotax-disabled);

  &,
  a,
  .MuiButtonBase-root {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > svg {
      width: 20px;
      height: 20px;
      stroke: var(--velotax-font-color-light);
    }
  }

  a,
  .MuiButtonBase-root {
    width: 100%;
    height: 100%;
    padding: 12px 12px 12px 0;
  }

  figure {
    margin: 0 16px 0 0;

    svg {
      width: 24px;
      height: 24px;
      &:not(.no-fill) {
        fill: var(--ant-primary-color);
      }
    }
  }

  .content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.125rem;
    color: var(--velotax-font-color-light);

    .ant-typography {
      color: inherit;
      position: relative;
      text-align: left;
    }
  }

  @media only screen and (max-device-width: 812px) {
    &.not-free {
      .content {
        .content-children {
          width: 100%;
          .ant-row {
            width: 100%;
            .ant-col {
              width: 100%;
              .ant-typography {
                width: calc(100% - 64px);
              }
            }
          }
        }
      }
    }
  }
`;
