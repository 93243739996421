import {  useState } from "react";
import { Collapse, Spin, Typography, } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { AiOutlineArrowUp, AiOutlineCheck } from "react-icons/ai";
import { Container } from "./styles";
import { DrawerModal, DrawerModalProps } from "../../components/DrawerModal";
import Button from "../../components/Button";
import UploadVelotax from "../../components/UploadVelotax";
import { useUploadDec } from "../../contexts/UploadDecContext";
import { DecUploadContent } from "../DecPrepreenchida/styles";
import { DecPrepreenchidaContent } from "../DecPrepreenchida";
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";
interface PrePreenchidaModalProps extends DrawerModalProps {
  setShowModalPrePreenchida: (b: boolean) => void;
}

export const PrePreenchidaModal: React.FC<PrePreenchidaModalProps> = ({
  visible,
  setShowModalPrePreenchida,
  ...props
}) => {
  const [showModal, setShowModal] = useState(true);
  const [loadingSkip] = useState(false);
  const { loading, beforeUpload, customRequest } = useUploadDec();

  const onCancel = () => {
    setShowModal(false);
  };

  return (
    <DrawerModal
      destroyOnClose
      width="600px"
      onCancel={onCancel}
      maskClosable={false}
      visible={visible && showModal}
      title="Preenchimento automático"
      // closable={!(loadingEcac.loopStatus || loadingEcac.getStatus)}
      {...props}
    >
      <Container>
        <Typography.Paragraph>
          Você pode conectar a sua conta gov.br ou carregar o arquivo DEC da sua
          declaração de 2019/2020. Selecione uma das opções abaixo:
        </Typography.Paragraph>

        <Collapse accordion defaultActiveKey={"gov.br"}>
          <Collapse.Panel
            forceRender
            key="gov.br"
            showArrow={false}
            collapsible={loadingSkip ? "disabled" : undefined}
            header={
              <Typography.Text>
                <div>
                  <span style={{ fontWeight: "bolder" }}>
                    Conecte sua conta gov.br
                  </span>
                  <Button
                    type="text"
                    size="large"
                    disabled={loadingSkip}
                    // icon={<QuestionCircleOutlined />}
                    onClick={(e) => {
                      e.stopPropagation();
                      // openHelpDrawer();
                    }}
                  />
                </div>
                <div className="opcao-recomendada">
                  <AiOutlineCheck />
                  Opção recomendada
                </div>
              </Typography.Text>
            }
          >
            <Spin spinning={loadingSkip}>
              <DecPrepreenchidaContent
                setShowModalPrePreenchida={setShowModalPrePreenchida}
              />
            </Spin>
          </Collapse.Panel>

          <Collapse.Panel
            key="dec"
            showArrow={false}
            collapsible={loadingSkip ? "disabled" : undefined}
            header={
              <Typography.Text>
                <div>
                  Carregue arquivo .DEC referente ao IR de 2019/2020
                  <Button
                    type="text"
                    size="large"
                    disabled={loadingSkip}
                    icon={<QuestionCircleOutlined />}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                </div>
              </Typography.Text>
            }
          >
            <UploadVelotax
              accept=".dec"
              tabIndex={1}
              loading={loading}
              beforeUpload={beforeUpload}
              customRequest={(params: RcCustomRequestOptions<any>) => {
                customRequest(params);
                setShowModalPrePreenchida(false);
              }}
            >
              <DecUploadContent>
                <div className="dec-icon">
                  <AiOutlineArrowUp />
                </div>
                <Typography.Text>
                  Arraste aqui sua declaração do ano passado (formato .DEC)
                </Typography.Text>
              </DecUploadContent>
            </UploadVelotax>
          </Collapse.Panel>
        </Collapse>
      </Container>
    </DrawerModal>
  );
};
