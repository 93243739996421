import styled, { css } from "styled-components";

export const Container = styled.div<{ imgHeight?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 150px;
  padding: 32px;
  cursor: pointer;
  background: var(--velotax-background-color);
  border-radius: 6px;
  position: relative;
  border: 2px solid var(--velotax-ghost-white);
  position: relative;
  transition: 0.3s;

  :hover {
    opacity: 0.9;
    filter: contrast(0.9);
  }

  img {
    ${(props) =>
      props.imgHeight
        ? css`
            height: 100%;
          `
        : css`
            width: 100%;
          `}
  }

  .loading-animation {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 8px;
    border-radius: 6px;
    backdrop-filter: blur(4px);
    background: var(--ant-info-color-outline);
    span {
      font-size: 14px;
      line-height: 16px;
      font-weight: bold;
      text-align: center;
    }
    .anticon-spin {
      margin: 4px 8px 4px 0;
      font-size: 28px;
      color: var(--white);

      svg {
        margin: 0;
      }
    }
  }

  &.integrado {
    border: 2px solid var(--ant-success-color);
  }

  .integrado-check {
    bottom: 12px;
    right: 12px;
    font-size: 22px;
    position: absolute;
  }

  .click-here {
    margin: 16px 0 0;
    font-size: 11px !important;
    font-weight: 500;
    line-height: 20px;
    color: var(--velotax-font-color);
    opacity: 0.8;
  }

  a.b3-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;
