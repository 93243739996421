import { Drawer, DrawerProps, Modal, ModalProps } from "antd";
import { isMobile } from "../../../utils";

export interface DrawerModalProps
  extends Omit<ModalProps, "visible" | "onCancel" | "getContainer">,
    Omit<DrawerProps, "visible" | "onClose"> {
  visible?: boolean;
  onCancel?: (e: any) => void;
  // open?: boolean;
}

export const DrawerModal: React.FC<DrawerModalProps> = ({
  height,
  visible,
  onCancel,
  children,
  className,
  getContainer,
  ...props
}) => {
  return isMobile() ? (
    <Drawer
      destroyOnClose
      visible={visible}
      placement="bottom"
      onClose={onCancel}
      height={height ?? "calc(100% - 64px)"}
      className={`modal-drawer ${className ? className : ""} ${
        !props.title ? "untitled" : ""
      } ${props.closable === false ? "" : "drawer-closable"}`}
      {...props}
    >
      {children}
    </Drawer>
  ) : (
    <Modal
      destroyOnClose
      footer={null}
      visible={visible}
      onCancel={onCancel}
      className={className}
      {...props}
    >
      {children}
    </Modal>
  );
};
