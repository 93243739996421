import { useState } from "react";
import { Divider, Steps, Typography } from "antd";
import { AiOutlineArrowRight } from "react-icons/ai";
import { LoadingOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import Button from "../../components/Button";
import { Container, Content } from "./styles";
import { useEcac } from "../../contexts/EcacContext";
import { useYear } from "../../contexts/YearContext";
import apiIrpf2023 from '../../services/apiIrpf2023';
import { useOldTransactions } from "../../contexts/OldTransactionsContext";


interface DecPrepreenchidaProps {
  successCallback?: () => void;
  setShowModalPrePreenchida: (p:boolean) => void;
}

export const DecPrepreenchidaContent: React.FC<DecPrepreenchidaProps> = ({

  setShowModalPrePreenchida
}) => {
  const [currentStep,setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(true);

  const {getOldTransactionsInit} = useOldTransactions();
 
  const programLink = 
  process.env.REACT_APP_NODE_ENV === "development"
    ? "https://velotax-programas.s3.amazonaws.com/posicao-2019-dev/velotax.msi"
    : "https://velotax-programas.s3.amazonaws.com/posicao-2019/velotax.msi";

  return (
    <Steps current={currentStep} direction="vertical">
      <Steps.Step
        key="1"
        title={
          <>
            <a
              {...({ target: "_blank" } )}
              rel="noreferrer"
              href={programLink}
              onClick={ () => {
                setCurrentStep(1);
                setCurrentStep(2);
                setLoading(true);
                let interval: NodeJS.Timeout | null = null;
                if (loading) {

                  interval = setInterval( ()=>{

                    apiIrpf2023.get(`/receitaFederal/checkStatusRecuperarDecIRPF`).then( ({data})=>{
                      if(data?.processamantoDec2019){
                        setLoading(false);
                        setShowModalPrePreenchida(false);

                         getOldTransactionsInit();
                          // window.location.reload();

                        return clearInterval(interval as unknown as NodeJS.Timeout);
                      }
                    })

                  },1000);
                }else {
                  if (interval) {
                    clearInterval(interval);
                  }
                }
              }}
            >
              <strong><u>Clique aqui</u></strong>
            </a>{" "}
            {"para baixar o arquivo de conexão"}
          </>
        }
      />
      <Steps.Step key="2" title="Instale o arquivo baixado no seu computador" />
      <Steps.Step
        key="3"
        icon={currentStep === 2 ? <LoadingOutlined /> : ''}
        title={
          <>
            Execute o atalho <strong>Velotax</strong> no seu desktop, faça
            o login no <strong>gov.br</strong> e aguarde alguns minutos.
          </>
        }
      />
    </Steps>
  )
};

export const DecPrepreenchida = () => {
  const { year } = useYear();
  const { loadingEcac, handleSkipEcacStep,   clearEcacInterval } =
    useEcac();

  return (
    <Container>
      <Content>
        <Typography.Title level={1}>
          Conecte sua conta <span className="gov-logo" />
          <Button
            type="text"
            size="large"
            // onClick={openHelpDrawer}
            icon={<QuestionCircleOutlined />}
          />
        </Typography.Title>

        <Typography.Title level={5}>
          Você terá acesso a sua declaração de IRPF {year} pré-preenchida
        </Typography.Title>

        <Divider />
        <Button
          type="link"
          size="large"
          className="skip-btn"
          onClick={async () => {
            clearEcacInterval();
            await handleSkipEcacStep();
            // updateUserEcac();
          }}
          loading={loadingEcac.skipStep}
          disabled={loadingEcac.postLogin}
        >
          Não utilizar o gov.br&nbsp;
          {!loadingEcac.skipStep && <AiOutlineArrowRight />}
        </Button>
      </Content>
    </Container>
  );
};
