import styled from "styled-components";

export const NotificationContainer = styled.div`
  h3 {
    border-bottom: 2px solid var(--gray-2);
    padding-bottom: 0.5rem;
    color: var(--velotax-font-color-light);
  }

  .divider {
    width: 100%;
    max-width: 80px;
    background-color: var(--ant-primary-color);
    height: 2px;
    margin-bottom: 1rem;
  }

  .illustration {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    margin-top: 4rem;

    span {
      margin-right: 1rem;
      color: var(--gray-2);
    }

    h5 {
      font-weight: 500;
      color: var(--velotax-font-color-light);
    }

    button, span {
      display: flex;
      justify-content: center;
      align-items: center;
      color: black !important;
    }
    span {
      margin: 0 0 0 0.5rem;
    }
    button {
      max-width: 160px;
      height: 52px;
      margin-right: auto;
      margin-top: 1rem;
    }
  }

  .loading {
    display: flex; 
  
    h5 {
      margin-left: 1rem;
    }
  }

  .card {
    background-color: var(--ant-primary-1);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    margin-bottom: 1rem;
    padding: 1rem;
    position: relative;
    cursor: pointer;

    &:hover {
      transition: 0.3s;
      background-color: var(--ant-primary-2);
    }

    .loading {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .header {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 8px;

      h5 {
        flex-grow: 1;
        margin-bottom: 0;
      }

      svg {
        color: var(--ant-primary-color);
        font-size: 20px;
        margin-right: 4px;
      }

      span {
        color: var(--velotax-font-color-ghost);
      }

      .actionButton {
        color: var(--gray-2);
        margin: 0;
        transition: 0.3s;
        cursor: pointer;

        &:hover {
          color: var(--ant-primary-color);
          transition: 0.3s;
        }
      }
    }

    .body {
      span {
        margin: 0;
        color: var(--black);
        font-weight: 400;
      }
    }
  }

  .viewed {
    background-color: var(--velotax-ghost);
    cursor: default;
    h5,
    svg,
    span {
      color: var(--velotax-font-color-ghost) !important;
    }
    &:hover {
      background-color: var(--velotax-ghost);
    }
  }

  .section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

    h5 {
      text-transform: uppercase;
      font-size: 14px;
      color: var(--velotax-font-color-light);
    }

    button {
      color: var(--gray-2);
      transition: 0.3s;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 500;
      &:hover {
        color: var(--ant-primary-color);
        transition: 0.3s;
      }
    }
  }
`;
