import { BiEdit } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import {
  AiOutlineArrowDown,
  AiOutlineArrowUp,
  AiOutlineCheck,
} from "react-icons/ai";
import {
  Col,
  Descriptions,
  Divider,
  Form,
  message,
  Row,
  Typography,
} from "antd";
import apis from "../../services/apis";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import { isSafari, maskCnpj } from "../../utils";
import { useAuth } from "../../contexts/AuthContext";
import { useBroker } from "../../contexts/BrokerContext";
import { Container, Question, QuestionContainer } from "./styles";
import {
  IdentificationData,
  identificationQuestions,
  validateIdentification,
} from "../../constants/identification";

interface IdentificationProps {}

export const Identification: React.FC<IdentificationProps> = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { handleProviderExists,currentPage } = useBroker();
  const [form] = Form.useForm<IdentificationData>();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const getData = useCallback(() => {
    setLoading(true);
    (currentPage?.api || apis)
      .get<IdentificationData>("/mei")
      .then((response) => {
        if (validateIdentification(response.data)) {
          form.setFieldsValue(response.data);
          setIsEditing(false);
        } else {
          setIsEditing(true);
        }
      })
      .catch(() => {
        setIsEditing(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [form,currentPage]);

  const handleNav = (value: 1 | -1) => {
    form
      .validateFields(
        value === 1
          ? identificationQuestions[currentQuestion].formItems.map(
              (formItem) => formItem.name ?? ""
            )
          : []
      )
      .then(() => {
        setCurrentQuestion((question) => {
          const newValue = question + value;
          const realValue =
            newValue >= 0 && newValue < identificationQuestions.length
              ? newValue
              : question;
          const container = document.getElementById(
            "mei-identification-container"
          );
          const element = document.getElementById(
            realValue < 0 ? "question-0" : `question-${realValue}`
          );
          const top =
            value === 1
              ? element?.offsetTop ?? 0
              : (element?.offsetTop ?? 0) - 64;
          isSafari
            ? container?.scrollTo({ top })
            : container?.scrollTo({ behavior: "smooth", top });
          return realValue;
        });
      })
      .catch(() => {});
  };

  const handleSubmit = (data: IdentificationData) => {
    setSaving(true);
    (currentPage?.api || apis)
      .post("/mei", {
        ...data,
        companyCode: data.companyCode.replace(/[-./]/g, ""),
        email: user.user.email,
      })
      .then(() => {
        setSaving(false);
        setIsEditing(false);
        handleProviderExists(true);
        message.success("Dados salvos com sucesso");
        setTimeout(() => {
          navigate("/mei/dasn");
        }, 500);
      })
      .catch(() => {
        setSaving(false);
        message.error("Erro ao salvar dados");
      });
  };

  const handleEdit = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setIsEditing(true);
      setCurrentQuestion(0);
    }, 300);
  };

  useEffect(() => {
    getData();
  }, [getData]);

  const event = useCallback(
    function (event: KeyboardEvent) {
      if (event.key === "Enter") {
        event.preventDefault();
        const button = document.getElementById(
          currentQuestion < identificationQuestions.length - 1
            ? `next-button-${currentQuestion}`
            : "submit-button"
        );
        button?.click();
      }
    },
    [currentQuestion]
  );

  useEffect(() => {
    document.addEventListener("keydown", event);
    return () => {
      document.removeEventListener("keydown", event);
    };
  }, [event]);

  return loading ? (
    <Loading />
  ) : isEditing ? (
    <Form form={form} size="large" onFinish={handleSubmit}>
      <Container id="mei-identification-container">
        {identificationQuestions.map((question, index) => (
          <QuestionContainer id={`question-${index}`} key={question.id}>
            <Question>
              <Typography.Title level={2}>{question.title}</Typography.Title>
              {question.formItems.map(({ FormItem, name }) => (
                <FormItem
                  key={name}
                  tabIndex={currentQuestion === index ? 1 : -1}
                />
              ))}
              <Divider />
              <Row justify={index === 0 ? "end" : "space-between"}>
                {index > 0 && (
                  <Col>
                    <Button
                      size="large"
                      onClick={() => handleNav(-1)}
                      icon={<AiOutlineArrowUp size={18} />}
                      tabIndex={currentQuestion === index ? 2 : -1}
                    >
                      Anterior
                    </Button>
                  </Col>
                )}
                {index < identificationQuestions.length - 1 ? (
                  <Col>
                    <Button
                      size="large"
                      type="primary"
                      id={`next-button-${index}`}
                      onClick={() => handleNav(1)}
                      icon={<AiOutlineArrowDown size={18} />}
                      tabIndex={currentQuestion === index ? 3 : -1}
                    >
                      Próximo
                    </Button>
                  </Col>
                ) : (
                  <Col>
                    <Button
                      size="large"
                      type="primary"
                      htmlType="submit"
                      loading={saving}
                      id="submit-button"
                      icon={<AiOutlineCheck size={18} />}
                      tabIndex={currentQuestion === index ? 3 : -1}
                    >
                      Finalizar
                    </Button>
                  </Col>
                )}
              </Row>
            </Question>
          </QuestionContainer>
        ))}
      </Container>
    </Form>
  ) : (
    <Container>
      <QuestionContainer>
        <Question>
          <Row justify="space-between" gutter={[16, 0]}>
            <Col>
              <Typography.Title level={2}>
                Identificação do MEI
              </Typography.Title>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={handleEdit}
                icon={<BiEdit size={18} />}
              >
                Editar
              </Button>
            </Col>
          </Row>
          <br />
          <Descriptions bordered layout="horizontal">
            <Descriptions.Item label="CNPJ" span={24}>
              {maskCnpj(form.getFieldValue("companyCode"))}
            </Descriptions.Item>
            <Descriptions.Item label="Atividade Principal" span={24}>
              {form.getFieldValue("mainActivity")}
            </Descriptions.Item>
          </Descriptions>
        </Question>
      </QuestionContainer>
    </Container>
  );
};
