import styled from "styled-components";

export const Container = styled.div`
  padding: 64px 32px 32px;

  h1 {
    width: 700px;
    margin: 0 auto 1.5rem;
    font-weight: 400;
    font-size: 2rem;
    position: relative;
    line-height: 2.5rem;
    padding-bottom: 1rem;
    color: var(--velotax-font-color-light);

    :after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 100px;
      background-color: var(--ant-primary-color);
    }
  }

  div.ant-typography {
    margin: 0 auto 1.5rem;
    font-size: 1rem;
    width: 700px;
    line-height: 1.5rem;
    text-align: justify;
    color: var(--velotax-font-color);
  }

  @media only screen and (max-device-width: 812px) {
    padding: 24px 24px 0;

    h1,
    div.ant-typography {
      width: 100%;
    }
  }
`;

export const Content = styled.div`
  .container-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    button {
      margin: 0px 0px 12px;
      :first-of-type {
        margin-right: 8px;
      }
    }
  }
  padding: 32px;
  margin: 0 auto;
  width: 700px;
  border-radius: 4px;
  background-color: var(--velotax-background-color);

  .add-button {
    display: flex;
    margin: 0 0 1.5rem auto;
  }

  .ant-list {
    padding: 0;
    border-radius: 4px;
    background-color: var(--velotax-background-color-ghost);
    .ant-list-item {
      padding: 16px;
      &.error-border {
        border: 1px solid var(--ant-error-color);
      }
      :first-child {
        border-radius: 4px 4px 0 0;
      }
      :last-child {
        border-radius: 0 0 4px 4px;
      }
    }
    .error-border + .error-border {
      border-top: none;
    }
    .ant-list-item-action > li {
      padding: 0;
    }
  }
  .ant-list-item-action > li,
  .ant-list-item-meta-title,
  .ant-list-item-meta-description {
    color: var(--velotax-font-color-light);
  }
  .ant-list-item-meta-description {
    opacity: 0.75;
  }

  .alert-icon {
    padding-top: 1px;
    fill: var(--ant-error-color) !important;
  }

  @media only screen and (max-device-width: 812px) {
    width: calc(100% + 48px);
    margin-left: -24px;
    padding: 24px 24px 64px;

    .container-buttons {
      flex-wrap: wrap;
      button {
        margin: 0 0 8px !important;
        width: 100%;
      }
    }
  }
`;
