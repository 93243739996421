import { Checkbox, Col, Divider, message, Row, Typography } from "antd";
import Button from "../../Button";
import { Container } from "./styles";
import { formatCurrency } from "../../../utils";
import { UserPlan as IUserPlan } from "../../../constants/plans";
import { useAuth } from "../../../contexts/AuthContext";
import { useMemo, useState } from "react";

interface PlanProps {
  plan: IUserPlan;
  planBuyed: string;
  handleSelect: React.MouseEventHandler;
  checkIfCupomIsValid: (code: string) => void;
  cupomMsg: string;
  cupomInfo: any;
  codeSave: string;
  setCode: any;
  showInputCupom: boolean;
  buttonLoading?: boolean;
}

export const UserPlan: React.FC<PlanProps> = ({
  plan,
  planBuyed,
  handleSelect,
  checkIfCupomIsValid,
  cupomMsg,
  cupomInfo,
  codeSave,
  setCode,
  showInputCupom,
  buttonLoading,
}) => {
  const { user } = useAuth();
  const [acceptTerms, setAcceptTerms] = useState(false);
  const isActive = user?.user?.userPlanInfoVelotax?.active;
  const hasPlan = (planBuyed && isActive) || !!plan.free;
  const isPlanSelected =
    planBuyed && isActive && plan.type === user.user.userPlanInfoVelotax.type;

  const handleTerm = () => {
    setAcceptTerms((terms) => !terms);
  };

  const price = useMemo(() => {
    var isMonth = plan.interval === "Mensal";
    var planPrice = !isMonth ? plan.price * 12 : plan.price;

    if (cupomMsg !== "valid") return !isMonth ? planPrice / 12 : planPrice;

    if (cupomInfo?.type === "absolute") {
      planPrice = planPrice - Number(cupomInfo.discountValue);
    } else if (cupomInfo?.type === "percent") {
      planPrice = planPrice - planPrice * Number(cupomInfo.discountValue / 100);
    }

    planPrice = Number(planPrice.toFixed(2));

    return !isMonth ? planPrice / 12 : planPrice;
  }, [plan.price, cupomInfo, cupomMsg, plan.interval]);

  return (
    <Container>
      <Typography.Title level={3}>{plan.title}</Typography.Title>
      <div className="price">
        <Typography.Title
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
          level={4}
        >
          {plan.free ? (
            <span> Gratuito</span>
          ) : (
            <div>
              {formatCurrency(price)} <span className="per-month">/ mês</span>
              <br />
              <span className="per-month">
                {" "}
                plano {plan?.interval.toLocaleLowerCase()}
              </span>
              <br />
              {cupomInfo?.type && (
                <span className="cupom-discount">
                  {" "}
                  cupom de desconto de{" "}
                  {cupomInfo?.type === "percent"
                    ? `${cupomInfo?.discountValue}%`
                    : formatCurrency(cupomInfo?.discountValue)}
                </span>
              )}
            </div>
          )}
          <br />
          {!plan.free && plan.originalPrice !== plan.price && (
            <div className="original-price">
              <Typography.Text delete>
                {formatCurrency(plan.originalPrice)}{" "}
              </Typography.Text>
              <Typography.Text
                style={{ color: "var(--ant-error-color)" }}
                className="per-month"
              >{`${plan.percentDiscount}% de desconto ${
                plan.discountText ? plan.discountText : ""
              }`}</Typography.Text>
            </div>
          )}
        </Typography.Title>
      </div>
      <div
        style={{ marginTop: plan.free ? 55 : 0 }}
        className="included-features-user"
      >
        {plan.includedFeatures.map((feature: any) => (
          <div className="feature" key={feature.name}>
            <span>
              {feature.name}
              {feature?.secondaryIcon}
            </span>
            <span>{feature.icon}</span>
          </div>
        ))}
      </div>
      <Divider />
      <div className="other-features">
        {plan.descriptions.map((feature: any) => (
          <div className="feature" style={{ marginTop: 3 }} key={feature.label}>
            <span style={{ opacity: !feature.checked ? 0.5 : 1 }}>
              {feature.label}
              {feature.info}
            </span>
            <span>{feature.icon}</span>
          </div>
        ))}
      </div>
      <Row
        style={{ marginTop: 35 }}
      >
        {!isPlanSelected && !hasPlan && (
          <Col>
            <Checkbox
              checked={acceptTerms}
              onChange={handleTerm}
              className="checkbox-terms"
            >
              Li, entendi e estou de acordo com o{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://velotax-termos-e-condicoes.s3.amazonaws.com/TERMO+DE+ACEITE+E+CONTRATAC%CC%A7A%CC%83O_11.10.2022.pdf"
              >
                Termo de Contratação
              </a>
            </Checkbox>
          </Col>
        )}
        </Row>
        <Row 
        style={{ marginTop: 25 }}>
          <Button
          style={{ width: '100%'}}
            size="large"
            type="primary"
            onClick={(e) => {
              if (!acceptTerms) {
                message.warning("Você precisa aceitar o Termo de Contratação");
              } else {
                handleSelect(e);
              }
            }}
            loading={buttonLoading}
            disabled={hasPlan}
          >
            {isPlanSelected ? "Plano Selecionado!" : "Selecionar"}
          </Button>
        </Row>
    </Container>
  );
};
