import { Modal } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 32px 128px;
  @media only screen and (max-device-width: 812px) {
    padding: 0 16px 128px;
  }
`;

export const Content = styled.div`
  padding: 32px;
  min-width: 40vw;
  max-width: 500px;
  border-radius: 16px;
  background-color: var(--velotax-background-color);

  h2 {
    font-size: 24px;
    position: relative;
    color: var(--velotax-font-color);
    &::before {
      content: "";
      position: absolute;
      width: 4px;
      top: 0;
      left: -32px;
      height: 100%;
      background-color: var(--ant-primary-color);
    }
  }
  h3 {
    font-size: 20px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    color: var(--velotax-font-color);
  }

  .ant-list.ant-list-split {
    margin-top: 24px;
    padding: 0 0 0 16px;
    background-color: var(--velotax-ghost);
    border: 1px solid var(--velotax-ghost);
    border-radius: 8px;
    .ant-list-item-meta-title,
    .ant-list-item-meta-description {
      color: var(--velotax-font-color);
    }
    .ant-list-item-meta-description {
      opacity: 0.75;
    }
  }
  .list-description {
    display: flex;
    flex-direction: column;
  }
  @media only screen and (max-device-width: 812px) {
    padding: 24px;
    h2 {
      &::before {
        left: -24px;
      }
    }
  }
`;

export const ConfirmModal = styled(Modal)`
  .p {
    font-size: 16px;
    strong {
      font-weight: 500;
      color: var(--ant-primary-color);
    }
  }
`;
