import { Select, Typography } from "antd";
import { useLocation } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useState } from "react";
import { formatDate } from "../../utils";
import { monthList } from "./dataSourceList";
import { ModalStyled } from "../DividendosBolsa/styles";
import backendExterior from "../../services/apiExterior";
import { useBroker } from "../../contexts/BrokerContext";
import { BackButton } from "../../components/BackButton";
import { DividendosTabs } from "../../constants/dividendos";
import { DividendosModal } from "../../components/DividendosModal/index";
import { Container, Content, BolsaOperations, FlexDiv } from "./styles";

export const DividendosExterior = () => {
  const { state } = useLocation();
  const today = new Date();
  const currentYear = today.getFullYear();
  const initialYear = state?.year ?? currentYear;
  window.history.replaceState({}, document.title);

  const { currentBroker } = useBroker();

  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState<any>({});
  const [index, setIndex] = useState<any>({});
  const [showModal, setShowModal] = useState(false);
  const [year, setYear] = useState(initialYear);
  const [quotes, setQuotes] = useState([]);

  const yearOptions = Array.from(
    Array(new Date().getFullYear() - currentBroker.initialYear + 1)
  )
    .map((y, i) => ({
      value: currentBroker.initialYear + i,
      label: `${currentBroker.initialYear + i}`,
    }))
    .reverse();

  const getTotal = useCallback((sortedData: any[]) => {
    const totalData = [];
    for (const month of sortedData) {
      let acumulatorImpostoPagoExterior = 0;
      let acumulatorValorRendimento = 0;
      const orders: any[] = [];
      for (const el of month.orders ?? []) {
        if (checkType(el) === "valorRendimento") {
          orders.push({
            ...el,
            valorRendimento: el.value,
            valorRendimentoReal: el.value * month.dolarQuote ?? 0,
            date: formatDate(el.date),
          });
        } else {
          orders.push({
            ...el,
            impostoPagoExterior: el.value,
            impostoPagoExteriorReal: el.value * month.dolarQuote ?? 0,
            date: formatDate(el.date),
          });
        }
      }
      for (const item of orders) {
        acumulatorImpostoPagoExterior += item.impostoPagoExterior ?? 0;
        acumulatorValorRendimento += item.valorRendimento ?? 0;
      }

      totalData.push({
        ...month,
        impostoPagoExterior: acumulatorImpostoPagoExterior ?? 0,
        valorRendimento: acumulatorValorRendimento ?? 0,
      });
    }
    return totalData;
  }, []);

  const getHistoric = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await backendExterior.get(`/dividendos?year=${year}`);
      const res = await backendExterior.get("/xp/get-cotacoes");
      const newData = getTotal(data);
      setQuotes(res.data.values);
      setData(newData);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, [year, getTotal]);

  const onChangeYear = (value: string) => {
    setYear(parseInt(value));
  };

  useEffect(() => {
    getHistoric();
  }, [getHistoric, year]);

  const checkType = (el: any) => {
    if (el.type === "DIVIDEND" && el.operation === "BUY") {
      return "valorRendimento";
    } else return "impostoPagoExterior";
  };

  return (
    <>
      <Container>
        <Typography.Title level={1}>
          Dividendos recebidos no exterior
        </Typography.Title>
        <Content>
          <Typography.Paragraph>Selecione o ano:</Typography.Paragraph>
          <Select
            size="large"
            options={yearOptions}
            value={year.toString()}
            onChange={onChangeYear}
            placeholder="Selecione o ano"
            style={{ minWidth: "150px" }}
            getPopupContainer={(trigger) => trigger.parentElement}
          />
          <BolsaOperations>
            <div className="sub-header">
              <span className="show dividendos-header">Mês</span>
              <span className="show dividendos-header">Rendimento Bruto</span>
              <span className="show dividendos-header">
                Imposto Retido no Exterior
              </span>
              <span className="show dividendos-header"></span>
            </div>
            {monthList.map((item, index: number) => {
              return DividendosTabs(
                item,
                index,
                data,
                loading,
                setShowModal,
                setItem,
                setIndex
              ).map((container: any, rowIndex: any) => (
                <div key={rowIndex} className={container.class}>
                  {container.tabs.map((item: any, colIndex: any) => (
                    <span key={colIndex} className="show">
                      {item.content}
                    </span>
                  ))}
                </div>
              ));
            })}
          </BolsaOperations>
        </Content>
        <ModalStyled
          closable
          width={660}
          footer={null}
          destroyOnClose
          visible={showModal}
          onCancel={() => setShowModal(false)}
          title={`${monthList[index]?.monthName ?? ""} de ${year}`}
        >
          <DividendosModal item={item} year={year} quotes={quotes} />
        </ModalStyled>
        <Content className="notas">
          <FlexDiv>
            <Typography.Text>
              <InfoCircleOutlined /> A conversão de Dólar (US$) para Real (R$)
              utiliza a cotação do dólar PTAX de Compra fixada pelo Banco
              Central do Brasil para o último dia útil da primeira quinzena do
              mês anterior ao mês de recebimento do rendimento no exterior (art.
              65 da IN RFB nº 1.500 de 2014)
            </Typography.Text>
            <br />
          </FlexDiv>
        </Content>
        <BackButton
          to="/xpinvestimentos/exterior"
          className="dividendos-exterior"
        />
      </Container>
    </>
  );
};
