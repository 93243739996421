import { Typography } from "antd";
import { RiMoneyDollarCircleLine, RiFileSearchLine } from "react-icons/ri";

export const cards = [
  // {
  //   id: "irpf",
  //   icon: (
  //     <AiOutlineFileSearch
  //       size={24}
  //       className="no-fill"
  //       color="var(--ant-primary-color)"
  //     />
  //   ),
  //   content: (
  //     <Typography>
  //       Declaração IRPF
  //       <br />
  //       2023 no Velotax
  //     </Typography>
  //   ),
  // },
  {
    id: "anual-report",
    icon: (
      <RiFileSearchLine
        size={24}
        className="no-fill"
        color="var(--ant-primary-color)"
      />
    ),
    content: <Typography>RELATÓRIOS ANUAIS</Typography>,
    description: (
      <Typography>
        Relatórios auxiliares para preenchimento da DIRPF
      </Typography>
    ),
  },
  {
    id: "gain",
    icon: (
      <RiMoneyDollarCircleLine
        size={24}
        className="no-fill"
        color="var(--ant-primary-color)"
      />
    ),
    content: <Typography>INFORMES DE GANHO DE CAPITAL</Typography>,
    description: (
      <Typography>
        Relatórios auxiliares para acompanhamento do ganho de capital (GCAP)
      </Typography>
    ),
  },
];
