import styled from "styled-components";

export const ContainerModal: any = styled.div`
  width: 100%;
  .ant-typography {
    color: var(--velotax-font-color-dark);
  }
  .container-table {
    width: 100%;
    overflow-y: auto;
    .ant-table-thead {
      .ant-table-cell {
        font-weight: bold;
      }
    }
  }
  .year-resume {
    width: 100%;
    display: flex;
    margin: 16px 0;
    padding: 20px 24px;
    border-radius: 12px;
    background-color: #ededed;
    justify-content: space-between;
    .ant-typography {
      font-size: 15px;
      margin: 0px;
      font-weight: 900;
    }
  }
  .container-1 {
    gap: 8px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .ant-btn {
      display: flex;
      align-items: center;
      border-radius: 10px;
    }
    .ant-select {
      color: var(--velotax-font-color-dark) !important;
      .ant-select-arrow {
        .anticon {
          color: var(--velotax-font-color-dark);
        }
      }
      .ant-select-selector {
        height: 40px;
        border-radius: 4px !important;
        border: 1px solid #e8e8e8 !important;
        background-color: #e8e8e8 !important;
        .ant-select-selection-search,
        .ant-select-selection-item,
        .ant-select-selection-search input {
          font-size: 15px !important;
          font-weight: 900 !important;
        }
      }
      .ant-select-dropdown {
        border: none;
        border-radius: 2px;
        background-color: var(--white) !important;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
          0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
        .ant-select-item {
          background-color: var(--white) !important;
          color: var(--velotax-font-color-dark);
          :hover {
            background-color: #e8e8e8 !important;
          }
        }
        .ant-select-item-option-selected {
          font-weight: bold;
          background-color: #abaaa8 !important;
          color: var(--velotax-font-color-dark) !important;
          :hover {
            background-color: #abaaa8 !important;
          }
        }
      }
    }
  }
  .client-wallet-header-btns {
    display: flex;
    column-gap: 8px;
    align-items: center;
    .ant-btn {
      height: 32px;
      min-width: 32px;
      font-weight: 500;
      box-shadow: none;
      border-radius: 24px;
      border: 1px solid #e8e8e8;
      &.filtro-aplicado {
        color: var(--ant-primary-color);
        border: 1px solid var(--ant-primary-color);
        background-color: var(--ant-primary-1);
      }
      svg {
        fill: none;
        width: 18px;
        height: 18px;
        stroke: var(--ant-primary-color);
        &.fill {
          path {
            stroke: var(--ant-primary-color);
          }
        }
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    .container-1 {
      flex-direction: column;
      .client-wallet-header-btns {
        width: 100%;
        .ant-select {
          width: 35%;
        }
        .ant-select:first-of-type {
          width: calc(65% - 8px);
        }
        .ant-btn {
          width: calc(50% - 4px);
        }
      }
    }
    .container-table {
      .ant-pagination.ant-table-pagination {
        right: 24px;
        position: fixed;
      }
    }
  }
`;
