import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  .ant-drawer.modal-drawer.no-closable {
    .ant-drawer-content-wrapper {
      .ant-drawer-content {
        .ant-drawer-wrapper-body {
          .ant-drawer-header {
            .ant-drawer-header-title {
              flex-direction: row;
            }
          }
        }
      }
    }
  }

  .ant-drawer.modal-drawer,
  .ant-drawer.help-drawer {
    &.untitled {
      .ant-drawer-wrapper-body {
        padding-top: 0 !important;
        .ant-drawer-header {
          z-index: 2;
          height: 0 !important;
          padding: 0 !important;
          border-left: none !important;
          .ant-drawer-header-title {
            height: 0 !important;
            .ant-drawer-close {
              margin: 24px 24px 0 12px !important;
            }
          }
        }
        .ant-drawer-body {
          z-index: 1;
          overflow: unset;
        }
      }
    }
    &.payment-modal {
      .ant-drawer-content-wrapper {
        .ant-drawer-content {
          background-color: var(--white);
          .ant-drawer-wrapper-body {
            .ant-drawer-header {
              .ant-drawer-header-title {
                .ant-drawer-title {
                  color: var(--velotax-font-color-dark);
                }
              }
            }
          }
        }
      }
    }
    .ant-drawer-content-wrapper {
      .ant-drawer-content {
        background-color: var(--velotax-background-color);
        .ant-drawer-wrapper-body {
          padding-top: 32px;
          .ant-drawer-header {
            padding: 8px 24px;
            background-color: transparent;
            border-left: 4px solid var(--ant-primary-color);
            border-bottom: none;
            border-radius: 0;
            .ant-drawer-header-title {
              width: 100%;
              flex-direction: row-reverse;
              .ant-drawer-close {
                align-self: flex-start;
                margin: -24px 0 0 12px;
                .anticon.anticon-close {
                  svg {
                    width: 24px;
                    height: 24px;
                    fill: var(--ant-primary-color);
                  }
                }
              }
              .ant-drawer-title {
                font-size: 18px;
                line-height: 24px;
                color: var(--velotax-font-color-light);
              }
            }
          }
          .ant-drawer-body {
            iframe {
              max-width: 100%;
            }
          }
        }
      }
    }
  }

  .ant-table-filter-trigger:hover {
    color: var(--velotax-font-color-light);
  }
  .ant-table-filter-dropdown-btns {
    border-top: 1px solid var(--velotax-background-color-ghost);
    background-color: var(--velotax-background-color);
    .ant-btn-link[disabled] {
      color: var(--velotax-font-color-ghost);
    }
  }
  .ant-dropdown-menu-item-selected {
    background-color: var(--velotax-background-color-ghost);
  }

  @media only screen and (min-device-width: 813px) {
    .ant-drawer.modal-drawer,
    .ant-drawer.help-drawer {
      .ant-drawer-content-wrapper {
        .ant-drawer-content {
          .ant-drawer-wrapper-body {
            .ant-drawer-body {
              padding: 32px;
            }
          }
        }
      }
    }
  }
`;
