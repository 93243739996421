import { Col, Row } from "antd";
import { Button } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";

interface FooterModalProps {
  disabled?: boolean;
  onCancel: () => void;
  onOk?: () => void;
  loading?: boolean;
  cancelButtonText?: string;
  saveButtonText?: string;
  dark?: boolean;
}

export const FooterModal: React.FC<FooterModalProps> = ({
  onOk,
  dark,
  loading,
  disabled,
  onCancel,
  saveButtonText,
  cancelButtonText,
}) => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Button
          fullWidth
          size="large"
          onClick={onCancel}
          disabled={disabled}
          color={dark ? "inherit" : "primary"}
          variant={dark ? "contained" : "outlined"}
        >
          {cancelButtonText || "Cancelar"}
        </Button>
      </Col>
      <Col span={12}>
        <Button
          fullWidth
          size="large"
          color="secondary"
          variant="contained"
          disabled={disabled || loading}
          onClick={onOk ? onOk : undefined}
          type={onOk ? undefined : "submit"}
        >
          {saveButtonText || "Salvar"}
          {loading && <LoadingOutlined />}
        </Button>
      </Col>
    </Row>
  );
};
