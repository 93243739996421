import { Focused } from "react-credit-cards";
import { Form, message, Spin } from "antd";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useMemo,
} from "react";
import Footer from "./components/Footer";
import { CardList } from "../../CardList";
import { CardForm } from "./components/CardForm";
import AddressForm from "./components/AddressForm";
import { apiPlan } from "../../../services/apiPlan";
import { useAuth } from "../../../contexts/AuthContext";
import { ResultScreen } from "./components/ResultScreen";
import { removeSpecialCharacters } from "../../../utils";
import { Plan as IPlan, UserPlan } from "../../../constants/plans";
import { PendingScreen } from "./components/ResultScreen/components/PendingScreen";
import { useBroker } from "../../../contexts/BrokerContext";
import { apiPayment } from "../../../services/apiPayment";
import HandleTag from "../../../services/handleTag";

export enum ETypePurchase {
  MEI = "MEI",
  UNIQUE = "UNIQUE",
  VELOTAX_MAIN_BASIC = "VELOTAX_MAIN_BASIC",
  VELOTAX_MAIN_PRO = "VELOTAX_MAIN_PRO",
  VELOTAX_MAIN_CONCIERGE = "VELOTAX_MAIN_CONCIERGE",
}

interface ICreditCardStepProps {
  status: string;
  initialized: boolean;
  handleBack?: () => void;
  typePurchase: ETypePurchase;
  handleSuccess?: (res: any) => void;
  setInitialized: Dispatch<SetStateAction<boolean>>;
  handleClosableModal: Dispatch<SetStateAction<boolean>>;
  plan?: IPlan | UserPlan;
  darfData?: any;
  getCardCurrentStep?: (value: number) => void;
  handleIdBack?: (event: any) => void;
  customInstallments?: number;
  backToRoot?: () => void;
  cupom?: any;
  changeCardAction?: boolean;
  closeMainModal?: () => void;
}

export const CreditCardStep: React.FC<ICreditCardStepProps> = ({
  plan,
  handleBack,
  handleClosableModal,
  typePurchase,
  darfData,
  handleSuccess,
  handleIdBack,
  customInstallments,
  backToRoot,
  cupom,
  changeCardAction,
  closeMainModal,
}) => {
  const [cardForm] = Form.useForm();
  const [addressForm] = Form.useForm();
  const { user } = useAuth();
  const [cvc, setCvc] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [status, setStatus] = useState("");
  const [number, setNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [focused, setFocused] = useState<Focused>();
  const [holderDocument, setHolderDocument] = useState("");
  const [installments, setInstallments] = useState(1);

  const [selectedCard, setSelectedCard] = useState<any>();

  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [isValidCardForm, setIsValidCardForm] = useState(false);
  const [isValidAddressForm, setIsValidAddressForm] = useState(false);

  const isPlan = useMemo(() => {
    return [
      "VELOTAX_MAIN_BASIC",
      "VELOTAX_MAIN_PRO",
      "VELOTAX_MAIN_CONCIERGE",
    ].includes(plan?.type || "");
  }, [plan]);

  const value = useMemo(() => {
    if (isPlan) {
      var isMonth = (plan as any).interval === "Mensal";

      var planPrice = (!isMonth ? (plan?.price || 0) * 12 : plan?.price) || 0;

      if (cupom?.type === "absolute") {
        planPrice = planPrice - Number(cupom?.discountValue);
      } else if (cupom?.type === "percent") {
        planPrice = planPrice - planPrice * Number(cupom?.discountValue / 100);
      }

      planPrice = Number(planPrice.toFixed(2));

      return planPrice && isMonth ? planPrice * 12 : planPrice;
    }

    return darfData?.impostoTotalFinal;
  }, [
    darfData?.impostoTotalFinal,
    plan,
    isPlan,
    cupom?.discountValue,
    cupom?.type,
  ]);

  const { currentPage } = useBroker();

  const getCardInfo = () => {
    const addressInfo = addressForm.getFieldsValue();
    const monthExp = expiry?.split("/")?.[0];
    const monthYear = expiry?.split("/")?.[1];

    let cardInfo: any = {};

    const cardId = selectedCard?.id;

    if (cardId) {
      cardInfo.card_id = cardId;
    } else {
      cardInfo = {
        line_1: `${addressInfo?.neighborhood} - ${addressInfo?.street} - ${addressInfo?.number}`,
        zip_code: addressInfo?.cep?.replace("-", ""),
        city: addressInfo?.city,
        state: addressInfo?.state.toUpperCase(),
        holder_name: removeSpecialCharacters(name),
        number,
        exp_month: monthExp,
        exp_year: monthYear,
        holderDocument,
        cvv: cvc,
      };
    }

    return cardInfo;
  };

  useEffect(() => {
    if (currentStep < 0) {
      backToRoot && backToRoot();
    }
  }, [currentStep, backToRoot]);

  const getCustomerInfo = () => {
    let customerInfo;
    if (selectedCard?.customer_id) {
      customerInfo = { customer_id: selectedCard?.customer_id };
    } else {
      customerInfo = {
        name: user?.user?.name,
        email: user?.user?.email,
        document: user?.user?.cpf,
        phone,
      };
    }

    return customerInfo;
  };

  const createMeiPayment = () => {
    const addressInfo = addressForm.getFieldsValue();

    const url = "/payment/plan/create_payment";
    const monthExp = expiry?.split("/")?.[0];
    const monthYear = expiry?.split("/")?.[1];

    setLoading(true);
    handleClosableModal(false);
    (currentPage?.api || apiPlan)
      .post(url, {
        type: plan?.type,
        aceitouCGV: true,
        naoPossuiPendencia: true,
        paymentForm: "credit_card",
        clientInfo: {
          name: user?.user?.name,
          email: user?.user?.email,
          document: user?.user?.cpf,
          phone,
        },
        cardInfo: {
          cvv: cvc,
          holderDocument,
          number: number,
          exp_year: monthYear,
          exp_month: monthExp,
          holder_name: removeSpecialCharacters(name),
          country: "BR",
          city: addressInfo?.city,
          state: addressInfo?.state.toUpperCase(),
          zip_code: addressInfo?.cep?.replace("-", ""),
          line_1: `${addressInfo?.neighborhood} - ${addressInfo?.street} - ${addressInfo?.number}`,
        },
      })
      .then((res: any) => {
        setCurrentStep(2);
        if (res?.data?.status?.toLowerCase() === "active") {
          setStatus("active");
        }
      })
      .catch((err) => {
        message.error(err.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
        handleClosableModal(true);
      });
  };

  const createPlanMainVelotax = () => {
    const url = "/user-plan";

    let cardInfo: any = getCardInfo();
    let clientInfo: any = getCustomerInfo();

    setLoading(true);
    handleClosableModal(false);

    apiPayment
      .post(url, {
        ...darfData,
        type: plan?.type,
        from: "XP",
        cupomCode: cupom?.cupomCode.toUpperCase(),
        paymentForm: "credit_card",
        installments: 12,
        cardInfo,
        clientInfo,
        acceptTerms: true,
      })
      .then((res: any) => {
        if (res.data.paymentId || res.data.paymentRecurringId) {
          handleSuccess && handleSuccess(res.data);
          if(cupom?.cupomCode.toUpperCase() === 'VELOTAXER20') {
            HandleTag('171')
          }
        }
        setCurrentStep(4);
      })
      .catch((err) => {
        message.error(err.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
        handleClosableModal(true);
      });
  };
  const createUniquePayment = () => {
    let url = "/darf";

    let cardInfo: any = getCardInfo();
    let clientInfo: any = getCustomerInfo();

    setLoading(true);
    handleClosableModal(false);

    (currentPage?.api || apiPlan)
      .post(url, {
        ...darfData,
        paymentForm: "credit_card",
        paymentInfo: {
          installments: installments,
          cardInfo: cardInfo,
          clientInfo: clientInfo,
        },
      })
      .then((res: any) => {
        if (handleIdBack) handleIdBack(res?.data);
        setCurrentStep(3);
      })
      .catch((err) => {
        message.error(err.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
        handleClosableModal(true);
      });
  };

  const changeCardService = async () => {
    let cardInfo: any = getCardInfo();

    setLoading(true);

    apiPayment
      .post(`/user-plan/change_signature_card`, {
        card: cardInfo,
      })
      .then((ret) => {
        message.success("Cartão atualizado com sucesso");
      })
      .catch((err) => {
        message.error("Ocorreu um erro ao alterar cartão");
      })
      .finally(() => {
        setLoading(false);
        closeMainModal && closeMainModal();
      });
  };

  const handleNextStep = () => {
    if (getConditionToDisable()) {
      if (currentStep === 1) cardForm.validateFields();
      if (currentStep === 2) addressForm.validateFields();
      message.warning("Preencha corretamente o formulário");
      return;
    }
    if (currentStep === 1) {
      if (!darfData?.impostoTotalFinal) {
        HandleTag("57");
      }
      setCurrentStep(2);
    } else if (currentStep === 2 || currentStep === 0) {
      if (changeCardAction) {
        changeCardService();
      } else if (typePurchase === "MEI") {
        createMeiPayment();
      } else if (typePurchase === "UNIQUE") {
        createUniquePayment();
      } else if (["VELOTAX_MAIN_BASIC", "VELOTAX_MAIN_PRO", "VELOTAX_MAIN_CONCIERGE"].includes(typePurchase)) {
        createPlanMainVelotax();
      }
    }
  };

  const getConditionToDisable = () => {
    if (currentStep === 0 && !selectedCard?.id) return true;
    if (currentStep === 1 && !isValidCardForm) return true;
    if (currentStep === 2 && !isValidAddressForm) return true;
    return false;
  };

  const changeInstallments = (value: any) => {
    setInstallments(value);
  };

  return (
    <div>
      <Spin spinning={loading}>
        {currentStep === 0 && (
          <>
            <CardList
              installmentSelected={installments}
              onChangeInstallments={changeInstallments}
              plan={plan}
              value={value}
              selectedCardId={selectedCard?.id}
              onSelectCard={(card: any) => {
                setSelectedCard(card);

                if (!card) setCurrentStep(1);
              }}
            />
          </>
        )}

        {currentStep === 1 && (
          <>
            <br />
            <CardForm
              form={cardForm}
              number={number}
              setCvc={setCvc}
              setName={setName}
              focused={focused}
              setPhone={setPhone}
              setNumber={setNumber}
              setExpiry={setExpiry}
              setFocused={setFocused}
              setHolderDocument={setHolderDocument}
              setIsValidCardForm={setIsValidCardForm}
              uniqueValue={value}
              addInfo={{ isPlan }}
              signature={(plan as UserPlan)?.interval === "Mensal"}
              fixedInstallments={isPlan ? 12 : undefined}
              handleBack={changeInstallments}
              installmentSelected={installments}
              customInstallments={customInstallments}
            />
          </>
        )}

        {currentStep === 2 && (
          <>
            <br />
            <AddressForm
              form={addressForm}
              setLoading={setLoading}
              setIsValidAddressForm={setIsValidAddressForm}
            />
          </>
        )}

        {currentStep === 3 && (
          <ResultScreen
            plan={plan as IPlan}
            status={status}
            setCurrentStep={setCurrentStep}
          />
        )}

        {currentStep === 4 && <PendingScreen />}
        <Footer
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          hideTerms
          selectedCardId={selectedCard?.id}
          handleProceed={handleNextStep}
          typePurchase={typePurchase}
          backtoRoot={backToRoot}
        />
      </Spin>
    </div>
  );
};

export default CreditCardStep;
