import { Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { BolsaManualData, Question } from "./interfaces";
import {
  OldTransactions,
  OldTransactionsProps,
} from "../Darf/Components/OldTransactions";

export const validateBolsaManualData = (data: BolsaManualData) => {
  return true;
};

export const questions: Question[] = [
  // {
  //   id: "possuiInvestimentosBolsa2019",
  //   title: "Você tinha investimentos em 31/12/2019?",
  //   formItems: [
  //     {
  //       name: "possuiInvestimentosBolsa2019",
  //       FormItem: () => (
  //         <Form.Item
  //           name="possuiInvestimentosBolsa2019"
  //           rules={validationFieldRequired}
  //         >
  //           <Radio.Group>
  //             <Radio value={true}>Sim</Radio>
  //             <Radio value={false}>Não</Radio>
  //           </Radio.Group>
  //         </Form.Item>
  //       ),
  //     },
  //   ],
  // },
  {
    id: "posicoesBolsa2019",
    title: "Complete sua carteira de 31/12/2019",
    subTitle: (
      <>
        <InfoCircleOutlined />
        &nbsp;A B3 armazena dados somente a partir de 2020. Por isso, você precisará preencher manualmente o custo médio dos
        seus investimentos em 31/12/2019. Você pode encontrar essas informações na sua Declaração de Imposto de Renda de 2019/2020.
      </>
    ),
    children: (
      <Typography style={{ marginTop: "32px", textAlign: "right" }}>
        Lembre-se de clicar em finalizar para salvar seus dados
      </Typography>
    ),
    formItems: [
      {
        name: "posicoesBolsa2019",
        FormItem: ({ rest }) => (
          <OldTransactions {...(rest as OldTransactionsProps)} />
        ),
      },
    ],
  },
];
