import { Table } from "antd";
import { IoMdOptions } from "react-icons/io";
import Button from "../../../components/Button";
import { monthsExtended } from "../../../utils";
import { DrawerModal } from "../../../components/DrawerModal";
import { useB3Integration } from "../../../contexts/B3IntegrationContext";

const initialYear = 2020;

export const dayFilters: Array<any> = [];
for (let d = 1; d <= 31; d++) {
  dayFilters.push({
    text: d.toString(),
    value: d,
  });
}

const yearFilters = Array.from(
  Array(new Date().getFullYear() + 1 - initialYear)
)
  .map((y, i) => ({ value: initialYear + i, text: `${initialYear + i}` }))
  .reverse();

export const NotasHistoricoModal: React.FC = ({}) => {
  const {
    notas: {
      downloadNotaCorretagem,
      historicoNotasCorretagem,
      getHistoricoNotasCorretagem,
      setHistoricoNotasCorretagem,
      loadingHistoricoNotasCorretagem,
      showHistoricoNotasCorretagemModal,
      setShowHistoricoNotasCorretagemModal,
    },
  } = useB3Integration();

  return (
    <DrawerModal
      footer={null}
      title="Histórico de notas"
      visible={showHistoricoNotasCorretagemModal}
      className="notas-corretagem-historico-modal"
      onCancel={() => {
        setShowHistoricoNotasCorretagemModal(false);
        setHistoricoNotasCorretagem([]);
      }}
    >
      <Table
        rowKey="id"
        dataSource={historicoNotasCorretagem}
        loading={loadingHistoricoNotasCorretagem}
        pagination={{
          defaultPageSize: 5,
          total: historicoNotasCorretagem.length,
          defaultCurrent: 1,
          simple: true,
        }}
        onChange={(pagination, filters) => {
          getHistoricoNotasCorretagem({ filters });
        }}
        locale={{
          filterReset: "Limpar",
        }}
        columns={[
          {
            align: "center",
            title: "Dia",
            dataIndex: "day",
            filterIcon: <IoMdOptions className="defaultIconSize" />,
            filters: dayFilters,
            render: (status, item) => <>{status}</>,
          },
          {
            align: "center",
            title: "Mês",
            dataIndex: "month",
            filterIcon: <IoMdOptions className="defaultIconSize" />,
            filters: monthsExtended.map((m, i) => ({
              text: m,
              value: i + 1,
            })),
            render: (status, item) => <>{status}</>,
          },
          {
            align: "center",
            title: "Ano",
            dataIndex: "year",
            filterIcon: <IoMdOptions className="defaultIconSize" />,
            filters: yearFilters,
            render: (status, item) => <>{status}</>,
          },
          {
            align: "center",
            title: "Data de criação",
            dataIndex: "createdAt",
            render: (value, item) => <>{(new Date(value ?? Date.now())).toLocaleString('pt-br')}</>,
          },
          {
            className: "actionColumn",
            render: (status, item) => (
              <Button
                onClick={() =>
                  downloadNotaCorretagem(
                    item.path,
                    item.month,
                    item.year
                  )
                }
              >
                Ver nota
              </Button>
            ),
          },
        ]}
      />
    </DrawerModal>
  );
};
