import { Col, Row } from "antd";
import { GrMenu } from "react-icons/gr";
import { BsChevronLeft } from "react-icons/bs";
import Button from "../Button";
import { isMobile } from "../../utils";
import { LayoutHeader } from "./styles";
import { useAuth } from "../../contexts/AuthContext";
import { useHistory } from "../../contexts/HistoryContext";

interface IHeaderProps {}

const Header: React.FC<IHeaderProps> = () => {
  const { setShowDrawer } = useAuth();
  const { handleBackHistory } = useHistory();

  return (
    <>
      <LayoutHeader>
        {isMobile() && (
          <Row
            align="middle"
            justify="space-between"
            style={{ width: "100%", flexWrap: "nowrap" }}
          >
            <Col>
              <Button
                type="link"
                className="back-button"
                icon={<BsChevronLeft />}
                onClick={handleBackHistory}
              />
            </Col>
            <Col>
              <Button
                type="text"
                size="large"
                icon={<GrMenu />}
                className="menu-button"
                onClick={() => setShowDrawer(true)}
              />
            </Col>
          </Row>
        )}
      </LayoutHeader>
    </>
  );
};

export default Header;
