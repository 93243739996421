import clsx from "clsx";
import { useEffect } from "react";
import { Button } from "@mui/material";
import { List, Skeleton, Space, Typography } from "antd";
import { Container, Content, Field, Value } from "./styles";
import { Page } from "../../constants/brokers";
import {
  StatusCSSClassEnum,
  StatusTextEnum,
} from "../../constants/managerAccess";
import { useRequireAccess } from "../../contexts/RequireAccessContext";

interface ManagerAccessProps {
  item?: Page;
}

export const ManagerAccess: React.FC<ManagerAccessProps> = () => {
  const { checkPermissionUser, solicitantesAcesso, changePermission, loading } =
    useRequireAccess();

  window.history.replaceState({}, document.title);

  useEffect(() => {
    checkPermissionUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (token: string) => {
    changePermission(token);
  };

  return (
    <Container>
      <Typography.Title level={1}>Gestão de Acessos</Typography.Title>
      <Typography.Title level={2}>
        Escritórios e assessores com permissão de acesso à sua conta
      </Typography.Title>
      <Content>
        <List
          loading={loading}
          dataSource={solicitantesAcesso}
          itemLayout="horizontal"
          renderItem={(item: any, index) => (
            <List.Item
              actions={[
                <Button
                  fullWidth
                  size="small"
                  color="secondary"
                  variant="outlined"
                  onClick={() => handleClick(item.token)}
                >
                  {item?.isAuthorized ? "Bloquear acesso" : "Permitir acesso"}
                </Button>,
              ]}
            >
              <Skeleton title={false} loading={false} active>
                <List.Item.Meta
                  title={item?.name || item?.officeName || " "}
                  description={
                    <Space direction="vertical">
                      {item?.escritorio && (
                        <Field>
                          Escritório:
                          <Value>{item?.escritorio.name || ""}</Value>
                        </Field>
                      )}
                      {item?.assessor && (
                        <Field>
                          Assessor:
                          <Value>{item?.assessor.name || ""}</Value>
                        </Field>
                      )}
                      <div style={{ display: "flex" }}>
                        <span
                          className={clsx(
                            "assessor-status",
                            StatusCSSClassEnum[
                              String(
                                item?.isAuthorized
                              ) as keyof typeof StatusCSSClassEnum
                            ]
                          )}
                        >
                          {
                            StatusTextEnum[
                              String(
                                item?.isAuthorized
                              ) as keyof typeof StatusTextEnum
                            ]
                          }
                        </span>
                      </div>
                    </Space>
                  }
                />
              </Skeleton>
            </List.Item>
          )}
        />
      </Content>
    </Container>
  );
};
