import { useState } from "react";
import { Drawer, Typography, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { HiOutlineCurrencyDollar } from "react-icons/hi";
import api from "../../services/apiBolsa";
import { NotificationContainer } from "./styles";
import { isMobile } from "../../utils";
import notificationImage from "../../assets/notification.svg";
import { useNotification } from "../../contexts/NotificationContext";

interface IProps {
  open: boolean;
  onClose:
    | ((
        e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
      ) => void)
    | undefined;
}

export const NotificationDrawer = ({ open, onClose }: IProps) => {
  const { notification, setNotification, loading } = useNotification();
  const [loadingIndex, setLoadingIndex] = useState<number | "all" | null>(2);
  const lidas = (notification || []).filter((n) => n.viewed);
  const naoLidas = (notification || []).filter((n) => !n.viewed);

  const removeNotification = (index?: number) => {
    const notifications = notification.map((n, i: number) => {
      if (index === undefined || index === i)
        return { ...n, viewed: true, viewedDate: new Date() };
      return n;
    });

    setLoadingIndex(index === undefined ? "all" : index);
    api
      .patch(`/xpinvestimentos/notification`, {
        notifications,
      })
      .then(() => {
        setNotification(notifications);
        setLoadingIndex(null);
      })
      .catch(() => {
        message.error("Não foi possível remover notificação");
      });
  };

  return (
    <Drawer
      size="large"
      placement="right"
      onClose={onClose}
      visible={open}
      closeIcon={<AiOutlineArrowLeft />}
      title={
        <div onClick={onClose} style={{ cursor: "pointer" }}>
          Voltar
        </div>
      }
      {...(isMobile() ? { width: "100%" } : {})}
    >
      <NotificationContainer>
        <div>
          <Typography.Title level={3}>Notificações</Typography.Title>
          <div className="divider" />
        </div>

        {(loading || !notification?.length) && (
          <div className="illustration">
            <img src={notificationImage} alt="Notificações" />
            {loading ? (
              <div className="loading">
                <LoadingOutlined />
                <Typography.Title level={5}>
                  Buscando notificações
                </Typography.Title>
              </div>
            ) : !notification?.length ? (
              <Typography.Title level={5}>
                Você não possui novas notificações.
              </Typography.Title>
            ) : (
              <></>
            )}
          </div>
        )}

        {}
        {!!naoLidas?.length && !loading && (
          <div className="section">
            <Typography.Title level={5}>Não lidas</Typography.Title>
            <button onClick={() => removeNotification()}>
              Marcar todas como lida
            </button>
          </div>
        )}
        {!loading &&
          (notification || [])?.map((item, i) => {
            if (item.viewed) return <></>;
            return (
              <div key={i}>
                <div
                  className="card"
                  onClick={() => {
                    if (!(loadingIndex === i || loadingIndex === "all"))
                      removeNotification(i);
                  }}
                >
                  {(loadingIndex === i || loadingIndex === "all") && (
                    <div className="loading">
                      <LoadingOutlined />
                    </div>
                  )}
                  <div className="header">
                    <HiOutlineCurrencyDollar />
                    <Typography.Title level={5}>
                      Rendimentos a receber
                    </Typography.Title>
                    {item?.date && (
                      <Typography.Text>
                        {new Date(item?.date)?.toLocaleDateString("pt-BR", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        })}
                      </Typography.Text>
                    )}
                  </div>
                  <div className="body">
                    <Typography.Text>{item.message}</Typography.Text>
                  </div>
                </div>
              </div>
            );
          })}

        {/* Notificações Lidas */}
        {!!lidas?.length && !loading && (
          <div className="section">
            <Typography.Title level={5}>Lidas</Typography.Title>
          </div>
        )}
        {!loading &&
          (notification || [])?.map((item, i) => {
            if (!item.viewed) return <></>;
            return (
              <div key={i}>
                <div className="card viewed">
                  <div className="header">
                    <HiOutlineCurrencyDollar />
                    <Typography.Title level={5}>
                      Rendimentos a receber
                    </Typography.Title>
                    {item?.date && (
                      <Typography.Text>
                        {new Date(item?.date)?.toLocaleDateString("pt-BR", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        })}
                      </Typography.Text>
                    )}
                  </div>
                  <div className="body">
                    <Typography.Text>{item.message}</Typography.Text>
                  </div>
                </div>
              </div>
            );
          })}
      </NotificationContainer>
    </Drawer>
  );
};
