import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineCheck } from "react-icons/ai";
import { LoadingOutlined } from "@ant-design/icons";
import { Col, Divider, Form, Row, Typography } from "antd";
import { questions } from "./items";
import Loading from "../../components/Loading";
import { BolsaManualData } from "./interfaces";
import { BolsaManualContainer } from "./styles";
import { useBroker } from "../../contexts/BrokerContext";
import { PrePreenchidaModal } from "../PrePreenchidaModal";
import { useUploadDec } from "../../contexts/UploadDecContext";
import { Question, QuestionContainer } from "../MeiIdentification/styles";
import { useOldTransactions } from "../../contexts/OldTransactionsContext";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";

const bolsaManualId = "bolsa-manual-container";

interface BolsaManualProps {}

export const BolsaManual: React.FC<BolsaManualProps> = () => {
  const navigate = useNavigate();
  const { currentBroker } = useBroker();
  const [form] = Form.useForm<BolsaManualData>();
  const [currentQuestion] = useState(0);
  const [showAlertModal, setShowAlertModal] = useState(false);

  const { loadingGet, changed, getOldTransactionsInit, ...rest } =
    useOldTransactions();
  const [showModalPrePreenchida, setShowModalPrePreenchida] = useState(false);
  const { loading } = useUploadDec();

  const handleAlertModalVisibility = (value: boolean) => {
    setShowAlertModal(value);
  };

  const handleSubmit = async (data: BolsaManualData) => {
    try {
      if (rest.oldPositionList.some((oldPosition) => oldPosition.price === 0)) {
        handleAlertModalVisibility(true);
        return;
      }
      await rest.saveOldPositions();
      setTimeout(() => {
        navigate(`/${currentBroker.path}/bolsa-historic`);
      }, 1000);
    } catch (err) {}
  };

  useEffect(() => {
    if (rest.oldPositionList?.length < 1 && rest.oldPositionList !== null) {
      navigate(`/${currentBroker.path}/bolsa-historic`);
    }
  }, [rest.oldPositionList, navigate, currentBroker.path]);

  useEffect(() => {
    getOldTransactionsInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalPrePreenchida, loading]);

  return loadingGet ? (
    <Loading />
  ) : (
    <Form
      form={form}
      size="large"
      onFinish={handleSubmit}
      style={{ height: "100%", overflow: "hidden" }}
    >
      <BolsaManualContainer id={bolsaManualId}>
        {questions.map((question, index) => (
          <QuestionContainer
            width="700px"
            className="beauty-scrollbar"
            id={`question-${index}`}
            key={question.id}
          >
            <Typography.Title level={2}>{question.title}</Typography.Title>

            <Question width="700px">
              <Typography
                style={{ marginBottom: "32px", textAlign: "justify" }}
              >
                {question.subTitle}
              </Typography>
              <Row justify="start" className="btn-preencher-pos2019">
                <Col span={24}>
                  <Button
                    fullWidth
                    size="large"
                    color="secondary"
                    variant="contained"
                    disabled={rest.loadingPut}
                    onClick={() => setShowModalPrePreenchida(true)}
                  >
                    {rest.loadingPut && <LoadingOutlined />}&nbsp; Preencher
                    automaticamente
                  </Button>
                </Col>
              </Row>
              {question.formItems.map(({ FormItem, name }) => (
                <FormItem
                  key={name}
                  rest={{ ...rest, loadingGet }}
                  tabIndex={currentQuestion === index ? 1 : -1}
                />
              ))}

              {question.children}
              <Divider />
              <Row justify="space-between" align="middle" gutter={[16, 16]}>
                <Col>
                  <Button
                    size="large"
                    variant="text"
                    color="secondary"
                    onClick={() => {
                      navigate("/xpinvestimentos/bolsa");
                    }}
                  >
                    Voltar
                  </Button>
                </Col>
                <Col>
                  <Button
                    size="large"
                    type="submit"
                    color="secondary"
                    variant="contained"
                    id="submit-button"
                    tabIndex={currentQuestion === index ? 3 : -1}
                    endIcon={<AiOutlineCheck size={18} />}
                    startIcon={rest.loadingPut && <LoadingOutlined />}
                  >
                    Finalizar
                  </Button>
                </Col>
              </Row>
            </Question>
          </QuestionContainer>
        ))}

        <PrePreenchidaModal
          visible={showModalPrePreenchida}
          setShowModalPrePreenchida={setShowModalPrePreenchida}
          onCancel={() => setShowModalPrePreenchida(false)}
        />

        <DeleteConfirmationModal
          title="Deseja realmente prosseguir?"
          visibility={showAlertModal}
          onCancel={() => setShowAlertModal(false)}
          setVisibility={handleAlertModalVisibility}
          onOk={() => {
            return Promise.resolve(rest.saveOldPositions());
          }}
          body="O valor de algumas posições não foram preenchidas!"
          buttonLabel="Prosseguir"
          successMessage="Ativos salvos com sucesso!"
        />
      </BolsaManualContainer>
    </Form>
  );
};
