import moment from "moment";
import { Tooltip } from "antd";
import { BiErrorCircle } from "react-icons/bi";
import NumberFormat from "react-number-format";

export const removeLiNumbers = (t: string) => t.replace(/^([\d]+\.*)+/, "");

export const onlyNumbersToPhone = (value: string) =>
  value.replace(/[()_.%-/\s/]/g, "");

export const formatCurrency = (value: number, prefix = "R$ ") => {
  return (
    <NumberFormat
      prefix={prefix}
      decimalScale={2}
      displayType="text"
      decimalSeparator=","
      thousandSeparator="."
      fixedDecimalScale={true}
      value={
        value?.toLocaleString("pt-br", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) || 0
      }
    />
  );
};

export const numberToCurrency = (value: number) => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })

  return formatter.format(value)
}

export const numberToPercentage = (value?: number, toFixed = 2) => {
  const number = typeof value === "number" ? value : 0;
  return `${Number(number * (number < 1 ? 100 : 1)).toLocaleString("pt-br", {
    minimumFractionDigits: toFixed,
    maximumFractionDigits: toFixed,
  })}%`;
};

export const numberToPercentageWallet = (value?: number, toFixed = 2) => {
  const number = typeof value === "number" ? value : 0;
  return `${Number(number * 100).toLocaleString("pt-br", {
    minimumFractionDigits: toFixed,
    maximumFractionDigits: toFixed,
  })}%`;
};

export const currencyToNumber = (value: string, prefix = "R$") =>
  Number(
    value
      ?.replace(prefix, "")
      .replace(/\./g, "")
      .replace(/,/g, ".")
      .replace(/\s/g, "")
  ) || 0;

export const replaceDotByComma = (value: number) => {
  return String(value).replace(/\./g, ",");
};

export const ErrorIcon = (
  <Tooltip title="Este campo é obrigatório">
    <BiErrorCircle size={24} className="error-icon" />
  </Tooltip>
);

export const zero = (n: number) => (n < 10 ? `0${n}` : `${n}`);

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const day = zero(date.getDate());
  const month = zero(date.getMonth() + 1);
  return `${day}/${month}/${year}`;
};

export const formatDateHour = (dateString: string) =>
  `${moment(dateString).format("DD/MM/YYYY")} às ${moment(dateString).format(
    "HH:mm"
  )}`;
