import { useState } from "react";
import { ModalProps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Plan } from "./Plan";
import { useBroker } from "../../contexts/BrokerContext";
import { Content, PlanModalStyled, Loading } from "./styles";
import { Plans, Plan as IPlan } from "../../constants/plans";
import CreditCardStep, { ETypePurchase } from "../Payment/CreditCardStep";

interface PlanModalProps extends ModalProps {
  modalPlans?: IPlan[];
}

export const PlanModal: React.FC<PlanModalProps> = ({
  title,
  modalPlans,
  onCancel,
  ...rest
}) => {
  const {
    integration: { plans },
  } = useBroker();

  const [plan, setPlan] = useState<IPlan>();
  const [loading, setLoading] = useState(false);
  const [closable, setClosable] = useState(true);

  const handleSelectPlan = (plan?: IPlan) => {
    setLoading(true);
    setTimeout(() => {
      setPlan(plan);
      setLoading(false);
    }, 500);
  };

  const handleDeselectPlan = () => {
    setPlan(undefined);
  };

  return (
    <PlanModalStyled
      footer={null}
      closable={closable}
      title={!plan ? title ?? "Selecione um plano" : "Pagamento"}
      onCancel={(e) => {
        handleDeselectPlan();
        onCancel?.(e);
      }}
      {...rest}
    >
      <Content>
        {loading && (
          <Loading>
            <LoadingOutlined color="var(--ant-primary-color)" size={24} />
          </Loading>
        )}
        {!plan ? (
          (modalPlans || Plans).map((plan) => (
            <Plan
              plan={plan}
              key={plan.title}
              handleSelect={() => handleSelectPlan(plan)}
              buyed={
                (plans?.name === plan.type && plans.status === "active") ??
                false
              }
            />
          ))
        ) : (
          <CreditCardStep
            plan={plan}
            initialized={true}
            handleBack={handleDeselectPlan}
            typePurchase={ETypePurchase.MEI}
            handleClosableModal={setClosable}
            status=""
            handleSuccess={() => {}}
            setInitialized={() => {}}
            getCardCurrentStep={(value: number) => {}}
          />
        )}
      </Content>
    </PlanModalStyled>
  );
};
