import styled from "styled-components";

export const HomeContainer = styled.div`
  height: 100%;
  display: flex;
  padding: 64px 32px 32px 48px;

  & > .content {
    margin: 0 auto;
    width: 700px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.bolsa,
    &.exterior {
      .cards.cards-2 > div {
        width: 100%;
      }
    }
  }

  h1 {
    font-weight: 400;
    font-size: 2rem;
    position: relative;
    line-height: 2.5rem;
    padding-bottom: 1rem;
    margin-bottom: 3rem;
    color: var(--velotax-font-color-light);

    :after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 100px;
      background-color: var(--ant-primary-color);
    }
  }

  div.ant-typography {
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--velotax-font-color-light);

    strong {
      font-weight: 500;
    }
  }

  .ant-typography.destak {
    margin: 0 0 24px;
    display: block;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--velotax-font-color-light);
  }

  .cards {
    margin: 0;
    padding-bottom: 32px;
    width: 100%;
    display: grid;
    row-gap: 1rem;
    column-gap: 1rem;
    grid-template-columns: repeat(4, 1fr);

    &.cards-3 {
      grid-template-columns: repeat(3, 1fr);
    }
    &.cards-2 {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .home-footer {
    opacity: 0.75;
    font-size: 12px;
    font-style: italic;
    line-height: 16px;
    letter-spacing: 0.625px;
  }

  @media only screen and (max-device-width: 812px) {
    height: auto;
    padding: 24px 24px 64px;
    & > .content {
      width: 100%;
      max-width: 100%;
      &.bolsa,
      &.exterior {
        width: 100%;
        .cards.cards-2 > div {
          width: 100%;
        }
      }
    }

    .cards {
      width: 100%;
      grid-template-columns: repeat(2, 1fr) !important;

      > div {
        width: auto;
      }
    }
  }
`;
