import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { HeaderContainer } from "./styles";
import { useAuth } from "../../../contexts/AuthContext";
import { useBroker } from "../../../contexts/BrokerContext";
import VelotaxLogo from "../../../assets/velotax/velotax-light.svg";

export const Header = () => {
  const { user } = useAuth();
  const { currentBroker } = useBroker();

  const navigate = useNavigate();
  return (
    <HeaderContainer>
      <h1>
        <a
          href={currentBroker?.link || "https://www.velotax.com.br/"}
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="fixed"
            alt={currentBroker?.name || "Velotax logo light"}
            src={
              currentBroker.iconAlternative
                ? currentBroker.iconAlternative
                : currentBroker?.icon
                ? currentBroker?.icon
                : VelotaxLogo
            }
          />
        </a>
      </h1>
      {!user.user && (
        <div className="buttons">
          <Button size="medium" onClick={() => navigate("/p/login")}>
            <span className="xp-link">Entrar</span>
          </Button>
          <Button
            size="medium"
            type="button"
            color="secondary"
            variant="contained"
            onClick={() => navigate("/p/cadastrar")}
          >
            Começar
          </Button>
        </div>
      )}
    </HeaderContainer>
  );
};
