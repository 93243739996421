import clsx from "clsx";
import { useScroll } from "react-use";
import { useLocation } from "react-router-dom";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Layout } from "./styles";
import { PageInfo } from "../PageInfo";
import { Page } from "../../constants/brokers";
import { TabNavigator } from "../TabNavigator";
import { useAuth } from "../../contexts/AuthContext";
import { useBroker } from "../../contexts/BrokerContext";
import { IRPFBanner } from "../../pages/Home/IRPFBanner";
import { Row } from "antd";

interface IPageWrapperProps {
  header?: boolean;
  sideBar?: boolean;
  overflow?: boolean;
  page?: string;
}

const PageWrapper: React.FC<IPageWrapperProps> = ({
  children,
  sideBar,
  header,
  overflow,
}) => {
  const scrollRef = useRef(null);
  const { y } = useScroll(scrollRef);
  const { pathname } = useLocation();
  const { currentBroker, integration } = useBroker();
  const {
    user,
    loadingSession,
    b3Authorized,
    cameFromRegister,
    setCameFromRegister,
    setShowPhoneModal,
  } = useAuth();
  const [showIrpfBanner, setShowIrpfBanner] = useState(false);

  useEffect(() => {
    if (
      y > 100 &&
      user.user &&
      !loadingSession &&
      !user?.user?.cellphone &&
      cameFromRegister.phone &&
      !user?.user?.cellphoneFilled &&
      pathname.includes("historic") &&
      b3Authorized &&
      integration.lastUpdate !== ""
    ) {
      setShowPhoneModal(true);
      setCameFromRegister((data) => ({ ...data, phone: false }));
    }
  }, [
    y,
    pathname,
    user.user,
    b3Authorized,
    loadingSession,
    setShowPhoneModal,
    setCameFromRegister,
    cameFromRegister.phone,
    integration?.lastUpdate,
  ]);

  const currentPage = useMemo(
    () =>
      [
        {
          sidebar: true,
          header: true,
          path: "/",
          name: "Início",
          title: "",
        },
        {
          sidebar: true,
          header: true,
          path: "/carteira",
          name: "Meus investimentos",
          title: "",
        },
        {
          sidebar: true,
          header: true,
          path: "/pgbl",
          name: "Simulador de previdência",
          title: "",
        },
        {
          sidebar: true,
          header: true,
          path: "/conta",
          name: "Minha conta",
          title: "",
        },
        {
          sidebar: true,
          header: true,
          path: "/regulacao",
          name: "Regulação",
          title: "",
        },
        {
          sidebar: true,
          header: true,
          path: "/p/cadastrar",
          name: "Cadastre-se",
          title: "Cadastre-se",
        },
        {
          sidebar: true,
          header: true,
          path: "/p/login",
          name: "Entrar",
          title: "Entrar",
        },
        ...(currentBroker?.pages.reduce(
          (acc, cur) => [...acc, cur, ...(cur.subPages ?? [])],
          [] as Page[]
        ) || []),
      ].find(
        (page) =>
          pathname ===
          (page.path.substring(0, 1) === "/" ? page.path : `/${page.path}`)
      ),
    [pathname, currentBroker]
  );

  return (
    <>
      <IRPFBanner
        active={true}
        show={showIrpfBanner}
        setShow={setShowIrpfBanner}
      />
      <Layout className={clsx({ showIrpfBanner })}>
        {(sideBar ||
          (typeof currentPage?.sidebar === "boolean" &&
            currentPage?.sidebar)) && (
          <Sidebar pagePath={currentPage?.path || pathname} />
        )}
        <Layout>
          {process.env.REACT_APP_SHOW_B3_BANNER === 'true' && 
            <Row className="banner">
              No momento nossa integração com os sistemas da B3 está instável.
              Tente novamente mais tarde.
            </Row>
          }
          {(header ||
            (typeof currentPage?.header === "boolean" &&
              currentPage?.header)) && <Header />}
          <div
            ref={scrollRef}
            className={clsx("content", "beauty-scrollbar", "tab-navigator", {
              overflow,
            })}
          >
            {currentBroker.id === "mei" &&
              currentPage?.name === "Cadastre-se" && (
                <PageInfo
                  title="Faça a sua declaração IR do MEI"
                  subtitle="As informações do formulário são necessárias para prepararmos sua declaração"
                />
              )}
            {children}
          </div>
          <TabNavigator />
        </Layout>
      </Layout>
    </>
  );
};

export default PageWrapper;
