import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { AiOutlineArrowDown } from "react-icons/ai";
import { Col, Divider, Form, Input, Row, message } from "antd";
import api from "../../services/api";
import { errorMessage } from "../../utils";
import { DrawerModal } from "../DrawerModal";
import { useAuth } from "../../contexts/AuthContext";
import { useBroker } from "../../contexts/BrokerContext";
import { validationFullName } from "../../utils/formValidations";

export const NameNotification = () => {
  const { loadingIntegrationStatus } = useBroker();
  const {
    user,
    loadingSession,
    updateUserInfo,
    showNameModal,
    cameFromRegister,
    setShowNameModal,
  } = useAuth();

  const [showed, setShowed] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFinish = ({ name }: { name: string }) => {
    setLoading(true);
    api
      .put("/user/feed-userinfo", {
        name,
        document: user.user.cpf,
      })
      .then(() => {
        message.success("Obrigado por adicionar seu nome");
        updateUserInfo();
        setTimeout(() => {
          setShowNameModal(false);
        }, 1000);
      })
      .catch(() => {
        message.success(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (
      !showed &&
      user.user &&
      !loadingSession &&
      !loadingIntegrationStatus &&
      !user?.user?.name &&
      !cameFromRegister.bolsa
    ) {
      setShowed(true);
      setShowNameModal(true);
    }
  }, [
    showed,
    user.user,
    loadingSession,
    setShowNameModal,
    cameFromRegister.bolsa,
    loadingIntegrationStatus,
  ]);

  return (
    <DrawerModal
      width={600}
      height="auto"
      closable={false}
      onCancel={() => {}}
      maskClosable={false}
      className="no-closable"
      visible={showNameModal}
      title={<span style={{ fontWeight: "700" }}>Insira seu nome</span>}
    >
      <Form size="large" layout="vertical" onFinish={onFinish}>
        <Form.Item name="name" rules={validationFullName}>
          <Input disabled={loading} placeholder="Nome completo" />
        </Form.Item>
        <Divider />
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Button
              fullWidth
              size="large"
              type="submit"
              color="secondary"
              disabled={loading}
              variant="contained"
              startIcon={loading ? <LoadingOutlined /> : <AiOutlineArrowDown />}
            >
              Prosseguir
            </Button>
          </Col>
        </Row>
      </Form>
    </DrawerModal>
  );
};
