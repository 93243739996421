import { Tag } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const StyledTag = styled(Tag)`
  margin: 0;
  height: 32px;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  font-size: 12px;
  font-weight: 500;
`;
