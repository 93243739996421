import styled from "styled-components";

export const Container = styled.div`
  padding: 64px 32px 128px;
  @media only screen and (max-device-width: 812px) {
    padding: 0;
  }
`;

export const Content = styled.div`
  padding: 32px;
  margin: 0 auto;
  max-width: 600px;
  border-radius: 16px;
  background-color: var(--velotax-background-color);
  h1 {
    font-size: 24px;
    margin: 0 0 24px;
    position: relative;
    color: var(--velotax-font-color);

    &::before {
      content: "";
      top: 0;
      width: 4px;
      left: -32px;
      height: 100%;
      position: absolute;
      background-color: var(--ant-primary-color);
    }
  }
  @media only screen and (max-device-width: 812px) {
    padding: 24px;
    border-radius: 0;
    min-height: calc(100vh - 64px);
    h2 {
      &::before {
        left: -24px;
      }
    }
  }
`;

export const CalculatorsSelect = styled.div`
  h2 {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 24px;
    color: var(--velotax-font-color);
  }
`;
