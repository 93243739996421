import { Darf } from "../../pages/Darf";
import { Bolsa } from "../../pages/Bolsa";
import { Crypto } from "../../pages/Crypto";
import { MeiDas } from "../../pages/MeiDas";
import { Historic } from "../../pages/Historic";
import { Exterior } from "../../pages/Exterior";
import { Settings } from "../../pages/Settings";
import { DarfBolsa } from "../../pages/DarfBolsa";
import { MeiReport } from "../../pages/MeiReport";
import { Regulation } from "../../pages/Regulation";
import { ReportBolsa } from "../../pages/ReportBolsa";
import { Integration } from "../../pages/Integration";
import { BolsaManual } from "../../pages/BolsaManual";
import { Rendimentos } from "../../pages/Rendimentos";
import { DarfExterior } from "../../pages/DarfExterior";
import { AnnualReport } from "../../pages/AnnualReport";
import { HistoricBolsa } from "../../pages/HistoricBolsa";
import { ReportExterior } from "../../pages/ReportExterior";
import { DividendosBolsa } from "../../pages/DividendosBolsa";
import { Identification } from "../../pages/MeiIdentification";
import { IntegrationBolsa } from "../../pages/IntegrationBolsa";
import { IntegrationCrypto } from "../../pages/IntegrationCrypto";
import { DividendosExterior } from "../../pages/DividendosExterior";
import { IntegrationExterior } from "../../pages/IntegrationExterior";
import { IntegrationExteriorXP } from "../../pages/IntegrationExteriorXP";
import { TransferenciaCustodia } from "../../pages/TransferenciaCustodia";
import { DoacoesHeranca } from "../../pages/DoacoesHeranca";

export const Pages: { [key: string]: any } = {
  Darf,
  Bolsa,
  Crypto,
  MeiDas,
  Exterior,
  Historic,
  Settings,
  DarfBolsa,
  MeiReport,
  Regulation,
  ReportBolsa,
  Integration,
  BolsaManual,
  DoacoesHeranca,
  Rendimentos,
  AnnualReport,
  DarfExterior,
  HistoricBolsa,
  ReportExterior,
  Identification,
  DividendosBolsa,
  IntegrationBolsa,
  IntegrationCrypto,
  DividendosExterior,
  IntegrationExterior,
  TransferenciaCustodia,
  IntegrationExteriorXP,
};
