import { Layout } from "antd";
import styled from "styled-components";

export const LayoutHeader = styled(Layout.Header)`
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  z-index: 1;
  height: 64px;
  padding: 0 24px;
  column-gap: 8px;
  background: var(--velotax-background-color);
  box-shadow: 0px 0px 16px 12px rgba(0, 0, 0, 0.3);

  .ant-btn {
    &.menu-button {
      z-index: 1;
      width: 48px;
      height: 48px;
      margin-right: -8px;
      svg {
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        * {
          stroke: var(--ant-primary-color) !important;
        }
      }
    }
    &.back-button {
      width: 48px;
      height: 48px;
      margin-left: -16px;
      svg {
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        * {
          fill: var(--ant-primary-color) !important;
        }
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    display: flex;
  }
`;
