import { message } from "antd";
import React, { useEffect, useState } from "react";
import apis from "../../../services/apiExterior";
import IntegrationButton from "../IntegrationButton";
import { IntegrationStatus } from "../ExteriorIntegration";
// import passfolioLogo from "../../../assets/passfolio-logo.png";
import PassfolioIntegrationModal from "./PassfolioIntegrationModal";

interface IGetEstadoIntegracao {
  success: (data: any) => void;
  error: (data: any) => void;
}

interface IPassfolioIntegrationProps {
  dependentId?: string;
  integrationCallback: (type: IntegrationStatus) => void;
}

const PassfolioIntegration: React.FC<IPassfolioIntegrationProps> = ({
  dependentId,
  integrationCallback,
}) => {
  const [loading, setLoading] = useState(true);
  const [integrationModalVisible, setIntegrationModalVisible] = useState(false);
  const [integrado, setIntegrado] = useState(false);
  const [integrationData, setIntegrationData] = useState();

  const getEstadoIntegracao = ({ success, error }: IGetEstadoIntegracao) =>
    apis
      .get("/passfolio/integration-status", {
        headers: {
          "x-api-provider": "exterior-velotax",
        },
      })
      .then(({ data }) => {
        setIntegrationData(data);
        success(data);
      })
      .catch((err) => {
        error(err);
      });

  useEffect(() => {
    getEstadoIntegracao({
      success: (data) => {
        if (!data.integrated && data.providerExists) {
          setLoading(true);
          setIntegrado(false);
          integrationCallback("carregando");
        } else if (data.integrated) {
          setLoading(false);
          setIntegrado(true);
          integrationCallback("integrado");
        }
      },
      error: () => {
        integrationCallback("erro");
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (loading) {
      interval = setInterval(
        () => {
          integrationCallback("carregando");
          getEstadoIntegracao({
            success: (data: any) => {
              if (!data.integrated && data.providerExists) {
                integrationCallback("carregando");
              }

              if (!data.providerExists) {
                setLoading(false);
                setIntegrado(false);
              }

              if (data.integrated) {
                setLoading(false);
                setIntegrado(true);
                message.success("Integração feita com sucesso!");
                integrationCallback("integrado");
              }
            },
            error: (err: any) => {
              setLoading(false);
              integrationCallback("erro");
              if (err.response?.data?.message) {
                return message.error(err.response?.data?.message);
              }
              return message.error(
                "Algo deu errado na integração com a Foxbit, verifique suas credenciais"
              );
            },
          });
        },

        3000
      );
    } else {
      if (interval) {
        clearInterval(interval);
      }
    }
    return () => clearInterval(interval as unknown as NodeJS.Timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, integrado]);

  return (
    <>
      <IntegrationButton
        imgHeight
        logo="https://web.xpi.com.br/wp-content/themes/xpi-acquisition-xp/assets/images/footer/logo-xpi-preto.svg"
        loading={loading}
        integrado={integrado}
        alt="Integrar com a Passfolio"
        setIntegrationModalVisible={setIntegrationModalVisible}
      />
      <PassfolioIntegrationModal
        dependentId={dependentId}
        visible={integrationModalVisible}
        setVisible={setIntegrationModalVisible}
        setLoadingIntegration={setLoading}
        integrationData={integrationData}
      />
    </>
  );
};

export default PassfolioIntegration;
