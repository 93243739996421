import clsx from "clsx";
import React from "react";
import { useLocation } from "react-use";
import { Tooltip, Typography } from "antd";
import { CheckCircleTwoTone, LoadingOutlined } from "@ant-design/icons";
import { Container } from "./styles";
import { useB3Integration } from "../../../contexts/B3IntegrationContext";

interface IIntegrationButtonProps {
  alt: string;
  loading: boolean;
  integrado: boolean;
  setIntegrationModalVisible: Function;
  logo?: string;
  imgHeight?: boolean;
  handleClick?: () => void;
  b3Link?: string;
  isB3LinkRedirect?: boolean;
}

const IntegrationButton: React.FC<IIntegrationButtonProps> = ({
  alt,
  logo,
  b3Link,
  loading,
  integrado,
  imgHeight,
  isB3LinkRedirect,
  handleClick = () => {},
  setIntegrationModalVisible,
}) => {
  const location = useLocation();
  const { timer } = useB3Integration();
  const isB3 = location.pathname === "/xpinvestimentos/bolsa-integration";

  const onClick = () => {
    if (isB3 || location.pathname === "/xpinvestimentos/exterior-integration") {
      handleClick();
    } else if (!loading) {
      setIntegrationModalVisible(true);
    }
  };
  return (
    <Tooltip
      title={integrado ? "Clique para reintegrar" : "Clique para integrar"}
    >
      <Container
        onClick={onClick}
        imgHeight={imgHeight}
        className={clsx({ loading, integrado })}
      >
        {isB3LinkRedirect && b3Link && (
          <a href={b3Link} className="b3-link">
            .
          </a>
        )}
        <img src={logo} alt={alt} />
        <Typography.Paragraph className="click-here">
          Clique aqui
        </Typography.Paragraph>
        {!loading && integrado && (
          <div className="integrado-check">
            <CheckCircleTwoTone twoToneColor="var(--ant-success-color)" />
          </div>
        )}
        {isB3 && loading && (
          <div className="loading-animation">
            <LoadingOutlined color="fff" />
            <span>Verificando autorização com a B3 ({timer} s)</span>
          </div>
        )}
        {!isB3 && loading && (
          <div className="loading-animation">
            <LoadingOutlined color="fff" />
            <span>Isso pode demorar alguns minutos, aguarde</span>
          </div>
        )}
      </Container>
    </Tooltip>
  );
};

export default IntegrationButton;
