import styled from "styled-components";

export const Container = styled.div`
  margin: 0 auto;
  width: 700px;

  button {
    padding-left: 0px !important;
    padding-right: 0px !important;
    min-width: auto;
  }

  &.darf-bolsa,
  &.darf-exterior,
  &.historic-bolsa,
  &.integration-exterior,
  &.historic-exterior {
    margin-top: 24px;
  }

  &.dividendos-exterior {
    margin-top: -24px;
  }

  @media only screen and (max-device-width: 812px) {
    width: 100%;
    &.darf-bolsa,
    &.report-bolsa,
    &.dividendos-exterior,
    &.report-exterior,
    &.darf-exterior {
      padding: 0 24px;
    }
  }
`;
