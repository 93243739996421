import styled, { css } from "styled-components";

export const Container = styled.div`
  padding: 64px 24px;
  @media only screen and (max-device-width: 812px) {
    padding: 24px 24px 64px;
  }
`;

export const Content = styled.div`
  margin: 0 auto;
  max-width: 600px;

  h1 {
    font-weight: 400;
    font-size: 1.75rem;
    position: relative;
    line-height: 2.5rem;
    padding-bottom: 1rem;
    color: var(--velotax-font-color-light);

    :after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 100px;
      background-color: var(--ant-primary-color);
    }
  }

  div.ant-typography {
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--velotax-font-color-light);
  }

  .loading-get {
    margin: 24px 0;
    .anticon.anticon-loading,
    .anticon.anticon-loading svg {
      width: 32px;
      height: 32px;
    }
  }

  .renda-container {
    opacity: 0.8;
    margin-top: 16px;
    font-weight: 400;
    font-size: 0.85rem;
    text-align: justify;
    line-height: 1.4rem;
    color: var(--velotax-font-color-light) !important;
  }
`;

interface QuestionProps {
  show?: boolean;
}

export const Question = styled.div<QuestionProps>`
  width: 100%;
  padding: 32px;
  margin-top: 32px;
  border-radius: 4px;
  background-color: var(--white);

  ${({ show }) =>
    show
      ? css`
          display: block;
          visibility: visible;
        `
      : css`
          display: none;
          overflow: hidden;
          visibility: hidden;
          max-height: 100px;
        `}

  .question-title {
    display: flex;
    align-items: flex-start;
    font-size: 1.5rem;
    margin-bottom: 1rem;

    button.ant-btn-text {
      padding: 4px;
      span,
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .question-buttons {
    margin-top: 24px;
  }

  &,
  .anticon,
  .question-title,
  .ant-radio-wrapper,
  .ant-checkbox + span {
    color: var(--velotax-font-color-dark) !important;
  }

  .ant-tabs {
    color: var(--velotax-font-color) !important;
    .ant-tabs-nav {
      .ant-tabs-nav-wrap {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: 140px;
        .ant-tabs-tab {
          padding: 4px 0;
        }
      }
      .anticon svg {
        color: var(--velotax-font-color-dark) !important;
      }
    }
  }

  .pgbl-info-button {
    padding: 4px;
    font-size: 12px;
    display: flex;
    column-gap: 4px;
    align-items: center;
    font-weight: 500;
    svg {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
    }
  }

  .income-types {
    margin-bottom: 1rem;
    p {
      margin: 0;
      font-size: 1rem;
      display: flex;
      column-gap: 8px;
      span {
      }
    }
  }

  &.resume-container {
    font-size: 1.25rem;
    text-align: center;
    position: relative;
    .ant-typography {
      color: var(--velotax-font-color-dark) !important;
    }
    .assessor-text {
      font-size: 12px;
      margin: 16px 0 0;
      text-align: center;
    }
    button {
      margin-top: 16px;
    }
    .restituicao {
      margin: 16px 0;
      .ant-typography {
        margin-bottom: 0;
        font-size: 0.875rem;
        div {
          padding: 4px;
          font-weight: 500;
          line-height: 1rem;
          background-color: var(--ant-primary-color-hover);
          br {
            display: none;
          }
        }
        strong {
          padding: 4px;
          display: block;
          background-color: var(--ant-primary-color-outline);
        }
      }
    }
    .resume-edit-button {
      top: 32px;
      right: 32px;
      margin: 0;
      z-index: 1;
      position: absolute;
      svg {
        opacity: 0.85;
        fill: var(--velotax-font-color-dark);
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    padding: 24px;
    margin-top: 8px;
    .question-buttons {
      button {
        font-size: 0.85rem;
        letter-spacing: 0.5px;
        padding-left: 16px;
        padding-right: 16px;
      }
    }
    &.resume-container {
      padding: 16px;
      font-size: 0.875rem;
      .restituicao {
        justify-content: center;
        .ant-typography {
          div {
            font-size: 12px;
            font-weight: 600;
            br {
              display: block;
            }
          }
        }
      }
      .resume-edit-button {
        top: 16px;
        right: 16px;
      }
    }
    .small-text {
      font-size: 10px;
      letter-spacing: 0px;
      padding-left: 8px;
      padding-right: 8px;
    }

    .question-title {
      flex-direction: column;
    }
    .pgbl-info-button {
      padding: 4px 0;
      text-align: left;
    }

    .handle-label {
      .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink {
        min-width: 133%;
        white-space: normal;
        transform: translate(0, -12px) scale(0.75);
      }
    }
  }
`;
