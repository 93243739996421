import { Typography } from "antd";

export const DatePickerExtraFooter: React.FC = () => {
  return (
    <>
      <Typography.Text strong>Situação:</Typography.Text>
      <div className="sample-regular">
        <span>Imposto apurado</span>
      </div>
      <div className="sample-pending">
        <span>Imposto devido</span>
      </div>
    </>
  );
};
