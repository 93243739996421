import { FaRegEdit } from "react-icons/fa";
import { BsDownload } from "react-icons/bs";
import NumberFormat from "react-number-format";
import { AiOutlineFileSync } from "react-icons/ai";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Col,
  Divider,
  Form,
  List,
  message,
  Radio,
  Row,
  Select,
  Skeleton,
  Typography,
} from "antd";
import apis from "../../services/apis";
import Button from "../../components/Button";
import { Page } from "../../constants/brokers";
import { PageInfo } from "../../components/PageInfo";
import { PlanModal } from "../../components/PlanModal";
import { PlanEnum, Plans } from "../../constants/plans";
import { useBroker } from "../../contexts/BrokerContext";
import { ConfirmModal, Container, Content } from "./styles";
import {
  MeiReportDasStatusEnum,
  MeiReportStatusTextEnum,
} from "../../constants/das";
import {
  currencyToNumber,
  download,
  errorMessage,
  formatCurrency,
  formatDateHour,
  isSafari,
} from "../../utils";
import {
  validationFieldRequired,
  validationNumberFieldPositive,
} from "../../utils/formValidations";

interface MEIReportData {
  year?: number;
  annualInvoicing?: number;
  hasEmployee?: boolean;
}

interface MEIReportDataBack extends MEIReportData {
  status?: MeiReportDasStatusEnum;
  issueDate?: string;
  createdAt?: string;
  requestDate?: string;
  path?: string;
}

interface MEIReportProps {
  item: Page;
}

export const MeiReport: React.FC<MEIReportProps> = ({ item }) => {
  const currentYear = new Date().getFullYear();
  const [form] = Form.useForm<MEIReportData>();
  const contentElement = useRef<HTMLDivElement>(null);
  const {
    currentBroker,
    integration: { plans },
    currentPage,
  } = useBroker();

  const [year, setYear] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [loadingReports, setLoadingReports] = useState(false);
  const [reports, setReports] = useState<MEIReportDataBack[]>([]);

  const { apiUrl } = item.settings;
  const { initialYear } = currentBroker;

  const yearOptions = Array.from(Array(new Date().getFullYear() - initialYear))
    .map((y, i) => ({ value: initialYear + i, label: `${initialYear + i}` }))
    .reverse();

  const handleModal = () => {
    setShowModal((show) => !show);
    setYear(undefined);
    form.resetFields();
  };

  const getReports = useCallback(() => {
    setLoadingReports(true);
    (currentPage?.api || apis)
      .get<MEIReportDataBack[]>(apiUrl)
      .then((response) => setReports(response.data))
      .catch(() => message.error(errorMessage))
      .finally(() => setLoadingReports(false));
  }, [apiUrl, currentPage]);

  const handleReport = (data: MEIReportData) => {
    setLoading(true);
    (currentPage?.api || apis)
      .post(apiUrl, data)
      .then(() => {
        handleModal();
        getReports();
      })
      .catch(() => message.error(errorMessage))
      .finally(() => setLoading(false));
  };

  const checkIfPayed = (item: MEIReportData) => {
    if (
      plans?.status === "active" &&
      (plans?.name === PlanEnum.BASIC_V1 || plans?.name === PlanEnum.PRO_V1)
    ) {
      handleReport(item);
    } else {
      setShowPlanModal(true);
    }
  };

  const downloadAction = (item: MEIReportDataBack, index: number) => {
    if (item.path) {
      download(item.path);
    }
  };

  const editAction = (item: MEIReportDataBack, index: number) => {
    setYear(item.year);
    form.setFieldsValue({
      year: item.year,
      hasEmployee: item.hasEmployee,
      annualInvoicing: item.annualInvoicing,
    });
    isSafari
      ? contentElement.current?.scrollIntoView()
      : contentElement.current?.scrollIntoView({
          behavior: "smooth",
        });
  };

  const onValuesChange = (changed: any, values: MEIReportData) => {
    setYear(values.year);
    form.setFieldsValue({
      ...values,
      annualInvoicing: changed.annualInvoicing
        ? currencyToNumber(changed.annualInvoicing)
        : values.annualInvoicing,
    });
  };

  useEffect(() => {
    getReports();
  }, [getReports]);

  return (
    <Container>
      <PageInfo
        title="Emita sua declaração MEI (DASN)"
        subtitle={
          <>
            Para evitar multas e bloqueios com a Receita Federal, você precisa
            emitir sua declaração anual{" "}
            <strong>
              <u>até o fim de junho de {currentYear}</u>
            </strong>
          </>
        }
      />
      <Content ref={contentElement}>
        <Typography.Title level={2}>
          Emissão de declaração anual
        </Typography.Title>
        <Typography.Paragraph>
          Selecione um ano para emitir sua declaração anual.
        </Typography.Paragraph>
        <Form
          form={form}
          size="large"
          layout="vertical"
          requiredMark={false}
          onFinish={checkIfPayed}
          onValuesChange={onValuesChange}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item name="year">
                <Select
                  placeholder="Selecione o ano"
                  options={yearOptions}
                  getPopupContainer={(trigger) => trigger.parentElement}
                />
              </Form.Item>
            </Col>
          </Row>
          {year && (
            <>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="annualInvoicing"
                    rules={[
                      ...validationNumberFieldPositive,
                      {
                        validator: (rule, value) =>
                          value > 10000000
                            ? Promise.reject(
                                new Error(
                                  "O valor deve ser menor que R$ 10.000.000,00"
                                )
                              )
                            : Promise.resolve(),
                      },
                    ]}
                    label={`Qual foi seu faturamento bruto em ${year}?`}
                  >
                    <NumberFormat
                      prefix="R$ "
                      decimalScale={2}
                      allowNegative={false}
                      decimalSeparator=","
                      thousandSeparator="."
                      placeholder="R$ 0,00"
                      className="ant-input ant-input-lg"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="hasEmployee"
                    rules={validationFieldRequired}
                    label={`Você teve empregados em ${year}?`}
                  >
                    <Radio.Group>
                      <Radio value={true}>Sim</Radio>
                      <Radio value={false}>Não</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="end">
                <Col span={24}>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="full-width"
                    loading={loading}
                    icon={<></>}
                    style={{ marginBottom: "24px" }}
                  >
                    Emitir relatório
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Form>
        <Divider />
        <Typography.Title level={3}>
          <AiOutlineFileSync size={22} />
          Minhas declarações anuais
        </Typography.Title>
        <List
          dataSource={reports}
          itemLayout="horizontal"
          loading={loadingReports}
          renderItem={(item, index) => (
            <List.Item
              actions={
                item.status === MeiReportDasStatusEnum.EMITTED && item.path
                  ? [
                      <Button
                        type="text"
                        size="large"
                        onClick={() => downloadAction(item, index)}
                        icon={<BsDownload size={16} />}
                      />,
                    ]
                  : [
                      <Button
                        type="text"
                        size="large"
                        onClick={() => editAction(item, index)}
                        icon={<FaRegEdit size={16} />}
                      />,
                    ]
              }
            >
              <Skeleton title={false} loading={loadingReports} active>
                <List.Item.Meta
                  title={`Ano: ${item.year}`}
                  description={
                    <>
                      Status:{" "}
                      {
                        MeiReportStatusTextEnum[
                          item.status as keyof typeof MeiReportStatusTextEnum
                        ]
                      }
                      <br />
                      Teve empregados no ano: {item.hasEmployee ? "Sim" : "Não"}
                      <br />
                      Faturamento anual bruto:{" "}
                      {formatCurrency(item.annualInvoicing ?? 0)}
                      {item.requestDate && <br />}
                      {item.requestDate &&
                        `Solicitação: ${formatDateHour(item.requestDate)}`}
                      {item.issueDate && <br />}
                      {item.issueDate &&
                        item.status === MeiReportDasStatusEnum.EMITTED &&
                        `Emissão: ${formatDateHour(item.issueDate)}`}
                    </>
                  }
                />
              </Skeleton>
            </List.Item>
          )}
        />
        <ConfirmModal
          closable
          footer={null}
          destroyOnClose
          keyboard={false}
          visible={showModal}
          onCancel={handleModal}
          title="Relatório solicitado com sucesso!"
        >
          <Typography.Paragraph className="p">
            Em até <strong>48h úteis</strong> seu relatório será emitido e
            enviado para o seu email de cadastro. <strong>Aguarde!</strong>
          </Typography.Paragraph>
        </ConfirmModal>
        <PlanModal
          visible={showPlanModal}
          title="Contrate o plano para realizar a emissão"
          modalPlans={[Plans.find((plan) => plan.type === PlanEnum.PRO_V1)!]}
          onCancel={() => {
            setShowPlanModal(false);
          }}
        />
      </Content>
    </Container>
  );
};
