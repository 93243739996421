import { months } from "./date";
import { Posicao } from "../contexts/CarteiraContext";

const colors: any = [
  "var(--ant-primary-color)",
  "#121212",
  "#AABBCC",
  "var(--ant-primary-8)",
  "var(--ant-primary-4)",
  "#000058",
];

export const chartColors = [
  { background: "#FCC102", label: "white" },
  { background: "#C8D200", label: "black" },
  { background: "#FF2B01", label: "white" },
  { background: "#E50045", label: "white" },
  { background: "#CC13AF", label: "white" },
  { background: "#5B0084", label: "white" },
  { background: "#79002B", label: "white" },
  { background: "#125E22", label: "white" },
  { background: "#FF7F00", label: "white" },
]
export const getProviderColor = (provider: string, index: number) => {
  while (index > 8) {
    const differnce = index - 8;
    index = differnce - 1;
  }
  return chartColors[index].background;
}

export const getFontColor = (bc: string) => {
  const hex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(bc);
  const rgb = hex
    ? [parseInt(hex[1], 16), parseInt(hex[2], 16), parseInt(hex[3], 16)]
    : [0, 0, 0];

  const brightness = Math.round(
    (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000
  );
  return brightness > 200 ? "#000000" : "#FFFFFF";
};

export const getRandomDarkColor = () => {
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += Math.floor(Math.random() * 10);
  }
  return color;
};

export const getClassesAtivosChart = (carteiraCategorizada: {
  [key: string]: Posicao[];
}) => {
  const getCategoriaAtivos = (categoria: string) => {
    switch (categoria) {
      case "FII - Fundo de Investimento Imobiliário":
        return "FIIs";
      case "Fundos de Investimentos":
        return "FIs";
      case "ETF - Exchange Traded Fund":
        return "ETFs";
      case "BDR - Brazilian Depositary Receipts":
        return "BDRs";
      case "Opcao":
        return "Opções";
      case "Exterior":
        return "XP International";
      case "TesouroDireto":
        return "Tesouro Direto";
      case "undefined":
        return "Outros";
      default:
        return categoria;
    }
  };

  let i = 0;
  let classes: any[] = [];
  for (let index in carteiraCategorizada) {
    const sum = carteiraCategorizada[index].reduce((acc, cur) => {
      return acc + cur.posicao;
    }, 0);
    const name = getCategoriaAtivos(index);
    classes.push({
      name,
      value: sum,
      color: colors[i % colors.length],
    });
    i += 1;
  }
  return classes;
};

export const getCorretoraAtivosChart = (carteiraCategorizada: {
  [key: string]: Posicao[];
}) => {
  const getCorretora = (corretora: string) => {
    switch (corretora) {
      case "XP INVESTIMENTOS CCTVM S/A":
        return "XP";
      case "BANCO BTG PACTUAL S/A":
        return "BTG Pactual";
      case "undefined":
        return "XP International";
      default:
        return corretora;
    }
  };
  let corretoras: any = [];
  for (let ativos of Object.values(carteiraCategorizada)) {
    for (let ativo of ativos) {
      corretoras[ativo.corretora] =
        (corretoras[ativo.corretora] || 0) + ativo.posicao;
    }
  }
  let i = 0;
  let result = [];
  for (let index in corretoras) {
    result.push({
      name: getCorretora(index),
      value: corretoras[index],
      color: colors[i % colors.length],
    });
    i += 1;
  }
  return result;
};

export const getLineChartData = (arr: any[]) => {
  if (!arr) return [];
  const previousDate = arr[0].date.split("/").map((num: any) => Number(num));
  const currentDate = arr[arr.length - 1].date
    .split("/")
    .map((num: any) => Number(num));

  let response = arr.map((i) => {
    return { name: "", value: i.value };
  });

  response[0].name = `${previousDate[0]} ${months[previousDate[1] - 1]} ${previousDate[2]
    }`;
  response[response.length - 1].name = `${currentDate[0]} ${months[currentDate[1] - 1]
    } ${currentDate[2]}`;

  return response;
};
