import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
  }
  .ant-checkbox {
    margin-top: -2px;
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-left: 12px;

    .label {
      margin-left: 6px;
    }
  }

  .loading-wrapper {
    display: flex;
    align-items: center;
    margin-left: -2px;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .ant-checkbox-wrapper {
    margin-left: 0;
    display: flex;
    align-items: center;

    & + label {
      margin-top: 12px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: var(--ant-primary-color);
      border-color: var(--ant-primary-color);
    }

    .ant-checkbox-inner {
      background: transparent;
      border: 2px solid var(--ant-primary-color);
      width: 20px;
      height: 20px;
      border-radius: 2px;
    }
  }

  .ant-checkbox + span {
    margin-left: -10px;
  }
`;
