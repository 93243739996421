import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 64px 32px 64px;

  .juridic-messages-container {
    opacity: 0.8;
    width: 700px;
    margin: 0 auto;
    padding: 32px 0 32px 0;

    .message {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1rem;
    }

    p {
      font-size: 0.85rem;
      font-weight: 400;
      text-align: justify;
      line-height: 1.4rem;
      color: var(--velotax-font-color-light);
      margin-bottom: 0px;

      i {
        font-weight: bold;
        color: var(--velotax-font-color-light);
      }
    }
  }

  @media only screen and (max-device-width: 812px) {
    padding: 0;
    min-height: 100%;

    .page-title {
      font-size: 1.25rem;
      line-height: 1.75rem;
      padding-bottom: 1rem;
    }

    .juridic-messages-container {
      width: calc(100% - 48px);
      margin: 0 24px 48px;
      p {
        margin-left: 0;
      }
    }
  }
`;

export const Content = styled.div`
  padding: 32px;
  width: 700px;
  border-radius: 4px;
  background-color: var(--velotax-background-color);

  .destak {
    font-size: 14px;
    font-weight: 400;
    color: var(--velotax-font-color);
  }

  button.text-small,
  button.text-small:hover,
  button.text-small:focus,
  button.text-small:active,
  button.text-small:visited {
    color: var(--velotax-font-color-dark) !important;
  }
  @media only screen and (max-device-width: 812px) {
    padding: 24px;
    border-radius: 0;
    width: 100%;
    .text-small {
      font-size: 11px;
    }
    h2 {
      &::before {
        left: -24px;
      }
    }
  }
`;
